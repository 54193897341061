import React, { useContext } from 'react';
import { createRoot } from 'react-dom/client';
import * as eva from '@eva-design/eva';
import { registerRootComponent } from 'expo';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';

import Login from './containers/login';
import Pact from './containers/pact';
import Lumen from './containers/lumen';
import ELumen from './containers/elumen';
import Fuse from './containers/fuse';
import EFuse from './containers/efuse';
import DPact from './containers/dpact';
import DLumen from './containers/dlumen';
import EDLumen from './containers/edlumen';
import DFuse from './containers/dfuse';
import EDFuse from './containers/edfuse';
import Chat from './containers/chat';
import Agenda from './containers/agenda';
import AgendaInfo from './containers/agendainfo';
import DAgenda from './containers/dagenda';
import DAgendaInfo from './containers/dagendainfo';
import Task from './containers/task';
import TaskInfo from './containers/taskinfo';
import Shared from './containers/shared';
import Trash from './containers/trash';
import Calendar from './containers/calendar';
import CalendarO from './containers/calendar-organization';
import PactInfo from './containers/pactinfo';
import DPactInfo from './containers/dpactinfo';
import LumenInfo from './containers/lumeninfo';
import ELumenInfo from './containers/elumeninfo';
import DLumenInfo from './containers/dlumeninfo';
import EDLumenInfo from './containers/edlumeninfo';
import FuseInfo from './containers/fuseinfo';
import EFuseInfo from './containers/efuseinfo';
import DFuseInfo from './containers/dfuseinfo';
import EDFuseInfo from './containers/edfuseinfo';
import Profile from './containers/profile';
import Recovery from './containers/recovery';
import { Route, Router, Routes } from './utils/router';
import { Platform } from 'react-native';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthenticationProvider, authContext, User } from './hooks/authentication';
import { NavigationProvider } from './hooks/navigation';

import { default as themeMapping } from './theme';
import { MainLayout } from './components/layout/MainLayout';
import File from './containers/file';
import { SigningProcessProvider } from './hooks/signingprocess';
import { getServerConfig } from './services/configService';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (Array.prototype.at === undefined) {
  Array.prototype.at = function (index: number) {
    return this[index];
  };
}

if (String.prototype.at === undefined) {
  String.prototype.at = function (index: number) {
    return this[index];
  };
}

export const App = () => {
  const auth = useContext(authContext);

  if (auth.user?.accessToken) {
    return (
      <MainLayout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/pacts" element={<Pact />} />
          <Route path="/lumens" element={<Lumen />} />
          <Route path="/elumens" element={<ELumen />} />
          <Route path="/fuses" element={<Fuse />} />
          <Route path="/efuses" element={<EFuse />} />
          <Route path="/dpacts" element={<DPact />} />
          <Route path="/dlumens" element={<DLumen />} />
          <Route path="/edlumens" element={<EDLumen />} />
          <Route path="/dfuses" element={<DFuse />} />
          <Route path="/edfuses" element={<EDFuse />} />
          <Route path="/files" element={<File />} />
          <Route path="/shared" element={<Shared />} />
          <Route path="/trash" element={<Trash />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/agendas" element={<Agenda />} />
          <Route path="/agendas/:id" element={<AgendaInfo />} />
          <Route path="/dagendas" element={<DAgenda />} />
          <Route path="/dagendas/:id" element={<DAgendaInfo />} />
          <Route path="/tasks" element={<Task />} />
          <Route path="/tasks/:id" element={<TaskInfo />} />
          <Route path="/schedule" element={<Calendar />} />
          <Route path="/scheduleO" element={<CalendarO />} />
          <Route path="/pacts/:id" element={<PactInfo />} />
          <Route path="/dpacts/:id" element={<DPactInfo />} />
          <Route path="/lumens/:id" element={<LumenInfo />} />
          <Route path="/elumens/:id" element={<ELumenInfo />} />
          <Route path="/dlumens/:id" element={<DLumenInfo />} />
          <Route path="/edlumens/:id" element={<EDLumenInfo />} />
          <Route path="/fuses/:id" element={<FuseInfo />} />
          <Route path="/efuses/:id" element={<EFuseInfo />} />
          <Route path="/dfuses/:id" element={<DFuseInfo />} />
          <Route path="/edfuses/:id" element={<EDFuseInfo />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/recovery" element={<Recovery />} />
        </Routes>
      </MainLayout>
    );
  }

  if (window.location.pathname?.search('/recovery') >= 0) return <Recovery />;
  return <Login />;
};

export const Root = () => {
  const [isLoadingConfig, setIsLoadingConfig] = React.useState(true);

  React.useEffect(() => {
    getServerConfig().then((config) => {
      window.backendConfig = config.data;
      setIsLoadingConfig(false);
    });
  }, []);

  if (isLoadingConfig) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <AuthenticationProvider>
        <SigningProcessProvider>
          <NavigationProvider>
            <Router>
              <IconRegistry icons={EvaIconsPack} />
              <ApplicationProvider {...eva} theme={eva.light} customMapping={themeMapping as any}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </ApplicationProvider>
            </Router>
          </NavigationProvider>
        </SigningProcessProvider>
      </AuthenticationProvider>
    </React.Fragment>
  );
};

if (Platform.OS == 'web') {
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  rootTag.render(React.createElement(Root));
} else {
  registerRootComponent(Root);
}
