export const isTypePheDuyet = (type ,candidateType, candidateId, currentUserId, units, cohorts) => {
    if (type === 'CQ' && units?.find(unit => unit?._id === 'DPM-0013')?.members?.find(userId => userId === currentUserId) === undefined)
        return true;

    if (type === 'DU' && units?.find(unit => unit?._id === 'DPM-0050')?.members?.find(userId => userId === currentUserId) === undefined)
        return true;

    if (candidateType === 'user' && candidateId === currentUserId)
        return true;

    if (candidateType === 'cohort' && cohorts?.find(cohort => cohort?._id === candidateId && cohort?.members?.find(userId => userId === currentUserId) != undefined) != undefined)
        return true;

    if (candidateType === 'unit' && units?.find(unit => unit?._id === candidateId && unit?.members?.find(userId => userId === currentUserId) != undefined) != undefined)
        return true;
    
    return false;
}