import { Button, Divider, IndexPath, Layout, Menu, MenuGroup, MenuItem, Modal, Text } from '@ui-kitten/components';
import { Asset } from 'expo-asset';
import { Image } from 'expo-image';
import React, { useContext, useEffect, useRef } from 'react';
import { Animated, Platform, ScrollView, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import MFeatherIcon from 'react-native-vector-icons/Feather';
import FAIcon from 'react-native-vector-icons/FontAwesome5';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-native';
import { getBuildNumber } from '../../config';
import { authContext } from '../../hooks/authentication';
import { EBottomTab, navigationContext } from '../../hooks/navigation';
import { signContext } from '../../hooks/signingprocess';
import { getdFuse, getdLumen, getdPact, getFuse, getLumen, getPact, specId } from '../../services/signingprocess';
import { EjectWhiteIcon } from '../../share/filter-icon';

const SignOutIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 512 512">
    <Path
      d="M352 146.2L462 256 352 365.8l0-53.8c0-13.3-10.7-24-24-24l-120 0 0-64 120 0c13.3 0 24-10.7 24-24l0-53.8zM512 256c0-11.5-4.6-22.5-12.7-30.6L383.2 109.6c-8.7-8.7-20.5-13.6-32.8-13.6c-25.6 0-46.4 20.8-46.4 46.4l0 33.6-96 0c-26.5 0-48 21.5-48 48l0 64c0 26.5 21.5 48 48 48l96 0 0 33.6c0 25.6 20.8 46.4 46.4 46.4c12.3 0 24.1-4.9 32.8-13.6L499.3 286.6c8.1-8.1 12.7-19.1 12.7-30.6zM168 80c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l80 0z"
      fill="#424242"
    />
  </Svg>
);
const FeatherIcon = (props) => <FAIcon {...props} style={styles.icon} name="feather-alt" color="#424242" />;
const FeatherBlackIcon = (props) => <FAIcon {...props} style={styles.icon} name="feather-alt" color="#424242" />;
const TelegramIcon = (props) => <FAIcon {...props} style={styles.icon} name="paper-plane" color="#424242" />;
const TelegramBlackIcon = (props) => <FAIcon {...props} style={styles.icon} name="paper-plane" color="#424242" />;
const FileIcon = (props) => <FAIcon {...props} style={styles.icon} name="file" color="#424242" />;
const FileBlackIcon = (props) => <FAIcon {...props} style={styles.icon} name="file" color="#424242" />;
const GuideIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 512 512">
    <Path
      d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
      fill="#424242"
    />
  </Svg>
);
const SettingIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 512 512">
    <Path
      d="M256 0c17 0 33.6 1.7 49.8 4.8c7.9 1.5 21.8 6.1 29.4 20.1c2 3.7 3.6 7.6 4.6 11.8l9.3 38.5C350.5 81 360.3 86.7 366 85l38-11.2c4-1.2 8.1-1.8 12.2-1.9c16.1-.5 27 9.4 32.3 15.4c22.1 25.1 39.1 54.6 49.9 86.3c2.6 7.6 5.6 21.8-2.7 35.4c-2.2 3.6-4.9 7-8 10L459 246.3c-4.2 4-4.2 15.5 0 19.5l28.7 27.3c3.1 3 5.8 6.4 8 10c8.2 13.6 5.2 27.8 2.7 35.4c-10.8 31.7-27.8 61.1-49.9 86.3c-5.3 6-16.3 15.9-32.3 15.4c-4.1-.1-8.2-.8-12.2-1.9L366 427c-5.7-1.7-15.5 4-16.9 9.8l-9.3 38.5c-1 4.2-2.6 8.2-4.6 11.8c-7.7 14-21.6 18.5-29.4 20.1C289.6 510.3 273 512 256 512s-33.6-1.7-49.8-4.8c-7.9-1.5-21.8-6.1-29.4-20.1c-2-3.7-3.6-7.6-4.6-11.8l-9.3-38.5c-1.4-5.8-11.2-11.5-16.9-9.8l-38 11.2c-4 1.2-8.1 1.8-12.2 1.9c-16.1 .5-27-9.4-32.3-15.4c-22-25.1-39.1-54.6-49.9-86.3c-2.6-7.6-5.6-21.8 2.7-35.4c2.2-3.6 4.9-7 8-10L53 265.7c4.2-4 4.2-15.5 0-19.5L24.2 218.9c-3.1-3-5.8-6.4-8-10C8 195.3 11 181.1 13.6 173.6c10.8-31.7 27.8-61.1 49.9-86.3c5.3-6 16.3-15.9 32.3-15.4c4.1 .1 8.2 .8 12.2 1.9L146 85c5.7 1.7 15.5-4 16.9-9.8l9.3-38.5c1-4.2 2.6-8.2 4.6-11.8c7.7-14 21.6-18.5 29.4-20.1C222.4 1.7 239 0 256 0zM218.1 51.4l-8.5 35.1c-7.8 32.3-45.3 53.9-77.2 44.6L97.9 120.9c-16.5 19.3-29.5 41.7-38 65.7l26.2 24.9c24 22.8 24 66.2 0 89L59.9 325.4c8.5 24 21.5 46.4 38 65.7l34.6-10.2c31.8-9.4 69.4 12.3 77.2 44.6l8.5 35.1c24.6 4.5 51.3 4.5 75.9 0l8.5-35.1c7.8-32.3 45.3-53.9 77.2-44.6l34.6 10.2c16.5-19.3 29.5-41.7 38-65.7l-26.2-24.9c-24-22.8-24-66.2 0-89l26.2-24.9c-8.5-24-21.5-46.4-38-65.7l-34.6 10.2c-31.8 9.4-69.4-12.3-77.2-44.6l-8.5-35.1c-24.6-4.5-51.3-4.5-75.9 0zM208 256a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192z"
      fill="#424242"
    />
  </Svg>
);
const ProfileIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 448 512">
    <Path
      d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
      fill="#424242"
    />
  </Svg>
);
const XIcon = (props) => <FAIcon {...props} style={styles.icon} name="times" color="#FFFFFF" />;

const INFOIcon = (props) => (
  <Svg width={14} height={14} viewBox="0 0 512 512">
    <Path
      d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"
      fill="#FFFFFF"
    />
  </Svg>
);
const InfoIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 512 512">
    <Path
      d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
      fill="#424242"
    />
  </Svg>
);
const DriveIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 640 512">
    <Path
      d="M410.8 134.2c-19.3 8.6-42 3.5-55.9-12.5C332.8 96.1 300.3 80 264 80c-66.3 0-120 53.7-120 120c0 0 0 0 0 0s0 0 0 0l0 .2c0 20.4-12.8 38.5-32 45.3C74.6 258.7 48 294.3 48 336c0 53 43 96 96 96H504h3.3c.6-.1 1.3-.1 1.9-.2c46.2-2.7 82.8-41 82.8-87.8c0-36-21.6-67.1-52.8-80.7c-20.1-8.8-31.6-30-28.1-51.7c.6-3.8 .9-7.7 .9-11.7c0-39.8-32.2-72-72-72c-10.5 0-20.4 2.2-29.2 6.2zM512 479.8v.2h-8H464 144C64.5 480 0 415.5 0 336c0-62.7 40.1-116 96-135.8l0-.2c0-92.8 75.2-168 168-168c50.9 0 96.4 22.6 127.3 58.3C406.2 83.7 422.6 80 440 80c66.3 0 120 53.7 120 120c0 6.6-.5 13-1.5 19.3c48 21 81.5 68.9 81.5 124.7c0 72.4-56.6 131.6-128 135.8z"
      fill="#424242"
    />
  </Svg>
);
const AgendaIcon = (props) => <FAIcon {...props} style={styles.icon} name="briefcase" color="#424242" />;
const DAgendaIcon = (props) => <FAIcon {...props} style={styles.icon} name="landmark" color="#424242" />;
const TrashIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 448 512">
    <Path
      d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"
      fill="#424242"
    />
  </Svg>
);
const SharedIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 448 512">
    <Path
      d="M448 128c0 53-43 96-96 96c-28.9 0-54.8-12.8-72.4-33l-89.7 44.9c1.4 6.5 2.1 13.2 2.1 20.1s-.7 13.6-2.1 20.1L279.6 321c17.6-20.2 43.5-33 72.4-33c53 0 96 43 96 96s-43 96-96 96s-96-43-96-96c0-6.9 .7-13.6 2.1-20.1L168.4 319c-17.6 20.2-43.5 33-72.4 33c-53 0-96-43-96-96s43-96 96-96c28.9 0 54.8 12.8 72.4 33l89.7-44.9c-1.4-6.5-2.1-13.2-2.1-20.1c0-53 43-96 96-96s96 43 96 96zM96 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM400 128a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM352 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
      fill="#424242"
    />
  </Svg>
);
const OpenBookIcon = (props) => <MFeatherIcon {...props} style={styles.icon} name="repeat" color="#424242" />;
const SankeyIcon = (props) => (
  <Svg width={21} height={14} viewBox="0 0 576 512">
    <Path
      d="M294.4 44.8c6-8.1 15.5-12.8 25.6-12.8H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H336L249.6 211.2c-6 8.1-15.5 12.8-25.6 12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H208L294.4 44.8zM141.9 320H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H216.5l57.6 96H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-11.2 0-21.7-5.9-27.4-15.5L141.9 320z"
      fill="#424242"
    />
  </Svg>
);
const StarIcon = (props) => <FAIcon {...props} style={styles.icon} name="khanda" color="#424242" />;

const ImageMenuHeader = (props) => (
  <Svg width={20} height={20} viewBox="0 0 512 512">
    <Path
      d="M54.32 98.69C20.4 142.1 0 196.6 0 256c0 22.14 3.105 43.51 8.391 64h173.7L54.32 98.69zM493.2 160c-29.6-73.06-91.91-129.2-168.8-150.5L237.5 160H493.2zM291.4 2.727C279.8 1.119 268 0 256 0C186.1 0 122.9 28.05 76.69 73.42l86.93 150.6L291.4 2.727zM503.6 192h-173.7l127.8 221.3C491.6 369.9 512 315.4 512 256C512 233.9 508.9 212.5 503.6 192zM18.78 352c29.6 73.06 91.91 129.2 168.8 150.5L274.5 352H18.78zM220.6 509.3C232.2 510.9 243.1 512 256 512c69.86 0 133.1-28.05 179.3-73.42l-86.93-150.6L220.6 509.3z"
      fill="#01458E"
    />
  </Svg>
);

const AvatarSmallImage = (props) => (
  <Image {...props} style={styles.imagesmall} contentFit="contain" source={require('../../images/avatar.png')} transition={0} />
);

export const SideBar = () => {
  const [isWaiting, setIsWaiting] = React.useState(true);

  useEffect(() => {
    setTimeout(() => setIsWaiting(false), 10);
  }, []);

  const navigation = useContext(navigationContext);
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const { units, cohorts } = useContext(authContext);

  const getBaseData = async ({ queryKey }) => {
    const [_, prodId] = queryKey;
    // if (sign.sideBarState.flag) return null;
    const apiData = [
      navigation.state.role.isCQShow ? getPact(prodId) : null,
      getLumen(prodId),
      getFuse(prodId),
      getdPact(prodId),
      getdLumen(prodId),
      getdFuse(prodId),
    ];
    return await Promise.all(apiData);
  };

  const allData = useQuery(['all', specId.process], getBaseData);

  useEffect(() => {
    if (allData.isFetching) return;
    if (allData.data === undefined) return;

    const [pactData, lumenData, fuseData, dpactData, dlumenData, dfuseData] = allData.data;
    let pactNumber = 0;
    if (navigation.state.role.isCQShow) {
      let num = 0;

      pactData?.map((item) => {
        if (item.pendingForUsers?.find((id) => id === auth.user._id) != undefined) num += 1;

        if (
          item?.pendingForCohorts?.length > 0 &&
          item.pendingForCohorts?.find(
            (pcohort) =>
              cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
              undefined
          ) != undefined
        )
          num += 1;
      });
      pactNumber = num;
    }

    let num = 0;
    lumenData?.map((item) => {
      if (item.pendingForUsers?.find((id) => id === auth.user._id) != undefined) num += 1;

      if (
        item?.pendingForCohorts?.length > 0 &&
        item.pendingForCohorts?.find(
          (pcohort) =>
            cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;
    });
    const lumenNumber = num;

    num = 0;
    fuseData?.map((item) => {
      if (item.pendingForUsers?.find((id) => id === auth.user._id) != undefined) num += 1;

      if (
        item?.pendingForCohorts?.length > 0 &&
        item.pendingForCohorts?.find(
          (pcohort) =>
            cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;
    });
    const fuseNumber = num;

    num = 0;
    dpactData?.map((item) => {
      if (item.pendingForUsers?.find((id) => id === auth.user._id) != undefined) num += 1;

      if (
        item?.pendingForUnits?.find(
          (unitid) =>
            units?.find((unit) => unit._id === unitid && unit?.members?.find((userid) => userid === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;

      if (
        item?.pendingForCohorts?.length > 0 &&
        item.pendingForCohorts?.find(
          (pcohort) =>
            cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;
    });
    const dpactNumber = num;

    num = 0;
    dlumenData?.map((item) => {
      if (item.pendingForUsers?.find((id) => id === auth.user._id) != undefined) num += 1;

      if (
        item?.pendingForUnits?.find(
          (unitid) =>
            units?.find((unit) => unit._id === unitid && unit?.members?.find((userid) => userid === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;

      if (
        item?.pendingForCohorts?.length > 0 &&
        item.pendingForCohorts?.find(
          (pcohort) =>
            cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;
    });
    const dlumenNumber = num;

    num = 0;
    dfuseData?.map((item) => {
      if (item.pendingForUsers?.find((id) => id === auth.user._id) != undefined) num += 1;

      if (
        item?.pendingForUnits?.find(
          (unitid) =>
            units?.find((unit) => unit._id === unitid && unit?.members?.find((userid) => userid === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;

      if (
        item?.pendingForCohorts?.length > 0 &&
        item.pendingForCohorts?.find(
          (pcohort) =>
            cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
            undefined
        ) != undefined
      )
        num += 1;
    });
    const dfuseNumber = num;

    sign.setSideBarState({ flag: true, pactNumber, lumenNumber, fuseNumber, dpactNumber, dlumenNumber, dfuseNumber });
  }, [allData.isFetched, allData.isFetching, sign.sideBarState.flag, cohorts, units]);

  const pact = () => {
    return sign.sideBarState.pactNumber === 0 ? null : (
      <View style={styles.notSeenView}>
        <Text style={styles.textNumber}>{sign.sideBarState.pactNumber}</Text>
      </View>
    );
  };
  const lumen = () => {
    return sign.sideBarState.lumenNumber === 0 ? null : (
      <View style={styles.notSeenView}>
        <Text style={styles.textNumber}>{sign.sideBarState.lumenNumber}</Text>
      </View>
    );
  };
  const fuse = () => {
    return sign.sideBarState.fuseNumber === 0 ? null : (
      <View style={styles.notSeenView}>
        <Text style={styles.textNumber}>{sign.sideBarState.fuseNumber}</Text>
      </View>
    );
  };

  const dpact = () => {
    return sign.sideBarState.dpactNumber === 0 ? null : (
      <View style={styles.notSeenView}>
        <Text style={styles.textNumber}>{sign.sideBarState.dpactNumber}</Text>
      </View>
    );
  };
  const dlumen = () => {
    return sign.sideBarState.dlumenNumber === 0 ? null : (
      <View style={styles.notSeenView}>
        <Text style={styles.textNumber}>{sign.sideBarState.dlumenNumber}</Text>
      </View>
    );
  };
  const dfuse = () => {
    return sign.sideBarState.dfuseNumber === 0 ? null : (
      <View style={styles.notSeenView}>
        <Text style={styles.textNumber}>{sign.sideBarState.dfuseNumber}</Text>
      </View>
    );
  };

  const state = useLocation();
  const [isShowInfo, setIsShowInfo] = React.useState(false);

  const date = new Date();

  useEffect(() => {
    if (state.pathname.slice(0, 6) === '/pacts') {
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(0, 0), true);
    } else if (state.pathname.slice(0, 7) === '/lumens') {
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(1, 0), true);
    } else if (state.pathname.slice(0, 6) === '/fuses') {
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(2, 0), true);
    } else if (state.pathname.slice(0, 8) === '/elumens') {
      if (navigation.state.role.isCQShow) navigation.setSideBarState(EBottomTab.PACT, new IndexPath(0, 1), true);
      else navigation.setSideBarState(EBottomTab.PACT, new IndexPath(0, 0), true);
    } else if (state.pathname.slice(0, 7) === '/efuses') {
      if (navigation.state.role.isCQShow) navigation.setSideBarState(EBottomTab.PACT, new IndexPath(1, 1), true);
      else navigation.setSideBarState(EBottomTab.PACT, new IndexPath(1, 0), true);
    } else if (state.pathname.slice(0, 7) === '/dpacts') {
      let count = 0;
      if (navigation.state.role.isCQShow) count += 1;
      if (navigation.state.role.isDVShow) count += 1;
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(0, count), true);
    } else if (state.pathname.slice(0, 8) === '/dlumens') {
      let count = 0;
      if (navigation.state.role.isCQShow) count += 1;
      if (navigation.state.role.isDVShow) count += 1;
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(1, count), true);
    } else if (state.pathname.slice(0, 7) === '/dfuses') {
      let count = 0;
      if (navigation.state.role.isCQShow) count += 1;
      if (navigation.state.role.isDVShow) count += 1;
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(2, count), true);
    } else if (state.pathname.slice(0, 9) === '/edlumens') {
      let count = 0;
      if (navigation.state.role.isCQShow) count += 1;
      if (navigation.state.role.isDVShow) count += 1;
      if (navigation.state.role.isDangShow) count += 1;
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(0, count), true);
    } else if (state.pathname.slice(0, 8) === '/edfuses') {
      let count = 0;
      if (navigation.state.role.isCQShow) count += 1;
      if (navigation.state.role.isDVShow) count += 1;
      if (navigation.state.role.isDangShow) count += 1;
      navigation.setSideBarState(EBottomTab.PACT, new IndexPath(1, count), true);
    } else if (state.pathname.slice(0, 6) === '/files') {
      navigation.setSideBarState(EBottomTab.FILE, new IndexPath(0), true);
    } else if (state.pathname.slice(0, 7) === '/shared') {
      navigation.setSideBarState(EBottomTab.FILE, new IndexPath(1), true);
    } else if (state.pathname.slice(0, 6) === '/trash') {
      navigation.setSideBarState(EBottomTab.FILE, new IndexPath(2), true);
    } else if (state.pathname.slice(0, 10) === '/scheduleO') {
      navigation.setSideBarState(EBottomTab.SCHEDULE, new IndexPath(1), true);
    } else if (state.pathname.slice(0, 9) === '/schedule') {
      navigation.setSideBarState(EBottomTab.SCHEDULE, new IndexPath(0), true);
    } else if (state.pathname.slice(0, 8) === '/agendas') {
      navigation.setSideBarState(EBottomTab.MEETING, new IndexPath(0), true);
    } else if (state.pathname.slice(0, 9) === '/dagendas') {
      navigation.setSideBarState(EBottomTab.MEETING, new IndexPath(1), true);
    } else if (state.pathname.slice(0, 6) === '/tasks') {
      navigation.setSideBarState(EBottomTab.TASK, new IndexPath(0), true);
    } else if (state.pathname.slice(0, 8) === '/profile') {
      navigation.setSideBarState(EBottomTab.PROFILE, new IndexPath(0), true);
    }
  }, [state]);

  const downloadFileHD = async () => {
    if (Platform.OS === 'web') {
      window.open('/res/help.pdf');
    }
  };

  const SIDE_BAR_ANIMATION_TIME = 100;
  const [willClose, setWillClose] = React.useState(false);
  const moveAnimationToLeft = useRef(new Animated.Value(-screen.width * 0.7)).current;
  const changeColorAnimation = useRef(new Animated.Value(0)).current;

  const closeSideBar = (path = undefined) => {
    setWillClose(true);
    setTimeout(() => {
      setWillClose(false);
      navigation.setShowSideBar(false);
    }, SIDE_BAR_ANIMATION_TIME);
  };

  useEffect(() => {
    moveAnimationToLeft.setValue(willClose ? 0 : -screen.width * 0.7);
    Animated.timing(moveAnimationToLeft, {
      toValue: willClose ? -screen.width * 0.7 : 0,
      duration: SIDE_BAR_ANIMATION_TIME,
      useNativeDriver: true,
    }).start();

    changeColorAnimation.setValue(willClose ? 1 : 0);
    Animated.timing(changeColorAnimation, {
      toValue: willClose ? 0 : 1,
      duration: SIDE_BAR_ANIMATION_TIME,
      useNativeDriver: false,
    }).start();
  }, [isShowInfo, willClose]);

  const backgroundColor = changeColorAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(255, 255, 255, 0)', 'rgba(66, 66, 66, 0.63)'],
  });

  return (
    <Animated.View
      style={{
        ...styles.layoutMenuBar,
        zIndex: isWaiting ? -999 : 999,
        backgroundColor: isShowInfo ? 'rgba(66, 66, 66, 0.63)' : backgroundColor,
      }}
    >
      {!isShowInfo && (
        <Button
          appearance="ghost"
          style={styles.closeMenuButton}
          onPress={(event) => {
            setTimeout(() => {
              closeSideBar();
            }, 50);
          }}
        ></Button>
      )}
      <Modal
        visible={isShowInfo}
        onBackdropPress={() => {
          setTimeout(() => {
            closeSideBar();
          }, 50);
        }}
        style={styles.modelIOC}
      >
        <Layout style={{ borderRadius: 3, overflow: 'hidden' }}>
          <Layout style={styles.layoutIOC1}>
            <View style={styles.iocHeader}>
              <INFOIcon></INFOIcon>
              <View style={{ padding: 5 }}></View>
              <Text style={{ color: 'white', fontWeight: '600' }}>Về IOC-Office</Text>
            </View>
            <View>
              <Button
                appearance="ghost"
                size="small"
                onPress={() =>
                  setTimeout(() => {
                    navigation.setShowSideBar(false);
                  }, 50)
                }
                accessoryLeft={EjectWhiteIcon}
              ></Button>
            </View>
          </Layout>
          <Layout style={styles.layoutIOC2}>
            <View style={styles.iocContent}>
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `IOC e-Office © <strong>VNUHCM</strong> 2022 ${
                      date.getFullYear() != 2022 ? '- ' + JSON.stringify(date.getFullYear()) : ''
                    }\n`,
                  }}
                ></div>
              </Text>
              <View style={{ padding: 3 }}></View>
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Version ${getBuildNumber()}`,
                  }}
                ></div>
              </Text>
            </View>
            <View style={{ padding: 7 }}></View>
            <View style={{ width: '100%', height: 1, backgroundColor: '#dee2e6' }} />
            <View style={{ padding: 3.5 }}></View>
            <View style={styles.iocButton}>
              <Button
                size="tiny"
                style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                onPress={() =>
                  setTimeout(() => {
                    navigation.setShowSideBar(false);
                  }, 50)
                }
              >
                OK
              </Button>
            </View>
          </Layout>
        </Layout>
      </Modal>
      {!isShowInfo && (
        <Animated.View style={{ ...styles.layoutDisplayMenuBar, transform: [{ translateX: moveAnimationToLeft }] }}>
          <View style={styles.maxLayout}>
            <View style={styles.layoutHeader}>
              <View
                style={{
                  display: 'flex',
                  alignContent: 'space-between',
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                <View style={{ flexGrow: 1 }}>
                  <Text style={{ color: '#2C3A47', fontWeight: '600', fontSize: 23 }}>IOC Office</Text>
                </View>
                <View>
                  <Text style={{ color: '#2C3A47', fontSize: 12 }}>Build {getBuildNumber()}</Text>
                </View>
              </View>
            </View>
            <ScrollView style={styles.maxLayout}>
              <Menu
                style={styles.layoutMenu}
                selectedIndex={navigation.state.sideBarState[navigation.state.bottomTab]}
                onSelect={(index) => {
                  if (navigation.state.bottomTab === EBottomTab.PACT) {
                    let count = 0;
                    if (navigation.state.role.isCQShow) count += 1;
                    if (navigation.state.role.isDangShow) count += 1;
                    if (navigation.state.role.isDVShow) count += 1;
                    if (navigation.state.role.isCSDShow) count += 1;
                    if (index.row === 2 + count) return;
                    if (index.row < count && index.section === undefined) return;
                  } else if (navigation.state.bottomTab === EBottomTab.FILE && index.row === 5) return;
                  else if (navigation.state.bottomTab === EBottomTab.SCHEDULE && index.row === 4) return;
                  else if (navigation.state.bottomTab === EBottomTab.MEETING && index.row === 4) return;
                  else if (navigation.state.bottomTab === EBottomTab.TASK && index.row === 3) return;
                  else if (navigation.state.bottomTab === EBottomTab.CHAT && index.row === 2) return;
                  else if (navigation.state.bottomTab === EBottomTab.PROFILE && index.row === 3) return;

                  setWillClose(true);
                  setTimeout(() => {
                    setWillClose(false);
                    navigation.setSideBarState(navigation.state.bottomTab, index, false);
                  }, SIDE_BAR_ANIMATION_TIME);
                }}
              >
                {navigation.state.bottomTab === EBottomTab.PACT && navigation.state.role.flag && navigation.state.role.isCQShow && (
                  <MenuGroup
                    title={
                      <View>
                        <Text style={styles.text}>Khối cơ quan</Text>
                      </View>
                    }
                    initiallyExpanded={true}
                  >
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Xin ý kiến</Text>
                        </View>
                      }
                      accessoryLeft={FeatherIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('pacts');
                      }}
                      accessoryRight={pact}
                    />
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản đi</Text>
                        </View>
                      }
                      accessoryLeft={TelegramIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('lumens');
                      }}
                      accessoryRight={lumen}
                    />
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản đến</Text>
                        </View>
                      }
                      accessoryLeft={FileIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('fuses');
                      }}
                      accessoryRight={fuse}
                    />
                  </MenuGroup>
                )}
                {navigation.state.bottomTab === EBottomTab.PACT && navigation.state.role.flag && navigation.state.role.isDVShow && (
                  <MenuGroup
                    title={
                      <View>
                        <Text style={styles.text}>Khối đơn vị</Text>
                      </View>
                    }
                    initiallyExpanded={true}
                  >
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản từ VNUHCM</Text>
                        </View>
                      }
                      accessoryLeft={TelegramBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('elumens');
                      }}
                      accessoryRight={lumen}
                    />
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản gửi VNUHCM</Text>
                        </View>
                      }
                      accessoryLeft={FileBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('efuses');
                      }}
                      accessoryRight={fuse}
                    />
                  </MenuGroup>
                )}

                {navigation.state.bottomTab === EBottomTab.PACT && navigation.state.role.flag && navigation.state.role.isDangShow && (
                  <MenuGroup
                    title={
                      <View>
                        <Text style={styles.text}>Khối Đảng uỷ</Text>
                      </View>
                    }
                    initiallyExpanded={true}
                  >
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Xin ý kiến</Text>
                        </View>
                      }
                      accessoryLeft={FeatherBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('dpacts');
                      }}
                      accessoryRight={dpact}
                    />
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản đi</Text>
                        </View>
                      }
                      accessoryLeft={TelegramBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('dlumens');
                      }}
                      accessoryRight={dlumen}
                    />
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản đến</Text>
                        </View>
                      }
                      accessoryLeft={FileBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('dfuses');
                      }}
                      accessoryRight={dfuse}
                    />
                  </MenuGroup>
                )}

                {navigation.state.bottomTab === EBottomTab.PACT && navigation.state.role.flag && navigation.state.role.isCSDShow && (
                  <MenuGroup
                    title={
                      <View>
                        <Text style={styles.text}>Cơ sở Đảng trực thuộc</Text>
                      </View>
                    }
                    initiallyExpanded={true}
                  >
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản từ VNUHCM</Text>
                        </View>
                      }
                      accessoryLeft={TelegramBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('edlumens');
                      }}
                      accessoryRight={dlumen}
                    />
                    <MenuItem
                      style={styles.menuItem}
                      title={
                        <View>
                          <Text style={styles.text}>Văn bản gửi VNUHCM</Text>
                        </View>
                      }
                      accessoryLeft={FileBlackIcon}
                      onPress={() => {
                        // Reset Filter
                        navigation.setIndexState(-1);
                        navigate('edfuses');
                      }}
                      accessoryRight={dfuse}
                    />
                  </MenuGroup>
                )}

                {navigation.state.bottomTab === EBottomTab.MEETING && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Họp Ban Giám đốc</Text>
                      </View>
                    }
                    accessoryLeft={AgendaIcon}
                    onPress={() => {
                      // Reset Filter
                      navigation.setIndexState(-1);
                      navigate('agendas');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.MEETING && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Họp Đảng Ủy</Text>
                      </View>
                    }
                    accessoryLeft={DAgendaIcon}
                    onPress={() => {
                      // Reset Filter
                      navigation.setIndexState(-1);
                      navigate('dagendas');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.FILE && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Drive</Text>
                      </View>
                    }
                    accessoryLeft={DriveIcon}
                    onPress={() => {
                      navigate('files');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.FILE && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Shared</Text>
                      </View>
                    }
                    accessoryLeft={SharedIcon}
                    onPress={() => {
                      navigate('shared');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.FILE && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Trash</Text>
                      </View>
                    }
                    accessoryLeft={TrashIcon}
                    onPress={() => {
                      navigate('trash');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.SCHEDULE && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Lịch của lãnh đạo</Text>
                      </View>
                    }
                    accessoryLeft={StarIcon}
                    onPress={() => {
                      navigate('schedule');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.SCHEDULE && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Hoạt động chung</Text>
                      </View>
                    }
                    accessoryLeft={OpenBookIcon}
                    onPress={() => {
                      navigate('scheduleO');
                    }}
                  />
                )}
                {navigation.state.bottomTab === EBottomTab.TASK && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Chỉ đạo của BGĐ</Text>
                      </View>
                    }
                    accessoryLeft={SankeyIcon}
                    onPress={() => {
                      // Reset Filter
                      navigation.setIndexState(-1);
                      navigate('tasks');
                    }}
                  />
                )}

                {navigation.state.bottomTab === EBottomTab.PROFILE && (
                  <MenuItem
                    style={styles.menuGroup}
                    title={
                      <View>
                        <Text style={styles.text}>Thông tin tài khoản</Text>
                      </View>
                    }
                    accessoryLeft={ProfileIcon}
                    onPress={() => navigate('profile')}
                  />
                )}
                <MenuItem
                  style={styles.menuGroup}
                  title={
                    <View>
                      <Text style={styles.text}>Thiết lập sử dụng</Text>
                    </View>
                  }
                  accessoryLeft={SettingIcon}
                  onPress={() => {}}
                />
                <MenuItem
                  style={styles.menuGroup}
                  title={
                    <View>
                      <Text style={styles.text}>Hướng dẫn sử dụng</Text>
                    </View>
                  }
                  accessoryLeft={GuideIcon}
                  onPress={() => {
                    window.open('/filepreview.html?help.pdf','_blank');
                  }}
                />
                <MenuItem
                  style={styles.menuGroup}
                  title={
                    <View>
                      <Text style={styles.text}>Thông tin về IOC e-Office</Text>
                    </View>
                  }
                  accessoryLeft={InfoIcon}
                  onPress={() => {
                    setIsShowInfo(true);
                  }}
                />

                <Divider style={styles.dividerline} />
                <MenuItem
                  style={styles.menuGroup}
                  title={
                    <View>
                      <Text style={styles.text}>Đăng xuất</Text>
                    </View>
                  }
                  accessoryLeft={SignOutIcon}
                  onPress={() => {
                    navigation.setRole(false, false, false, false, false);
                    window?.ReactNativeWebView?.postMessage('Data from WebView / Website');
                    auth.signOut();
                    navigate('/login');
                    window.location.reload();
                  }}
                />
              </Menu>
            </ScrollView>
          </View>
        </Animated.View>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  layoutHeader: {
    flexDirection: 'row',
    width: '100%',
    height: 66,
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#edf1f7',
  },
  layoutMenu: {
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  layoutMenuBar: {
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: '#424242a1',
  },
  layoutDisplayMenuBar: {
    width: '70%',
    backgroundColor: '#ffffff',
    height: '100%',
    position: 'absolute',
  },
  layoutMenuItem: {
    paddingVertical: 8,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dividerline: {
    width: '100%',
    border: 1,
    borderColor: '#F000000',
    height: 7,
  },
  image: {
    width: '10%',
    height: 35,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 21,
  },
  imagesmall: {
    width: '10%',
    height: 30,
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  closeMenuButton: {
    width: '100%',
    height: '100%',
    opacity: 0.1,
  },
  notSeenView: {
    backgroundColor: '#e84393',
    borderRadius: 50,
    paddingHorizontal: 5,
    paddingBottom: 2,
    paddingTop: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modelIOC: {
    padding: 0,
    width: '100%',
    paddingHorizontal: 10,
    paddingBottom: screen.height * 0.25,
  },
  layoutIOC1: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#2c3a47',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  iocHeader: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  layoutIOC2: { display: 'flex', flexDirection: 'column', flex: 1, flexGrow: 1, padding: 10, backgroundColor: 'white', width: '100%' },
  iocButton: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    flexDirection: 'row',
  },
  iocContent: { flex: 6, marginRight: 5 },
  menuGroup: {
    paddingHorizontal: 12,
  },
  menuItem: {
    paddingVertical: 8,
    paddingHorizontal: 14,
    minHeight: 38,
  },
  text: {
    fontSize: 13,
  },
  textNumber: {
    color: '#FFFFFF',
    fontSize: 11,
    minWidth: 15,
    textAlign: 'center',
    fontWeight: '600',
  },
  maxLayout: {
    height: '100%',
    width: '100%',
    flexShrink: 1,
  },
});
