import {
  Button,
  Card,
  Datepicker,
  IndexPath,
  Input,
  Layout,
  List,
  MenuItem,
  OverflowMenu,
  Select,
  SelectItem,
  Spinner,
  Text,
} from '@ui-kitten/components';
import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-native';
import { navigationContext } from '../../hooks/navigation';
import { getSchedule, HostIDs, removeSchedule, setSchedule, updateSchedule } from '../../services/schedule';
import { PlusIcon } from '../../share/filter-icon';

const LeftIcon = (props) => <Icon {...props} style={styles.iconHeader} name="caret-left" color="#636e72" />;

const RightIcon = (props) => <Icon {...props} style={styles.iconHeader} name="caret-right" color="#636e72" />;

const RenameIcon = (props) => (
  <Svg width={18} height={16} viewBox="0 0 640 512" style={styles.menuIcon}>
    <Path
      d="M64 96c-17.7 0-32 14.3-32 32V384c0 17.7 14.3 32 32 32H576c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H64zM0 128C0 92.7 28.7 64 64 64H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm176 32c6.5 0 12.4 4 14.9 10.1l64 160c3.3 8.2-.7 17.5-8.9 20.8s-17.5-.7-20.8-8.9L216.4 320l-.4 0H136l-.4 0-8.8 21.9c-3.3 8.2-12.6 12.2-20.8 8.9s-12.2-12.6-8.9-20.8l64-160c2.4-6.1 8.3-10.1 14.9-10.1zm0 59.1L148.4 288h55.1L176 219.1zM288 176c0-8.8 7.2-16 16-16h56c30.9 0 56 25.1 56 56c0 12.1-3.8 23.3-10.4 32.5C421.5 258.4 432 276 432 296c0 30.9-25.1 56-56 56H304c-8.8 0-16-7.2-16-16V320 256 192 176zm72 96H320v48h56c13.3 0 24-10.7 24-24s-10.7-24-24-24H360zm0-32c13.3 0 24-10.7 24-24s-10.7-24-24-24H320v48h40z"
      fill="#424242"
    />
  </Svg>
);

const DeleteIcon = (props) => (
  <Svg width={18} height={16} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M32 128c0-17.7 14.3-32 32-32H370.7c8.5 0 16.6 3.4 22.6 9.4L544 256 393.4 406.6c-6 6-14.1 9.4-22.6 9.4H64c-17.7 0-32-14.3-32-32V128zM64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H370.7c17 0 33.3-6.7 45.3-18.7L566.6 278.6c6-6 9.4-14.1 9.4-22.6s-3.4-16.6-9.4-22.6L416 82.7C404 70.7 387.7 64 370.7 64H64zm84.7 116.7c-6.2 6.2-6.2 16.4 0 22.6L201.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L224 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L246.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z"
      fill="#424242"
    />
  </Svg>
);

const MoreButton = (props) => {
  const { handleDeleteSchedule, handleUpdateSchedule } = props;
  const [isShowMenu, setIsShowMenu] = useState(false);

  const MoreIcon = (props) => (
    <Svg width={16} height={14} viewBox="0 0 448 512" style={styles.menuIcon}>
      <Path
        d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"
        fill="#424242"
      />
    </Svg>
  );

  return (
    <OverflowMenu
      style={{ width: 120 }}
      visible={isShowMenu}
      anchor={() => <Button accessoryLeft={MoreIcon} size="small" appearance="ghost" onPress={() => setIsShowMenu(true)}></Button>}
      onBackdropPress={() => setIsShowMenu(false)}
    >
      <MenuItem
        style={{ height: 40 }}
        title="Update"
        appearance="file-menu"
        accessoryLeft={<RenameIcon name="edit" color="#424242" />}
        onPress={() => {
          setIsShowMenu(false);
          handleUpdateSchedule();
        }}
      />
      <MenuItem
        style={{ height: 40 }}
        title="Remove"
        appearance="file-menu"
        accessoryLeft={<DeleteIcon name="trash" color="#424242" />}
        onPress={() => {
          setIsShowMenu(false);
          handleDeleteSchedule();
        }}
      />
    </OverflowMenu>
  );
};

export default (props) => {
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [isWaitingElement, setIsWaitingElement] = React.useState(false);

  const showWaiting = () => {
    // setIsWaiting(true);
    // setTimeout(() => setIsWaiting(false), 500);
  };

  const showWaitingElement = () => {
    // setIsWaitingElement(true);
    // setTimeout(() => setIsWaitingElement(false), 500);
  };

  useEffect(() => {
    // setTimeout(() => setIsWaiting(false), 500);
  }, []);
  const navigation = useContext(navigationContext);
  const [date, setDate] = React.useState(new Date());

  useEffect(() => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }, [date]);

  let startDate: Date;
  if (date.getDay() === 0) {
    startDate = new Date(date.getTime() + (-6 - date.getDay()) * 1000 * 60 * 60 * 24);
  } else {
    startDate = new Date(date.getTime() + (1 - date.getDay()) * 1000 * 60 * 60 * 24);
  }

  let endDate = new Date(startDate.getTime() + 6 * 1000 * 60 * 60 * 24 + 1000 * 60 * 60 * 23.99);

  const scheduleData = useQuery(['schedule', date], () => getSchedule(HostIDs.organization, startDate.getTime(), endDate.getTime()));

  const [scheduleDataProcessed, setScheduleDataProcessed] = React.useState([]);

  useEffect(() => {
    const data = scheduleData.data
      ?.sort((itemA, itemB) => {
        const timeA = new Date(itemA.start);
        const timeB = new Date(itemB.start);
        if (timeA.getDay() == timeB.getDay()) {
          if (itemA.hostId === itemB.hostId) {
            return itemA.start > itemB.start ? 1 : -1;
          } else if (itemA.hostId === '129d2929ecae8ee9eca0d6a477_0003' && itemB.hostId === '129d2929ecae8ee9eca0d6a477_0004') return -1;
          else return 1;
        } else return itemA.start > itemB.start ? 1 : -1;
      })
      .map((item, index, array) => {
        const timeStart = new Date(item.start);
        let flagName = false;
        let flagDay = false;
        if (index != 0) {
          let previousTime = new Date(array[index - 1].start);
          let previousId = array[index - 1].hostId;
          if (item.hostId != previousId) flagName = true;
          if (timeStart.getDay() != previousTime.getDay()) {
            flagDay = true;
            flagName = true;
          }
        }
        const day =
          timeStart.getDay() === 0
            ? 'Chủ nhật'
            : timeStart.getDay() === 1
            ? 'Thứ hai'
            : timeStart.getDay() === 2
            ? 'Thứ ba'
            : timeStart.getDay() === 3
            ? 'Thứ tư'
            : timeStart.getDay() === 4
            ? 'Thứ năm'
            : timeStart.getDay() === 5
            ? 'Thứ sáu'
            : 'Thứ bảy';
        const dateDay = timeStart.toLocaleString('hi', { day: '2-digit' });
        const dateMonth = timeStart.toLocaleString('da-DK', { month: '2-digit' });
        const fullday = day + ' ' + dateDay + ' tháng ' + dateMonth;
        item.flagName = flagName;
        item.flagDay = flagDay;
        item.day = fullday;
        return item;
      });

    setScheduleDataProcessed(data);
  }, [scheduleData.isFetched, scheduleData.isFetching]);

  const onClickLeft = async () => {
    showWaitingElement();
    setDate(new Date(date.getTime() - 7 * 1000 * 60 * 60 * 24));
  };

  const onClickRight = async () => {
    showWaitingElement();
    setDate(new Date(date.getTime() + 7 * 1000 * 60 * 60 * 24));
  };

  const useDatepickerState = (initialDate = null) => {
    const [date, setDate] = React.useState(initialDate);
    return { date, onSelect: setDate };
  };

  const datepickerState = useDatepickerState();
  const [flagProcess, setFlagProcess] = React.useState(false);
  const [flagError, setFlagError] = React.useState(false);
  const [flagCreate, setFlagCreate] = React.useState(false);
  const [error, setError] = React.useState('');
  const [hour, setHour] = React.useState('');
  const [agenda, setAgenda] = React.useState('');
  const [attendances, setAttendances] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [selectedId, setSelectedId] = React.useState('');
  const data = ['Chọn lịch', 'Thầy Quân', 'Thầy Tâm', 'Hoạt động chung'];
  const displayValue = data[selectedIndex.row];

  const onClickSubmit = async () => {
    if (selectedIndex.row === 0) {
      setError('Chưa chọn lịch');
      setFlagError(true);
    } else if (datepickerState.date === null) {
      setError('Chưa chọn ngày');
      setFlagError(true);
    } else if (agenda === '') {
      setError('Chưa nhập nội dung');
      setFlagError(true);
    } else {
      const hostId = selectedIndex.row === 1 ? HostIDs.MrQuan : selectedIndex.row === 2 ? HostIDs.MrTam : HostIDs.organization;
      const start = new Date(datepickerState.date);
      let hours = parseInt(hour.slice(0, 5).split(':')[0]?.slice(0, 2));
      let minutes = parseInt(hour.slice(0, 5).split(':')[1]?.slice(0, 2));
      hours = hours ? hours : 0;
      minutes = minutes ? minutes : 0;
      start.setTime(start.getTime() + 1000 * 60 * (60 * hours + minutes));
      if (!flagProcess) {
        await setSchedule(hostId, start.getTime(), 0, agenda, attendances, location);
      } else {
        await updateSchedule(selectedId, start.getTime(), 0, agenda, attendances, location);
        setSelectedId('');
        setFlagProcess(false);
      }
      setAgenda('');
      setHour('');
      setAttendances('');
      setLocation('');
      datepickerState.onSelect(null);
      setFlagError(false);
      setError('');
      setSelectedIndex(new IndexPath(0));
      setFlagCreate(false);
      showWaitingElement();
      scheduleData.refetch();
    }
  };

  const deleteSchedule = async (id) => {
    await removeSchedule(id);
    showWaitingElement();
    scheduleData.refetch();
  };

  const renderSchedule = ({ item, index }) => {
    const timeStart = new Date(item.start);
    const time = timeStart.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });
    return (
      <View style={styles.headerView}>
        {(index === 0 || item.flagDay) && (
          <View style={styles.layoutCenter}>
            <Text style={{ fontSize: 15, fontWeight: '600', color: '#5b5fc7' }}>{item.day}</Text>
          </View>
        )}
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: 10,
            paddingTop: 1.5,
            minHeight: 33.5,
            marginBottom: -4,
          }}
        >
          <View style={styles.timeView}>
            <Text category="s2">{time === '00:00' ? 'Cả ngày' : time}</Text>
          </View>
          <View>
            {navigation.state._schedule_editors && (
              <MoreButton
                handleDeleteSchedule={() => deleteSchedule(item._id)}
                handleUpdateSchedule={() => {
                  setFlagProcess(true);
                  setFlagCreate(true);
                  setAgenda(item.agenda);
                  setAttendances(item.attendances);
                  setLocation(item.location);
                  setSelectedId(item._id);
                  setSelectedIndex(new IndexPath(item.hostId === HostIDs.MrQuan ? 1 : item.hostId === HostIDs.MrTam ? 2 : 3));
                  datepickerState.onSelect(new Date(timeStart.toDateString()));
                  setHour(time === '00:00' ? '' : time);
                }}
              />
            )}
          </View>
        </View>
        <View style={styles.columnView}>
          <View style={{ width: '100%', flexDirection: 'column' }}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 12,
                width: '100%',
                flexDirection: 'row',
                borderStyle:
                  (!item.flagName && scheduleDataProcessed[index + 1]?.flagName) ||
                  (item.flagName && scheduleDataProcessed[index + 1]?.flagName) ||
                  (!item.flagDay && scheduleDataProcessed[index + 1]?.flagName) ||
                  (item.flagDay && scheduleDataProcessed[index + 1]?.flagName)
                    ? 'solid'
                    : 'dashed',
                borderBottomWidth: 1,
                borderColor: '#f0f0f0',
              }}
            >
              <View style={styles.contentView}>
                <Text style={{ lineHeight: 22 }}>{item.agenda}</Text>
                {item.attendances != '' && <View style={styles.padding3}></View>}
                {item.attendances != '' && <Text>- Thành phần: {item.attendances}</Text>}
                {item.location != '' && <View style={styles.padding3}></View>}
                {item.location != '' && (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={styles.location}>
                      <Text style={{ color: '#FFFFFF', fontSize: 14 }}>
                        {!isNaN(item?.location) && !isNaN(parseFloat(item?.location)) ? 'Phòng ' + item.location : item.location}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
        {index === scheduleDataProcessed?.length - 1 &&
          window.document.getElementById('listSchedule')?.firstElementChild?.clientHeight >
            window.document.getElementById('listSchedule')?.clientHeight && <View style={{ height: 80 }}></View>}
      </View>
    );
  };

  return (
    <Layout style={styles.mainLayout}>
      <Layout style={styles.showLayout}>
        <View style={styles.headerView}>
          <View style={styles.layoutRow}>
            <Text style={{ margin: 2 }} category="s1">
              HOẠT ĐỘNG CHUNG
            </Text>
          </View>
        </View>
        {!flagCreate && navigation.state._schedule_editors && (
          <Button style={styles.addButton} appearance="ghost" size="tiny" accessoryLeft={PlusIcon} onPress={() => setFlagCreate(true)} />
        )}
      </Layout>
      {!flagCreate && (
        <Layout style={styles.container}>
          {isWaiting && (
            <View
              // @ts-ignore
              style={{
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80%',
                width: '100%',
                background: 'white',
                zIndex: 999,
              }}
            >
              <Spinner appearance="ioc" status="primary" />
            </View>
          )}
          <View style={{ flexDirection: 'column' }}>
            <View style={styles.maxLayout}>
              <View style={styles.layoutBar}>
                <Button size="tiny" appearance="ghost_ioc" accessoryLeft={LeftIcon} onPress={() => onClickLeft()}></Button>
                <Datepicker
                  appearance="ioc-s"
                  style={styles.datePicker1}
                  placeholder={() => {
                    return (
                      <Text>
                        <div style={{ color: '#636e72' }}>
                          Tuần từ{' '}
                          <strong>
                            {startDate.getMonth() === endDate.getMonth()
                              ? startDate.toLocaleString('hi', { day: '2-digit' })
                              : startDate.toLocaleString('hi', { day: '2-digit', month: '2-digit' })}
                          </strong>{' '}
                          → <strong>{endDate.toLocaleString('hi', { day: '2-digit' })}</strong>/
                          {endDate.toLocaleString('hi', { month: '2-digit', year: 'numeric' }).split('A')}
                        </div>
                      </Text>
                    );
                  }}
                  accessibilityElementsHidden
                  onSelect={(nextDate) => setDate(nextDate)}
                />
                <Button size="tiny" appearance="ghost_ioc" accessoryLeft={RightIcon} onPress={() => onClickRight()}></Button>
              </View>
            </View>
          </View>
          <View style={styles.maxlayout}>
            {isWaitingElement && (
              <View
                // @ts-ignore
                style={{
                  position: 'fixed',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '80%',
                  width: '100%',
                  background: 'white',
                  zIndex: 999,
                }}
              >
                <Spinner appearance="ioc" status="primary" />
              </View>
            )}
            {scheduleData.isFetched && (
              <View style={{ flexShrink: 1 }}>
                <ScrollView style={{ height: '100%' }} nativeID="listSchedule">
                  <View style={styles.container}>
                    {scheduleData.data?.length > 0 ? (
                      <List style={{ width: '100%', backgroundColor: 'white' }} data={scheduleDataProcessed} renderItem={renderSchedule} />
                    ) : (
                      <Card style={{ borderWidth: 0 }}>
                        <Text style={{ textAlign: 'center' }}>Chưa có dữ liệu hoạt động</Text>
                      </Card>
                    )}
                  </View>
                </ScrollView>
              </View>
            )}
          </View>
        </Layout>
      )}
      {flagCreate && (
        <View style={{ flexDirection: 'column' }}>
          <View style={styles.maxLayout}>
            {flagError && (
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text status="danger">{error}</Text>
              </View>
            )}
            <View style={styles.layoutBarEdit}>
              <Datepicker
                appearance={datepickerState.date === null ? 'ioc-create' : 'ioc-create1'}
                style={styles.datePicker}
                placeholder={'Ngày'}
                {...datepickerState}
              />
              <Input
                style={{ backgroundColor: 'white', borderWidth: 0 }}
                placeholder="Giờ (vd. 8:30)"
                value={hour}
                onChangeText={(nextValue) => setHour(nextValue)}
              />
            </View>
            <View>
              <Select
                appearance="ioc1"
                style={{ backgroundColor: 'white', borderRadius: 0, borderBottomWidth: 1, borderTopWidth: 1, borderColor: '#e4e9f2' }}
                selectedIndex={selectedIndex}
                value={displayValue}
                disabled={flagProcess}
                onSelect={(index) => setSelectedIndex(index)}
              >
                <SelectItem appearance="ioc" title={'Chọn lịch'} />
                <SelectItem appearance="ioc" title="Thầy Quân" />
                <SelectItem appearance="ioc" title="Thầy Tâm" />
                <SelectItem appearance="ioc" title="Hoạt động chung" />
              </Select>
            </View>
            <Input
              style={{ backgroundColor: 'white', borderWidth: 0, borderBottomWidth: 1, borderRadius: 0, paddingBottom: 3 }}
              placeholder="Thành phần (optional)"
              value={attendances}
              onChangeText={(nextValue) => setAttendances(nextValue)}
            />
            <Input
              style={{ backgroundColor: 'white', borderWidth: 0, borderBottomWidth: 1, borderRadius: 0, paddingBottom: 3 }}
              placeholder="Địa điểm (optional)"
              value={location}
              onChangeText={(nextValue) => setLocation(nextValue)}
            />
            <Input
              style={{ backgroundColor: 'white', borderWidth: 0, color: '#424242' }}
              multiline={true}
              textStyle={{ minHeight: 164 }}
              placeholder="Nội dung hoạt động"
              placeholderTextColor={'#424242'}
              value={agenda}
              onChangeText={(nextValue) => setAgenda(nextValue)}
            />
            <View style={styles.editButtonView}>
              <View style={{ paddingHorizontal: 5 }}>
                <Button size="tiny" style={styles.button} onPress={() => onClickSubmit()}>
                  {!flagProcess ? 'Thêm hoạt động' : 'Cập nhật'}
                </Button>
              </View>
              <View style={{ paddingHorizontal: 5 }}>
                <Button
                  size="tiny"
                  style={styles.buttonCancel}
                  onPress={() => {
                    setFlagCreate(false);
                    setError('');
                    setHour('');
                    setFlagProcess(false);
                    setSelectedIndex(new IndexPath(0));
                    setAttendances('');
                    setLocation('');
                    setAgenda('');
                    datepickerState.onSelect(null);
                  }}
                >
                  Cancel
                </Button>
              </View>
            </View>
          </View>
        </View>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderTopColor: '#f0f0f0',
    borderTopWidth: 1,
  },
  layoutBar: {
    display: 'flex',
    width: '100%',
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  layoutBarEdit: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  datePicker: {
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
    width: '100%',
    flexShrink: 1,
  },
  datePicker1: {
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  addButton: {
    width: 25,
    height: 25,
    marginRight: 16,
  },
  button: {
    marginVertical: 4,
    width: '100%',
    backgroundColor: '#0d6efd',
    borderColor: '#0d6efd',
  },
  buttonCancel: {
    marginVertical: 4,
    width: '100%',
    backgroundColor: '#6c757d',
    borderColor: '#6c757d',
  },
  layoutRow: {
    display: 'flex',
    width: '100%',
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
  },

  layoutCenter: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
    paddingVertical: 6.5,
    backgroundColor: '#f0f5f5',
  },
  columnView: {
    flexShrink: 1,
    width: '100%',
    flexDirection: 'column',
  },
  headerName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 7,
    backgroundColor: '#f0f0f061',
    paddingVertical: 10,
  },
  padding3: {
    padding: 4,
  },
  timeView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  contentView: {
    flexShrink: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 20,
  },
  location: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    backgroundColor: '#e84393',
    borderRadius: 4,
    flexShrink: 1,
  },
  mainLayout: {
    flexDirection: 'column',
    height: '100%',
    flexShrink: 1,
  },
  showLayout: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 45,
  },
  headerView: {
    flexShrink: 1,
    width: '100%',
  },
  filterView: {
    minHeight: 38,
    paddingRight: 4,
  },
  maxLayout: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
  },
  editButtonView: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 8,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  menuIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxlayout: {
    width: '100%',
    height: '100%',
    flexShrink: 1,
  },
});
