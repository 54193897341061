import {
  Avatar,
  Button,
  Datepicker,
  IndexPath,
  Input,
  Layout,
  List,
  ListItem,
  Select,
  SelectItem,
  Spinner,
  Text,
  Tooltip,
  ViewPager,
} from '@ui-kitten/components';
import * as DocumentPicker from 'expo-document-picker';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { signContext } from '../../hooks/signingprocess';
import { downloadFile as dlFile, getNameFile, uploadFile } from '../../services/files';
import { getFuseInfo, getSpecLine, HostIDs, sharesUser, updateSigningProcess } from '../../services/signingprocess';
import {
  DownIcon,
  EjectIcon,
  EjectSignIcon,
  ExelFileIcon,
  ImageIcon,
  PdfFileIcon,
  PowerPointIcon,
  UnknownFileIcon,
  UpIcon,
  WordFileIcon,
} from '../../share/filter-icon';
import { getAvatarColor } from '../../utils/avatar';
import { canOpen, isPdf } from '../../utils/files';
import { isChromeAndroid } from '../../utils/browser';

import Svg, { Path } from 'react-native-svg';
import { PlusVBDIcon } from '../../share/filter-icon';
import { Animated } from 'react-native';
import { isTypePheDuyet } from '../../utils/function';
import { getBaseApiUrl } from '../../config';
const PhuTrachIcon = (props) => (
  <Svg width={18} height={18} viewBox="0 0 576 512">
    <Path 
      d="M64 48H320c8.8 0 16 7.2 16 16V220.5c14.6-9.5 30.8-17 48-21.8V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c3.3 0 6.6-.3 9.7-.7c-17.9-12.8-33.3-28.8-45.3-47.3H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16zm56 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm456 48a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"
      fill="#424242"
    />
  </Svg>
);

const ChuyenTiepIcon = (props) => (
  <Svg width={18} height={18} viewBox="0 0 448 512">
    <Path
      d="M448 112c0 44.2-35.8 80-80 80c-22.9 0-43.6-9.6-58.1-25l-151 75.5c.8 4.4 1.1 8.9 1.1 13.5s-.4 9.1-1.1 13.5l151 75.5c14.6-15.4 35.2-25 58.1-25c44.2 0 80 35.8 80 80s-35.8 80-80 80s-80-35.8-80-80c0-9.7 1.7-19 4.9-27.7L147.2 299.5c-14.3 22-39 36.5-67.2 36.5c-44.2 0-80-35.8-80-80s35.8-80 80-80c28.2 0 52.9 14.5 67.2 36.5l145.7-72.9c-3.2-8.6-4.9-17.9-4.9-27.7c0-44.2 35.8-80 80-80s80 35.8 80 80zM80 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM416 112a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM368 448a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
      fill="#424242"
    />
  </Svg>
);

const ShareAllIcon = (props) => (
  <Svg width={18} height={18} viewBox="0 0 576 512">
    <Path
      d="M366.6 39.5c9.1-9.6 24.3-10 33.9-.9L542.2 173.2c19.9 18.9 19.9 50.7 0 69.6L400.5 377.4c-9.6 9.1-24.8 8.7-33.9-.9s-8.7-24.8 .9-33.9L509.2 208 367.5 73.4c-9.6-9.1-10-24.3-.9-33.9zM224 64c0-12.6 7.4-24.1 19-29.2s25-3 34.4 5.4l160 144c6.7 6.1 10.6 14.7 10.6 23.8s-3.8 17.7-10.6 23.8l-160 144c-9.4 8.5-22.9 10.6-34.4 5.4s-19-16.6-19-29.2V288H192c-53 0-96 43-96 96c0 30.4 12.8 47.9 22.2 56.7c5.5 5.1 9.8 12 9.8 19.5c0 10.9-8.8 19.7-19.7 19.7c-2.8 0-5.6-.6-8.1-1.9C81.5 467.9 0 417.3 0 304c0-97.2 78.8-176 176-176h48V64z"
      fill="#5b5fc7"
    />
  </Svg>
);

const AddIcon = (props) => (
  <Svg width={12} height={14} viewBox="0 0 448 512" style={styles.menuIcon}>
    <Path
      d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
      fill="#636e72"
    />
  </Svg>
);

const LayerIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M464 4c-11 0-20 9-20 20V60H408c-11 0-20 9-20 20s9 20 20 20h36v36c0 11 9 20 20 20s20-9 20-20V100h36c11 0 20-9 20-20s-9-20-20-20H484V24c0-11-9-20-20-20zM288 128c-8.5 0-17 1.7-24.8 5.1L53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 133.1c-7.8-3.4-16.3-5.1-24.8-5.1zm-5.9 49.2c1.9-.8 3.9-1.2 5.9-1.2s4 .4 5.9 1.2L477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256l183.8-78.8zM85.1 337.4L53.9 350.8C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4z"
      fill="#e84393"
    />
  </Svg>
);
const EyeIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
      fill="#0984e3"
    />
  </Svg>
);

const FlagIcon = (props) => (
  <View
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: 90,
      width: 8,
      height: 8,
      borderColor: props.check === 'true' ? '#524f4f' : '#BDBDBD',
      backgroundColor: props.check === 'true' ? '#524f4f' : '#FFFFFF',
    }}
  />
);

const AvatarImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? props.name === 'G26' ? 'CP' : props.name === 'G26.2.02' ? 'LT': name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar) return <Avatar size={props.size ? props.size : 'medium'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 40,
          width: 40,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Text style={{ color: '#ffffff', fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

export default (props) => {
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [isShowFile, setIsShowFile] = React.useState(true);
  const [isShowCollapse, setIsShowCollapse] = React.useState(false);

  const showWaiting = () => {
    setIsWaiting(true);
    setTimeout(() => setIsWaiting(false), 500);
  };
  const auth = useContext(authContext);
  const { units, cohorts } = useContext(authContext);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const data = ['Xét duyệt TC', 'LĐ.VP duyệt'];
  const displayValue = data[selectedIndex.row];
  const [nextStep, setNextStep] = React.useState(3);
  const [isTravel, setIsTravel] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState({ isTrue: false, isShow: false, action: null, index: -1, isClone: false });

  const [dataTimeLine, setDataTimeLine] = React.useState(null);
  const [dataTimeLineProcess, setDataTimeLineProcess] = React.useState(null);
  const [pactName, setPactName] = React.useState('');
  const [specName, setSpecName] = React.useState('');
  const [tag, setTag] = React.useState('');
  const [tagColor, setTagColor] = React.useState('');
  const [leftList, setLeftList] = React.useState(null);
  const [rightList, setRightList] = React.useState({ process: false, data: null });
  const [conclusion, setConclusion] = React.useState('');
  const [isPending, setIsPending] = React.useState(false);
  const [isShowWait, setIsShowWait] = React.useState(false);
  const [isShowRequest, setIsShowRequest] = React.useState(true);
  const [textWait, setTextWait] = React.useState('');
  const [mapFileNames, setMapFileNames] = useState({});
  const [waiting, setWaiting] = React.useState(false);
  
  const ID_BGD = 'DPM-0013';
  const [BGD, setBGD] = React.useState([]);
  const [BGDID, setBGDID] = React.useState([]);
  const [selectBGD, setSelectBGD] = useState(new IndexPath(0));
  
  useEffect(()=> {
    if (BGD?.length > 0 || BGDID?.length > 0) return;
    if (auth?.users === null || auth?.users?.length < 0) return;
    if (units === null) return;
    const unit = units?.find(unit => unit?._id === ID_BGD)
    let userIdList = [];
    let userNameList = [];
    if (unit != undefined) {
      if (unit?.headmaster != ''){
        const user = auth?.users?.find(user => user?._id === unit?.headmaster)
        if (user != undefined) {
          userNameList?.push(user?.prefix + ' ' + user.fullname);
          userIdList?.push(user._id);
        }
      }
      for (let j = 0; j < unit?.heads?.length ; j++) {
        if (userIdList?.find(id => id === unit?.heads[j]) === undefined) {
          const user = auth?.users?.find(user => user?._id === unit?.heads[j])
          if (user != undefined) {
            userNameList?.push(user?.prefix + ' ' + user.fullname);
            userIdList?.push(user._id);
          }
        }
      }
      setBGD(userNameList);
      setBGDID(userIdList);
    }

  },[units, BGD, BGDID, auth, auth.users])

  const ID_LDP = 'SPM-0001';
  const [LDP, setLDP] = React.useState([]);
  const [LDPID, setLDPID] = React.useState([]);
  const [selectLDP, setSelectLDP] = useState(new IndexPath(0));
  
  useEffect(()=> {
    if (LDP?.length > 0 || LDPID?.length > 0) return;
    if (auth?.users === null || auth?.users?.length < 0) return;
    if (units === null) return;
    const unit = units?.find(unit => unit?._id === ID_LDP)
    let userIdList = [];
    let userNameList = [];
    if (unit != undefined) {
      for (let j = 0; j < unit?.leads?.length ; j++) {
        if (userIdList?.find(id => id === unit?.leads[j]) === undefined) {
          const user = auth?.users?.find(user => user?._id === unit?.leads[j])
          if (user != undefined) {
            userNameList?.push(user?.prefix + ' ' + user.fullname);
            userIdList?.push(user._id);
          }
        }
      }
      setLDP(userNameList);
      setLDPID(userIdList);
    }

  },[units, LDP, LDPID, auth, auth.users])

  const [selectedIndexView, setSelectedIndexView] = React.useState({ index: 0, flag: false });

  const [selectedIndexViewShare, setSelectedIndexViewShare] = React.useState({ index: 0, flag: false, isEdit: false, showTab: false, showEdit: false });

  const [xuLyVBhostID, setXyLyVBHostId] = React.useState({ flag: false, hostId: '', isProcess: false, NAME: [], ID: [], index: [], excludeId: [] });

  useEffect(() => {
    if (selectedIndex.row === 0) setNextStep(3);
    else if (selectedIndex.row === 1) setNextStep(4);
  }, [selectedIndex.row]);

  const [isShowProcess, setIsShowProcess] = React.useState(false);
  const [specId, setSpecId] = React.useState({ flag: false, data: null });
  const state = useLocation();

  useEffect(() => {
    if (specId.flag) return;
    specId.data = state?.pathname?.slice(8, 50);
    specId.flag = true;
    if (state != null) setSpecId({ ...specId });
  }, [specId, specId.flag]);

  const getFuse = async ({ queryKey }) => {
    if (!queryKey) return null;

    const [_, prodId] = queryKey;
    return await getFuseInfo(prodId);
  };
  const rootSpec = useQuery(['efuseinfo', specId.data], getFuse);

  const [SpecLine, setSpecLine] = React.useState({ flag: false, data: null });

  useEffect(() => {
    if (rootSpec.isFetching) return;
    showWaiting();
    setIsShowProcess(false);
    setXyLyVBHostId({ flag: false, hostId: '', isProcess: false, NAME: [], ID: [], index: [], excludeId: [] })
    setSelectedIndexViewShare({flag: false, index: 0, isEdit: false, showTab: false, showEdit: false})
    selectedIndexView.flag = false;
    setSelectedIndexView({ ...selectedIndexView });
    if (SpecLine.flag) return;
    if (rootSpec.data?.at(0)?.specId === null || rootSpec.data?.at(0)?.specId === undefined) return;
    getSpecLine(rootSpec.data?.at(0)?.specId).then((data) => {
      SpecLine.flag = true;
      SpecLine.data = data;
      setSpecLine({ ...SpecLine });
    });
    setIsShowProcess(false);
  }, [rootSpec.isFetched, rootSpec.isFetching]);
  const sign = useContext(signContext);

  useEffect(() => {
    if (rootSpec.isFetching) return;
    if (rootSpec.data === undefined || rootSpec.data === null) return;
    setIsPending(false);
    setIsTravel(false);
    setNextStep(3);
    setDataTimeLine(rootSpec.data?.at(0)?.progresses);
    setDataSpec(rootSpec.data?.at(0));
    const actionlast = rootSpec.data?.at(0)?.progresses?.at(rootSpec.data?.at(0)?.progresses?.length - 1);

    setSpecName('Fuse');
    setPactName(rootSpec.data?.at(0)?.name);
    if (rootSpec.data?.at(0)?.status === 'cancelled' && actionlast?.step === 0) {
      sign.setStatus({ color: '#636e72', tag: 'Hủy' });
    } else if (rootSpec.data?.at(0)?.status === 'cancelled') {
      sign.setStatus({ color: '#636e72', tag: 'Trả hồ sơ' });
    } else if (actionlast?.status === 'done') {
      sign.setStatus({ color: '#575fcf', tag: 'Hoàn tất' });
    } else if (
      actionlast?.status === 'pending' &&
      (actionlast?.name === 'Ban GĐ duyệt' ||
        actionlast?.name === 'Nhận chỉ đạo') &&
      actionlast?.actions?.find(action=>action?.actorId ==='' && action?.tag === '_boss' && isTypePheDuyet('CQ', action?.candidateType, action?.candidateId, auth.user._id, auth.units, auth.cohorts)) != undefined
    ) {
      sign.setStatus({ color: '#0984e3', tag: 'Chờ phê duyệt' });
    } else {
      sign.setStatus({ color: '#e84393', tag: 'Đang xử lý' });
    }
  }, [rootSpec.isFetched, rootSpec.isFetching]);
  const [dataSpec, setDataSpec] = React.useState(null);
  const [tempSpec, setTempSpec] = React.useState(null);

  useEffect(() => {
    if (BGDID?.length <= 0) return;
    const indexBGD = BGDID?.indexOf(dataSpec?.bossId);
    if (indexBGD != -1) setSelectBGD(new IndexPath(indexBGD));
    else setSelectBGD(new IndexPath(0));
    if (dataSpec?.pendingForUsers?.length > 0 && dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id)) setIsPending(true);
    else if (
      dataSpec?.pendingForUnits?.length > 0 &&
      dataSpec.pendingForUnits?.find(
        (punit) =>
          units?.find((unit) => unit._id === punit && unit.members?.find((user) => user === auth.user._id) != undefined) != undefined
      )
    )
      setIsPending(true);
    else if (
      dataSpec?.pendingForCohorts?.length > 0 &&
      dataSpec.pendingForCohorts?.find(
        (pcohort) =>
          cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
          undefined
      )
    )
      setIsPending(true);
  }, [dataSpec, cohorts, units, auth, auth.user, auth.users, BGDID]);

  const getFileOrFolderIcon = (originalName: string) => {
    const fileName = (originalName || '').toLowerCase();

    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return <WordFileIcon></WordFileIcon>;
    } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      return <PowerPointIcon></PowerPointIcon>;
    } else if (fileName.endsWith('.pdf')) {
      return <PdfFileIcon></PdfFileIcon>;
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return <ExelFileIcon></ExelFileIcon>;
    } else if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.gif') ||
      fileName.endsWith('.psd') ||
      fileName.endsWith('.ai')
    ) {
      return <ImageIcon></ImageIcon>;
    } else {
      return <UnknownFileIcon></UnknownFileIcon>;
    }
  };

  const checkUserInclude = (process, curUserId, unitList, cohortList, type) => {
    let processType = type.toLowerCase();
    let arr_skip_step = [0, 1];

    if (["_gov", "_hyperspace"].includes(process.applicantId)) {
      return true;
    }

    if (['pact'].includes(processType)) {
      arr_skip_step = [0, 1];
    }

    if (['fuse', 'dfuse'].includes(processType)) {
      arr_skip_step = [0, 1];

      if ('_hyperspace' == process?.applicantId || '_gov' == process?.applicantId || 'Fuse-external' == process?.specName) {
        arr_skip_step = [];
      }
    }

    if (['lumen', 'dlumen'].includes(processType)) {
      arr_skip_step = [0, 1, 7];
    }

    if (['elumen'].includes(processType)) {
      arr_skip_step = [6];
    }

    for (var i = 0; i < process?.progresses?.length; i++) {
      let progress = process?.progresses[i];

      if (arr_skip_step?.includes(progress?.step)) continue;

      for (var mm = 0; mm < progress?.actions?.length; mm++) {
        let action = progress?.actions?.at(mm);
        if (action?.candidateType === 'user' && action?.candidateId === curUserId) {
          return true;
        }
        if (action?.candidateType === 'unit') {
          let unit = unitList?.find((unit) => unit._id === action.candidateId);
          if (unit?.members?.find((id) => id === curUserId) != undefined) return true;
        }
        if (action?.candidateType === 'cohort') {
          let cohort = cohortList?.find((cohort) => cohort._id === action.candidateId);
          if (cohort?.members?.find((id) => id === curUserId) != undefined) return true;
        }
      }
    }

    return false;
  };

  const getDirection = (progress) => {
    let direction = progress?.actions?.at(0).requested;
    for (var i = 0; i < progress?.actions?.length; i++) {
      let a = progress?.actions?.at(i);
      if (a?.role === 'primary' && a?.direction != '' && a.direction != a.requested) direction = a.direction;
    }
    return direction;
  };
  const [isVPTN, setIsVPTN] = React.useState(false);
  const [isHaveXetDuyetTC, setIsHaveXetDuyetTC] = React.useState(false);
  const [isVPtiepnhan, setIsVPtiepnhan] = React.useState(false);
  const [isTCHCduyet, setIsTCHDduyet] = React.useState(false);
  const [isPCVPduyet, setIsPCVPduyet] = React.useState(false);
  const [isNCD, setIsNCD] = React.useState(false);
  const [isBGDduyet, setIsBGDduyet] = React.useState(true);
  const [isTrinhVB, setIsTrinhVB] = React.useState(false);
  const [isShowTL0, setIsShowTL0] = React.useState(false);
  const [Incantation, setIncantation] = React.useState(null);
  const [AppendixProcess, setAppendixProcess] = React.useState({ documentNumber: '', signatory: '' });
  const [placeholderText, setPlaceholderText] = React.useState('Nội dung trình (nếu có)');

  const refScrollView = React.useRef<ScrollView>(null);
  const changeScrollViewtoBottom = () => {
    setTimeout(() => refScrollView.current?.scrollToEnd({ animated: true }), 500);
  };

  useEffect(() => {
    if (!xuLyVBhostID.flag) return;
    if (units === null) return;
    if (xuLyVBhostID.isProcess) return;
    if (xuLyVBhostID.NAME.length > 0) {
      xuLyVBhostID.index = [0];
      xuLyVBhostID.isProcess = true;
      setXyLyVBHostId({ ...xuLyVBhostID });
      return;
    }
    xuLyVBhostID.NAME = [];
    xuLyVBhostID.ID = [];
    xuLyVBhostID.NAME.push('Chọn cán bộ');
    xuLyVBhostID.ID.push('');
    const unit = units?.find((unit) => unit?._id === xuLyVBhostID.hostId);
    for (let i = 0; i < unit?.members?.length; i++) {
      const user = auth.users.find((user) => user._id === unit?.members[i]);
      if (user?.conscriptions?.find(unitU => unitU?.unitId === xuLyVBhostID.hostId && unitU?.rank <= 1000) && user._id != auth.user._id && xuLyVBhostID.excludeId?.find(id=>id === user._id) === undefined) {
        xuLyVBhostID.NAME.push(user.fullname);
        xuLyVBhostID.ID.push(user._id);
      }
    }

    setXyLyVBHostId({ ...xuLyVBhostID });
  }, [xuLyVBhostID, auth.users, units, auth]);

  const addXuLyVBHostID = () => {
    xuLyVBhostID.index.push(0);
    setXyLyVBHostId({ ...xuLyVBhostID });
  };
  const removeXuLyVBHostID = (index) => {
    xuLyVBhostID.index.splice(index, 1);
    setXyLyVBHostId({ ...xuLyVBhostID });
  };
  useEffect(() => {
    if (dataTimeLine === null || dataTimeLine === undefined) return;

    setTimeout(async () => {
      for (let index = 0; index < dataTimeLine?.length; index++) {
        const item = dataTimeLine[index];
        for (let fileIndex = 0; fileIndex < item?.refFileIds.length; fileIndex++) {
          if (!!mapFileNames[item.refFileIds.at(fileIndex)]) continue;

          const fileName = await getNameFile({ id: item.refFileIds.at(fileIndex) });
          mapFileNames[item.refFileIds.at(fileIndex)] = fileName;
        }

        for (let fileIndex = 0; fileIndex < item?.signFileIds.length; fileIndex++) {
          if (!!mapFileNames[item.signFileIds.at(fileIndex)]) continue;

          const fileName = await getNameFile({ id: item.signFileIds.at(fileIndex) });
          mapFileNames[item.signFileIds.at(fileIndex)] = fileName;
        }

        for (let fileIndex = 0; fileIndex < item?.supFileIds.length; fileIndex++) {
          if (!!mapFileNames[item.supFileIds.at(fileIndex)]) continue;

          const fileName = await getNameFile({ id: item.supFileIds.at(fileIndex) });
          mapFileNames[item.supFileIds.at(fileIndex)] = fileName;
        }
      }
      setMapFileNames({ ...mapFileNames });
    }, 0);
  }, [dataTimeLine]);

  const checkShowShare = (itemAction, unitList, user) => {
    if(user?.conscriptions?.length <= 0) return false;

    let heads = [];

    if(itemAction?.atTime === 0) {
      return false;
    };

    if(itemAction?.hostId === ""){
      heads = unitList?.find(unit => unit?._id === user?.conscriptions?.at(0).unitId)?.heads;
    }else{
      heads = unitList?.find(unit => unit?._id === itemAction?.hostId)?.heads;
    }

    if(!heads?.includes(user?._id)) {
      return false;
    }

    if( itemAction?.incantation?.includes(user?._id) ) return true;

    return false;

  }


  useEffect(() => {
    if (dataTimeLine === null || dataTimeLine === undefined) return;
    if (Object.keys(mapFileNames).length === 0) return;
    if (units === null) return;
    if (cohorts === null) return;
    if (auth.user === null) return;
    if (auth.users === null) return;
    if (dataSpec === null) return;
    if (auth.users?.length <= 0) return;

    let showTag = false;
    let n = 0;
    let m = 0;
    for (let i = 0; i < rightList.data?.length; i++) {
      if (rightList?.data?.at(i)?.tag === 'primary') n++;
      if (rightList?.data?.at(i)?.tag != 'primary') m++;
    }
    if (n > 0 && m > 0) showTag = true;

    if (dataSpec?.applicantId === '_gov' || dataSpec?.applicantId === '_hyperspace' ) {
      setDocumentNumber(dataSpec?.appendix?.documentNumber);
      setSignatory(dataSpec?.appendix?.signatory);
      setDate(new Date(dataSpec?.appendix?.issueDate));
      if (dataSpec?.appendix?.codeName) {
        setCodeName(dataSpec?.appendix?.codeName);
      }
      if (dataSpec?.appendix?.documentType) {
        setSelectType(new IndexPath(LoaiVB?.indexOf(dataSpec?.appendix?.documentType)));
      }
    }

    const data = dataTimeLine?.map(async (item, index, array) => {
      item.actions = await item.actions
        ?.map((item, index) => {
          item.actionIndex = index;
          if (showTag) {
            if (item?.tag == 'primary') item.tagname = 'XLC';
            else if (item?.tag == 'secondary') item.tagname = 'PH';
            else if (item?.tag == 'audience') item.tagname = 'XĐB';
          }
          let ids;
          if (item?.direction == 'confirm' && item.incantation != '') ids = item.incantation.split(',');
          item.ids = [];
          for (let i = 0; i < ids?.length; i++) {
            const user = auth.users?.find((user) => user._id === ids?.at(i));
            const name = user?.fullname?.split(' ');
            visibles.push(false);
            if (dataSpec.seen.hasOwnProperty(ids?.at(i)) && dataSpec.seen[ids?.at(i)] > item?.atTime) {
              item?.ids?.push({ name: name?.at(name?.length - 1) + ' ' + name?.at(0), seen: true });
            } else item?.ids?.push({ name: name?.at(name?.length - 1) + ' ' + name?.at(0), seen: false });
          }
          return item;
        })
        ?.sort((itemA, itemB) => {
          if (dataSpec?.status === 'closed') return -1;
          else
            return (itemA?.candidateType === 'user' && itemA?.candidateId === auth.user._id) ||
              (itemA?.candidateType === 'cohort' &&
                cohorts?.find(
                  (cohort) => cohort?._id === itemA?.candidateId && cohort?.members?.find((id) => id === auth.user._id) != undefined
                ) != undefined) ||
              (itemA?.candidateType === 'unit' &&
                units?.find((unit) => unit?._id === itemA?.candidateId && unit?.members?.find((id) => id === auth.user._id) != undefined) !=
                  undefined)
              ? 1
              : -1;
        })
        ?.map(async (itemAction, indexAction, arrayAction) => {
          if (itemAction.direction === 'submit') {
            itemAction.process = 'Nộp hồ sơ';
            itemAction.processcolor = '#198753';
            if (item.flag === undefined) item.flag = 1;
          } else if (itemAction.direction === 'approve') {
            itemAction.process = 'Đã duyệt';
            itemAction.processcolor = '#198753';
            if (item.flag === undefined) item.flag = 1;
          } else if (itemAction.direction === 'sign') {
            itemAction.process = 'Đã ký';
            itemAction.processcolor = '#5b5fc7';
            if (item.flag === undefined) item.flag = 2;
          } else if (itemAction.direction === 'reset') {
            itemAction.process = 'Yêu cầu hiệu chỉnh';
            item.flag = 0;
            itemAction.processcolor = '#e58e26';
          } else if (itemAction.direction === 'reject') {
            itemAction.process = 'Từ chối';
            item.flag = 0;
            itemAction.processcolor = '#e58e26';
          } else if (itemAction.direction === 'cancel' && item?.name === 'Nộp hồ sơ') {
            itemAction.process = 'Hủy';
            item.flag = 0;
            itemAction.processcolor = '#dc3545';
          } else if (itemAction.direction === 'cancel') {
            itemAction.process = 'Trả hồ sơ';
            item.flag = 0;
            itemAction.processcolor = '#e58e26';
          } else if (itemAction.direction === 'confirm') {
            itemAction.process = 'Xác nhận';
            itemAction.processcolor = '#198753';
            if (item.flag === undefined) item.flag = 1;
          } else if (itemAction.direction === 'end') {
            itemAction.process = 'Đã xem';
            itemAction.processcolor = '#5b5fc7';
            if (item.flag === undefined) item.flag = 1;
          } else if (itemAction.direction === 'forward') {
            itemAction.process = 'Chỉ đạo';
            itemAction.processcolor = '#5b5fc7';
            if (item.flag === undefined) item.flag = 1;
          } else {
            itemAction.process = 'Chờ xử lý';
            item.flag = -1;
          }

          if (
            itemAction?.incantation &&
            index === array?.length - 2 &&
            array?.at(index)?.status === 'done' &&
            array?.at(index + 1)?.status === 'pending'
          ) {
            let te = JSON.parse(itemAction.incantation);
            item.te = te;
          }

          if (item.name === 'Nhận văn bản') item.flag = 3;

          if (
            item.name === 'Nhận chỉ đạo' &&
            item.status === 'pending' &&
            itemAction?.actorId === '' &&
            itemAction?.candidateId === auth.user._id &&
            itemAction?.role === 'primary' &&
            (itemAction?.tag === 'primary' || itemAction?.tag === 'secondary') && item.xylyVB === undefined
          ) {
            item.xylyVB = true;
            xuLyVBhostID.flag = true;
            xuLyVBhostID.hostId = itemAction?.hostId ? itemAction?.hostId : auth.user?.conscriptions?.at(0).unitId;
            setXyLyVBHostId({ ...xuLyVBhostID });
            setPlaceholderText('Phản hồi hoặc chỉ thị chuyển tiếp (nếu có)');
          }
          if (
            item.name === 'Nhận chỉ đạo' &&
            item.status === 'pending' &&
            itemAction?.actorId === '' &&
            itemAction?.candidateId === auth.user._id &&
            itemAction?.role === 'primary' &&
            (itemAction?.tag != 'primary' && itemAction?.tag != 'secondary')
          ) {
            item.xylyVB = false;
            xuLyVBhostID.flag = false;
            xuLyVBhostID.hostId = "";
            setXyLyVBHostId({ ...xuLyVBhostID });
          }

          if (
            item.name === 'Nhận chỉ đạo' &&
            itemAction?.actorId != '' 
          ) {
            const isChuyenTiep = checkShowShare(itemAction, units, auth.user);

            if (isChuyenTiep) {
              itemAction.showEdit = true;
              selectedIndexViewShare.flag = true;
              selectedIndexViewShare.showEdit = true;
              setSelectedIndexViewShare({...selectedIndexViewShare})
              item.xylyVB = true;
              xuLyVBhostID.flag = true;
              xuLyVBhostID.hostId = itemAction?.hostId ? itemAction?.hostId : auth.user?.conscriptions?.at(0).unitId;
               
              setXyLyVBHostId({ ...xuLyVBhostID });
              setPlaceholderText('Chỉ thị chuyển tiếp (nếu có)');
            }
            else {
              selectedIndexViewShare.flag = true;
              setSelectedIndexViewShare({...selectedIndexViewShare})
            }
          }
          

          if (item.status === 'done') {
            if (itemAction?.candidateId === '_gov') {
              itemAction.name = 'G26';
              itemAction.firstname = 'C';
              itemAction.lastname = 'P';
              itemAction.flagProcess = false;
              itemAction.process = 'Đại học Quốc gia TP.HCM';
              itemAction.processcolor = '#198754';
            } else if (itemAction?.candidateId === '_hyperspace') {
              itemAction.name = 'G26.2.02';
              itemAction.firstname = 'L';
              itemAction.lastname = 'T';
              itemAction.flagProcess = false;
              itemAction.process = 'Trường ĐH Khoa học tự nhiên';
              itemAction.processcolor = '#198754';
            } else {
              const user = auth.users?.find((user) => user._id === itemAction.actorId);
              const name = user?.fullname?.split(' ');
              itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
              itemAction.flagProcess = false;
              if (user?.avatar) {
                itemAction.avatar = user.avatar;
              } else {
                itemAction.firstname = auth.users?.find((user) => user._id === itemAction.actorId)?.firstname;
                itemAction.lastname = auth.users?.find((user) => user._id === itemAction.actorId)?.lastname;
              }
            }
            const time = new Date(itemAction?.atTime);
            const month = time.toLocaleString('en-US', { month: '2-digit' });
            itemAction.timeprocess =
              time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) +
              ', ' +
              time.getDate() +
              ' tháng ' +
              month;
          } else if (itemAction.candidateType === 'user') {
            const user = auth.users?.find((user) => user._id === itemAction.candidateId);
            const name = user?.fullname?.split(' ');
            if (itemAction.candidateId === auth.user._id && itemAction.actorId === '') {
              itemAction.flagProcess = true;
            } else {
              itemAction.flagProcess = false;
            }
            const unit = units?.find((unit) => unit._id === user?.conscriptions?.at(0).unitId);
            itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
            if (item.flag === -1 && itemAction.direction === '' && itemAction?.candidateId === auth.user._id) {
              item.avatarname = name?.at(name?.length - 1) + ' ' + name?.at(0);
              item.unitcode = unit?.code;
              if (user?.avatar) item.avatar = user.avatar;
              else item.avatar = undefined;
            } else if (item.flag === -1 && itemAction.direction === '' && item.avatarname === undefined && item.unitcode === undefined) {
              item.avatarname = name?.at(name?.length - 1) + ' ' + name?.at(0);
              item.unitcode = unit?.code;
              if (user?.avatar) item.avatar = user.avatar;
              else item.avatar = undefined;
            }
            if (user?.avatar) {
              itemAction.avatar = user.avatar;
            } else {
              itemAction.firstname = auth.users?.find((user) => user._id === itemAction.candidateId)?.firstname;
              itemAction.lastname = auth.users?.find((user) => user._id === itemAction.candidateId)?.lastname;
            }
          } else if (itemAction.actorId != '' && itemAction.candidateId != 'Cohort-0000001') {
            const user = auth.users?.find((user) => user._id === itemAction.actorId);
            const name = user?.fullname?.split(' ');
            itemAction.flagProcess = false;
            itemAction.avatar = user?.avatar;
            itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
          } else {
            if (itemAction.actorId != '' && itemAction.candidateId === 'Cohort-0000001') {
              item.travel = true;
              itemAction.flagProcess = false;
              const user = auth.users?.find((user) => user._id === itemAction.actorId);
              const name = user?.fullname?.split(' ');
              const unit = units?.find((unit) => unit._id === user?.conscriptions?.at(0).unitId);
              itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
              if (item.flag === -1 && itemAction.direction === '') {
                item.avatarname = name?.at(name?.length - 1) + ' ' + name?.at(0);
              }
              if (user?.avatar) {
                if (item.flag === -1 && itemAction.direction === '') item.avatar = user.avatar;
                itemAction.avatar = user.avatar;
              } else {
                itemAction.firstname = auth.users?.find((user) => user._id === itemAction.candidateId)?.firstname;
                itemAction.lastname = auth.users?.find((user) => user._id === itemAction.candidateId)?.lastname;
              }
            } else if (itemAction?.candidateId === '_gov') {
              itemAction.name = 'G26';
              itemAction.firstname = 'C';
              itemAction.lastname = 'P';
              itemAction.flagProcess = false;
            } else if (itemAction?.candidateId === '_hyperspace') {
              itemAction.name = 'G26.2.02';
              itemAction.firstname = 'L';
              itemAction.lastname = 'T';
              itemAction.flagProcess = false;
            } else {
              if (item.travel) item.travel = true;
              else if (
                itemAction.candidateId === 'Cohort-0000001' &&
                itemAction.actorId === '' &&
                cohorts?.find(
                  (cohort) => cohort._id === 'Cohort-0000001' && cohort.members?.find((userId) => userId === auth.user._id) != undefined
                ) != undefined
              )
                item.pending = true;
              else item.travel = false;
              const cohort = cohorts?.find((cohort) => cohort._id === itemAction.candidateId);
              itemAction.name = cohort?.name;
              itemAction.firstname = cohort?.name;
              itemAction.lastname = '';
              if (item.flag === -1 && itemAction.direction === '') {
                item.avatarname = cohort?.name;
                item.unitcode = cohort?.code;
              }
              if (
                itemAction.actorId === '' &&
                cohorts?.find(
                  (cohort) =>
                    cohort._id === itemAction.candidateId && cohort.members?.find((userId) => userId === auth.user._id) != undefined
                ) != undefined
              )
                itemAction.flagProcess = true;
              else itemAction.flagProcess = false;
            }
          }

          let bUserInclude = checkUserInclude(dataSpec, auth.user._id, units, cohorts, 'fuse');
          let bConclusion = false;
          if (dataSpec?.bossId == auth.user._id) {
            bConclusion = true;
          }

          if (dataSpec?.progresses?.at(index).step < 2) {
            bConclusion = true;
          }

          let direction = getDirection(dataSpec?.progresses?.at(index));
          if (direction == 'reject' || direction == 'cancel' || direction == 'reset' || direction == 'sign') {
            bConclusion = true;
          }
          if (bUserInclude) {
            bConclusion = true;
          }
          if (itemAction?.tag === '_boss') {
            bConclusion = true;
          }

          itemAction.bConclusion = bConclusion;

          if (item.te) {
            setIsIncatationProcess(false);
            setIncantation(item.te);
          }

          if (
            item.status === 'pending' &&
            dataSpec?.pendingForCohorts?.length > 0 &&
            !(
              dataSpec.pendingForCohorts?.find(
                (cohortId) =>
                  cohorts?.find(
                    (cohort) => cohort._id === cohortId && cohort.members?.find((userId) => userId === auth.user._id) != undefined
                  ) != undefined
              ) != undefined
            )
          ) {
            item.travel = undefined;
          }

          if (
            item.name === 'VP Tiếp nhận' &&
            item.status === 'pending' &&
            dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id) != undefined
          ) {
            item.vptiepnhan = true;
          }

          if (item.name === 'Trình văn bản') {
            item.trinhvb = true;
          }

          if (
            item.name === 'TP. TCHC duyệt' &&
            item.status === 'pending' &&
            dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id) != undefined
          ) {
            item.tchcduyet = true;
          }

          if (
            item.name === 'Nhận chỉ đạo' &&
            item.status === 'pending' &&
            itemAction?.tag === 'primary' &&
            item?.candidateId === auth.user._id &&
            dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id) != undefined
          ) {
            item.ncd = true;
          }

          if (item.name === 'Nhận chỉ đạo') {
            xuLyVBhostID.excludeId?.push(itemAction?.candidateId);
          }

          if (item.name === 'Nhận chỉ đạo' && item.status === 'pending' && (item.xylyVB === undefined || item.xylyVB === false)) {
            setPlaceholderText('Phản hồi (nếu có)');
          }

          if (
            (item.name === 'PC.VP duyệt' || item.name === 'LĐ.VP duyệt') &&
            item.status === 'pending' &&
            dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id) != undefined
          ) {
            item.pcvpduyet = true;
          }

          if (item.name === 'Ban GĐ duyệt' && item.status === 'pending') {
            setPlaceholderText('Chỉ đạo (nếu có)');
          }

          if (
            item.name === 'Ban GĐ duyệt' &&
            item.status === 'pending' &&
            dataSpec?.pendingForUsers?.length > 0 &&
            dataSpec.pendingForUsers?.find(
              (userId) => auth.users?.find((user) => user._id === userId && (userId === auth.user._id) != undefined) != undefined
            ) != undefined
          ) {
            item.BGD = true;
          }

          if (indexAction === 0) {
            item.actionTime = parseFloat(itemAction.atTime);
          } else if (indexAction < arrayAction?.length - 1) {
            if (itemAction?.atTime > arrayAction[indexAction + 1]?.atTime) item.actionTime = parseFloat(itemAction.atTime);
            else item.actionTime = parseFloat(arrayAction[indexAction + 1]?.atTime);
          }
          return itemAction;
        });
      if (item.actions?.length > 0) {
        Promise.all(item.actions).then((newData) => (item.actions = newData?.sort((itemA, itemB) => {
          if (itemB.tag === 'primary' && itemA.tag === 'secondary') return 1;
          else if (itemB.tag === 'secondary' && itemA.tag === 'audience') return 1;
          else if (itemB.tag === 'primary' && itemA.tag === 'audience') return 1;
          else return -1;
        })));
      }
      if (item?.shares && item?.shares?.length > 0) {
        selectedIndexViewShare.showTab = true;
        setSelectedIndexViewShare({...selectedIndexViewShare});
        
        item.shares =  item.shares?.map((itemShare, indexShare) => {
          itemShare.processcolor = '#787777'
          itemShare.bConclusion = true;
          const user = auth.users?.find((user) => user._id === itemShare.actorId);
          const name = user?.fullname?.split(' ');
          if (itemShare?.sharedIds?.find(id => id === auth.user._id) != undefined  && selectedIndexViewShare.index === 0) {
            selectedIndexViewShare.index = 1;
            setSelectedIndexViewShare({...selectedIndexViewShare})
          }
          itemShare.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
          itemShare.flagProcess = false;
          if (user?.avatar) {
            itemShare.avatar = user.avatar;
          } else {
            itemShare.firstname = auth.users?.find((user) => user._id === itemShare.actorId)?.firstname;
            itemShare.lastname = auth.users?.find((user) => user._id === itemShare.actorId)?.lastname;
          }
          
          const time = new Date(itemShare?.atTime);
          const month = time.toLocaleString('en-US', { month: '2-digit' });
          itemShare.process =
            time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) +
            ', ' +
            time.getDate() +
            ' tháng ' +
            month;

          let ids = itemShare.sharedIds;
          itemShare.ids = [];
          for (let i = 0; i < ids?.length; i++) {
            const user = auth.users?.find((user) => user._id === ids?.at(i));
            const name = user?.fullname?.split(' ');
            visibles.push(false);
            if (dataSpec.seen.hasOwnProperty(ids?.at(i)) && dataSpec.seen[ids?.at(i)] > itemShare?.atTime) {
              itemShare?.ids?.push({ name: name?.at(name?.length - 1) + ' ' + name?.at(0), seen: true });
            } else itemShare?.ids?.push({ name: name?.at(name?.length - 1) + ' ' + name?.at(0), seen: false });
          }
          return itemShare;
        })    
      }

      if (item?.step === 0 && item.status === 'pending') {
        if (isEdit.index === -1) {
          isEdit.isShow = true;
          isEdit.index = index;
          isEdit.isTrue = true;
          setIsEdit({ ...isEdit });
        }
      }

      if (item.vptiepnhan) {
        setIsVPtiepnhan(true);
      }
      if (item.tchcduyet) {
        setIsTCHDduyet(true);
      }

      if (item.pcvpduyet) {
        setIsPCVPduyet(true);
      }

      if (item.ncd) {
        setIsNCD(true);
      }

      if (item.trinhvb) {
        setIsTrinhVB(true);
      }
      if (item.BGD) {
        setIsBGDduyet(false);
      }
      if (item.travel != undefined) {
        setTextWait('Xin chờ các thành viên phụ xử lý trước');
        setIsShowWait(!item.travel);
        setIsTravel(item.travel);
        setIsPending(item.travel);
      }
      if (item.pending != undefined) {
        setIsPending(true);
        setIsShowWait(false);
      }
      const time = new Date(item.actionTime);

      item.actionTimeToString = time.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
      if (!isShowTL0 && item?.supFileIds?.length > 0) {
        setIsShowTL0(true);
      }
      let totalFile = 0;
      if (item?.refFileIds?.length > 0) {
        totalFile += item?.refFileIds?.length;
        let refname = [];
        for (let i = 0; i < item.refFileIds.length; i++) {
          const name = mapFileNames[item.refFileIds.at(i)];
          refname.push(name);
        }

        item.refFileName = refname;
      }
      if (item?.signFileIds?.length > 0) {
        totalFile += item?.signFileIds?.length;
        let signname = [];
        for (let i = 0; i < item.signFileIds.length; i++) {
          const name = mapFileNames[item.signFileIds.at(i)];
          signname.push(name);
        }

        item.signFileName = signname;
      }
      if (item?.supFileIds?.length > 0) {
        totalFile += item?.supFileIds?.length;
        let supname = [];
        for (let i = 0; i < item.supFileIds.length; i++) {
          const name = mapFileNames[item.supFileIds.at(i)];
          supname.push(name);
        }

        item.supFileName = supname;
      }
      if (totalFile > 4 && !isShowCollapse) {
        setIsShowCollapse(true);
      }

      if (item.status === 'done' && ((index < array.length - 1 && array[index + 1].status !== 'done') || index === array.length - 1)) {
        if (isEdit.action === null) {
          isEdit.action = item;
          setIsEdit({ ...isEdit });
          setLeftList(item);
          setIndexChoose(index);
        }
      }

      if (item.status === 'done' && ((index === array.length - 2 && array[index + 1].status !== 'done') || index === array.length - 1)) {
        setTempSpec(item);
      }
      if (index === array.length - 1) {
        Promise.all(item.actions).then((action) => {
          rightList.process = false;
          rightList.data = action;
          setRightList({ ...rightList });
        });
      }
      return item;
    });

    if (data?.length > 0) {
      Promise.all(data).then((newData) => setDataTimeLineProcess(newData));
    }
  }, [dataTimeLine, auth, auth.users, units, cohorts, dataSpec, mapFileNames]);

  const [visibles, setVisibles] = React.useState([false]);

  useEffect(() => {
    if (rightList.data === null) return;
    if (rightList.process) return;
    if (auth.users?.length <= 0) return;
    if (dataSpec === null) return;

    let showTag = false;
    let n = 0;
    let m = 0;
    for (let i = 0; i < rightList.data?.length; i++) {
      if (rightList?.data?.at(i)?.tag === 'primary') n++;
      if (rightList?.data?.at(i)?.tag != 'primary') m++;
    }
    if (n > 0 && m > 0) showTag = true;

    rightList.process = true;
    rightList.data = rightList.data
      ?.map((item, index) => {
        if (showTag) {
          if (item?.tag == 'primary') item.tagname = 'XLC';
          else if (item?.tag == 'secondary') item.tagname = 'PH';
          else if (item?.tag == 'audience') item.tagname = 'XĐB';
        }
        let ids;
        if (item?.direction == 'confirm' && item.incantation != '') ids = item.incantation.split(',');
        item.ids = [];
        for (let i = 0; i < ids?.length; i++) {
          const user = auth.users?.find((user) => user._id === ids?.at(i));
          const name = user?.fullname?.split(' ');
          visibles.push(false);
          if (dataSpec.seen.hasOwnProperty(ids?.at(i)) && dataSpec.seen[ids?.at(i)] > item?.atTime) {
            item?.ids?.push({ name: name?.at(name?.length - 1) + ' ' + name?.at(0), seen: true });
          } else item?.ids?.push({ name: name?.at(name?.length - 1) + ' ' + name?.at(0), seen: false });
        }
        return item;
      })
      ?.sort((itemA, itemB) => {
        if (dataSpec?.status === 'closed') return -1;
        else if ((itemA?.candidateType === 'user' && itemA?.candidateId === auth.user._id && itemA?.actorId === '') ||
            (itemA?.candidateType === 'cohort' &&
              cohorts?.find(
                (cohort) => cohort?._id === itemA?.candidateId && cohort?.members?.find((id) => id === auth.user._id) != undefined
              ) != undefined && itemA?.actorId === '') ||
            (itemA?.candidateType === 'unit' &&
              units?.find((unit) => unit?._id === itemA?.candidateId && unit?.members?.find((id) => id === auth.user._id) != undefined) !=
                undefined && itemA?.actorId === '')) {
            if ((itemB?.candidateType === 'user' && itemB?.candidateId === auth.user._id && itemB?.actorId === '') ||
            (itemB?.candidateType === 'cohort' &&
              cohorts?.find(
                (cohort) => cohort?._id === itemB?.candidateId && cohort?.members?.find((id) => id === auth.user._id) != undefined
              ) != undefined && itemB?.actorId === '') ||
            (itemB?.candidateType === 'unit' &&
              units?.find((unit) => unit?._id === itemB?.candidateId && unit?.members?.find((id) => id === auth.user._id) != undefined) !=
                undefined && itemB?.actorId === '')) {
                  if (itemB.tag === 'primary' && itemA.tag === 'secondary') return 1;
                  else if (itemB.tag === 'secondary' && itemA.tag === 'audience') return 1;
                  else if (itemB.tag === 'primary' && itemA.tag === 'audience') return 1;
                  else return -1;
                }
            else return 1;
        }
        else return -1;
    
      });

    setRightList({ ...rightList });
  }, [auth, auth.user, auth.users, rightList, units, cohorts, dataSpec]);

  const downloadFile = async (id, filename) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf')) {
        newHref = '/filepreview.html?fileid=' + id;

      }

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const [FormState, setFormState] = React.useState({
    CHOOSE: ['Chọn'],
    CHOOSEID: [''],
    CHOOSEHEADS: [['Lãnh đạo ban']],
    CHOOSEHEADIDS: [['']],
    isProcess: false,
  });

  useEffect(() => {
    if (
      dataTimeLineProcess === null ||
      leftList === null ||
      rightList === null ||
      !rightList.process ||
      !FormState.isProcess ||
      !selectedIndexView.flag
    ) {
      return;
    }
    if (!isShowProcess) setIsShowProcess(true);
  }, [dataTimeLineProcess, leftList, rightList, cohorts, auth, auth.user, auth.users, FormState, selectedIndexView.flag]);

  const DANGID = ['DPM-0010', 'DPM-0011', 'DPM-0054', 'DPM-0012', 'DPM-0053'];

  useEffect(() => {
    if (FormState.isProcess) return;
    if (units === null) return;
    if (auth.users?.length <= 0) return;

    setIsShowChoose(false);
    FormState.CHOOSE = ['Chọn'];
    FormState.CHOOSEID = [''];
    FormState.CHOOSEHEADS = [['Lãnh đạo ban']];
    FormState.CHOOSEHEADIDS = [['']];
    const XLC = units?.filter(
      (item) =>
        item?.turf === 'local' &&
        DANGID.find((unitid) => unitid === item?._id) === undefined &&
        !(item?._id?.search('ADM') >= 0) &&
        !(item?._id?.search('MOD') >= 0) &&
        !(item?.status != 'active' || item?.heads?.length <= 0)
    );
    for (let i = 0; i < XLC?.length; i++) {
      FormState.CHOOSE.push(XLC[i]?.code);
      FormState.CHOOSEID.push(XLC[i]?._id);
      let name = [];
      let id = [];

      if (XLC[i]?.headmaster != '') {
        const user = auth?.users?.find(user => user?._id === XLC[i]?.headmaster)
        if (user != undefined) {
          name?.push(user.fullname);
          id?.push(user._id);
        }
      }
      for (let j = 0; j < XLC[i]?.heads?.length; j++) {
        const user = auth.users?.find((user) => user._id === XLC[i].heads[j]);
        if (id?.find(id => id === XLC[i].heads[j]) === undefined) {
          name.push(user?.fullname);
          id.push(XLC[i].heads[j]);
        }
      }
      FormState.CHOOSEHEADS.push(name);
      FormState.CHOOSEHEADIDS.push(id);
    }
    FormState.CHOOSE.push('DANG');
    FormState.CHOOSEID.push('');
    FormState.CHOOSEHEADS.push([]);
    FormState.CHOOSEHEADIDS.push([]);

    const DANG = units?.filter((item) => DANGID.find((unitid) => unitid === item?._id) != undefined);
    for (let i = 0; i < DANG?.length; i++) {
      FormState.CHOOSE.push(DANG[i]?.code);
      FormState.CHOOSEID.push(DANG[i]?._id);
      let name = [];
      let id = [];
      if (DANG[i]?.headmaster != '') {
        const user = auth?.users?.find(user => user?._id === DANG[i]?.headmaster)
        if (user != undefined) {
          name?.push(user.fullname);
          id?.push(user._id);
        }
      }
      for (let j = 0; j < DANG[i]?.heads?.length; j++) {
        const user = auth.users?.find((user) => user._id === DANG[i].heads[j]);
        if (id?.find(id => id === DANG[i].heads[j]) === undefined) {
          name.push(user?.fullname);
          id.push(DANG[i].heads[j]);
        }
      }
      FormState.CHOOSEHEADS.push(name);
      FormState.CHOOSEHEADIDS.push(id);
    }

    FormState.CHOOSE.push('DVTV');
    FormState.CHOOSEID.push('');
    FormState.CHOOSEHEADS.push([]);
    FormState.CHOOSEHEADIDS.push([]);

    const DVTV = units?.filter(
      (item) =>
        item?.turf === 'tether' &&
        DANGID.find((unitid) => unitid === item?._id) === undefined &&
        !(item?._id?.search('ADM') >= 0) &&
        !(item?._id?.search('MOD') >= 0) &&
        !(item?.status != 'active' || item?.heads?.length <= 0)
    );
    for (let i = 0; i < DVTV?.length; i++) {
      FormState.CHOOSE.push(DVTV[i]?.code);
      FormState.CHOOSEID.push(DVTV[i]?._id);
      let name = [];
      let id = [];
      if (DVTV[i]?.headmaster != '') {
        const user = auth?.users?.find(user => user?._id === DVTV[i]?.headmaster)
        if (user != undefined) {
          name?.push(user.fullname);
          id?.push(user._id);
        }
      }
      for (let j = 0; j < DVTV[i]?.heads?.length; j++) {
        const user = auth.users?.find((user) => user._id === DVTV[i].heads[j]);
        if (id?.find(id => id === DVTV[i].heads[j]) === undefined) {
          name.push(user?.fullname);
          id.push(DVTV[i].heads[j]);
        }
      }
      FormState.CHOOSEHEADS.push(name);
      FormState.CHOOSEHEADIDS.push(id);
    }

    FormState.isProcess = true;

    setFormState({ ...FormState });
  }, [units, FormState, auth, auth.users]);

  const addDVNState = (type: string) => {
    if (type === 'secondary') {
      DVNState.secondary.push({ index: 0, indexChoose: 0 });
      setDVNState({ ...DVNState });
    } else {
      DVNState.audience.push({ index: 0, indexChoose: 0 });
      setDVNState({ ...DVNState });
    }
  };

  const removeDVState = (type: string, index) => {
    if (type === 'secondary') {
      DVNState.secondary.splice(index, 1);
      setDVNState({ ...DVNState });
    } else {
      DVNState.audience.splice(index, 1);
      setDVNState({ ...DVNState });
    }
  };

  const updateAppendix = ({ documentNumber, signatory }) => {
    if (documentNumber != null) AppendixProcess.documentNumber = documentNumber;
    else if (signatory != null) AppendixProcess.signatory = signatory;
    setAppendixProcess({ ...AppendixProcess });
  };

  const [DVNState, setDVNState] = useState({ primary: { index: 0, indexChoose: 0 }, secondary: [], audience: [], isProcess: false });

  const [isIncatationProcess, setIsIncatationProcess] = useState(false);

  useEffect(() => {
    if ((Incantation === null || Incantation?.primary?.unit === '') && !isTCHCduyet && !isPCVPduyet && isBGDduyet) return;
    if (isIncatationProcess) return;
    if (!FormState.isProcess) return;
    if ((isTCHCduyet || isPCVPduyet || !isBGDduyet) && (Incantation === null || Incantation?.primary?.unit === '')) {
      DVNState.primary.index = 0;
      DVNState.primary.indexChoose = 0;
      DVNState.secondary = [{ index: 0, indexChoose: 0 }];
      DVNState.audience = [{ index: 0, indexChoose: 0 }];
    } else {
      let index = FormState.CHOOSEID.indexOf(Incantation?.primary?.unit);
      if (index === -1) {
        DVNState.primary.index = 0;
        DVNState.primary.indexChoose = 0;
      } else {
        DVNState.primary.index = index;
        DVNState.primary.indexChoose = FormState.CHOOSEHEADIDS[index].indexOf(Incantation?.primary?.user);
      }
      DVNState.secondary = [];
      DVNState.audience = [];
      if (Incantation?.secondary?.length <= 0) {
        DVNState.secondary = [{ index: 0, indexChoose: 0 }];
      } else
        Incantation?.secondary?.map((item, index) => {
          let temp = { index: 0, indexChoose: 0 };
          let indexS = FormState.CHOOSEID.indexOf(item?.unit);
          if (indexS === -1) {
            temp.index = 0;
            temp.indexChoose = 0;
          } else {
            temp.index = indexS;
            temp.indexChoose = FormState.CHOOSEHEADIDS[indexS].indexOf(item?.user);
          }
          DVNState.secondary.push(temp);
        });

      if (Incantation?.audience?.length <= 0) {
        DVNState.audience = [{ index: 0, indexChoose: 0 }];
      } else
        Incantation?.audience?.map((item, index) => {
          let temp = { index: 0, indexChoose: 0 };
          let indexS = FormState.CHOOSEID.indexOf(item?.unit);
          if (indexS === -1) {
            temp.index = 0;
            temp.indexChoose = 0;
          } else {
            temp.index = indexS;
            temp.indexChoose = FormState.CHOOSEHEADIDS[indexS].indexOf(item?.user);
          }
          DVNState.audience.push(temp);
        });
    }

    DVNState.isProcess = true;
    setDVNState({ ...DVNState });
    setIsIncatationProcess(true);
  }, [
    Incantation,
    FormState,
    FormState.CHOOSEID,
    FormState.CHOOSE,
    FormState.CHOOSEHEADIDS,
    FormState.CHOOSEHEADS,
    isIncatationProcess,
    isTCHCduyet,
    isPCVPduyet,
    isBGDduyet,
  ]);

  useEffect(() => {
    if (FormState.isProcess && DVNState.isProcess) {
      setIsShowChoose(true);
    }
  }, [FormState, FormState.CHOOSE, FormState.CHOOSEID, DVNState]);

  const AlertHandlerVB = () => {
    //function to make simple alert
    alert('Chưa upload văn bản');
  };
  const AlertHandlerBDGduyet = () => {
    //function to make simple alert
    alert('Chưa chọn xử lý chính');
  };
  const AlertHandlerSoden = () => {
    //function to make simple alert
    alert('Số đến không hợp lệ');
  };
  const AlertHandlerLoaiVB = () => {
    //function to make simple alert
    alert('Loại văn bản không hợp lệ');
  };
  const AlertHandlerSoVB = () => {
    //function to make simple alert
    alert('Số văn bản không hợp lệ');
  };
  const AlertHandlerNguoiKy = () => {
    //function to make simple alert
    alert('Người ký không hợp lệ');
  };

  const OnClick = async (direction: string) => {
    const actionIndex = rightList.data.find((action) => action.flagProcess === true)?.actionIndex;
    let res;
    if (isEdit.isTrue && isEdit.action?.signFileIds?.length <= 0 && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerVB();
      return;
    }
    if (tempSpec === null) return;

    if (isVPtiepnhan && codeName === '' && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerSoden();
      return;
    }
    if (isVPtiepnhan && selectType.row === 0 && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerLoaiVB();
      return;
    }
    if (isVPtiepnhan && documentNumber === '' && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerSoVB();
      return;
    }
    if (isVPtiepnhan && signatory === '' && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerNguoiKy();
      return;
    }

    if (
      (isTCHCduyet || isPCVPduyet) &&
      direction === 'approve' &&
      DVNState.primary.index === 0 &&
      ((DVNState.audience?.length > 0 && DVNState.audience[0]?.index != 0) ||
        (DVNState.secondary?.length > 0 && DVNState.secondary[0]?.index != 0))
    ) {
      AlertHandlerBDGduyet();
      return;
    }

    if (!isBGDduyet && DVNState.primary.index === 0 && direction === 'forward') {
      AlertHandlerBDGduyet();
      return;
    }
    let incatation = {
      primary: {
        unit: FormState.CHOOSEID[DVNState.primary.index],
        user: FormState.CHOOSEHEADIDS[DVNState.primary.index][DVNState.primary.indexChoose],
      },
      secondary: [],
      audience: [],
    };
    let designation = [];
    designation.push({
      role: 'primary',
      tag: 'primary',
      userId: FormState.CHOOSEHEADIDS[DVNState.primary.index][DVNState.primary.indexChoose],
      hostId: FormState.CHOOSEID[DVNState.primary.index],
    });
    for (let i = 0; i < DVNState.secondary.length; i++) {
      if (DVNState.secondary[i]?.index != 0) {
        incatation.secondary.push({
          unit: FormState.CHOOSEID[DVNState.secondary[i]?.index],
          user: FormState.CHOOSEHEADIDS[DVNState.secondary[i]?.index][DVNState.secondary[i]?.indexChoose],
        });
        if (designation?.find(des=> des?.userId === FormState.CHOOSEHEADIDS[DVNState.secondary[i]?.index][DVNState.secondary[i]?.indexChoose]) != undefined) continue
        designation.push({
          role: 'primary',
          tag: 'secondary',
          userId: FormState.CHOOSEHEADIDS[DVNState.secondary[i].index][DVNState.secondary[i].indexChoose],
          hostId: FormState.CHOOSEID[DVNState.secondary[i].index],
        });
      }
    }

    for (let i = 0; i < DVNState.audience.length; i++) {
      if (DVNState.audience[i]?.index != 0) {
        incatation.audience.push({
          unit: FormState.CHOOSEID[DVNState.audience[i]?.index],
          user: FormState.CHOOSEHEADIDS[DVNState.audience[i]?.index][DVNState.audience[i]?.indexChoose],
        });
        if (designation?.find(des=> des?.userId === FormState.CHOOSEHEADIDS[DVNState.audience[i]?.index][DVNState.audience[i]?.indexChoose]) != undefined) continue
        designation.push({
          role: 'primary',
          tag: 'audience',
          userId: FormState.CHOOSEHEADIDS[DVNState.audience[i].index][DVNState.audience[i].indexChoose],
          hostId: FormState.CHOOSEID[DVNState.audience[i].index],
        });
      }
    }

    let incatationNhanVB = '';
    if (xuLyVBhostID.flag) {
      for (let j = 0; j < xuLyVBhostID.index.length; j++) {
        if (xuLyVBhostID.index[j] === 0) continue;
        incatationNhanVB += xuLyVBhostID.ID[xuLyVBhostID.index[j]];
        if (j < xuLyVBhostID.index.length - 1) incatationNhanVB += ',';
      }
    }

    const modification = {
      bossId: BGDID[selectBGD.row],
      codeName: codeName,
      documentNumber: documentNumber,
      documentType: LoaiVB[selectType.row],
      issueDate: date.getTime(),
      leadIds: LDPID[selectLDP.row],
      signatory: signatory,
    };
    setWaiting(true);
    if (direction === 'sign') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'sign',
          conclusion: conclusion,
          incantation: null,
          modification: null,

          designation: designation,
          destination: null,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'confirm') {
      if (!xuLyVBhostID.flag)
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'confirm',
            designation: designation,
            conclusion: conclusion,
            destination: null,
            incantation: '',
            modification: null,
            next: {},
            nextStep: nextStep,
            refFileIds: tempSpec.refFileIds,
            signFileIds: tempSpec.signFileIds,
            supFileIds: tempSpec.supFileIds,
          },
          specName,
          specId.data
        );
      else
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'confirm',
            designation: null,
            conclusion: conclusion,
            destination: null,
            incantation: incatationNhanVB,
            modification: null,
            next: {},
            nextStep: nextStep,
            refFileIds: tempSpec.refFileIds,
            signFileIds: tempSpec.signFileIds,
            supFileIds: tempSpec.supFileIds,
          },
          specName,
          specId.data
        );
    } else if (direction === 'approve') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'approve',
          designation: designation,
          conclusion: conclusion,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: modification,
          next: {},
          nextStep: nextStep,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'submit') {
      if (isEdit.isTrue)
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'submit',
            conclusion: conclusion,
            designation: designation,
            destination: null,
            incantation: JSON.stringify(incatation),
            modification: modification,
            next: {},
            nextStep: null,
            refFileIds: isEdit.action.refFileIds,
            signFileIds: isEdit.action.signFileIds,
            supFileIds: isEdit.action.supFileIds,
          },
          specName,
          specId.data
        );
      else
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'submit',
            conclusion: conclusion,
            designation: designation,
            destination: null,
            incantation: JSON.stringify(incatation),
            modification: modification,
            next: {},
            nextStep: null,
            refFileIds: tempSpec.refFileIds,
            signFileIds: tempSpec.signFileIds,
            supFileIds: tempSpec.supFileIds,
          },
          specName,
          specId.data
        );
    } else if (direction === 'reject') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'reject',
          conclusion: conclusion,
          designation: designation,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: modification,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'reset') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'reset',
          conclusion: conclusion,
          designation: designation,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: modification,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'cancel') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'cancel',
          conclusion: conclusion,
          designation: designation,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: modification,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'forward') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'forward',
          conclusion: conclusion,
          designation: designation,
          destination: null,
          incantation: '',
          modification: null,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'end') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'end',
          designation: designation,
          conclusion: conclusion,
          destination: null,
          incantation: '',
          modification: null,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    }

    if (res?.status === 'ok' && !isBGDduyet) navigate('/efuses');

    setIsEdit({ isTrue: false, isShow: false, action: null, index: -1, isClone: false });
    setConclusion('');
    setTimeout(() => setWaiting(false), 200);
    rootSpec.refetch();
  };
  
  const AlertHandlerChuyenTiep = () => {
    //function to make simple alert
    alert('Chưa chọn người chuyển tiếp');
  };

  const AlertHandlerNhanxet = () => {
    //function to make simple alert
    alert('Chưa nhập chỉ thị chuyển tiếp');
  };

  const OnClickAddUser = async () => {
    let res;

    if (conclusion === '') {
      AlertHandlerNhanxet();
      return;
    }
    if (xuLyVBhostID.index?.find(index => index != 0) === undefined) {
      AlertHandlerChuyenTiep();
      return;
    }
    let sharedIds = [];
    for (let i =0; i< xuLyVBhostID.index?.length; i++){
      if (xuLyVBhostID.index?.at(i) != 0) {
        sharedIds?.push(xuLyVBhostID.ID?.at(xuLyVBhostID.index?.at(i)))
      }
    }

    setWaiting(true);
    res = await sharesUser(specId.data, 'Fuse', sharedIds?.join(','), conclusion);

    setIsEdit({ isTrue: false, isShow: false, action: null, index: -1, isClone: false });
    setConclusion('');
    setTimeout(() => setWaiting(false), 200);
    rootSpec.refetch();
  };

  var [indexChoose, setIndexChoose] = React.useState(0);
  var [isShowChoose, setIsShowChoose] = React.useState(false);

  const removeFile = (type: string, index: number) => {
    if (!isEdit.isClone) {
      isEdit.isClone = true;
      isEdit.action = JSON.parse(JSON.stringify(isEdit.action));
    }
    if (type === 'ref') {
      isEdit.action.refFileName.splice(index, 1);
      isEdit.action.refFileIds.splice(index, 1);
      setIsEdit({ ...isEdit });
    } else if (type === 'sign') {
      isEdit.action.signFileName.splice(index, 1);
      isEdit.action.signFileIds.splice(index, 1);
      setIsEdit({ ...isEdit });
    } else if (type === 'sup') {
      isEdit.action.supFileName.splice(index, 1);
      isEdit.action.supFileIds.splice(index, 1);
      setIsEdit({ ...isEdit });
    }
  };

  const uploadNewFile = async (type: string, isEditd: any) => {
    if (!isEdit.isClone) {
      isEdit.isClone = true;
      isEdit.action = JSON.parse(JSON.stringify(isEdit.action));
    }
    const result = await DocumentPicker.getDocumentAsync({});
    const formData = new FormData();
    formData.append('parentId', '_temp');
    formData.append('attemptFor', specName.search('DFuse') >= 0 ? 'DFuse' : 'Fuse');
    formData.append('filedata', (result as any).file);

    const temp = await uploadFile({ data: formData });
    const fileid = temp?.result?.fileIds?.at(0);
    let filename = '';
    await getNameFile({ id: fileid }).then((name) => {
      filename = name;
    });
    if (type === 'ref') {
      isEdit.action.refFileName = [filename];
      isEdit.action.refFileIds = [fileid];
      setIsEdit({ ...isEdit });
    } else if (type === 'sign') {
      if (isEdit.action.signFileName?.length > 0) {
        isEdit.action.signFileName?.push(filename);
      } else {
        isEdit.action.signFileName = [filename];
      }
      isEdit.action.signFileIds?.push(fileid);
      setIsEdit({ ...isEdit });
    } else if (type === 'sup') {
      if (isEdit.action.supFileName?.length > 0) {
        isEdit.action.supFileName?.push(filename);
      } else {
        isEdit.action.supFileName = [filename];
      }
      isEdit.action.supFileIds?.push(fileid);
      setIsEdit({ ...isEdit });
    }
  };

  useEffect(() => {
    if (dataSpec === null) return;
    let tempflag = false;
    if (dataSpec?.pendingForUsers?.length > 0 && dataSpec.pendingForUsers?.find((id) => id === auth.user._id) != undefined) tempflag = true;
    if (
      dataSpec?.pendingForUnits?.length > 0 &&
      dataSpec.pendingForUnits?.find(
        (unitId) =>
          units?.find((unit) => unit._id === unitId && unit.members?.find((userId) => userId === auth.user._id) != undefined) != undefined
      ) != undefined
    )
      tempflag = true;
    if (
      dataSpec?.pendingForCohorts?.length > 0 &&
      dataSpec.pendingForCohorts?.find(
        (cohortId) =>
          cohorts?.find((cohort) => cohort._id === cohortId && cohort.members?.find((userId) => userId === auth.user._id) != undefined) !=
          undefined
      ) != undefined
    )
      tempflag = true;
    setIsShowRequest(tempflag);
  }, [dataSpec, units, cohorts, auth.user]);

  const renderStatus = ({ item, index }) => {
    if (selectedIndexViewShare.flag && selectedIndexView.index === dataSpec?.progresses?.length - 1 && selectedIndexViewShare.index === 1) {
      if (
        dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length > 6 &&
        !isShowMore &&
        index > 0 &&
        ((index < dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 1 && !isPending) || (index < dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 2 && isPending))
      )
        return null;
      else if (dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length > 6 && !isShowMore && index === dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 1 && !isPending)
        return (
          <ListItem
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              flexDirection: 'row',
              paddingHorizontal: 10,
              paddingVertical: 8,
            }}
            key={index}
            onPress={() => setIsShowMore(true)}
          >
            <MoreIcon></MoreIcon>
            <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
              Hiển thị tiếp {dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 1} xử lý khác...
            </Text>
          </ListItem>
        );
      else if (dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length > 6 && !isShowMore && index === dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 2 && isPending)
        return (
          <ListItem
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              flexDirection: 'row',
              paddingHorizontal: 10,
              paddingVertical: 8,
            }}
            key={index}
            onPress={() => setIsShowMore(true)}
          >
            <MoreIcon></MoreIcon>
            <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
              Hiển thị tiếp {dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 2} xử lý khác...
            </Text>
          </ListItem>
        );
    }
    else {
    if (
      leftList?.actions?.length > 6 &&
      !isShowMore &&
      index > 0 &&
      ((index < leftList?.actions?.length - 1 && !isPending) || (index < leftList?.actions?.length - 2 && isPending))
    )
      return null;
    else if (leftList?.actions?.length > 6 && !isShowMore && index === leftList?.actions?.length - 1 && !isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {leftList?.actions?.length - 1} xử lý khác...
          </Text>
        </ListItem>
      );
    else if (leftList?.actions?.length > 6 && !isShowMore && index === leftList?.actions?.length - 2 && isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {leftList?.actions?.length - 2} xử lý khác...
          </Text>
        </ListItem>
      );
    }
    return (
      <View style={{width:'100%'}}>
      <View style={styles.processView} key={index}>
        <View style={styles.columnmaxWidth}>
          <View style={styles.rowmaxWidthPb5}>
            <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
            <View style={styles.columnPl5}>
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 15 }} category="s1">
                  {item.name} <Text style={{ fontSize: 12, color: '#787777' }}> {item.timeprocess}</Text>
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, color: item.processcolor, marginTop: 2 }}>
                  {item.process}
                  {item?.tagname && (
                    <View
                      style={{
                        backgroundColor: '#9ca59d',
                        paddingHorizontal: 5,
                        borderRadius: 5,
                        minWidth: 37,
                        alignItems: 'center',
                        marginTop: 2,
                        marginLeft: 7,
                        paddingVertical: 2,
                      }}
                    >
                      <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                    </View>
                  )}
                </Text>
              </View>
            </View>
          </View>

          {item?.ids?.length > 0 && (
            <View style={{ flexDirection: 'row', width: '100%', paddingVertical: 2 }}>
              <Text style={{ fontSize: 13, paddingTop: 3, marginLeft: 4, fontStyle: 'italic' }}>Đã chuyển tiếp đến </Text>

              <Tooltip
                style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                anchor={() => (
                  <Text
                    style={{ fontSize: 13, color: '#e84393', paddingTop: 3, fontStyle: 'italic', fontWeight: '600' }}
                    onPress={() => {
                      visibles[index] = true;
                      setVisibles([...visibles]);
                    }}
                  >
                    {item?.ids?.length} cán bộ
                  </Text>
                )}
                visible={visibles[index]}
                onBackdropPress={() => {
                  visibles[index] = false;
                  setVisibles([...visibles]);
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  {item?.ids?.map((itemG, indexG) => (
                    <View key={indexG} style={{ flexDirection: 'row' }}>
                      <Text style={{ color: '#FFFFFF', marginBottom: indexG === item?.ids?.length - 1 ? 0 : 4, fontSize: 14 }}>
                        {itemG?.name} {itemG?.seen === true && <Text style={{ color: '#FFFFFF', fontSize: 14 }}> → Seen</Text>}
                      </Text>
                    </View>
                  ))}
                </View>
              </Tooltip>
            </View>
          )}
          {item.bConclusion === true && (
            <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
            </View>
          )}
        </View>
      </View>
      {item?.showEdit != undefined && item.showEdit && selectedIndexViewShare.flag && !selectedIndexViewShare.isEdit && selectedIndexViewShare.index === 0 && selectedIndexViewShare.showEdit && 
                          <View style={{
                            flexDirection: 'column',
                            width: '100%',
                            paddingTop: 0,
                            paddingHorizontal: 13,
                            alignItems: 'flex-end',
                          }}>
                            <View style={styles.maxWidthnotShrink}>
                              <View
                                style={{
                                  width: '100%',
                                  alignItems: 'flex-end',
                                  flexDirection: 'row',
                                }}
                              >                            
                                <View>
                                  <Button
                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#FFFFFF' }}
                                    size="tiny"
                                    accessoryLeft={ShareAllIcon}
                                    onPress={() => {
                                      selectedIndexViewShare.isEdit = true;
                                      setSelectedIndexViewShare({...selectedIndexViewShare});
                                    }}
                                  ></Button>
                                </View>  
                              </View>                     
                            </View>
                          </View>
                        }

      {item?.showEdit != undefined && item.showEdit && selectedIndexViewShare.flag && selectedIndexViewShare.isEdit && 
        xuLyVBhostID.isProcess && (
          <View style={{
            flexDirection: 'column',
            width: '100%',
            paddingTop: 0,
            paddingHorizontal: 13,
            alignItems: 'flex-end',
          }}>
            <View style={styles.maxWidthnotShrink}>
              <View style={styles.columnmaxWidth}>
                <View style={styles.columnmaxWidth}>
                  <View
                    style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <Text>Chuyển xử lý - optional</Text>
                    <Button
                      appearance="ghost"
                      size="small"
                      accessoryLeft={LayerIcon}
                      onPress={() => addXuLyVBHostID()}
                    ></Button>
                  </View>
                  {xuLyVBhostID.index.map((itemG, indexG) => (
                    <View style={styles.rowmaxWidthPv5} key={indexG}>
                      <Select
                        appearance="ioc"
                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                        selectedIndex={new IndexPath(itemG)}
                        value={xuLyVBhostID.NAME[itemG]}
                        onSelect={(i: IndexPath) => {
                          xuLyVBhostID.index[indexG] = i.row;

                          setXyLyVBHostId({ ...xuLyVBhostID });
                        }}
                      >
                        {xuLyVBhostID.NAME.map((item, index) => (
                          <SelectItem appearance="ioc" title={item} key={index} />
                        ))}
                      </Select>
                      {indexG > 0 && (
                        <View
                          style={{
                            width: 30,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            position: 'absolute',
                            right: 3,
                            top: 20,
                            bottom: 20,
                          }}
                        >
                          <Button
                            appearance="ghost"
                            size="small"
                            accessoryLeft={EjectSignIcon}
                            onPress={() => removeXuLyVBHostID(indexG)}
                          ></Button>
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ width: '100%', paddingVertical: 7 }}>
                <Input
                  style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                  multiline={true}
                  textStyle={{ minHeight: 60 }}
                  placeholder={placeholderText}
                  placeholderTextColor={'#787777'}
                  value={conclusion}
                  onChangeText={(nextValue) => setConclusion(nextValue)}
                />
              </View>
              {waiting && (
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    paddingVertical: 13,
                  }}
                >
                  <View
                    style={{
                      paddingHorizontal: 5,
                      backgroundColor: '#5b5fc7',
                      borderRadius: 3,
                      flexDirection: 'row',
                    }}
                  >
                    <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
                    <Text style={{ color: '#FFFFFF', paddingVertical: 7, paddingHorizontal: 5, fontSize: 13 }}>
                      Đang xử lý...
                    </Text>
                  </View>
                </View>
              )}
              {!waiting && (
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    paddingVertical: 13,
                  }}
                >                            
                  <View style={styles.paddingH5}>
                    <Button
                      style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                      size="tiny"
                      onPress={() => {
                        OnClickAddUser();
                      }}
                    >
                      Xác nhận
                    </Button>
                  </View>  
                </View>
              )}
            </View>
          </View>
        )}
      </View>
    );
  };
  const [isShowMore, setIsShowMore] = React.useState(false);
  const MoreIcon = (props) => <Icon2 {...props} style={styles.icon} name="unfold-more-horizontal" color="#787777" />;

  const renderStatusRight = ({ item, index }) => {
    if (selectedIndexViewShare.flag && selectedIndexView.index === dataSpec?.progresses?.length - 1 && selectedIndexViewShare.index === 1) {
      if (
        dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length > 6 &&
        !isShowMore &&
        index > 0 &&
        ((index < dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 1 && !isPending) || (index < dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 2 && isPending))
      )
        return null;
      else if (dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length > 6 && !isShowMore && index === dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 1 && !isPending)
        return (
          <ListItem
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              flexDirection: 'row',
              paddingHorizontal: 10,
              paddingVertical: 8,
            }}
            key={index}
            onPress={() => setIsShowMore(true)}
          >
            <MoreIcon></MoreIcon>
            <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
              Hiển thị tiếp {dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 1} xử lý khác...
            </Text>
          </ListItem>
        );
      else if (dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length > 6 && !isShowMore && index === dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 2 && isPending)
        return (
          <ListItem
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              flexDirection: 'row',
              paddingHorizontal: 10,
              paddingVertical: 8,
            }}
            key={index}
            onPress={() => setIsShowMore(true)}
          >
            <MoreIcon></MoreIcon>
            <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
              Hiển thị tiếp {dataTimeLineProcess?.at(dataTimeLineProcess?.length -1)?.shares?.length - 2} xử lý khác...
            </Text>
          </ListItem>
        );
    }
    else {
    if (
      rightList.data?.length > 6 &&
      !isShowMore &&
      index > 0 &&
      ((index < rightList.data?.length - 1 && !isPending) || (index < rightList.data?.length - 2 && isPending))
    )
      return null;
    else if (rightList.data?.length > 6 && !isShowMore && index === rightList.data?.length - 1 && !isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {rightList.data?.length - 1} xử lý khác...
          </Text>
        </ListItem>
      );
    else if (rightList.data?.length > 6 && !isShowMore && index === rightList.data?.length - 2 && isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {rightList.data?.length - 2} xử lý khác...
          </Text>
        </ListItem>
      );
    }
    return (
      <View style={{width:'100%'}}>
      <View style={styles.processView} key={index}>
        <View style={styles.columnmaxWidth}>
          <View style={styles.rowmaxWidthPb5}>
            <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
            <View style={styles.columnPl5}>
              <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                {item.name}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                  {item.process}
                  {item?.tagname && (
                    <View
                      style={{
                        backgroundColor: '#9ca59d',
                        paddingHorizontal: 5,
                        borderRadius: 5,
                        minWidth: 37,
                        alignItems: 'center',
                        marginTop: 2,
                        marginLeft: 7,
                        paddingVertical: 2,
                      }}
                    >
                      <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                    </View>
                  )}
                </Text>
              </View>
            </View>
          </View>

          {item?.ids?.length > 0 && (
            <View style={{ flexDirection: 'row', width: '100%', paddingVertical: 2 }}>
              <Text style={{ fontSize: 13, paddingTop: 3, marginLeft: 4, fontStyle: 'italic' }}>Đã chuyển tiếp đến </Text>

              <Tooltip
                style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                anchor={() => (
                  <Text
                    style={{ fontSize: 13, color: '#e84393', paddingTop: 3, fontStyle: 'italic', fontWeight: '600' }}
                    onPress={() => {
                      visibles[index] = true;
                      setVisibles([...visibles]);
                    }}
                  >
                    {item?.ids?.length} cán bộ
                  </Text>
                )}
                visible={visibles[index]}
                onBackdropPress={() => {
                  visibles[index] = false;
                  setVisibles([...visibles]);
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  {item?.ids?.map((itemG, indexG) => (
                    <View key={indexG} style={{ flexDirection: 'row' }}>
                      <Text style={{ color: '#FFFFFF', marginBottom: indexG === item?.ids?.length - 1 ? 0 : 4, fontSize: 14 }}>
                        {itemG?.name} {itemG?.seen === true && <Text style={{ color: '#FFFFFF', fontSize: 14 }}> → Seen</Text>}
                      </Text>
                    </View>
                  ))}
                </View>
              </Tooltip>
            </View>
          )}
          <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
            {item.process != 'Chờ xử lý' && (
              <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
            )}
          </View>
        </View>
      </View>
      {item?.showEdit != undefined && item.showEdit && selectedIndexViewShare.flag && !selectedIndexViewShare.isEdit && selectedIndexViewShare.index === 0 && selectedIndexViewShare.showEdit && 
                          <View style={{
                            flexDirection: 'column',
                            width: '100%',
                            paddingTop: 0,
                            paddingHorizontal: 13,
                            alignItems: 'flex-end',
                          }}>
                            <View style={styles.maxWidthnotShrink}>
                              <View
                                style={{
                                  width: '100%',
                                  alignItems: 'flex-end',
                                  flexDirection: 'row',
                                }}
                              >                            
                                <View>
                                  <Button
                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#FFFFFF' }}
                                    size="tiny"
                                    accessoryLeft={ShareAllIcon}
                                    onPress={() => {
                                      selectedIndexViewShare.isEdit = true;
                                      setSelectedIndexViewShare({...selectedIndexViewShare});
                                    }}
                                  ></Button>
                                </View>  
                              </View>                     
                            </View>
                          </View>
                        }

      {item?.showEdit != undefined && item.showEdit && selectedIndexViewShare.flag && selectedIndexViewShare.isEdit && 
        xuLyVBhostID.isProcess && (
          <View style={{
            flexDirection: 'column',
            width: '100%',
            paddingTop: 0,
            paddingHorizontal: 13,
            alignItems: 'flex-end',
          }}>
            <View style={styles.maxWidthnotShrink}>
              <View style={styles.columnmaxWidth}>
                <View style={styles.columnmaxWidth}>
                  <View
                    style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <Text>Chuyển xử lý - optional</Text>
                    <Button
                      appearance="ghost"
                      size="small"
                      accessoryLeft={LayerIcon}
                      onPress={() => addXuLyVBHostID()}
                    ></Button>
                  </View>
                  {xuLyVBhostID.index.map((itemG, indexG) => (
                    <View style={styles.rowmaxWidthPv5} key={indexG}>
                      <Select
                        appearance="ioc"
                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                        selectedIndex={new IndexPath(itemG)}
                        value={xuLyVBhostID.NAME[itemG]}
                        onSelect={(i: IndexPath) => {
                          xuLyVBhostID.index[indexG] = i.row;

                          setXyLyVBHostId({ ...xuLyVBhostID });
                        }}
                      >
                        {xuLyVBhostID.NAME.map((item, index) => (
                          <SelectItem appearance="ioc" title={item} key={index} />
                        ))}
                      </Select>
                      {indexG > 0 && (
                        <View
                          style={{
                            width: 30,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            position: 'absolute',
                            right: 3,
                            top: 20,
                            bottom: 20,
                          }}
                        >
                          <Button
                            appearance="ghost"
                            size="small"
                            accessoryLeft={EjectSignIcon}
                            onPress={() => removeXuLyVBHostID(indexG)}
                          ></Button>
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ width: '100%', paddingVertical: 7 }}>
                <Input
                  style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                  multiline={true}
                  textStyle={{ minHeight: 60 }}
                  placeholder={placeholderText}
                  placeholderTextColor={'#787777'}
                  value={conclusion}
                  onChangeText={(nextValue) => setConclusion(nextValue)}
                />
              </View>
              {waiting && (
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    paddingVertical: 13,
                  }}
                >
                  <View
                    style={{
                      paddingHorizontal: 5,
                      backgroundColor: '#5b5fc7',
                      borderRadius: 3,
                      flexDirection: 'row',
                    }}
                  >
                    <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
                    <Text style={{ color: '#FFFFFF', paddingVertical: 7, paddingHorizontal: 5, fontSize: 13 }}>
                      Đang xử lý...
                    </Text>
                  </View>
                </View>
              )}
              {!waiting && (
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    paddingVertical: 13,
                  }}
                >                            
                  <View style={styles.paddingH5}>
                    <Button
                      style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                      size="tiny"
                      onPress={() => {
                        OnClickAddUser();
                      }}
                    >
                      Xác nhận
                    </Button>
                  </View>  
                </View>
              )}
            </View>
          </View>
        )}
      </View>
    );
  };

  const LoaiVB = ['Loại văn bản', 'Công văn', 'Quyết định', 'Báo cáo', 'Tờ trình', 'Kế hoạch', 'Thư mời', 'Khác'];
  const [selectType, setSelectType] = useState(new IndexPath(0));
  const [date, setDate] = React.useState(new Date());


  const [codeName, setCodeName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [signatory, setSignatory] = useState('');

  useEffect(() => {
    if (dataTimeLineProcess === null) return;
    if (selectedIndexView.flag) return;
    selectedIndexView.index = dataTimeLineProcess?.length - 1;
    selectedIndexView.flag = true;
    setSelectedIndexView({ ...selectedIndexView });
  }, [dataTimeLineProcess]);

  useEffect(() => {
    if (!selectedIndexView.flag) return;
    if (dataTimeLineProcess?.at(selectedIndexView.index)?.status === 'done') {
      setLeftList(dataTimeLineProcess?.at(selectedIndexView.index));
      if (isEdit.isTrue && selectedIndexView.index === isEdit.index) {
        isEdit.isShow = true;
        setIsEdit({ ...isEdit });
      } else if (isEdit.isTrue) {
        isEdit.isShow = false;
        setIsEdit({ ...isEdit });
      }
    } else if (dataTimeLineProcess?.at(selectedIndexView.index)?.status === 'pending') {
      if (isEdit.isTrue && selectedIndexView.index === isEdit.index) {
        isEdit.isShow = true;
        setIsEdit({ ...isEdit });
      } else if (isEdit.isTrue) {
        isEdit.isShow = false;
        setIsEdit({ ...isEdit });
      }
    }
  }, [selectedIndexView]);

  const collapseAnim = React.useRef(new Animated.Value(273)).current;

  useEffect(()=>{
    Animated.timing(collapseAnim, {
      toValue: isShowFile? 273: 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  },[isShowFile])

  return (
    <Layout style={styles.layout} nativeID="layoutPact">
      {isWaiting && (
        <View
          // @ts-ignore
          style={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80%',
            width: '100%',
            background: 'white',
            zIndex: 999,
          }}
        >
          <View style={{ top: '-10%' }}>
            <Spinner appearance="ioc" status="primary" />
          </View>
        </View>
      )}
      {isShowProcess && (
        <Layout style={styles.maxLayout}>
          <View style={styles.maxLayout}>
            <View style={styles.layoutRow}>
              <Text
                style={{
                  ...styles.textHeader,
                  fontWeight: pactName?.length > 80 ? '400' : '600',
                  fontSize: pactName?.length > 80 ? 15 : 16,
                }}
                category="s1"
              >
                {pactName}
              </Text>
            </View>
            <Animated.View style={{maxHeight: collapseAnim, overflow:'scroll'}}>
            <View>
            {(isEdit.index != selectedIndexView.index || !isShowRequest) && (
              <View style={styles.headerFileView}>
                {dataTimeLineProcess?.at(selectedIndexView.index).signFileIds?.length > 0 && (
                  <View style={{ flexDirection: 'row', alignItems: 'flex-start', paddingBottom: 2, paddingTop: 5 }}>
                    <View style={styles.width60maxHeight}>
                      <View style={styles.Pv5Center}>
                        <Text style={styles.labelFile}>Văn bản </Text>
                      </View>
                    </View>
                    <View style={styles.columnmaxWidthStart}>
                      {dataTimeLineProcess?.at(selectedIndexView.index).signFileIds?.map((itemF, indexF) => (
                        <Button
                          appearance="file-pact"
                          style={{
                            maxWidth: '100%',
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: 7,
                            marginRight: 0,
                          }}
                          size="small"
                          accessoryLeft={getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index).signFileName?.at(indexF))}
                          onPress={() => {
                            downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index).signFileName?.at(indexF));
                          }}
                          key={indexF}
                        >
                          <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                            {dataTimeLineProcess?.at(selectedIndexView.index).signFileName?.at(indexF)}
                          </Text>
                        </Button>
                      ))}
                    </View>
                  </View>
                )}
                {dataTimeLineProcess?.at(selectedIndexView.index).signFileIds?.length <= 0 &&
                  selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                  dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileIds?.length > 0 && (
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start', paddingBottom: 2, paddingTop: 5 }}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>Văn bản </Text>
                        </View>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        {dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileIds?.map((itemF, indexF) => (
                          <Button
                            appearance="file-pact"
                            style={{
                              maxWidth: '100%',
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              marginLeft: 7,
                              marginRight: 0,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileName?.at(indexF))}
                            onPress={() => {
                              downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileName?.at(indexF));
                            }}
                            key={indexF}
                          >
                            <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                              {dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileName?.at(indexF)}
                            </Text>
                          </Button>
                        ))}
                      </View>
                    </View>
                  )}
                {dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length > 0 && (
                  <View style={styles.fileView}>
                    <View style={styles.width60maxHeight}>
                      <View style={styles.Pv5Center}>
                        <Text style={styles.labelFile}>TL khác </Text>
                      </View>
                    </View>
                    <View style={styles.columnmaxWidthStart}>
                      {dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.map((itemF, indexF) => (
                        <Button
                          appearance="file-pact"
                          style={{
                            maxWidth: '100%',
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: 7,
                            marginRight: 0,
                          }}
                          size="small"
                          accessoryLeft={getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index).supFileName?.at(indexF))}
                          onPress={() => {
                            downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index).supFileName?.at(indexF));
                          }}
                          key={indexF}
                        >
                          <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                            {dataTimeLineProcess?.at(selectedIndexView.index).supFileName?.at(indexF)}
                          </Text>
                        </Button>
                      ))}
                    </View>
                  </View>
                )}
                {dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length <= 0 &&
                  selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                  dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileIds?.length > 0 && (
                    <View style={styles.fileView}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>TL khác </Text>
                        </View>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        {dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileIds?.map((itemF, indexF) => (
                          <Button
                            appearance="file-pact"
                            style={{
                              maxWidth: '100%',
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              marginLeft: 7,
                              marginRight: 0,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileName?.at(indexF))}
                            onPress={() => {
                              downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileName?.at(indexF));
                            }}
                            key={indexF}
                          >
                            <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                              {dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileName?.at(indexF)}
                            </Text>
                          </Button>
                        ))}
                      </View>
                    </View>
                  )}
                {(isShowTL0 || isEdit.isTrue) &&
                  ((dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length <= 0 && selectedIndexView.index != dataTimeLineProcess?.length - 1) ||
                    (selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                      dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length <= 0 &&
                      dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileIds?.length <= 0)) && (
                    <View style={styles.fileView}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>TL khác </Text>
                        </View>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        <Button
                          appearance="file-pact"
                          style={{
                            paddingVertical: 1,
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: 7,
                            marginRight: 0,
                          }}
                          size="small"
                        >
                          Không có
                        </Button>
                      </View>
                    </View>
                  )}
              </View>
            )}
            {isEdit.isTrue && isEdit.index === selectedIndexView.index && isShowRequest && (
              <View style={styles.headerFileView}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start', paddingBottom: 2, paddingTop: 5 }}>
                  <View style={styles.maxHeightStart}>
                    <View style={styles.rowCenter}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>Văn bản </Text>
                        </View>
                      </View>
                      <Button
                        style={styles.addButton}
                        size="tiny"
                        appearance="ghost"
                        accessoryLeft={AddIcon}
                        onPress={() => uploadNewFile('sign', isEdit)}
                      />
                    </View>
                  </View>
                  {isEdit.action?.signFileIds?.length > 0 && (
                    <View style={styles.columnView}>
                      {isEdit.action?.signFileIds?.map((item, index) => (
                        <View style={styles.rowmaxWidth} key={index}>
                          <Button
                            appearance="file-pact"
                            style={{
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              paddingRight: 0,
                              marginLeft: 7,
                              marginRight: -7,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(isEdit.action?.signFileName?.at(index))}
                            onPress={() => {
                              downloadFile(item, isEdit.action?.signFileName?.at(index));
                            }}
                            accessoryRight={
                              <Button
                                appearance="ghost_remove"
                                style={{ paddingHorizontal: 0 }}
                                size="tiny"
                                accessoryLeft={EjectIcon}
                                onPress={() => {
                                  removeFile('sign', index);
                                }}
                              ></Button>
                            }
                          >
                            <Text numberOfLines={1} ellipsizeMode="tail">
                              {isEdit.action?.signFileName?.at(index)}
                            </Text>
                          </Button>
                        </View>
                      ))}
                    </View>
                  )}
                  {isEdit.action?.signFileIds?.length <= 0 && (
                    <Button
                      appearance="file-pact"
                      style={{
                        paddingVertical: 1,
                        flexShrink: 1,
                        justifyContent: 'flex-start',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        borderColor: '#dee2e6',
                        borderRadius: 50,
                        marginVertical: 2.5,
                        marginLeft: 7,
                        marginRight: 0,
                      }}
                      size="small"
                    >
                      Không có
                    </Button>
                  )}
                </View>

                <View style={styles.fileView}>
                  <View style={styles.maxHeightStart}>
                    <View style={styles.rowCenter}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>TL khác </Text>
                        </View>
                      </View>
                      <Button
                        style={styles.addButton}
                        size="tiny"
                        appearance="ghost"
                        accessoryLeft={AddIcon}
                        onPress={() => uploadNewFile('sup', isEdit)}
                      />
                    </View>
                  </View>
                  {isEdit.action?.supFileIds?.length > 0 && (
                    <View style={styles.columnView}>
                      {isEdit.action?.supFileIds?.map((item, index) => (
                        <View style={styles.rowmaxWidth} key={index}>
                          <Button
                            appearance="file-pact"
                            style={{
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              paddingRight: 0,
                              marginLeft: 7,
                              marginRight: -7,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(isEdit.action?.supFileName?.at(index))}
                            onPress={() => {
                              downloadFile(item, isEdit.action?.supFileName?.at(index));
                            }}
                            accessoryRight={
                              <Button
                                appearance="ghost_remove"
                                style={{ paddingHorizontal: 0 }}
                                size="tiny"
                                accessoryLeft={EjectIcon}
                                onPress={() => {
                                  removeFile('sup', index);
                                }}
                              ></Button>
                            }
                          >
                            <Text numberOfLines={1} ellipsizeMode="tail">
                              {isEdit.action?.supFileName?.at(index)}
                            </Text>
                          </Button>
                        </View>
                      ))}
                    </View>
                  )}
                  {isEdit.action?.supFileIds?.length <= 0 && (
                    <Button
                      appearance="file-pact"
                      style={{
                        paddingVertical: 1,
                        flexShrink: 1,
                        justifyContent: 'flex-start',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        borderColor: '#dee2e6',
                        borderRadius: 50,
                        marginVertical: 2.5,
                        marginLeft: 7,
                        marginRight: 0,
                      }}
                      size="small"
                    >
                      Không có
                    </Button>
                  )}
                </View>
              </View>
            )}
            </View>
            </Animated.View>
             
            <ViewPager
              style={{ height: '100%', flexShrink: 1 }}
              selectedIndex={selectedIndexView.index}
              onSelect={(index) => {
                selectedIndexView.index = index;
                setSelectedIndexView({ ...selectedIndexView });
              }}
            >
              {dataTimeLineProcess?.map((item, index) => (
                <ScrollView style={styles.maxLayout1} ref={refScrollView} key={index}>
                  <View style={styles.columnmaxLayout}>
                    
                    <View style={{ maxHeight: '100%' }}>
                      <View style={styles.maxWidthnotShrink}>
                        <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                          <ListItem
                            style={{
                              marginTop: 11.5,
                              marginBottom: 4,
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              paddingHorizontal: 19,
                              paddingVertical: 4,
                              flexDirection: 'row',
                              flexShrink: 1,
                              backgroundColor: '#5b5fc7',
                            }}
                            key={index}
                            onPress={() => { if (isShowFile) setIsShowFile(false); else if (!isShowFile) setIsShowFile(true)}}   
                          >
                            {isShowCollapse && (
                              <Button style={{paddingHorizontal: 0, marginLeft: -8}} appearance='ghost_add' size='tiny' accessoryLeft={isShowFile ? UpIcon : DownIcon} 
                              onPress={() => { if (isShowFile) setIsShowFile(false); else if (!isShowFile) setIsShowFile(true)}}
                              ></Button>                      
                            )}   
                            <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', flexShrink: 1 }}>
                              {item.flag != -1 && (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    flexShrink: 1,
                                  }}
                                >
                                  <View style={styles.columnCenter}>
                                    <Text style={{ color: 'white', fontSize: 12 }} category="s1">
                                      {item.name?.toUpperCase()}
                                    </Text>
                                  </View>
                                </View>
                              )}
                              {item.flag === -1 && (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    flexShrink: 1,
                                  }}
                                >
                                  <View style={styles.columnCenter}>
                                    <Text style={{ color: 'white', fontSize: 12 }} category="s1">
                                      {item.name?.toUpperCase()}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </View>
                          </ListItem>
                        </View>
                      </View>
                      {selectedIndexViewShare.flag && selectedIndexViewShare.showTab && index === dataSpec?.progresses?.length - 1 && (
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            marginBottom: 0,
                            marginTop: 4,
                          }}
                        >
                          <View
                            style={{
                              justifyContent: 'center',
                              flexDirection: 'row',
                              borderBottomColor: '#5b5fc7',
                              borderBottomWidth: selectedIndexViewShare.index === 0 ? 2 : 0,

                              width: '50%',
                              flexShrink: 1,
                            }}
                          >
                            <Button
                              status="basic"
                              appearance="ghost-top-tab"
                              style={{}}
                              accessoryLeft={PhuTrachIcon}
                              onPress={() => {
                                selectedIndexViewShare.index = 0;
                                setSelectedIndexViewShare({...selectedIndexViewShare})
                                }
                              }
                            >
                              Phụ trách
                            </Button>
                          </View>
                          <View
                            style={{
                              justifyContent: 'center',
                              flexDirection: 'row',
                              borderBottomColor: '#5b5fc7',
                              borderBottomWidth: selectedIndexViewShare.index === 1 ? 2 : 0,
                              width: '50%',
                              flexShrink: 1,
                            }}
                          >
                            <Button
                              status="basic"
                              appearance="ghost-top-tab"
                              style={{}}
                              accessoryLeft={ChuyenTiepIcon}
                              onPress={() => {
                                selectedIndexViewShare.index = 1;
                                selectedIndexViewShare.isEdit = false;
                                setSelectedIndexViewShare({...selectedIndexViewShare})
                                }
                              }
                            >
                              Chuyển tiếp
                            </Button>
                          </View>
                        </View>
                      )}
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                          paddingTop: 5,
                          justifyContent: 'flex-start',
                        }}
                      >
                        {(index !== dataSpec?.progresses?.length - 1 || item?.status === 'done') && (
                          <View style={styles.alignStartStart}>
                            <List
                              style={{
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                              }}
                              data={
                                selectedIndexViewShare.flag && index === dataSpec?.progresses?.length - 1 && selectedIndexViewShare.index === 1 ? item?.shares : 
                                item?.actions}
                              renderItem={renderStatus}
                            />
                          </View>
                        )}
                        {index === dataSpec?.progresses?.length - 1 && item?.status === 'pending' && (
                          <View style={styles.alignStartStart}>
                            <List
                              style={{
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                              }}
                              data={
                                selectedIndexViewShare.flag && index === dataSpec?.progresses?.length - 1 && selectedIndexViewShare.index === 1 ? item?.shares :
                                rightList.data}
                              renderItem={renderStatusRight}
                            />
                          </View>
                        )}
                      </View>

                        
                      {isShowWait && index === dataSpec?.progresses?.length - 1 && (
                        <View
                          style={{
                            width: '100%',
                            paddingHorizontal: 13,
                            paddingTop: 3,
                            marginLeft: 4,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text>{textWait}</Text>
                        </View>
                      )}
                      {isPending && isShowRequest && index === dataSpec?.progresses?.length - 1 && selectedIndexViewShare.index != 1 && (
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                            paddingTop: 0,
                            paddingHorizontal: 13,
                            alignItems: 'flex-end',
                          }}
                        >
                          {specName != null && (
                            <View style={styles.maxWidthnotShrink}>
                              {(isTCHCduyet || (isPCVPduyet && isIncatationProcess) || !isBGDduyet) && (
                                <View style={styles.columnmaxWidth}>
                                  {isShowChoose && DVNState.isProcess && (
                                    <View style={styles.columnmaxWidth}>
                                      <View style={styles.columnmaxWidth}>
                                        {isBGDduyet && (
                                          <View
                                            style={{
                                              flexDirection: 'row',
                                              width: '100%',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Text>Chỉ đạo</Text>
                                          </View>
                                        )}
                                        {isBGDduyet && (
                                          <View style={styles.ChidaoView}>
                                            <Select
                                              appearance="ioc"
                                              style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                              selectedIndex={selectBGD}
                                              value={BGD[selectBGD.row]}
                                              onSelect={(i: IndexPath) => {
                                                setSelectBGD(i);
                                              }}
                                            >
                                              {BGD?.map((item, index) => (
                                                <SelectItem appearance="ioc" title={item} key={index} />
                                              ))}
                                            </Select>
                                          </View>
                                        )}
                                        <Text>Xử lý chính</Text>
                                        <View style={styles.rowmaxWidthPv5}>
                                          <Select
                                            appearance="ioc"
                                            style={{ backgroundColor: 'white', width: '38%' }}
                                            selectedIndex={new IndexPath(DVNState.primary.index)}
                                            value={FormState.CHOOSE[DVNState.primary.index]}
                                            onSelect={(indexSelect: IndexPath) => {
                                              DVNState.primary.index = indexSelect.row;
                                              DVNState.primary.indexChoose = 0;
                                              setDVNState({ ...DVNState });
                                            }}
                                          >
                                            {FormState.CHOOSE.map((item, index) =>
                                              item === 'DANG' || item === 'DVTV' ? (
                                                <SelectItem appearance="ioc" disabled={true} title={item} key={index} />
                                              ) : (
                                                <SelectItem appearance="ioc" title={item} key={index} />
                                              )
                                            )}
                                          </Select>
                                          <View style={styles.paddingH5}></View>
                                          <Select
                                            appearance="ioc"
                                            style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                            selectedIndex={new IndexPath(DVNState.primary.indexChoose)}
                                            value={FormState.CHOOSEHEADS[DVNState.primary.index][DVNState.primary.indexChoose]}
                                            onSelect={(i: IndexPath) => {
                                              DVNState.primary.indexChoose = i.row;
                                              setDVNState({ ...DVNState });
                                            }}
                                          >
                                            {FormState.CHOOSEHEADS[DVNState.primary.index]?.map((item, index) => (
                                              <SelectItem appearance="ioc" title={item} key={index} />
                                            ))}
                                          </Select>
                                        </View>
                                        {DVNState.secondary?.length > 0 && (
                                          <View
                                            style={{
                                              flexDirection: 'row',
                                              width: '100%',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                              paddingTop: 8,
                                            }}
                                          >
                                            <Button
                                              style={{ marginLeft: -8, paddingRight: 4 }}
                                              appearance="ghost_add"
                                              size="small"
                                              accessoryLeft={LayerIcon}
                                              onPress={() => addDVNState('secondary')}
                                            ></Button>
                                            <Text>Phối hợp</Text>
                                          </View>
                                        )}
                                        {DVNState.secondary?.map((item, index) => (
                                          <View style={styles.rowmaxWidthPv5} key={index}>
                                            <Select
                                              appearance="ioc"
                                              style={{ backgroundColor: 'white', width: '38%' }}
                                              selectedIndex={new IndexPath(DVNState.secondary[index]?.index)}
                                              value={FormState.CHOOSE[DVNState.secondary[index]?.index]}
                                              onSelect={(indexSelect: IndexPath) => {
                                                DVNState.secondary[index].index = indexSelect.row;
                                                DVNState.secondary[index].indexChoose = 0;
                                                setDVNState({ ...DVNState });
                                              }}
                                            >
                                              {FormState.CHOOSE.map((item, index) =>
                                                item === 'DANG' || item === 'DVTV' ? (
                                                  <SelectItem appearance="ioc" disabled={true} title={item} key={index} />
                                                ) : (
                                                  <SelectItem appearance="ioc" title={item} key={index} />
                                                )
                                              )}
                                            </Select>
                                            <View style={styles.paddingH5}></View>
                                            <Select
                                              appearance="ioc"
                                              style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                              selectedIndex={new IndexPath(DVNState.secondary[index]?.indexChoose)}
                                              value={
                                                FormState.CHOOSEHEADS[DVNState.secondary[index]?.index][
                                                  DVNState.secondary[index]?.indexChoose
                                                ]
                                              }
                                              onSelect={(i: IndexPath) => {
                                                DVNState.secondary[index].indexChoose = i.row;
                                                setDVNState({ ...DVNState });
                                              }}
                                            >
                                              {FormState.CHOOSEHEADS[DVNState.secondary[index]?.index]?.map((item, index) => (
                                                <SelectItem appearance="ioc" title={item} key={index} />
                                              ))}
                                            </Select>
                                            {index != 0 && (
                                              <View
                                                style={{
                                                  width: 30,
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  backgroundColor: 'white',
                                                  position: 'absolute',
                                                  right: 3,
                                                  top: 20,
                                                  bottom: 20,
                                                }}
                                              >
                                                <Button
                                                  appearance="ghost"
                                                  size="small"
                                                  accessoryLeft={EjectSignIcon}
                                                  onPress={() => removeDVState('secondary', index)}
                                                ></Button>
                                              </View>
                                            )}
                                          </View>
                                        ))}
                                        {DVNState.audience?.length > 0 && (
                                          <View
                                            style={{
                                              flexDirection: 'row',
                                              width: '100%',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                              paddingTop: 8,
                                            }}
                                          >
                                            <Button
                                              style={{ marginLeft: -8, paddingRight: 4 }}
                                              appearance="ghost_add"
                                              size="small"
                                              accessoryLeft={EyeIcon}
                                              onPress={() => addDVNState('')}
                                            ></Button>
                                            <Text>Xem để biết</Text>
                                          </View>
                                        )}
                                        {DVNState.audience?.map((item, index) => (
                                          <View style={styles.rowmaxWidthPv5} key={index}>
                                            <Select
                                              appearance="ioc"
                                              style={{ backgroundColor: 'white', width: '38%' }}
                                              selectedIndex={new IndexPath(DVNState.audience[index]?.index)}
                                              value={FormState.CHOOSE[DVNState.audience[index]?.index]}
                                              onSelect={(indexSelect: IndexPath) => {
                                                DVNState.audience[index].index = indexSelect.row;
                                                DVNState.audience[index].indexChoose = 0;
                                                setDVNState({ ...DVNState });
                                              }}
                                            >
                                              {FormState.CHOOSE.map((item, index) =>
                                                item === 'DANG' || item === 'DVTV' ? (
                                                  <SelectItem appearance="ioc" disabled={true} title={item} key={index} />
                                                ) : (
                                                  <SelectItem appearance="ioc" title={item} key={index} />
                                                )
                                              )}
                                            </Select>
                                            <View style={styles.paddingH5}></View>
                                            <Select
                                              appearance="ioc"
                                              style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                              selectedIndex={new IndexPath(DVNState.audience[index]?.indexChoose)}
                                              value={
                                                FormState.CHOOSEHEADS[DVNState.audience[index]?.index][
                                                  DVNState.audience[index]?.indexChoose
                                                ]
                                              }
                                              onSelect={(i: IndexPath) => {
                                                DVNState.audience[index].indexChoose = i.row;
                                                setDVNState({ ...DVNState });
                                              }}
                                            >
                                              {FormState.CHOOSEHEADS[DVNState.audience[index]?.index]?.map((item, index) => (
                                                <SelectItem appearance="ioc" title={item} key={index} />
                                              ))}
                                            </Select>
                                            {index != 0 && (
                                              <View
                                                style={{
                                                  width: 30,
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  backgroundColor: 'white',
                                                  position: 'absolute',
                                                  right: 3,
                                                  top: 20,
                                                  bottom: 20,
                                                }}
                                              >
                                                <Button
                                                  appearance="ghost"
                                                  size="small"
                                                  accessoryLeft={EjectSignIcon}
                                                  onPress={() => removeDVState('', index)}
                                                ></Button>
                                              </View>
                                            )}
                                          </View>
                                        ))}
                                      </View>
                                    </View>
                                  )}
                                </View>
                              )}
                              {isVPtiepnhan && (
                                <View style={styles.columnmaxWidth}>
                                  <View style={styles.columnmaxWidth}>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <Text>Tiếp nhận văn bản</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <View style={{ width: '50%', paddingRight: 10 }}>
                                        <Input
                                          style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                          placeholder="Số đến"
                                          placeholderTextColor={'#787777'}
                                          value={codeName}
                                          onChangeText={(nextValue) => setCodeName(nextValue)}
                                          textStyle={{ width: 100 }}
                                        />
                                      </View>
                                      <Select
                                        appearance="ioc"
                                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                        selectedIndex={selectType}
                                        value={LoaiVB[selectType.row]}
                                        onSelect={(i: IndexPath) => {
                                          setSelectType(i);
                                        }}
                                      >
                                        {LoaiVB?.map((item, index) => (
                                          <SelectItem appearance="ioc" title={item} key={index} />
                                        ))}
                                      </Select>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5_1}>
                                      <View style={{ width: '50%', paddingRight: 10 }}>
                                        <Input
                                          style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                          placeholder="Số văn bản"
                                          placeholderTextColor={'#787777'}
                                          value={documentNumber}
                                          onChangeText={(nextValue) => setDocumentNumber(nextValue)}
                                          textStyle={{ width: 100 }}
                                        />
                                      </View>
                                      <View style={{ width: '50%' }}>
                                        <Input
                                          style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                          placeholder="Người ký"
                                          placeholderTextColor={'#787777'}
                                          value={signatory}
                                          onChangeText={(nextValue) => setSignatory(nextValue)}
                                          textStyle={{ width: 100 }}
                                        />
                                      </View>
                                    </View>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 4 }}
                                    >
                                      <Text>Ngày ban hành</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <View
                                        style={{
                                          width: '100%',
                                          borderWidth: 1,
                                          borderRadius: 5,
                                          borderColor: '#e4e9f2',
                                        }}
                                      >
                                        <Datepicker
                                          appearance="ioc"
                                          style={styles.datePicker}
                                          placeholder={
                                            '' + date.toLocaleString('hi', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('A')
                                          }
                                          accessibilityElementsHidden
                                          onSelect={(nextDate) => setDate(nextDate)}
                                        />
                                      </View>
                                    </View>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 4 }}
                                    >
                                      <Text>Ban giám đốc</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <Select
                                        appearance="ioc"
                                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                        selectedIndex={selectBGD}
                                        value={BGD[selectBGD.row]}
                                        onSelect={(i: IndexPath) => {
                                          setSelectBGD(i);
                                        }}
                                      >
                                        {BGD?.map((item, index) => (
                                          <SelectItem appearance="ioc" title={item} key={index} />
                                        ))}
                                      </Select>
                                    </View>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 4 }}
                                    >
                                      <Text>Lãnh đạo phòng</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <Select
                                        appearance="ioc"
                                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                        selectedIndex={selectLDP}
                                        value={LDP[selectLDP.row]}
                                        onSelect={(i: IndexPath) => {
                                          setSelectLDP(i);
                                        }}
                                      >
                                        {LDP?.map((item, index) => (
                                          <SelectItem appearance="ioc" title={item} key={index} />
                                        ))}
                                      </Select>
                                    </View>
                                  </View>
                                </View>
                              )}
                              {xuLyVBhostID.isProcess && (
                                <View style={styles.columnmaxWidth}>
                                  <View style={styles.columnmaxWidth}>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <Text>Chuyển xử lý - optional</Text>
                                      <Button
                                        appearance="ghost"
                                        size="small"
                                        accessoryLeft={LayerIcon}
                                        onPress={() => addXuLyVBHostID()}
                                      ></Button>
                                    </View>
                                    {xuLyVBhostID.index.map((itemG, indexG) => (
                                      <View style={styles.rowmaxWidthPv5} key={indexG}>
                                        <Select
                                          appearance="ioc"
                                          style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                          selectedIndex={new IndexPath(itemG)}
                                          value={xuLyVBhostID.NAME[itemG]}
                                          onSelect={(i: IndexPath) => {
                                            xuLyVBhostID.index[indexG] = i.row;

                                            setXyLyVBHostId({ ...xuLyVBhostID });
                                          }}
                                        >
                                          {xuLyVBhostID.NAME.map((item, index) => (
                                            <SelectItem appearance="ioc" title={item} key={index} />
                                          ))}
                                        </Select>
                                        {indexG > 0 && (
                                          <View
                                            style={{
                                              width: 30,
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              backgroundColor: 'white',
                                              position: 'absolute',
                                              right: 3,
                                              top: 20,
                                              bottom: 20,
                                            }}
                                          >
                                            <Button
                                              appearance="ghost"
                                              size="small"
                                              accessoryLeft={EjectSignIcon}
                                              onPress={() => removeXuLyVBHostID(indexG)}
                                            ></Button>
                                          </View>
                                        )}
                                      </View>
                                    ))}
                                  </View>
                                </View>
                              )}
                              {isNCD && (
                                <View style={styles.columnmaxWidth}>
                                  <View style={styles.columnmaxWidth}>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <Text>Tiếp nhận văn bản</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <View style={{ width: '50%', paddingRight: 10 }}>
                                        <Input
                                          style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                          placeholder="Số đến"
                                          placeholderTextColor={'#787777'}
                                          value={codeName}
                                          onChangeText={(nextValue) => setCodeName(nextValue)}
                                          textStyle={{ width: 100 }}
                                        />
                                      </View>
                                      <Select
                                        appearance="ioc"
                                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                        selectedIndex={selectType}
                                        value={LoaiVB[selectType.row]}
                                        onSelect={(i: IndexPath) => {
                                          setSelectType(i);
                                        }}
                                      >
                                        {LoaiVB?.map((item, index) => (
                                          <SelectItem appearance="ioc" title={item} key={index} />
                                        ))}
                                      </Select>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5_1}>
                                      <View style={{ width: '50%', paddingRight: 10 }}>
                                        <Input
                                          style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                          placeholder="Số văn bản"
                                          placeholderTextColor={'#787777'}
                                          value={documentNumber}
                                          onChangeText={(nextValue) => setDocumentNumber(nextValue)}
                                          textStyle={{ width: 100 }}
                                        />
                                      </View>
                                      <View style={{ width: '50%' }}>
                                        <Input
                                          style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                          placeholder="Người ký"
                                          placeholderTextColor={'#787777'}
                                          value={signatory}
                                          onChangeText={(nextValue) => setSignatory(nextValue)}
                                          textStyle={{ width: 100 }}
                                        />
                                      </View>
                                    </View>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 4 }}
                                    >
                                      <Text>Ngày ban hành</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <View
                                        style={{
                                          width: '100%',
                                          borderWidth: 1,
                                          borderRadius: 5,
                                          borderColor: '#e4e9f2',
                                        }}
                                      >
                                        <Datepicker
                                          appearance="ioc"
                                          style={styles.datePicker}
                                          placeholder={
                                            '' + date.toLocaleString('hi', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('A')
                                          }
                                          accessibilityElementsHidden
                                          onSelect={(nextDate) => setDate(nextDate)}
                                        />
                                      </View>
                                    </View>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 4 }}
                                    >
                                      <Text>Ban giám đốc</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <Select
                                        appearance="ioc"
                                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                        selectedIndex={selectBGD}
                                        value={BGD[selectBGD.row]}
                                        onSelect={(i: IndexPath) => {
                                          setSelectBGD(i);
                                        }}
                                      >
                                        {BGD?.map((item, index) => (
                                          <SelectItem appearance="ioc" title={item} key={index} />
                                        ))}
                                      </Select>
                                    </View>
                                    <View
                                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 4 }}
                                    >
                                      <Text>Lãnh đạo phòng</Text>
                                    </View>
                                    <View style={styles.rowmaxWidthPv5}>
                                      <Select
                                        appearance="ioc"
                                        style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                        selectedIndex={selectLDP}
                                        value={LDP[selectLDP.row]}
                                        onSelect={(i: IndexPath) => {
                                          setSelectLDP(i);
                                        }}
                                      >
                                        {LDP?.map((item, index) => (
                                          <SelectItem appearance="ioc" title={item} key={index} />
                                        ))}
                                      </Select>
                                    </View>
                                  </View>
                                </View>
                              )}

                              <View style={{ width: '100%', paddingVertical: 7 }}>
                                <Input
                                  style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                  multiline={true}
                                  textStyle={{ minHeight: 60 }}
                                  placeholder={placeholderText}
                                  placeholderTextColor={'#787777'}
                                  value={conclusion}
                                  onChangeText={(nextValue) => setConclusion(nextValue)}
                                />
                              </View>
                              {waiting && (
                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    paddingVertical: 13,
                                  }}
                                >
                                  <View
                                    style={{
                                      paddingHorizontal: 5,
                                      backgroundColor: '#5b5fc7',
                                      borderRadius: 3,
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
                                    <Text style={{ color: '#FFFFFF', paddingVertical: 7, paddingHorizontal: 5, fontSize: 13 }}>
                                      Đang xử lý...
                                    </Text>
                                  </View>
                                </View>
                              )}
                              {!waiting && (
                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    paddingVertical: 13,
                                  }}
                                >
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'sign') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#5b5fc7', borderColor: '#5b5fc7' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('sign');
                                        }}
                                      >
                                        Ký Duyệt
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'confirm') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('confirm');
                                        }}
                                      >
                                        Xác nhận
                                      </Button>
                                    </View>
                                  )}

                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'forward') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#6c757d' }}
                                        size="tiny"
                                        appearance="outline"
                                        status="basic"
                                        onPress={() => {
                                          OnClick('forward');
                                        }}
                                      >
                                        Chỉ đạo
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'end') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('end');
                                        }}
                                      >
                                        Đã xem
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'approve') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('approve');
                                        }}
                                      >
                                        Duyệt
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'submit') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('submit');
                                        }}
                                      >
                                        Gửi
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'reject') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#ffc107', borderColor: '#ffc107' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('reject');
                                        }}
                                      >
                                        Từ chối
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'reset') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#8f9bb3' }}
                                        size="tiny"
                                        status="basic"
                                        appearance="ghost"
                                        onPress={() => {
                                          OnClick('reset');
                                        }}
                                      >
                                        YC Hiệu chỉnh
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'cancel') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#ff3d71' }}
                                        size="tiny"
                                        status="danger"
                                        appearance="outline"
                                        onPress={() => {
                                          OnClick('cancel');
                                        }}
                                      >
                                        {isEdit.isTrue ? 'Hủy' : 'Trả hồ sơ'}
                                      </Button>
                                    </View>
                                  )}
                                </View>
                              )}
                            </View>
                          )}
                        </View>
                      )}
                    </View>
                    <View style={{ height: '100%', flexShrink: 1 }}></View>
                  </View>
                </ScrollView>
              ))}
            </ViewPager>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: 5,
                position: 'absolute',
                bottom: 0,
              }}
            >
              {dataTimeLineProcess?.map((item, index) => (
                <View style={{ padding: 2 }} key={index}>
                  <FlagIcon check={index === selectedIndexView.index ? 'true' : 'false'}></FlagIcon>
                </View>
              ))}
            </View>
            <View style={{ height: 26 }}></View>
          </View>
        </Layout>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    color: '#424242',
    margin: 0,
    fontSize: 16,
    lineHeight: 22,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProcess: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCirle: {
    display: 'flex',
    fontSize: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutRow: {
    display: 'flex',
    paddingTop: 8,
    marginTop: 5,
    marginBottom: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 13,
    paddingRight: 13,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    minWidth: 40,
    maxWidth: 40,
    minHeight: 27,
    maxHeight: 27,
    paddingHorizontal: 8,
    flexShrink: 1,
    justifyContent: 'center',
    borderColor: '#dee2e6',
    borderRadius: 50,
    marginVertical: 2.5,
  },
  maxLayout: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  datePicker: {
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
  },
  headerFileView: {
    paddingLeft: 13,
    paddingRight: 13,
    marginRight: 7,
    flexDirection: 'column',
    paddingBottom: 5,
  },
  fileView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 2,
  },
  columnmaxWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  rowmaxWidthPb5: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5,
    width: '100%',
  },
  columnPl5: {
    flexDirection: 'column',
    paddingLeft: 7,
  },
  rowCenterPt5: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 2,
  },
  columnView: {
    flexDirection: 'column',
    flexShrink: 1,
  },
  columnmaxWidthStart: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flexShrink: 1,
  },
  width60maxHeight: {
    minWidth: 60,
    alignItems: 'flex-start',
    height: '100%',
  },
  Pv5Center: {
    paddingVertical: 7,
    alignItems: 'center',
  },
  menuIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxHeightStart: {
    alignItems: 'flex-start',
    height: '100%',
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  rowmaxWidth: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnmaxLayout: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexShrink: 1,
  },
  maxLayout1: {
    height: '100%',
    width: '100%',
  },
  paddingH5: {
    paddingLeft: 10,
  },
  alignStartStart: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  maxWidthnotShrink: {
    width: '100%',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowmaxWidthPv5: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  rowmaxWidthPv5_1: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: 3,
    paddingBottom: 5,
  },
  ChidaoView: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 13
  },
  labelFile: {
    fontStyle: 'italic',
  },
  processView: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 13,
    paddingVertical: 8,
  },
  loadingIcon: {
    transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }],
  },
});
