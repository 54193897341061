import {
  Button,
  Card,
  Datepicker,
  IndexPath,
  Input,
  Layout,
  List,
  ListItem,
  MenuItem,
  OverflowMenu,
  Spinner,
  Text,
} from '@ui-kitten/components';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { navigationContext } from '../../hooks/navigation';
import { signContext } from '../../hooks/signingprocess';
import { agendaId, getAgenda } from '../../services/agendaprocess';
import { putSeen } from '../../services/signingprocess';
import {
  AllIcon,
  AllRenderIcon,
  DoneIcon,
  DoneRenderIcon,
  EjectIcon,
  EjectRenderIcon,
  OpenIcon,
  OpenRenderIcon,
  SearchIcon,
  SearchRenderIcon,
} from '../../share/filter-icon';

const FlagIcon = (props) => (
  <Icon1
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}
    name="circle"
    color={props.check === 1 ? '#12B76A' : props.check === 2 ? '#0d6efd' : props.check === 3 ? '#dc3545' : '#BDBDBD'}
  />
);

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 576 512">
    <Path
      d="M320 32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H296.2c9.8 11.8 21 22.3 33.5 31.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V198.6c-11.2 3.2-21.9 7.4-32 12.6V64c0-17.7-14.3-32-32-32zM64 144c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm16 80H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H208c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zM432 480a112 112 0 1 0 0-224 112 112 0 1 0 0 224zm0-256a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm44.7 100.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7z"
      fill="#424242"
    />
  </Svg>
);

const SortDownIcon = (props) => (
  <Icon1
    {...props}
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      minWidth: 16,
      minHeight: 16,
    }}
    name="caret-down"
    color="#424242"
  />
);

const SortUpIcon = (props) => (
  <Icon1
    {...props}
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      minWidth: 16,
      minHeight: 16,
    }}
    name="caret-up"
    color="#424242"
  />
);

export default (props) => {
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = React.useState(false);

  const showWaiting = () => {
    // setIsWaiting(true);
    // setTimeout(() => setIsWaiting(false), 500);
  };

  useEffect(() => {
    // setTimeout(() => setIsWaiting(false), 500);
  }, []);

  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));

  const [selectedProcess, setSelectedProcess] = React.useState<string>(agendaId.all);
  const [visible, setVisible] = React.useState(false);
  const rootPact = useQuery(['agendas', selectedProcess], () => getAgenda(selectedProcess));
  const [pactProcessed2, setPactProcessed2] = React.useState({
    currentData: null,
    preData: null,
    newData: null,
    isNewData: false,
    isCreate: false,
    isReset: false,
    isLoadOld: false,
    index: 0,
  });
  const { units, cohorts } = useContext(authContext);
  const [isSortName, setIsSortName] = React.useState(false);
  const [isSortDate, setIsSortDate] = React.useState(false);
  const [sortType, setSortType] = React.useState(-1);
  const [isSortDateTime, setIsSortDateTime] = React.useState(false);
  const useDatepickerState = (initialDate = null) => {
    const [date, setDate] = React.useState(initialDate);
    return { date, onSelect: setDate };
  };

  const datepickerState = useDatepickerState();
  useEffect(() => {
    if (sign.index != null) {
      setSelectedIndex(new IndexPath(sign.index));
      sign.setNullIndex();
    }
  }, []);

  const onItemSelect = (index) => {
    if (index.row === selectedIndex.row) {
      setVisible(false);
      return;
    }
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });

    if (index?.row === 0) {
      setIsSortDate(false);
      setIsSortDateTime(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(agendaId.all);
    } else if (index?.row === 1) {
      setIsSortDate(false);
      setIsSortDateTime(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(agendaId.process);
    } else if (index?.row === 2) {
      setIsSortDate(false);
      setIsSortDateTime(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(agendaId.done);
    } else if (index?.row === 3) {
      setIsSortDate(false);
      setIsSortDateTime(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(agendaId.cancel);
    } else if (index?.row === 4) {
      setIsSortDate(false);
      setIsSortDateTime(false);
      setIsSortName(false);
      setIsShowSearch(false);
      setIsSearch(true);
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 0);
    showWaiting();
    setVisible(false);
  };

  const [isShowSearch, setIsShowSearch] = React.useState(true);
  const [isSearch, setIsSearch] = React.useState(false);
  const [searchName, setSearchName] = React.useState('');

  const onClickSearch = () => {
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    const id = searchName != '' ? '&keyword=' + searchName : '';
    const temptime = new Date(datepickerState.date);
    const timestring = datepickerState.date ? '&time=' + temptime.getTime() : '';
    setSelectedProcess('/?spec=Agenda-BTB&pgSkip=0&pgLimit=40' + id + timestring);
    setTimeout(() => {
      setSelectedIndex(new IndexPath(4));
    }, 20);
    setIsShowSearch(true);
    showWaiting();
  };

  const FiltersIcon = (props) => <Icon {...props} style={styles.iconHeader} color="#5b5fc7" />;

  const renderToggleButton = () => (
    <Button
      style={styles.filterButton}
      size="tiny"
      appearance="ghost-filter"
      onPress={() => setVisible(true)}
      accessoryRight={
        selectedIndex.row === 0 ? (
          <AllRenderIcon></AllRenderIcon>
        ) : selectedIndex.row === 1 ? (
          <OpenRenderIcon></OpenRenderIcon>
        ) : selectedIndex.row === 2 ? (
          <DoneRenderIcon></DoneRenderIcon>
        ) : selectedIndex.row === 3 ? (
          <EjectRenderIcon></EjectRenderIcon>
        ) : (
          <SearchRenderIcon></SearchRenderIcon>
        )
      }
    >
      {selectedIndex.row === 0
        ? 'Tất cả'
        : selectedIndex.row === 1
        ? 'Đang mở'
        : selectedIndex.row === 2
        ? 'Kết thúc'
        : selectedIndex.row === 3
        ? 'Đã hủy'
        : 'Tra cứu'}
    </Button>
  );

  useEffect(() => {
    if (rootPact.isFetching) return;
    if (rootPact.data === undefined) return;
    if (units === null) return;
    if (cohorts === null) return;
    const data = rootPact.data
      ?.sort((itemA, itemB) => {
        if (isSortDate) return itemA.createdAt > itemB.createdAt ? 1 : -1;
        else return itemA.createdAt < itemB.createdAt ? 1 : -1;
      })
      .map((item, index, array) => {
        const actionlast = item?.progresses?.at(item?.progresses?.length - 1);

        const timeStart = new Date(item.createdAt);
        const timeMeeting = new Date(item.time);
        item.pendingForUsers?.length > 0
          ? (item.handle =
              item.pendingForUsers?.find((id) => id === auth.user._id) != undefined
                ? auth.user.firstname + ' ' + auth.user.lastname.split(' ')[0]
                : auth.users?.find((user) => user._id === item.pendingForUsers?.at(0))?.firstname +
                  ' ' +
                  auth.users?.find((user) => user._id === item.pendingForUsers?.at(0))?.lastname?.split(' ')[0])
          : item.pendingForUnits?.length > 0
          ? (item.handle = units?.find((unit) => unit._id === item.pendingForUnits?.at(0))?.code)
          : item.pendingForCohorts?.length > 0
          ? (item.handle = cohorts?.find((cohort) => cohort._id === item.pendingForCohorts?.at(0))?.code)
          : (item.handle = '-');

        let start = -1;
        for (let i = 0; i < item.location?.length; i++) {
          if (start != -1 && i === item.location.length - 1 && item.location?.at(i) >= '0' && item.location?.at(i) <= '9') {
            item.locationShow = item.location?.slice(start);
            break;
          } else if (start != -1 && (item.location?.at(i) < '0' || item.location?.at(i) > '9')) {
            item.locationShow = item.location?.slice(start, i);
            break;
          } else if (start === -1 && i === item.location.length - 1 && item.location?.at(i) >= '0' && item.location?.at(i) <= '9') {
            item.locationShow = item.location?.slice(start);
            break;
          } else if (start === -1 && item.location?.at(i) >= '0' && item.location?.at(i) <= '9') {
            start = i;
          } else if (i === item.location.length - 1) {
            item.locationShow = item.location;
          }
        }

        if (item?.seen[auth.user._id] === undefined) {
          item.isSeen = false;
        } else {
          if (item?.modifiedBy === auth.user._id) {
            item.isSeen = true;
          } else if (item?.seen[auth.user._id] > item?.modifiedAt) {
            item.isSeen = true;
          } else item.isSeen = false;
        }

        if (item?.status === 'initial') {
          item.tagName = 'Đang soạn';
        } else if (item?.status === 'closed') {
          item.tagName = 'Kết thúc';
        } else if (item?.status === 'cancelled') {
          item.tagName = 'Đã hủy';
        } else {
          item.tagName = 'Đang mở';
        }

        const date = timeStart.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
        const dateMeeting = timeMeeting.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
        item.date = date;
        item.dateMeeting = dateMeeting;
        return item;
      });

    pactProcessed2.newData = data;
    pactProcessed2.isNewData = true;
    setPactProcessed2({ ...pactProcessed2 });
  }, [rootPact.isFetched, rootPact.isFetching, selectedIndex, auth, auth.user, auth.users, units, cohorts]);

  useEffect(() => {
    if (!pactProcessed2.isCreate && pactProcessed2.isNewData) {
      pactProcessed2.currentData = pactProcessed2.newData;
      pactProcessed2.preData = pactProcessed2.newData;
      pactProcessed2.isNewData = false;
      pactProcessed2.isCreate = true;
      pactProcessed2.index = pactProcessed2.newData?.length > 39 ? 1 : -1;
      pactProcessed2.newData = null;
      setPactProcessed2({ ...pactProcessed2 });
    } else if (pactProcessed2.isNewData && JSON.stringify(pactProcessed2.newData) === JSON.stringify(pactProcessed2.preData)) {
      pactProcessed2.newData = null;
      pactProcessed2.isNewData = false;
      setPactProcessed2({ ...pactProcessed2 });
    } else if (
      pactProcessed2.isNewData &&
      JSON.stringify(pactProcessed2.newData) != JSON.stringify(pactProcessed2.preData) &&
      pactProcessed2.isLoadOld
    ) {
      pactProcessed2.currentData = [...pactProcessed2.currentData, ...pactProcessed2.newData];
      pactProcessed2.preData = pactProcessed2.newData;
      pactProcessed2.isNewData = false;
      pactProcessed2.isLoadOld = false;
      pactProcessed2.index = pactProcessed2.newData?.length > 39 ? pactProcessed2.index + 1 : -1;
      pactProcessed2.newData = null;
      setPactProcessed2({ ...pactProcessed2 });
    }
  }, [pactProcessed2]);

  const onClickSortName = () => {
    setIsSortName(isSortName ? false : true);
    if (isSortName) {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.name < itemB.name ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    } else {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.name > itemB.name ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    }
  };

  const onClickSortDateTime = () => {
    setIsSortDateTime(isSortDateTime ? false : true);
    if (isSortDateTime) {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.time < itemB.time ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    } else {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.time > itemB.time ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    }
  };

  const navigation = useContext(navigationContext);
  const [navigationIndex, setNavigationIndex] = React.useState(false);

  useEffect(() => {
    if (navigation.state.indexState === -1) return;
    if (navigation.state.indexState === 4) return;
    if (navigationIndex) return;
    setNavigationIndex(true);
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    if (navigation.state.indexState === 0) {
      setSelectedProcess(agendaId.all);
    } else if (navigation.state.indexState === 1) {
      setSelectedProcess(agendaId.process);
    } else if (navigation.state.indexState === 2) {
      setSelectedProcess(agendaId.done);
    } else if (navigation.state.indexState === 3) {
      setSelectedProcess(agendaId.cancel);
    }
    setTimeout(() => {
      setSelectedIndex(new IndexPath(navigation.state.indexState));
    }, 20);
  }, [navigation, navigation.state]);

  const renderItem = ({ item, index }) => {
    let name = item.name;
    const stringReplace = 'BAN GIÁM ĐỐC';
    const indexString = item.name?.toUpperCase()?.indexOf(stringReplace);
    if (indexString != -1 && item.name != '' && item.name != null) {
      name = item.name?.slice(0, indexString) + 'BGĐ' + item.name?.slice(indexString + stringReplace.length);
    }
    return (
      <ListItem
        style={{
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onPress={() => {
          navigation.setIndexState(selectedIndex.row);
          putSeen(item?._id, 'Agenda');
          navigate(`/agendas/${item?._id}`);
        }}
        key={index}
      >
        <View style={styles.layoutBorder}>
          <View style={styles.layoutColumnLeft45}>
            <Text
              style={{ fontSize: 14, fontWeight: item?.isSeen ? '400' : '600', color: item?.isSeen ? '#222' : '#424242' }}
              numberOfLines={1}
              category="s1"
            >
              {name}
            </Text>
            <View style={{ padding: 3 }}></View>
            <Text style={{ color: '#667085', fontSize: 13 }} numberOfLines={1}>
              {item.locationShow != '-' && item.locationShow != null
                ? !isNaN(item?.location) && !isNaN(parseFloat(item?.location))
                  ? 'Phòng ' + item.locationShow
                  : item.locationShow
                : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter25}>
            <FlagIcon check={item?.status === 'initial' || item?.status === 'open' ? 1 : 0}></FlagIcon>
          </View>
          <View style={styles.layoutCenter30}>
            <Text style={{ fontSize: 14, marginRight: 18 }}>
              {item.dateMeeting != '' && item.dateMeeting != null ? item.dateMeeting : '01-01-1997'}
            </Text>
          </View>
        </View>
      </ListItem>
    );
  };

  return (
    <Layout style={{ flex: 1, flexDirection: 'column' }} nativeID="layoutAgenda">
      <Layout style={styles.mainLayout}>
        <View style={styles.headerView}>
          <View style={styles.layoutRow}>
            <Text style={styles.textHeader} category="s1">
              HỌP BAN GIÁM ĐỐC
            </Text>
          </View>
        </View>

        <View style={{}}>
          <OverflowMenu
            anchor={renderToggleButton}
            visible={visible}
            selectedIndex={selectedIndex}
            onSelect={onItemSelect}
            onBackdropPress={() => setVisible(false)}
          >
            <MenuItem appearance="file-menu" accessoryLeft={AllIcon} title="Tất cả" />
            <MenuItem appearance="file-menu" accessoryLeft={OpenIcon} title="Đang mở" />
            <MenuItem appearance="file-menu" accessoryLeft={DoneIcon} title="Kết thúc" />
            <MenuItem appearance="file-menu" accessoryLeft={EjectIcon} title="Đã hủy" />
            <MenuItem appearance="file-menu" accessoryLeft={SearchIcon} title="Tra cứu" />
          </OverflowMenu>
        </View>
      </Layout>
      <View style={styles.maxlayout}>
        {isWaiting && (
          <View
            // @ts-ignore
            style={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '80%',
              width: '100%',
              background: 'white',
              zIndex: 999,
            }}
          >
            <Spinner appearance="ioc" status="primary" />
          </View>
        )}
        {isSearch && (
          <View style={styles.searchView}>
            {!isShowSearch && (
              <Input
                style={{ paddingVertical: 5 }}
                placeholder="Từ khóa"
                value={searchName}
                onChangeText={(nextValue) => setSearchName(nextValue)}
              />
            )}
            {!isShowSearch && (
              <View style={{ paddingVertical: 5, paddingBottom: 10 }}>
                <Datepicker
                  appearance={datepickerState.date === null ? 'ioc-Agenda' : 'ioc-Agenda1'}
                  style={styles.datePicker}
                  placeholder={'Ngày họp'}
                  {...datepickerState}
                />
              </View>
            )}
            {!isShowSearch && (
              <Button
                style={{ borderColor: '#dee2e6', borderRadius: 48, maxHeight: 40 }}
                status="basic"
                appearance="ghost"
                accessoryLeft={SearchIcon}
                onPress={() => onClickSearch()}
              >
                Search
              </Button>
            )}
            {isShowSearch && (
              <Button
                style={{ borderColor: '#dee2e6', borderRadius: 48, minHeight: 30, maxHeight: 30, marginTop: -10, marginBottom: 3 }}
                status="basic"
                appearance="ghost"
                onPress={() => setIsShowSearch(false)}
              >
                SEARCH LẠI
              </Button>
            )}
          </View>
        )}
        {isShowSearch &&
          pactProcessed2.isCreate &&
          (pactProcessed2.currentData?.length > 0 ? (
            <View style={styles.layoutColumn}>
              <View style={styles.layoutRowHeader}>
                <View style={styles.layoutRowLeft45}>
                  <Button
                    style={styles.buttonMall}
                    size="small"
                    appearance="left"
                    onPress={() => {
                      setSortType(0);
                      onClickSortName();
                    }}
                    accessoryRight={sortType === 0 ? (isSortName ? SortUpIcon : SortDownIcon) : null}
                  >
                    Chủ đề
                  </Button>
                </View>
                <View style={styles.layoutCenter25}>
                  <Text style={{ fontSize: 14 }}>Tr. Thái</Text>
                </View>
                <View style={styles.layoutCenter30}>
                  <Button
                    style={styles.buttonMallDay}
                    size="small"
                    appearance="left"
                    onPress={() => {
                      setSortType(1);
                      onClickSortDateTime();
                    }}
                    accessoryRight={sortType === 1 ? (isSortDateTime ? SortUpIcon : SortDownIcon) : null}
                  >
                    Ngày họp
                  </Button>
                </View>
              </View>
              {pactProcessed2.isLoadOld && (
                <View style={styles.loadingView}>
                  <ActivityIndicator style={styles.loadingIcon} size="small" color="#424242" />
                  <Text style={{ color: '#424242', paddingVertical: 5, paddingLeft: 5, fontSize: 16 }}>Loading...</Text>
                </View>
              )}
              <List
                nativeID="chatView"
                onScroll={(evt) => {
                  setTimeout(() => {
                    if (
                      (selectedIndex.row === 0 || selectedIndex.row === 2 || selectedIndex.row === 3 || selectedIndex.row === 4) &&
                      evt.nativeEvent.contentOffset.y >=
                        window.document.getElementById('chatView')?.firstElementChild?.clientHeight -
                          window.document.getElementById('chatView')?.clientHeight +
                          8 -
                          40 &&
                      pactProcessed2.index != -1 &&
                      !pactProcessed2.isLoadOld &&
                      pactProcessed2.isCreate
                    ) {
                      pactProcessed2.isLoadOld = true;
                      setPactProcessed2({ ...pactProcessed2 });
                      setTimeout(() => {
                        if (selectedIndex.row === 0) {
                          setSelectedProcess('/?spec=Agenda-BTB&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 2) {
                          setSelectedProcess(
                            '/?spec=Agenda-BTB&status=closed&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40'
                          );
                        } else if (selectedIndex.row === 3) {
                          setSelectedProcess(
                            '/?spec=Agenda-BTB&status=cancelled&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40'
                          );
                        } else if (selectedIndex.row === 4 && isShowSearch) {
                          const id = searchName != '' ? '&keyword=' + searchName : '';
                          const temptime = new Date(datepickerState.date);
                          const timestring = datepickerState.date ? '&time=' + temptime.getTime() : '';
                          setSelectedProcess(
                            '/?spec=Agenda-BTB&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40' + id + timestring
                          );
                        }
                      }, 20);
                    }
                  }, 50);
                }}
                scrollEnabled={!pactProcessed2.isLoadOld}
                style={{
                  maxHeight: '100%',
                  width: '100%',
                }}
                data={pactProcessed2.currentData}
                renderItem={renderItem}
              />
            </View>
          ) : (
            <Card style={{ borderWidth: 0 }}>
              <View style={styles.nullView}>
                <NullIcon></NullIcon>
              </View>
              <View style={{ padding: 6 }}></View>
              <Text style={{ textAlign: 'center' }}>Chưa có phiên họp liên quan</Text>
            </Card>
          ))}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    margin: 2,
  },
  icon: {
    minHeight: 14,
    minWidth: 18,
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMallDay: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 80,
  },
  layoutRow: {
    display: 'flex',
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft45: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 5,
  },
  layoutColumnLeft45: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '25%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 5,
  },
  layoutCenter25: {
    display: 'flex',
    width: '17%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 40,
    backgroundColor: '#5b5fc7',
    borderRadius: 40,
  },

  headerView: {
    flexShrink: 1,
    width: '100%',
    paddingRight: 8,
    minHeight: 45,
    justifyContent: 'center',
  },

  mainLayout: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  filterButton: {
    marginHorizontal: 4,
    marginLeft: 4,
    marginRight: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchView: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  loadingView: {
    zIndex: 1000,
    backgroundColor: '#FFFFFF',
    borderWidth: 0,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    justifyContent: 'center',
  },
  loadingIcon: { transform: [{ scaleX: 0.5 }, { scaleY: 0.5 }], paddingTop: 8 },
  maxlayout: {
    width: '100%',
    height: '100%',
    flexShrink: 1,
  },
  nullView: { width: '100%', justifyContent: 'center', alignItems: 'center' },
  datePicker: {
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
  },
});
