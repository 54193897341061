import { createContext, useState } from 'react';

export const navigationContext = createContext<INavigationContext>(null);

export enum EBottomTab {
  PACT = 0,
  FILE = 1,
  MEETING = 2,
  TASK = 3,
  SCHEDULE = 4,
  CHAT = 5,
  PROFILE = 6,
}

export interface NavigationState {
  bottomTab: EBottomTab;
  isShowSideBar: boolean;
  sideBarState: {
    [tab: string]: any;
  };
  indexState: number;
  role: { flag: boolean; isCQShow: boolean; isDangShow: boolean; isDVShow: boolean, isCSDShow: boolean };
  _schedule_editors: boolean;
}

export interface INavigationContext {
  state: NavigationState;
  setBottomTab: (newState: EBottomTab) => void;
  setShowSideBar: (newState: boolean) => void;
  setIndexState: (index: number) => void;
  setIndexStateAndShowSideBar: (index: number, flag: boolean) => void;
  setSideBarState: (tab: EBottomTab, item: any, dontclose: boolean) => void;
  setRole: (flag: boolean, isCQShow: boolean, isDangShow: boolean, isDVShow: boolean, isCSDShow: boolean) => void;
  set_schedule_editors: () => void;
}

export const useNavigationProvider = (): INavigationContext => {
  const [navigation, setNavigation] = useState<NavigationState>({
    bottomTab: EBottomTab.PACT,
    isShowSideBar: false,
    sideBarState: {},
    indexState: -1,
    role: { flag: false, isCQShow: false, isDangShow: false, isDVShow: false, isCSDShow:false },
    _schedule_editors: false,
  });

  const setRole = (flag: boolean, isCQShow: boolean, isDangShow: boolean, isDVShow: boolean, isCSDShow: boolean) => {
    if (!flag) {
      navigation.role.flag = false;
      navigation.role.isCQShow = false;
      navigation.role.isDangShow = false;
      navigation.role.isDVShow = false;
      navigation.role.isCSDShow = false;
      setNavigation({ ...navigation });
      return;
    }
    if (navigation.role.flag) {
      return;
    }
    navigation.role.flag = true;
    navigation.role.isCQShow = isCQShow;
    navigation.role.isDangShow = isDangShow;
    navigation.role.isDVShow = isDVShow;
    navigation.role.isCSDShow = isCSDShow;
    setNavigation({ ...navigation });
  };

  const set_schedule_editors = () => {
    if (navigation._schedule_editors) {
      return;
    }
    navigation._schedule_editors = true;
    setNavigation({ ...navigation });
  };

  const setIndexState = (index: number) => {
    navigation.indexState = index;
    setNavigation({ ...navigation });
  };

  const setIndexStateAndShowSideBar = (index, flag) => {
    navigation.indexState = index;
    navigation.isShowSideBar = flag;
    setNavigation({ ...navigation });
  };

  const setBottomTab = (newState: EBottomTab) => {
    setNavigation({
      ...navigation,
      bottomTab: newState,
    });
  };

  const setShowSideBar = (newState: boolean) => {
    setNavigation({
      ...navigation,
      isShowSideBar: newState,
    });
  };

  const setSideBarState = (tab: EBottomTab, item: number, dontclose: boolean) => {
    if (dontclose)
      setNavigation({
        ...navigation,
        sideBarState: {
          ...navigation.sideBarState,
          [navigation.bottomTab]: item,
        },
      });
    else
      setNavigation({
        ...navigation,
        sideBarState: {
          ...navigation.sideBarState,
          [navigation.bottomTab]: item,
        },
        isShowSideBar: false,
      });
  };

  return {
    state: navigation,
    set_schedule_editors,
    setRole,
    setIndexStateAndShowSideBar,
    setIndexState,
    setBottomTab,
    setShowSideBar,
    setSideBarState,
  };
};

export const NavigationProvider = ({ children }) => {
  const navigation = useNavigationProvider();

  return <navigationContext.Provider value={navigation}>{children}</navigationContext.Provider>;
};
