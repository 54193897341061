import { Layout } from '@ui-kitten/components';
import React, { useContext, useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { navigationContext } from '../../hooks/navigation';
import { BottomTab } from './BottomNavigation';
import { SideBar } from './SideBar';
import { TopNavigation } from './TopNavigation';

declare global {
  interface Window {
    lastheight: number;
    initHeight: number;
    shouldScrollUp: boolean;
    ReactNativeWebView: any;
  }
}

window.lastheight = window.visualViewport.height;
window.initHeight = Dimensions.get('window').height;
window.shouldScrollUp = false;

export const MainLayout = ({ children }) => {
  const [height, setHeight] = useState(Dimensions.get('window').height);

  useEffect(() => {
    setInterval(() => {
      if (window.lastheight < window.initHeight) {
        if (window.visualViewport.height == window.initHeight) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'instant',
          });
        }
      }

      if (window.visualViewport.height != window.lastheight) {
        window.lastheight = window.visualViewport.height;
      }
    }, 10);
  }, []);

  const styles = StyleSheet.create({
    mainLayout: {
      flex: 1,
      flexDirection: 'column',
      height: height,
    },
    mainScreen: {
      flex: 1,
      flexDirection: 'column',
      height: '100%',
    },
    viewPoint: {
      flexShrink: 1,
      height: height - 66 - 66,
      marginTop: 66,
    },
  });

  const navigation = useContext(navigationContext);
  const [forceRender, setForeceRender] = React.useState(0);

  React.useEffect(() => {
    window.onpopstate = () => {
      setForeceRender(Math.random());
    };
  }, []);

  return (
    <Layout style={styles.mainLayout} nativeID="mainLayout">
      <Layout style={styles.mainScreen}>
        <TopNavigation />

        <View style={styles.viewPoint} nativeID="content">
          {children}
        </View>

        <BottomTab key={forceRender} />
      </Layout>

      {navigation.state.isShowSideBar && <SideBar />}
    </Layout>
  );
};
