import {
  Autocomplete,
  AutocompleteItem,
  Button,
  ButtonGroup,
  Card,
  Input,
  Layout,
  List,
  ListItem,
  MenuItem,
  Modal,
  OverflowMenu,
  Spinner,
  Text,
} from '@ui-kitten/components';
import * as DocumentPicker from 'expo-document-picker';
import React, { useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import IconF from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useQuery } from 'react-query';
import HeaderTitle from '../../components/HeaderTitle';
import { authContext, User } from '../../hooks/authentication';
import {
  createFolder,
  deleteFileOrFolder,
  downloadFile as dlFile,
  getFolder,
  renameFileOrFolder,
  shareFileOrFolder,
  uploadFile,
} from '../../services/files';
import { FileMoreMenu } from '../../share/fiile-more-menu';
import {
  EjectIcon,
  EjectWhiteIcon,
  FileExelFileIcon,
  FileFolderIcon,
  FileImageIcon,
  FilePdfFileIcon,
  FilePowerPointIcon,
  FileUnknownFileIcon,
  FileWordFileIcon,
  PlusIcon,
} from '../../share/filter-icon';
import { canOpen, isPdf } from '../../utils/files';
import { isChromeAndroid } from '../../utils/browser';

const RightIcon = (props) => <IconF {...props} style={styles.iconHeader} name="chevron-right" color="#424242" />;

const DeleteIcon = (props) => (
  <Svg width={26} height={15.6} viewBox="0 0 448 512">
    <Path
      d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
      fill="#FFFFFF"
    />
  </Svg>
);

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 512 512">
    <Path
      d="M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
      fill="#424242"
    />
  </Svg>
);

const NewFileIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M448 160v32h32V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384h80c19.6 0 37.3-11.9 44.6-30.2l64-160c5.9-14.8 4.1-31.5-4.8-44.7S543.9 224 528 224H128c-19.6 0-37.3 11.9-44.6 30.2L32 382.8V96c0-17.7 14.3-32 32-32H181.5c8.5 0 16.6 3.4 22.6 9.4l22.6-22.6L204.1 73.4l26.5 26.5c18 18 42.4 28.1 67.9 28.1H416c17.7 0 32 14.3 32 32zM384 448H64c-5.3 0-10.3-2.6-13.2-7s-3.6-10-1.6-14.9l64-160c2.4-6.1 8.3-10.1 14.9-10.1H528c5.3 0 10.3 2.6 13.2 7s3.6 10 1.6 14.9l-64 160C476.4 444 470.5 448 464 448H384z"
      fill="#424242"
    />
  </Svg>
);

const UploadIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 384 512" style={styles.menuIcon}>
    <Path
      d="M203.3 116.7C200.2 113.6 196.1 112 192 112S183.8 113.6 180.7 116.7l-144 144c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0L176 166.6V464c0 8.844 7.156 16 16 16s16-7.156 16-16V166.6l116.7 116.7c6.25 6.25 16.38 6.25 22.62 0s6.25-16.38 0-22.62L203.3 116.7zM368 32h-352C7.156 32 0 39.16 0 48S7.156 64 16 64h352C376.8 64 384 56.84 384 48S376.8 32 368 32z"
      fill="#424242"
    />
  </Svg>
);

const ABIcon = (props) => (
  <Svg width={26} height={15.6} viewBox="0 0 640 512">
    <Path
      d="M64 112c-8.8 0-16 7.2-16 16V384c0 8.8 7.2 16 16 16H576c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H64zM0 128C0 92.7 28.7 64 64 64H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm184 32c9.5 0 18.1 5.6 21.9 14.3l64 144c5.4 12.1-.1 26.3-12.2 31.7s-26.3-.1-31.7-12.2l-4.3-9.7H146.3l-4.3 9.7c-5.4 12.1-19.6 17.6-31.7 12.2s-17.6-19.6-12.2-31.7l64-144c3.9-8.7 12.4-14.3 21.9-14.3zm0 83.1L167.6 280h32.8L184 243.1zM304 184c0-13.3 10.7-24 24-24h52c33.1 0 60 26.9 60 60c0 9.2-2.1 17.9-5.8 25.7c13.3 11 21.8 27.6 21.8 46.3c0 33.1-26.9 60-60 60H328c-13.3 0-24-10.7-24-24v-8V256 192v-8zm48 24v24h28c6.6 0 12-5.4 12-12s-5.4-12-12-12H352zm0 96h44c6.6 0 12-5.4 12-12s-5.4-12-12-12H380 352v24z"
      fill="#FFFFFF"
    />
  </Svg>
);

const XIcon = (props) => <Icon {...props} style={styles.icon} name="times" color="#FFFFFF" />;

export const getFileOrFolderIcon = (originalName: string, type: string) => {
  const fileName = originalName.toLowerCase();

  let IconType = <FileFolderIcon></FileFolderIcon>;

  if (type === 'file') {
    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      IconType = <FileWordFileIcon></FileWordFileIcon>;
    } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      IconType = <FilePowerPointIcon></FilePowerPointIcon>;
    } else if (fileName.endsWith('.pdf')) {
      IconType = <FilePdfFileIcon></FilePdfFileIcon>;
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      IconType = <FileExelFileIcon></FileExelFileIcon>;
    } else if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.gif') ||
      fileName.endsWith('.psd') ||
      fileName.endsWith('.ai')
    ) {
      IconType = <FileImageIcon></FileImageIcon>;
    } else {
      IconType = <FileUnknownFileIcon></FileUnknownFileIcon>;
    }
  }

  return IconType;
};

export default (props) => {
  const auth = useContext(authContext);

  const [currentFolderId, setCurrentFolerId] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedData, setSelectedData] = useState<any>();
  const rootFolder = useQuery(['files', currentFolderId], () => getFolder(currentFolderId));
  const { users } = useContext(authContext);
  const [isShowAddMenu, setIsShowAddMenu] = useState<boolean>(false);
  const [isShowRenameModal, setIsShowRenameModal] = useState<boolean>(false);
  const [isShowShareModal, setIsShowShareModal] = useState<boolean>(false);
  const [isShowCreateFolder, setIsShowCreateFolder] = useState<boolean>(false);
  const [newFileFolderName, setNewFileOrFolderName] = useState<string>('');

  const [folderPaths, setFolderPaths] = useState([{ id: '', name: 'root' }]);

  const [shareQueryUser, setShareQueryUser] = useState('');

  const [filteredUser, setFilteredUser] = useState<User[]>(null);

  useEffect(() => {
    if (auth.users?.length <= 0) return;
    if (filteredUser != null) return;
    setFilteredUser(auth.users);
  }, [auth, auth.users]);

  const [selectedShareUsers, setSelectedShareUsers] = useState<{ _id: string; fullname: string }[]>([]);

  useEffect(() => {
    if (selectedData && selectedData.sharings) {
      setSelectedShareUsers(selectedData?.sharings?.map((id) => users.find((user) => user._id === id)));
    }
  }, [selectedId, selectedData?._id]);

  const createNewFolder = () => {
    createFolder({ parentId: currentFolderId != '' ? currentFolderId : '/', name: newFileFolderName }).then(() => {
      setIsShowCreateFolder(false);
      rootFolder.refetch();
    });
  };

  const handleShareFileOrFolder = () => {
    shareFileOrFolder({
      id: selectedId,
      sharings: selectedShareUsers.map((user) => user._id),
    }).then(() => {
      setIsShowShareModal(false);
    });
  };

  const uploadNewFile = async () => {
    const result = await DocumentPicker.getDocumentAsync({});
    const formData = new FormData();
    formData.append('parentId', currentFolderId != '' ? currentFolderId : '/');
    formData.append('sharings', '');
    formData.append('filedata', (result as any).file);

    await uploadFile({ data: formData });

    rootFolder.refetch();
    setIsShowAddMenu(false);
  };

  const renameFile = async (id, newName) => {
    await renameFileOrFolder({ id, name: newName });

    rootFolder.refetch();
    setIsShowRenameModal(false);
  };

  const downloadFile = async (id, filename, isOpen = false) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!isOpen || !canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf') && isOpen) {
        newHref = '/filepreview.html?fileid=' + id;

      }
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!isOpen || !canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const deleteFile = async (id) => {
    await deleteFileOrFolder({ id });

    rootFolder.refetch();
  };
  const [isShowDeleteModal, setIsShowDeleteModal] = React.useState(false);
  const [seletedDeleteId, setSeletedDeleteId] = React.useState('');

  const onclickDelete = (id) => {
    setIsShowDeleteModal(true);
    setSeletedDeleteId(id);
  };

  const renderFiles = ({ item, index }) => {
    const createdAt = new Date(item.createdAt);

    const description = `${
      createdAt.toLocaleString('hi', { day: '2-digit' }) +
      ' tháng ' +
      createdAt.toLocaleString('hi', { month: '2-digit' }) +
      ', ' +
      createdAt.getFullYear()
    }`;

    let IconType = getFileOrFolderIcon(item.name, item.type);
    return (
      <View style={styles.fileView}>
        <FileMoreMenu
          handleDeleteFile={() => onclickDelete(item?._id)}
          handleRenameMenu={() => {
            setSelectedId(item._id);
            setSelectedData(item);
            setIsShowRenameModal(true);
            setNewFileOrFolderName(item.name);
          }}
          handleOpen={() => {
            if (item.type === 'directory') {
              setCurrentFolerId(item._id);
              setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
            } else {
              downloadFile(item._id, item.name, true);
            }
          }}
          handleDownloadFile={() => {
            if (item.type === 'directory') {
              setCurrentFolerId(item._id);
              setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
            } else {
              downloadFile(item._id, item.name);
            }
          }}
          handleShowDownload={(flag: boolean) => {
            flag = item.type === 'directory' ? false : true;
            return flag;
          }}
          handleShareFile={() => {
            setSelectedId(item._id);
            setSelectedData(item);
            setIsShowShareModal(true);
          }}
          AnchorElement={(props) => (
            <ListItem
              title={
                <View>
                  <Text style={{ fontSize: 14, fontWeight: '400', color: '#222' }}>{item.name}</Text>
                  <View style={{ padding: 3 }}></View>
                </View>
              }
              description={
                <View>
                  <Text style={{ fontSize: 13, fontWeight: '400', color: '#8f9bb3' }}>{description}</Text>
                </View>
              }
              accessoryLeft={IconType}
              accessoryRight={(props) => (
                <FileMoreMenu
                  handleDeleteFile={() => onclickDelete(item?._id)}
                  handleRenameMenu={() => {
                    setSelectedId(item._id);
                    setSelectedData(item);
                    setIsShowRenameModal(true);
                    setNewFileOrFolderName(item.name);
                  }}
                  handleOpen={() => {
                    if (item.type === 'directory') {
                      setCurrentFolerId(item._id);
                      setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
                    } else {
                      downloadFile(item._id, item.name, true);
                    }
                  }}
                  handleDownloadFile={() => {
                    if (item.type === 'directory') {
                      setCurrentFolerId(item._id);
                      setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
                    } else {
                      downloadFile(item._id, item.name);
                    }
                  }}
                  handleShowDownload={(flag: boolean) => {
                    flag = item.type === 'directory' ? false : true;
                    return flag;
                  }}
                  handleShareFile={() => {
                    setSelectedId(item._id);
                    setSelectedData(item);
                    setIsShowShareModal(true);
                  }}
                ></FileMoreMenu>
              )}
              onPress={() => {
                if (item.type === 'directory') {
                  setCurrentFolerId(item._id);
                  setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
                } else {
                  props.onPress();
                }
              }}
            ></ListItem>
          )}
        />
      </View>
    );
  };

  let displayedFolderPath = [...folderPaths];

  while (displayedFolderPath.length > 3) {
    displayedFolderPath.splice(1, 1);
  }

  if (displayedFolderPath.length !== folderPaths.length) {
    displayedFolderPath.splice(1, 0, { id: '....', name: '....' });
  }

  useEffect(() => {
    const selectedUser = users?.filter((user) => selectedShareUsers?.find((user1) => user1._id === user._id) === undefined);
    setFilteredUser(selectedUser);
  }, [selectedShareUsers]);

  useEffect(() => {
    setTimeout(() => {
      setShareQueryUser('');
      setFilteredUser(users);
    }, 500);
  }, [users]);

  return (
    <Layout style={styles.layoutColumn}>
      <View style={styles.headerFileView}>
        {!isShowShareModal && <HeaderTitle title="DRIVE" icon={null} />}
        {!isShowShareModal && (
          <View style={styles.newFileView}>
            <OverflowMenu
              anchor={() => (
                <Button
                  style={styles.addButton}
                  appearance="ghost"
                  size="tiny"
                  accessoryLeft={PlusIcon}
                  onPress={() => setIsShowAddMenu(true)}
                />
              )}
              style={{ width: 120 }}
              visible={isShowAddMenu}
              onBackdropPress={() => setIsShowAddMenu(false)}
            >
              <MenuItem
                appearance="file-menu"
                title={<Text style={styles.menuText}>New</Text>}
                accessoryLeft={NewFileIcon}
                onPress={() => {
                  setIsShowCreateFolder(true);
                  setNewFileOrFolderName('');
                  setIsShowAddMenu(false);
                }}
              />
              <MenuItem
                appearance="file-menu"
                title={<Text style={styles.menuText}>Upload</Text>}
                accessoryLeft={UploadIcon}
                onPress={() => uploadNewFile()}
              />
            </OverflowMenu>
          </View>
        )}
      </View>
      {!isShowShareModal && (
        <View style={styles.container}>
          <View style={styles.folderPath}>
            <ButtonGroup appearance="ghost" size="small" style={{ margin: 0 }}>
              {displayedFolderPath.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Button
                    style={{ backgroundColor: '#ffffff', marginBottom: 1 }}
                    appearance={displayedFolderPath?.length - 1 === index ? 'root-pact1' : 'root-pact'}
                    size="small"
                    disabled={item.name === '....'}
                    onPress={() => {
                      setFolderPaths(folderPaths.splice(0, folderPaths.findIndex((value) => value.id == item.id) + 1));

                      setCurrentFolerId(item.id);
                    }}
                  >
                    {item.name.length < 15 ? item.name : item.name.slice(0, 15) + '...'}
                  </Button>
                  {displayedFolderPath?.length - 1 != index && (
                    <Button
                      disabled
                      style={{ backgroundColor: '#ffffff', width: 6, minWidth: 6, marginBottom: 1 }}
                      appearance="root-pact"
                      size="small"
                      accessoryLeft={RightIcon}
                    ></Button>
                  )}
                </React.Fragment>
              ))}
            </ButtonGroup>
          </View>

          {rootFolder.isFetched &&
            !isShowShareModal &&
            (rootFolder.data?.contains?.length > 0 ? (
              <List
                style={{ backgroundColor: '#FFFFFF' }}
                nativeID="listFile"
                data={rootFolder.data.contains.sort((itemA, itemB) => {
                  if (itemA.type === 'directory' && itemB.type === 'file') {
                    return -1;
                  }

                  if (itemA.type === 'file' && itemB.type === 'directory') {
                    return 1;
                  }

                  return itemA.name > itemB.name ? 1 : -1;
                })}
                renderItem={renderFiles}
              />
            ) : (
              <Card style={{ borderWidth: 0 }}>
                <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <NullIcon></NullIcon>
                </View>
                <View style={{ padding: 6 }}></View>
                <Text style={{ textAlign: 'center' }}>Chưa có files</Text>
              </Card>
            ))}
        </View>
      )}

      <Modal
        visible={isShowRenameModal}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onBackdropPress={() => {
          setIsShowRenameModal(false);
        }}
        style={styles.modalView}
      >
        <Layout style={{ borderRadius: 3, overflow: 'hidden' }}>
          <Layout style={styles.modalLayout}>
            <View style={styles.modalHeaderView}>
              <ABIcon></ABIcon>
              <View style={styles.padding5}></View>
              <Text style={{ color: 'white', fontWeight: '600' }}>Rename</Text>
            </View>
            <View>
              <Button appearance="ghost" size="small" onPress={() => setIsShowRenameModal(false)} accessoryLeft={EjectWhiteIcon}></Button>
            </View>
          </Layout>
          <Layout style={styles.modalHeaderLayout}>
            <View style={styles.modalTextHeaderView}>
              <Input
                style={{ width: '100%' }}
                placeholder="New Name"
                value={newFileFolderName}
                disabled={false}
                onChangeText={(value) => setNewFileOrFolderName(value)}
              />
            </View>
            <View style={styles.padding5}></View>
            <View style={styles.view1}>
              <Button
                size="tiny"
                style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                onPress={() => renameFile(selectedId, newFileFolderName)}
              >
                OK
              </Button>
              <View style={{ paddingHorizontal: 5 }}></View>
              <Button
                size="tiny"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                onPress={() => setIsShowRenameModal(false)}
              >
                Cancel
              </Button>
            </View>
          </Layout>
        </Layout>
      </Modal>

      {isShowShareModal && (
        <Layout style={styles.modalSharedHeaderLayout}>
          <View style={styles.modalHeaderShareView}>
            <Text style={{ color: '#424242', fontWeight: '600', fontSize: 15 }}>SHARING</Text>
          </View>
        </Layout>
      )}
      {isShowShareModal && auth.users?.length > 0 && (
        <Layout style={styles.modalSharedMainView}>
          <Layout style={styles.maxWidthView}>
            <Layout style={styles.chooseView}>
              <View style={styles.chooseView1}>
                <Autocomplete
                  editable={true}
                  style={{ width: '100%', minWidth: 'calc(100vw - 94.5px)' }}
                  placeholder="Tên người nhận"
                  value={shareQueryUser}
                  onChangeText={(value) => {
                    setShareQueryUser(value);
                    setFilteredUser(
                      users.filter(
                        (user) =>
                          user.fullname.toLocaleLowerCase().includes(value.toLocaleLowerCase()) &&
                          selectedShareUsers?.find((user1) => user1._id === user._id) === undefined
                      )
                    );
                  }}
                  onSelect={(index) => {
                    setSelectedShareUsers([
                      ...selectedShareUsers,
                      { _id: filteredUser[index]._id, fullname: filteredUser[index].fullname },
                    ]);
                    setShareQueryUser('');
                  }}
                >
                  {filteredUser.map((user) => (
                    <AutocompleteItem
                      appearance="ioc_autocomplete"
                      style={{ margin: 5, backgroundColor: '#f7f9fc' }}
                      key={user._id}
                      title={user.fullname}
                    ></AutocompleteItem>
                  ))}
                </Autocomplete>
              </View>
              <Button
                size="small"
                appearance="file-pact"
                style={{ marginVertical: 4, backgroundColor: '#FFFFFF', borderColor: '#424242', height: 40 }}
                onPress={() => {
                  setShareQueryUser('');
                  setFilteredUser(users?.filter((user) => selectedShareUsers?.find((user1) => user1._id === user._id) === undefined));
                }}
              >
                Clear
              </Button>
            </Layout>
          </Layout>

          <Text style={{ paddingBottom: 5.5, paddingTop: 15, fontSize: 14, fontWeight: '600' }}>Danh sách người nhận</Text>
          <View style={{ flexShrink: 1 }}>
            {selectedShareUsers?.length > 0 && (
              <List
                style={{ marginLeft: -4 }}
                data={selectedShareUsers.map(({ _id: id }) => users.find((user) => user._id == id))}
                renderItem={({ item, index }) => {
                  return (
                    <ListItem
                      appearance="ioc"
                      key={index}
                      title={item?.fullname}
                      accessoryRight={
                        <Button
                          accessoryLeft={<EjectIcon></EjectIcon>}
                          size="tiny"
                          appearance="ghost"
                          onPress={() => {
                            setFilteredUser(
                              users?.filter(
                                (user) => item._id === user._id || selectedShareUsers?.find((user1) => user1._id === user._id) === undefined
                              )
                            );
                            setSelectedShareUsers(selectedShareUsers.filter((user) => user._id != item._id));
                          }}
                        ></Button>
                      }
                    ></ListItem>
                  );
                }}
              ></List>
            )}
          </View>
          {selectedShareUsers?.length <= 0 && (
            <View style={{ borderWidth: 0, paddingTop: 6 }}>
              <Text style={{ textAlign: 'center', fontSize: 13 }}>Chưa có ai</Text>
            </View>
          )}
          <Layout style={styles.layout1}>
            <Button
              size="tiny"
              style={{ marginVertical: 4, backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
              onPress={() => handleShareFileOrFolder()}
            >
              Share
            </Button>
            <View style={{ paddingHorizontal: 5 }}></View>
            <Button
              size="tiny"
              style={{ marginVertical: 4, backgroundColor: '#6c757d', borderColor: '#6c757d' }}
              onPress={() => {
                setSelectedId('');
                setIsShowShareModal(false);
                setShareQueryUser('');
                setFilteredUser(auth.users);
              }}
            >
              Cancel
            </Button>
          </Layout>
        </Layout>
      )}

      <Modal
        visible={isShowCreateFolder}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onBackdropPress={() => {
          setIsShowCreateFolder(false);
        }}
        style={styles.modalView}
      >
        <Layout style={{ borderRadius: 3, overflow: 'hidden' }}>
          <Layout style={styles.modalLayout}>
            <View style={styles.modalHeaderView}>
              <ABIcon></ABIcon>
              <View style={styles.padding5}></View>
              <Text style={{ color: 'white', fontWeight: '600' }}>New Folder</Text>
            </View>
            <View>
              <Button appearance="ghost" size="small" onPress={() => setIsShowCreateFolder(false)} accessoryLeft={EjectWhiteIcon}></Button>
            </View>
          </Layout>
          <Layout style={styles.modalHeaderLayout}>
            <View style={styles.modalTextHeaderView}>
              <Input
                placeholder="New Folder"
                value={newFileFolderName}
                disabled={false}
                onChangeText={(value) => setNewFileOrFolderName(value)}
              />
            </View>

            <View style={styles.padding5}></View>
            <View style={styles.view1}>
              <Button size="tiny" style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} onPress={() => createNewFolder()}>
                OK
              </Button>
              <View style={{ paddingHorizontal: 5 }}></View>
              <Button
                size="tiny"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                onPress={() => setIsShowCreateFolder(false)}
              >
                Cancel
              </Button>
            </View>
          </Layout>
        </Layout>
      </Modal>
      <Modal
        visible={isShowDeleteModal}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onBackdropPress={() => setIsShowDeleteModal(false)}
        style={styles.modalEmpty}
      >
        <Layout style={{ borderRadius: 3, overflow: 'hidden' }}>
          <Layout style={styles.modalLayoutDelete}>
            <View style={styles.modalHeaderView}>
              <DeleteIcon></DeleteIcon>
              <View style={{ padding: 5 }}></View>
              <Text style={{ color: 'white', fontWeight: '600' }}>Delete</Text>
            </View>
            <View>
              <Button appearance="ghost" size="small" onPress={() => setIsShowDeleteModal(false)} accessoryLeft={EjectWhiteIcon}></Button>
            </View>
          </Layout>
          <Layout style={styles.emptyLayout}>
            <View style={styles.layoutdelete}>
              <Text>Có thực sự muốn xóa?</Text>
            </View>
            <View style={{ width: '100%', height: 1, backgroundColor: '#dee2e6' }} />
            <View style={{ padding: 3.5 }}></View>
            <View style={styles.editButtonView}>
              <View style={{ paddingRight: 10 }}>
                <Button
                  style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                  size="tiny"
                  onPress={() => {
                    setIsShowDeleteModal(false);
                    deleteFile(seletedDeleteId);
                  }}
                >
                  OK
                </Button>
              </View>
              <Button
                size="tiny"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                onPress={() => setIsShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </View>
          </Layout>
        </Layout>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  folderPath: {
    display: 'flex',
    width: '100%',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderTopColor: '#f0f0f0',
    borderTopWidth: 1,
    flexShrink: 1,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  addButton: {
    width: 25,
    height: 25,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 21,
  },
  fileView: {
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
    minHeight: 50,
    paddingLeft: 7,
    justifyContent: 'center',
  },
  layoutColumn: {
    flex: 1,
    flexDirection: 'column',
    flexShrink: 1,
  },
  headerFileView: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 4,
  },
  newFileView: {
    minWidth: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    padding: 0,
    width: '100%',
    paddingHorizontal: 10,
    paddingBottom: screen.height * 0.25,
  },
  modalHeaderView: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  modalHeaderShareView: {
    paddingHorizontal: 15,
    paddingTop: 11.5,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  modalHeaderLayout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    padding: 10,
    backgroundColor: 'white',
    width: '100%',
  },
  padding3_5: {
    padding: 3.5,
  },
  padding5: {
    padding: 5,
  },
  modalTextHeaderView: {
    flex: 6,
  },
  modalLayout: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#2C3A47',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  modalSharedHeaderLayout: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  modalSharedMainView: {
    paddingTop: 5.5,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingHorizontal: 15,
    flexShrink: 1,
    backgroundColor: 'white',
    width: '100%',
  },
  view1: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    flexDirection: 'row',
  },
  maxWidthView: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
  chooseView: {
    flex: 6,
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    alignItems: 'center',
  },
  chooseView1: {
    width: '100%',
    flexShrink: 1,
    paddingRight: 11.5,
  },
  layout1: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingVertical: 11.5,
    width: '100%',
    flexDirection: 'row',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 15,
    fontWeight: 400,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 1,
  },
  menuItem: {
    paddingVertical: 4,
  },
  menuText: {
    fontSize: 13,
  },
  menuIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  layoutdelete: {
    paddingLeft: 6,
    paddingTop: 2,
    paddingBottom: 10,
  },
  modalEmpty: {
    padding: 0,
    width: '100%',
    paddingHorizontal: 10,
    paddingBottom: screen.height * 0.25,
  },
  emptyLayout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    padding: 10,
    backgroundColor: 'white',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  editButtonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  modalLayoutDelete: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#DC3545',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});
