import { sendAuthenicatedRequest, sendSigningProcessRequest, sendSigningProcessRequestOrther, sendSignRequest } from './base';
import qs from 'qs';

export enum HostIDs {
    organization = "_organization",
    all = "129d2929ecae8ee9eca0d6a477_0003%2C129d2929ecae8ee9eca0d6a477_0004",
    MrQuan = "129d2929ecae8ee9eca0d6a477_0003",
    MrTam = "129d2929ecae8ee9eca0d6a477_0004",
    MrsTram = '129d2929ecae8ee9eca0d6a477_0046'
}

export enum specId {
  all = "/?pgSkip=0&pgLimit=40",
  process = "/?status=open",
  done = "/?status=closed&pgSkip=0&pgLimit=40",
  cancel = "/?status=cancelled&pgSkip=0&pgLimit=40"
}



export const getPact = async (specId: string) => {
  return await (
    await sendSigningProcessRequest('/pact'+specId, 'GET', null)
  ).data.result.processes;
};

export const getLumen = async (specId: string) => {
  return await (
    await sendSigningProcessRequest('/lumen'+specId, 'GET', null)
  ).data.result.processes;
};

export const getFuse = async (specId: string) => {
  return await (
    await sendSigningProcessRequest('/fuse'+specId, 'GET', null)
  ).data.result.processes;
};

export const sharesUser = async (id: string, appName: string, sharedIds: string, conclusion: string) => {
  const data = qs.stringify({
      appName: appName,
      sharedIds: sharedIds,
      conclusion: conclusion
  })
  return await (
      await sendSigningProcessRequest('/share/' + id, 'PUT',data, null)
).data;
};

export const getUnits = async () => {
  return await (
    await sendAuthenicatedRequest('/units', 'GET', null)
  ).data.result.units;
};

export const getCohorts = async () => {
  return await (
    await sendAuthenicatedRequest('/cohorts', 'GET', null)
  ).data.result.cohorts;
};

export const getdPact = async (specId: string) => {
  return await (
    await sendSigningProcessRequest('/dpact'+specId, 'GET', null)
  ).data.result.processes;
};

export const getdLumen = async (specId: string) => {
  return await (
    await sendSigningProcessRequest('/dlumen'+specId, 'GET', null)
  ).data.result.processes;
};

export const getdFuse = async (specId: string) => {
  return await (
    await sendSigningProcessRequest('/dfuse'+specId, 'GET', null)
  ).data.result.processes;
};

export const updateSigningProcess = async (idata: {actionIndex: number,designation: any,destination: any[],incantation: string, modification:{},direction: string, conclusion: string, next: {}, nextStep: number, refFileIds: any[], signFileIds: any[], supFileIds: any[] }, specName: string, specId: string) => {
  let spec = '';

  if (specName === 'Pact')
    spec = 'pact';
  else if (specName === 'Lumen')
    spec = 'lumen';
  else if (specName.search('DFuse') >= 0)
    spec = 'dfuse'
  else if (specName === 'DLumen')
    spec = 'dlumen';
  else if (specName === 'DPact')
    spec = 'dpact';
  else spec = 'fuse';

  const data = JSON.stringify({
    actionIndex: idata.actionIndex,
    direction: idata.direction,
    designation: idata.designation,
    destination: idata.destination,
    incantation: idata.incantation,
    modification: idata.modification,
    conclusion: idata.conclusion,
    next: idata.next,
    nextStep: idata.nextStep,
    refFileIds: idata.refFileIds,
    signFileIds: idata.signFileIds,
    supFileIds  : idata.supFileIds,
  },(key, value) => {
    if (value !== null) return value
  });


  const temp = await (
    await sendSigningProcessRequestOrther('/' + spec + '/' + specId, 'PUT', data)
  ).data;

  return temp;
};

export const getSpecInfo = async (specName, specId) => {
  let spec = '';
  if (specName === 'Pact')
    spec = 'pact';
  else if (specName === 'Lumen')
    spec = 'lumen';
  else if (specName.search('DFuse') >= 0)
    spec = 'dfuse';
  else if(specName.search('DPact') >= 0) 
  spec = 'dpact';
  else if (specName.search('DLumen') >= 0) 
    spec = 'dlumen';
  else spec = 'fuse';

  return await (
    await sendSigningProcessRequest('/' + spec + '/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getPactInfo = async ( specId) => {
  return await (
    await sendSigningProcessRequest('/pact/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getdPactInfo = async ( specId) => {
  return await (
    await sendSigningProcessRequest('/dpact/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getLumenInfo = async ( specId) => {
  return await (
    await sendSigningProcessRequest('/lumen/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getdLumenInfo = async ( specId) => {
  return await (
    await sendSigningProcessRequest('/dlumen/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getFuseInfo = async ( specId) => {
  return await (
    await sendSigningProcessRequest('/fuse/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getdFuseInfo = async ( specId) => {
  return await (
    await sendSigningProcessRequest('/dfuse/' + specId, 'GET', null)
  ).data.result.processes;
};

export const getSpecLine = async (specId) => {
  return await (
    await sendSigningProcessRequest('/spec?id=' + specId, 'GET', null)
  ).data.result.spec.specification.checkpoints;
};

export const putSeen = async (specId, appName) => {
  const data = {
    appName: appName,
  };
  return await (
    await sendSigningProcessRequest('/seen/' + specId, 'PUT', data)
  ).data;
};

export const getSign = async (appName, conclusion, fileId, processId) => {
  const data = JSON.stringify({
    appName: appName,
    conclusion: conclusion,
    fileId: fileId,
    processId: processId,
  },(key, value) => {
    if (value !== null) return value
  });
  return await (
    await sendSignRequest('/digital-sign', 'POST', data)
  ).data;
};

export const getUtext = async () => {
  return await (
    await sendSignRequest('/uext', 'GET', null)
  ).data.result.extensions;
};

export const postUtext = async (list) => {
  const data = {
    recentDirectChatEntries: list
  }
  return await (
    await sendSignRequest('/uext', 'POST', data)
  ).data.message;
};

export const postfreshUtext = async (list) => {
  const data = {
    freshChatEntries: list
  }
  return await (
    await sendSignRequest('/uext', 'POST', data)
  ).data.message;
};

export async function digestMessage(message) {
 if(message == '') return '';
 
   const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
   const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
   const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
   const hashHex = hashArray
     .map((b) => b.toString(16).padStart(2, "0"))
     .join(""); // convert bytes to hex string
   return hashHex.substring(13, 19).toUpperCase();
}