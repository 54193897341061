import { Button, Input, Layout, Text } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { useLocation, useNavigate } from 'react-router-native';
import { changeResetPassword } from '../../services/files';
import { authContext } from '../../hooks/authentication';
import { getUnits } from '../../services/signingprocess';

export const Recovery = (props) => {
  const navigate = useNavigate();
  const auth = React.useContext(authContext);
  const [username, setUsername] = React.useState('');
  const [recoveryToken, setRecoveryToken] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rPassword, setrPassword] = React.useState('');
  const [error, setError] = React.useState({ isError: false, text: '' });
  const state = useLocation();
  const DS = ['DPM-0010', 'DPM-0011', 'DPM-0054', 'DPM-0012', 'DPM-0053'];
  const [waiting, setWaiting] = React.useState(false);

  useEffect(() => {
    if (auth.user?.accessToken === null || auth.user?.accessToken === undefined) return;
    if (auth.user?.accessToken && (auth.units === null || auth.units === undefined || auth.units?.length <= 0)) {
      getUnits().then((units) => auth.setUnits({ data: units }));

      return;
    }

    if (
      auth.units?.find(
        (unit) =>
          (unit?.turf === 'local' || DS?.find((id) => id === unit?._id) != undefined) && unit?.members?.find((id) => id === auth.user._id)
      ) != undefined
    ) {
      navigate('/pacts');
    } else if (auth.units?.find((unit) => unit?.turf === 'tether' && unit?.members?.find((id) => id === auth.user._id)) != undefined) {
      navigate('/elumens');
    } else if (
      auth.units?.find(
        (unit) => (unit?.turf === 'd-local' || unit?._id === 'DPM-0014') && unit?.members?.find((id) => id === auth.user._id)
      ) != undefined
    ) {
      navigate('/dpacts');
    }
  }, [auth, auth.user, auth.units]);

  useEffect(() => {
    if (state?.pathname?.search('/recovery') < 0) return;
    const search = state?.search?.split('?u=')[1]?.split('&h=');
    if (search?.length < 2) return;
    if (search?.at(0) != undefined) setUsername(search?.at(0));
    if (search?.at(1) != undefined) setRecoveryToken(search?.at(1));
  }, [state]);

  const AlertHandler = () => {
    //function to make simple alert
    alert('Mật khẩu đã được thiết lập thành công');
  };

  const onClickResetPSW = () => {
    setWaiting(true);
    setTimeout(async () => {
      const res = await changeResetPassword({ username: username, recoveryToken: recoveryToken, password: password, rPassword: rPassword });
      if (res?.status === 'error') {
        setError({ isError: true, text: res?.message });
        setWaiting(false);
        return;
      }
      if (res?.status === 'ok') {
        AlertHandler();
        setWaiting(false);
        navigate('login');
        window.location.reload();
      }
    });
  };

  return (
    <Layout style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: '#01458E', fontSize: 35, fontWeight: '600' }}>IOC OFFICE</Text>
      </View>
      <View style={{ alignItems: 'center', width: '100%' }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 20,
            width: '100%',
            justifyContent: 'center',
            paddingHorizontal: 15,
          }}
        >
          <Text style={{ margin: 2, textAlign: 'center' }}>Thiết lập lại mật khẩu mới cho tài khoản</Text>
        </View>
        {error.isError && (
          <Text style={styles.text} status="danger">
            {error.text}
          </Text>
        )}
        <Input
          style={styles.inputPassword}
          placeholder="Mật khẩu"
          secureTextEntry={true}
          value={password}
          onChangeText={(nextValue) => setPassword(nextValue)}
        />
        <Input
          style={styles.inputPrePassword}
          placeholder="Gõ lại mật khẩu"
          secureTextEntry={true}
          value={rPassword}
          onChangeText={(nextValue) => setrPassword(nextValue)}
        />
        {waiting && (
          <Layout style={styles.layoutButton}>
            <View
              style={{
                borderRadius: 48,
                backgroundColor: '#8644f2',
                alignItems: 'center',
                height: 35,
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
              <Text style={{ color: '#FFFFFF', paddingVertical: 5, paddingLeft: 5 }}>Đang xử lý</Text>
            </View>
          </Layout>
        )}
        {!waiting && (
          <Layout style={styles.layoutButton}>
            <Button style={styles.button} onPress={() => onClickResetPSW()}>
              Xác nhận
            </Button>
          </Layout>
        )}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '10%',
    height: 50,
    borderRadius: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
  },
  rowRight: {
    display: 'flex',
    width: '100%',
  },
  text: {
    margin: 2,
  },
  textRight: {
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 8,
    textAlign: 'right',
    color: '#636e72',
    fontWeight: 'bold',
    fontSize: 11,
    marginBottom: 5,
  },
  input: {
    paddingLeft: 15,
    paddingRight: 15,
    marginVertical: 4,
    width: '100%',
  },
  inputPassword: {
    paddingLeft: 15,
    paddingRight: 15,
    marginVertical: 4,
    width: '100%',
  },
  inputPrePassword: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    marginVertical: 4,
    width: '100%',
  },
  button: {
    marginVertical: 4,
    borderRadius: 48,
    maxHeight: 35,
    minHeight: 35,
    width: '100%',
    backgroundColor: '#5b5fc7',
    borderColor: '#5b5fc7',
  },
  iconProcess: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutButton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    marginBottom: screen.height * 0.2,
  },
  loadingIcon: {
    transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }],
  },
});

export default Recovery;
