import Svg, { Path } from 'react-native-svg';

export const PenIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);

export const PenRenderIcon = (props) => {
  return (
    <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
      <Path
        d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"
        fill="#5b5fc7"
      />
    </Svg>
  );
};

export const AllIcon = (props) => (
  <Svg width={19} height={16} viewBox="0 0 24 24">
    <Path
      d="M2.5,8.86l9,5.2a1,1,0,0,0,1,0l9-5.2A1,1,0,0,0,22,8a1,1,0,0,0-.5-.87l-9-5.19a1,1,0,0,0-1,0l-9,5.19A1,1,0,0,0,2,8,1,1,0,0,0,2.5,8.86ZM12,4l7,4-7,4L5,8Zm8.5,7.17L12,16,3.5,11.13a1,1,0,0,0-1.37.37,1,1,0,0,0,.37,1.36l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.36A1,1,0,0,0,20.5,11.13Zm0,4L12,20,3.5,15.13a1,1,0,0,0-1.37.37,1,1,0,0,0,.37,1.36l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.36A1,1,0,0,0,20.5,15.13Z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const AllRenderIcon = (props) => (
  <Svg width={15.3} height={15.3} viewBox="0 0 24 24">
    <Path
      d="M2.5,8.86l9,5.2a1,1,0,0,0,1,0l9-5.2A1,1,0,0,0,22,8a1,1,0,0,0-.5-.87l-9-5.19a1,1,0,0,0-1,0l-9,5.19A1,1,0,0,0,2,8,1,1,0,0,0,2.5,8.86ZM12,4l7,4-7,4L5,8Zm8.5,7.17L12,16,3.5,11.13a1,1,0,0,0-1.37.37,1,1,0,0,0,.37,1.36l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.36A1,1,0,0,0,20.5,11.13Zm0,4L12,20,3.5,15.13a1,1,0,0,0-1.37.37,1,1,0,0,0,.37,1.36l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.36A1,1,0,0,0,20.5,15.13Z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const TasksIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M153.8 72.1c8.9-9.9 8.1-25-1.8-33.9s-25-8.1-33.9 1.8L63.1 101.1 41 79C31.6 69.7 16.4 69.7 7 79s-9.4 24.6 0 33.9l40 40c4.7 4.7 11 7.2 17.6 7s12.8-3 17.2-7.9l72-80zm0 160c8.9-9.9 8.1-25-1.8-33.9s-25-8.1-33.9 1.8L63.1 261.1 41 239c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l40 40c4.7 4.7 11 7.2 17.6 7s12.8-3 17.2-7.9l72-80zM216 120H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24zM192 256c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H216c-13.3 0-24 10.7-24 24zM160 416c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184c-13.3 0-24 10.7-24 24zm-64 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const TasksRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M153.8 72.1c8.9-9.9 8.1-25-1.8-33.9s-25-8.1-33.9 1.8L63.1 101.1 41 79C31.6 69.7 16.4 69.7 7 79s-9.4 24.6 0 33.9l40 40c4.7 4.7 11 7.2 17.6 7s12.8-3 17.2-7.9l72-80zm0 160c8.9-9.9 8.1-25-1.8-33.9s-25-8.1-33.9 1.8L63.1 261.1 41 239c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l40 40c4.7 4.7 11 7.2 17.6 7s12.8-3 17.2-7.9l72-80zM216 120H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24zM192 256c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H216c-13.3 0-24 10.7-24 24zM160 416c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184c-13.3 0-24 10.7-24 24zm-64 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
      fill="#5b5fc7"
    />
  </Svg>
);
export const WaitIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M256 464c114.9 0 208-93.1 208-208s-93.1-208-208-208S48 141.1 48 256c0 5.5 .2 10.9 .6 16.3L1.8 286.1C.6 276.2 0 266.2 0 256C0 114.6 114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256c-10.2 0-20.2-.6-30.1-1.8l13.8-46.9c5.4 .4 10.8 .6 16.3 .6zm-2.4-48l14.3-48.6C324.2 361.4 368 313.8 368 256c0-61.9-50.1-112-112-112c-57.8 0-105.4 43.8-111.4 100.1L96 258.4c0-.8 0-1.6 0-2.4c0-88.4 71.6-160 160-160s160 71.6 160 160s-71.6 160-160 160c-.8 0-1.6 0-2.4 0zM39 308.5l204.8-60.2c12.1-3.6 23.4 7.7 19.9 19.9L203.5 473c-4.1 13.9-23.2 15.6-29.7 2.6l-28.7-57.3c-.7-1.3-1.5-2.6-2.5-3.7l-88 88c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l88-88c-1.1-1-2.3-1.9-3.7-2.5L36.4 338.2c-13-6.5-11.3-25.6 2.6-29.7z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const WaitRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M256 464c114.9 0 208-93.1 208-208s-93.1-208-208-208S48 141.1 48 256c0 5.5 .2 10.9 .6 16.3L1.8 286.1C.6 276.2 0 266.2 0 256C0 114.6 114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256c-10.2 0-20.2-.6-30.1-1.8l13.8-46.9c5.4 .4 10.8 .6 16.3 .6zm-2.4-48l14.3-48.6C324.2 361.4 368 313.8 368 256c0-61.9-50.1-112-112-112c-57.8 0-105.4 43.8-111.4 100.1L96 258.4c0-.8 0-1.6 0-2.4c0-88.4 71.6-160 160-160s160 71.6 160 160s-71.6 160-160 160c-.8 0-1.6 0-2.4 0zM39 308.5l204.8-60.2c12.1-3.6 23.4 7.7 19.9 19.9L203.5 473c-4.1 13.9-23.2 15.6-29.7 2.6l-28.7-57.3c-.7-1.3-1.5-2.6-2.5-3.7l-88 88c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l88-88c-1.1-1-2.3-1.9-3.7-2.5L36.4 338.2c-13-6.5-11.3-25.6 2.6-29.7z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const DoneIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 448 512">
    <Path
      d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const DoneRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 448 512">
    <Path
      d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"
      fill="#5b5fc7"
    />
  </Svg>
);
export const EjectIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 384 512">
    <Path
      d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);

export const EjectWhiteIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 384 512">
    <Path
      d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
      fill="#FFFFFF"
    />
  </Svg>
);

export const EjectSignIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 384 512">
    <Path
      d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
      fill="#8f9bb3"
    />
  </Svg>
);

export const PlusIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 384 512">
    <Path
      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);

export const PlusVBDIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 384 512">
    <Path
      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
      fill="#8f9bb3"
    />
  </Svg>
);

export const RemoveIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 384 512">
    <Path
      d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const EjectRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 384 512">
    <Path
      d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"
      fill="#5b5fc7"
    />
  </Svg>
);
export const SearchIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5B5FC7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const SearchRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const OpenIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M464 256c0-114.9-93.1-208-208-208c-13.3 0-24-10.7-24-24s10.7-24 24-24C397.4 0 512 114.6 512 256s-114.6 256-256 256c-13.3 0-24-10.7-24-24s10.7-24 24-24c114.9 0 208-93.1 208-208zM232.3 134.4l112 104c4.9 4.5 7.7 10.9 7.7 17.6s-2.8 13-7.7 17.6l-112 104c-9.7 9-24.9 8.5-33.9-1.3s-8.5-24.9 1.3-33.9L266.9 280H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H266.9l-67.2-62.4c-9.7-9-10.3-24.2-1.3-33.9s24.2-10.3 33.9-1.3z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5b5fc7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);

export const OpenRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M464 256c0-114.9-93.1-208-208-208c-13.3 0-24-10.7-24-24s10.7-24 24-24C397.4 0 512 114.6 512 256s-114.6 256-256 256c-13.3 0-24-10.7-24-24s10.7-24 24-24c114.9 0 208-93.1 208-208zM232.3 134.4l112 104c4.9 4.5 7.7 10.9 7.7 17.6s-2.8 13-7.7 17.6l-112 104c-9.7 9-24.9 8.5-33.9-1.3s-8.5-24.9 1.3-33.9L266.9 280H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H266.9l-67.2-62.4c-9.7-9-10.3-24.2-1.3-33.9s24.2-10.3 33.9-1.3z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const DeadIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M73 127L256 49.4 439 127c5.9 2.5 9.1 7.8 9 12.8c-.4 91.4-38.4 249.3-186.3 320.1c-3.6 1.7-7.8 1.7-11.3 0C102.4 389 64.5 231.2 64 139.7c0-5 3.1-10.2 9-12.8zM457.7 82.8L269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2zM256 128c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5b5fc7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);
export const DeadRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M73 127L256 49.4 439 127c5.9 2.5 9.1 7.8 9 12.8c-.4 91.4-38.4 249.3-186.3 320.1c-3.6 1.7-7.8 1.7-11.3 0C102.4 389 64.5 231.2 64 139.7c0-5 3.1-10.2 9-12.8zM457.7 82.8L269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2zM256 128c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const KNIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M88 64C74.7 64 64 74.7 64 88s10.7 24 24 24h71.4L106.3 240H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H86.4h52H373.6h52H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H405.7L352.6 112H424c13.3 0 24-10.7 24-24s-10.7-24-24-24H88zM438.9 320h-52l46.9 113.2c5.1 12.2 19.1 18.1 31.4 13s18.1-19.1 13-31.4L438.9 320zm-313.8 0h-52L33.8 414.8c-5.1 12.2 .7 26.3 13 31.4s26.3-.7 31.4-13L125.1 320zm33.2-80l53.1-128h89.3l53.1 128H158.3z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5b5fc7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);

export const KNRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M88 64C74.7 64 64 74.7 64 88s10.7 24 24 24h71.4L106.3 240H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H86.4h52H373.6h52H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H405.7L352.6 112H424c13.3 0 24-10.7 24-24s-10.7-24-24-24H88zM438.9 320h-52l46.9 113.2c5.1 12.2 19.1 18.1 31.4 13s18.1-19.1 13-31.4L438.9 320zm-313.8 0h-52L33.8 414.8c-5.1 12.2 .7 26.3 13 31.4s26.3-.7 31.4-13L125.1 320zm33.2-80l53.1-128h89.3l53.1 128H158.3z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const ProgressIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 512 512">
    <Path
      d="M464 112v64H320V112H464zM48 64C21.5 64 0 85.5 0 112v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zM464 336v64H192V336H464zM48 288c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48H48z"
      fill={props.style.tintColor === '#5b5fc7' || props.style.tintColor === '#5b5fc7' ? props.style.tintColor : '#424242'}
    />
  </Svg>
);

export const ProgressRenderIcon = (props) => (
  <Svg width={14.4} height={15.3} viewBox="0 0 512 512">
    <Path
      d="M464 112v64H320V112H464zM48 64C21.5 64 0 85.5 0 112v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zM464 336v64H192V336H464zM48 288c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48H48z"
      fill="#5b5fc7"
    />
  </Svg>
);

export const PdfFileIcon = (props) => (
  <Svg width={18} height={13} style={{ minWidth: 18, minHeight: 13 }} viewBox="0 0 512 512">
    <Path
      d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"
      fill="#DC3545"
    />
  </Svg>
);
export const FilePdfFileIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 512 512">
    <Path
      d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"
      fill={props.color ? props.color : '#DC3545'}
    />
  </Svg>
);

export const ExelFileIcon = (props) => (
  <Svg width={18} height={13} style={{ minWidth: 18, minHeight: 13 }} viewBox="0 0 384 512">
    <Path
      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
      fill="#198753"
    />
  </Svg>
);
export const FileExelFileIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 384 512">
    <Path
      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
      fill={props.color ? props.color : '#198753'}
    />
  </Svg>
);

export const ImageIcon = (props) => (
  <Svg width={18} height={13} style={{ minWidth: 18, minHeight: 13 }} viewBox="0 0 512 512">
    <Path
      d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
      fill="#636e72"
    />
  </Svg>
);
export const FileImageIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 512 512">
    <Path
      d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
      fill={props.color ? props.color : '#636e72'}
    />
  </Svg>
);

export const PowerPointIcon = (props) => (
  <Svg width={18} height={13} style={{ minWidth: 18, minHeight: 13 }} viewBox="0 0 384 512">
    <Path
      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM136 240h68c42 0 76 34 76 76s-34 76-76 76H160v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V368 264c0-13.3 10.7-24 24-24zm68 104c15.5 0 28-12.5 28-28s-12.5-28-28-28H160v56h44z"
      fill="#DC3545"
    />
  </Svg>
);
export const FilePowerPointIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 384 512">
    <Path
      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM136 240h68c42 0 76 34 76 76s-34 76-76 76H160v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V368 264c0-13.3 10.7-24 24-24zm68 104c15.5 0 28-12.5 28-28s-12.5-28-28-28H160v56h44z"
      fill={props.color ? props.color : '#DC3545'}
    />
  </Svg>
);

export const WordFileIcon = (props) => (
  <Svg width={18} height={13} style={{ minWidth: 18, minHeight: 13 }} viewBox="0 0 384 512">
    <Path
      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM111 257.1l26.8 89.2 31.6-90.3c3.4-9.6 12.5-16.1 22.7-16.1s19.3 6.4 22.7 16.1l31.6 90.3L273 257.1c3.8-12.7 17.2-19.9 29.9-16.1s19.9 17.2 16.1 29.9l-48 160c-3 10-12 16.9-22.4 17.1s-19.8-6.2-23.2-16.1L192 336.6l-33.3 95.3c-3.4 9.8-12.8 16.3-23.2 16.1s-19.5-7.1-22.4-17.1l-48-160c-3.8-12.7 3.4-26.1 16.1-29.9s26.1 3.4 29.9 16.1z"
      fill="#0D6EFD"
    />
  </Svg>
);
export const FileWordFileIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 384 512">
    <Path
      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM111 257.1l26.8 89.2 31.6-90.3c3.4-9.6 12.5-16.1 22.7-16.1s19.3 6.4 22.7 16.1l31.6 90.3L273 257.1c3.8-12.7 17.2-19.9 29.9-16.1s19.9 17.2 16.1 29.9l-48 160c-3 10-12 16.9-22.4 17.1s-19.8-6.2-23.2-16.1L192 336.6l-33.3 95.3c-3.4 9.8-12.8 16.3-23.2 16.1s-19.5-7.1-22.4-17.1l-48-160c-3.8-12.7 3.4-26.1 16.1-29.9s26.1 3.4 29.9 16.1z"
      fill={props.color ? props.color : '#0D6EFD'}
    />
  </Svg>
);

export const UnknownFileIcon = (props) => (
  <Svg width={18} height={13} style={{ minWidth: 18, minHeight: 13 }} viewBox="0 0 384 512">
    <Path
      d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
      fill="#636e72"
    />
  </Svg>
);

export const FileUnknownFileIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 384 512">
    <Path
      d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
      fill={props.color ? props.color : '#636e72'}
    />
  </Svg>
);

export const FileFolderIcon = (props) => (
  <Svg width={30} height={20} viewBox="0 0 512 512">
    <Path
      d="M448 480H64c-35.3 0-64-28.7-64-64V192H512V416c0 35.3-28.7 64-64 64zm64-320H0V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64z"
      fill={props.color ? props.color : '#FFC107'}
    />
  </Svg>
);

export const UpIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 320 512" style={{alignSelf:'center',justifyContent:'center'}}>
    <Path
      d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"
      fill="#FFFFFF"
    />
  </Svg>
);

export const DownIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 320 512" style={{alignSelf:'center',justifyContent:'center'}}>
    <Path
      d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
      fill="#FFFFFF"
    />
  </Svg>
);
