import { Button, MenuItem, OverflowMenu, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Icon from 'react-native-vector-icons/FontAwesome5';

const OpenIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
      fill="#424242"
    />
  </Svg>
);

const RenameIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 640 512" style={styles.menuIcon}>
    <Path
      d="M64 112c-8.8 0-16 7.2-16 16V384c0 8.8 7.2 16 16 16H576c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H64zM0 128C0 92.7 28.7 64 64 64H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm184 32c9.5 0 18.1 5.6 21.9 14.3l64 144c5.4 12.1-.1 26.3-12.2 31.7s-26.3-.1-31.7-12.2l-4.3-9.7H146.3l-4.3 9.7c-5.4 12.1-19.6 17.6-31.7 12.2s-17.6-19.6-12.2-31.7l64-144c3.9-8.7 12.4-14.3 21.9-14.3zm0 83.1L167.6 280h32.8L184 243.1zM304 184c0-13.3 10.7-24 24-24h52c33.1 0 60 26.9 60 60c0 9.2-2.1 17.9-5.8 25.7c13.3 11 21.8 27.6 21.8 46.3c0 33.1-26.9 60-60 60H328c-13.3 0-24-10.7-24-24v-8V256 192v-8zm48 24v24h28c6.6 0 12-5.4 12-12s-5.4-12-12-12H352zm0 96h44c6.6 0 12-5.4 12-12s-5.4-12-12-12H380 352v24z"
      fill="#424242"
    />
  </Svg>
);

const ShareIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z"
      fill="#424242"
    />
  </Svg>
);

const DeteleIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 448 512" style={styles.menuIcon}>
    <Path
      d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm63 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
      fill="#424242"
    />
  </Svg>
);

const RestoreIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 448 512" style={styles.menuIcon}>
    <Path
      d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H288V352c0-17.7 14.3-32 32-32h80V96c0-8.8-7.2-16-16-16H64zM288 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V320v5.5c0 17-6.7 33.3-18.7 45.3l-90.5 90.5c-12 12-28.3 18.7-45.3 18.7H288z"
      fill="#424242"
    />
  </Svg>
);

const DownloadIcon = (props) => (
  <Svg width={16} height={14} viewBox="0 0 384 512" style={styles.menuIcon}>
    <Path
      d="M360 480c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 432c-13.3 0-24 10.7-24 24s10.7 24 24 24l336 0zM174.5 344.4c4.5 4.8 10.9 7.6 17.5 7.6s12.9-2.7 17.5-7.6l128-136c9.1-9.7 8.6-24.8-1-33.9s-24.8-8.6-33.9 1L216 267.5l0-83.5 0-128c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 128 0 83.5L81.5 175.6c-9.1-9.7-24.3-10.1-33.9-1s-10.1 24.3-1 33.9l128 136z"
      fill="#424242"
    />
  </Svg>
);

interface Props {
  handleShowDownload?: any;
  handleRestoreFile?: any;
  handleDeleteFile?: any;
  handleRenameMenu?: any;
  handleOpen?: any;
  handleDownloadFile?: any;
  handleShareFile?: any;
  isShowShare?: boolean;
  isShowRename?: boolean;
  isShowDelete?: boolean;
  isShowRestore?: boolean;
  isShowOpen?: boolean;
  AnchorElement?: any;
}

export const FileMoreMenu = (props: Props) => {
  const {
    handleRestoreFile,
    handleShowDownload,
    handleDeleteFile,
    handleRenameMenu,
    handleOpen,
    handleDownloadFile,
    handleShareFile,
    isShowShare = true,
    isShowDelete = true,
    isShowRename = true,
    isShowOpen = true,
    isShowRestore = false,
    AnchorElement,
  } = props;
  const [isShowMenu, setIsShowMenu] = useState(false);

  let isShowDownload: boolean;

  isShowDownload = handleShowDownload ? handleShowDownload() : false;

  const MoreIcon = (props) => (
    <Svg width={16} height={14} viewBox="0 0 128 512" style={styles.menuIcon}>
      <Path
        d="M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zM112 96A48 48 0 1 0 16 96a48 48 0 1 0 96 0z"
        fill="#424242"
      />
    </Svg>
  );

  return (
    <OverflowMenu
      style={{ width: 120 }}
      visible={isShowMenu}
      anchor={() =>
        AnchorElement ? (
          <View>
            <AnchorElement onPress={() => setIsShowMenu(true)} />
          </View>
        ) : (
          <Button accessoryLeft={MoreIcon} appearance="ghost" onPress={() => setIsShowMenu(true)}></Button>
        )
      }
      onBackdropPress={() => setIsShowMenu(false)}
    >
      {isShowOpen && (
        <MenuItem
          appearance="file-menu"
          title={
            <View>
              <Text style={styles.menuText}>Open</Text>
            </View>
          }
          accessoryLeft={OpenIcon}
          onPress={() => {
            setIsShowMenu(false);
            handleOpen();
          }}
        />
      )}

      {isShowRename && (
        <MenuItem
          appearance="file-menu"
          title={
            <View>
              <Text style={styles.menuText}>Rename</Text>
            </View>
          }
          accessoryLeft={RenameIcon}
          onPress={() => {
            setIsShowMenu(false);
            handleRenameMenu();
          }}
        />
      )}

      {isShowShare && (
        <MenuItem
          appearance="file-menu"
          title={
            <View>
              <Text style={styles.menuText}>Share</Text>
            </View>
          }
          accessoryLeft={ShareIcon}
          onPress={() => {
            setIsShowMenu(true);
            handleShareFile();
          }}
        />
      )}

      {isShowDownload && (
        <MenuItem
          appearance="file-menu"
          title={
            <View>
              <Text style={styles.menuText}>Download</Text>
            </View>
          }
          accessoryLeft={DownloadIcon}
          onPress={() => {
            setIsShowMenu(false);
            handleDownloadFile();
          }}
        />
      )}
      {isShowRestore && (
        <MenuItem
          appearance="file-menu"
          title={
            <View>
              <Text style={styles.menuText}>Restore</Text>
            </View>
          }
          accessoryLeft={RestoreIcon}
          onPress={() => {
            setIsShowMenu(false);
            handleRestoreFile();
          }}
        />
      )}
      {isShowDelete && (
        <MenuItem
          appearance="file-menu"
          title={
            <View>
              <Text style={styles.menuText}>Delete</Text>
            </View>
          }
          accessoryLeft={DeteleIcon}
          onPress={() => {
            setIsShowMenu(false);
            handleDeleteFile();
          }}
        />
      )}
    </OverflowMenu>
  );
};

const styles = StyleSheet.create({
  menuItem: {},
  menuText: {
    fontSize: 13,
  },
  menuIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
