import { Button, ButtonGroup, Card, Layout, List, ListItem, Spinner, Text } from '@ui-kitten/components';
import React, { useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import IconF from 'react-native-vector-icons/Feather';
import { useQuery } from 'react-query';
import HeaderTitle from '../../components/HeaderTitle';
import { authContext } from '../../hooks/authentication';
import { downloadFile as dlFile, getFolder, getSharedFolder } from '../../services/files';
import { FileMoreMenu } from '../../share/fiile-more-menu';
import { canOpen, isPdf } from '../../utils/files';
import { getFileOrFolderIcon } from '../file';
import { isChromeAndroid } from '../../utils/browser';

const RightIcon = (props) => <IconF {...props} style={styles.iconHeader} name="chevron-right" color="#424242" />;

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 384 512">
    <Path
      d="M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
      fill="#424242"
    />
  </Svg>
);

export default (props) => {
  const [currentFolderId, setCurrentFolerId] = useState('/');
  const rootFolder = useQuery(['shared', currentFolderId], () =>
    currentFolderId === '/' ? getSharedFolder() : getFolder(currentFolderId)
  );
  const { users } = useContext(authContext);

  const [folderPaths, setFolderPaths] = useState([{ id: '/', name: 'root' }]);

  const downloadFile = async (id, filename, isOpen = false) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!isOpen || !canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf') && isOpen) {
        newHref = '/filepreview.html?fileid=' + id;

      }

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!isOpen || !canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const renderFiles = ({ item, index }) => {
    const createdAt = new Date(item.createdAt);
    const owner = users.find((user) => user._id === item.ownerId);
    const description = `${owner?.fullname ? '' + owner?.fullname : ''}`;

    let IconType = getFileOrFolderIcon(item.name, item.type);

    return (
      <View style={styles.renderFile}>
        <FileMoreMenu
          handleDownloadFile={() => {
            if (item.type === 'directory') {
              setCurrentFolerId(item._id);
              setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
            } else {
              downloadFile(item._id, item.name);
            }
          }}
          handleOpen={() => {
            if (item.type === 'directory') {
              setCurrentFolerId(item._id);
              setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
            } else {
              downloadFile(item._id, item.name, true);
            }
          }}
          handleShowDownload={(flag: boolean) => {
            flag = item.type === 'directory' ? false : true;
            return flag;
          }}
          isShowDelete={false}
          isShowRename={false}
          isShowShare={false}
          AnchorElement={(props) => (
            <ListItem
              title={
                <View>
                  <Text style={{ fontSize: 14, fontWeight: '400', color: '#222' }}>{item.name}</Text>
                  <View style={{ padding: 3 }}></View>
                </View>
              }
              description={
                <View>
                  <Text style={{ fontSize: 13, fontWeight: '400', color: '#8f9bb3' }}>{description}</Text>
                </View>
              }
              accessoryLeft={IconType}
              accessoryRight={() => (
                <FileMoreMenu
                  handleDownloadFile={() => {
                    if (item.type === 'directory') {
                      setCurrentFolerId(item._id);
                      setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
                    } else {
                      downloadFile(item._id, item.name);
                    }
                  }}
                  handleOpen={() => {
                    if (item.type === 'directory') {
                      setCurrentFolerId(item._id);
                      setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
                    } else {
                      downloadFile(item._id, item.name, true);
                    }
                  }}
                  handleShowDownload={(flag: boolean) => {
                    flag = item.type === 'directory' ? false : true;
                    return flag;
                  }}
                  isShowDelete={false}
                  isShowRename={false}
                  isShowShare={false}
                />
              )}
              onPress={() => {
                if (item.type === 'directory') {
                  setCurrentFolerId(item._id);
                  setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
                } else {
                  props.onPress();
                }
              }}
            />
          )}
        />
      </View>
    );
  };

  let displayedFolderPath = [...folderPaths];

  while (displayedFolderPath.length > 3) {
    displayedFolderPath.splice(1, 1);
  }

  if (displayedFolderPath.length !== folderPaths.length) {
    displayedFolderPath.splice(1, 0, { id: '....', name: '....' });
  }

  return (
    <Layout style={styles.layout}>
      <HeaderTitle title="SHARED" icon={null} />

      <View style={styles.container}>
        <View style={styles.folderPath}>
          <ButtonGroup appearance="ghost" size="small" style={{ margin: 0 }}>
            {displayedFolderPath.map((item, index) => (
              <React.Fragment key={item.id}>
                <Button
                  style={{ backgroundColor: '#ffffff', marginBottom: 1 }}
                  appearance={displayedFolderPath?.length - 1 === index ? 'root-pact1' : 'root-pact'}
                  size="small"
                  disabled={item.name === '....'}
                  onPress={() => {
                    setFolderPaths(folderPaths.splice(0, folderPaths.findIndex((value) => value.id == item.id) + 1));

                    setCurrentFolerId(item.id);
                  }}
                >
                  {item.name.length < 15 ? item.name : item.name.slice(0, 15) + '...'}
                </Button>
                {displayedFolderPath?.length - 1 != index && (
                  <Button
                    disabled
                    style={{ backgroundColor: '#ffffff', width: 6, minWidth: 6, marginBottom: 1 }}
                    appearance="root-pact"
                    size="small"
                    accessoryLeft={RightIcon}
                  ></Button>
                )}
              </React.Fragment>
            ))}
          </ButtonGroup>
        </View>

        {rootFolder.isFetched &&
          (rootFolder.data?.contains?.length > 0 || (currentFolderId === '/' && rootFolder.data?.length > 0) ? (
            <List
              style={{ backgroundColor: '#FFFFFF' }}
              data={(currentFolderId === '/' ? rootFolder.data : rootFolder.data.contains).sort((itemA, itemB) => {
                if (itemA.type === 'directory' && itemB.type === 'file') {
                  return -1;
                }

                if (itemA.type === 'file' && itemB.type === 'directory') {
                  return 1;
                }

                return itemA.name > itemB.name ? 1 : -1;
              })}
              renderItem={renderFiles}
            />
          ) : (
            <Card style={{ borderWidth: 0 }}>
              <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <NullIcon></NullIcon>
              </View>
              <View style={{ padding: 6 }}></View>
              <Text style={{ textAlign: 'center' }}>Chưa có files</Text>
            </Card>
          ))}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  folderPath: {
    display: 'flex',
    width: '100%',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderTopColor: '#f0f0f0',
    borderTopWidth: 1,
    flexShrink: 1,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  addButton: {
    width: 50,
    height: 50,
    backgroundColor: '#5b5fc7',
    borderRadius: 40,
  },
  renderFile: {
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
    minHeight: 50,
    justifyContent: 'center',
    paddingLeft: 7,
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 15,
    fontWeight: 400,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 1,
  },
  maxlayout: {
    width: '100%',
    height: '100%',
    flexShrink: 1,
  },
});
