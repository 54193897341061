import {
  Autocomplete,
  AutocompleteItem,
  Avatar,
  Button,
  ButtonGroup,
  Card,
  IndexPath,
  Layout,
  List,
  ListItem,
  Spinner,
  Text,
  Tooltip,
} from '@ui-kitten/components';
import {
  EjectIcon,
  ExelFileIcon,
  FileExelFileIcon,
  FileFolderIcon,
  FileImageIcon,
  FilePdfFileIcon,
  FilePowerPointIcon,
  FileUnknownFileIcon,
  FileWordFileIcon,
  ImageIcon,
  PdfFileIcon,
  PlusIcon,
  PowerPointIcon,
  UnknownFileIcon,
  WordFileIcon,
} from '../../share/filter-icon';

import { Platform } from 'expo-modules-core';
import 'quill/dist/quill.snow.css';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import Icon2 from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon3 from 'react-native-vector-icons/Ionicons';
import { useQuery } from 'react-query';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { navigationContext } from '../../hooks/navigation';
import { signContext } from '../../hooks/signingprocess';
import { getAccessToken } from '../../services/authentication';
import { getUtext, postfreshUtext, postUtext } from '../../services/signingprocess';

import * as DocumentPicker from 'expo-document-picker';
import Svg, { Path } from 'react-native-svg';
import { getChatWsUrl } from '../../config';
import { downloadFile as dlFile, getFolder, getNameFile, getSharedFolder, uploadFile } from '../../services/files';
import { getAvatarColor } from '../../utils/avatar';
import { canOpen, isPdf } from '../../utils/files';
import { isChromeAndroid } from '../../utils/browser';
import { getBaseApiUrl } from '../../config';
const RightIcon = (props) => <Icon2 {...props} style={styles.iconHeader1} name="chevron-right" color="#424242" />;

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 384 512">
    <Path
      d="M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
      fill="#424242"
    />
  </Svg>
);
const LinkIcon = (props) => (
  <Svg width={17} height={17} viewBox="0 0 448 512">
    <Path
      d="M375 73c-26-26-68.1-26-94.1 0L89 265C45.3 308.6 45.3 379.4 89 423s114.4 43.6 158.1 0L399 271c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L281 457c-62.4 62.4-163.5 62.4-225.9 0S-7.4 293.4 55 231L247 39C291.7-5.7 364.2-5.7 409 39s44.7 117.2 0 161.9L225.2 384.7c-31.6 31.6-83.6 28.7-111.5-6.2c-23.8-29.8-21.5-72.8 5.5-99.8L271 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L153.2 312.7c-9.7 9.7-10.6 25.1-2 35.8c10 12.5 28.7 13.6 40 2.2L375 167c26-26 26-68.1 0-94.1z"
      fill="#636e72"
    />
  </Svg>
);

const CalenderIcon = (props) => (
  <Svg width={17} height={17} viewBox="0 0 576 512">
    <Path
      d="M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48H432 400 288 48V448c0 8.8 7.2 16 16 16H284.5c12.3 18.8 28 35.1 46.3 48H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-80c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304c0-8.8-7.2-16-16-16z"
      fill="#636e72"
    />
  </Svg>
);
const CloudUpIcon = (props) => (
  <Svg width={17} height={17} viewBox="0 0 640 512">
    <Path
      d="M354.9 121.7c13.8 16 36.5 21.1 55.9 12.5c8.9-3.9 18.7-6.2 29.2-6.2c39.8 0 72 32.2 72 72c0 4-.3 7.9-.9 11.7c-3.5 21.6 8.1 42.9 28.1 51.7C570.4 276.9 592 308 592 344c0 46.8-36.6 85.2-82.8 87.8c-.6 0-1.3 .1-1.9 .2H504 144c-53 0-96-43-96-96c0-41.7 26.6-77.3 64-90.5c19.2-6.8 32-24.9 32-45.3l0-.2v0 0c0-66.3 53.7-120 120-120c36.3 0 68.8 16.1 90.9 41.7zM512 480v-.2c71.4-4.1 128-63.3 128-135.8c0-55.7-33.5-103.7-81.5-124.7c1-6.3 1.5-12.8 1.5-19.3c0-66.3-53.7-120-120-120c-17.4 0-33.8 3.7-48.7 10.3C360.4 54.6 314.9 32 264 32C171.2 32 96 107.2 96 200l0 .2C40.1 220 0 273.3 0 336c0 79.5 64.5 144 144 144H464h40 8zM223 255c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V384c0 13.3 10.7 24 24 24s24-10.7 24-24V249.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
      fill="#636e72"
    />
  </Svg>
);
const FaceIcon = (props) => (
  <Svg width={17} height={17} viewBox="0 0 512 512">
    <Path
      d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
      fill="#636e72"
    />
  </Svg>
);

const icons = ReactQuill.Quill.import('ui/icons');
icons['bold'] =
  '<svg width="15" height="15" viewBox="0 0 512 512"> <Path d="M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z" fill="#636e72" /></svg>';
icons['italic'] =
  '<svg width="15" height="15" viewBox="0 0 512 512"> <Path d="M128 56c0-13.3 10.7-24 24-24H360c13.3 0 24 10.7 24 24s-10.7 24-24 24H291.3L144.7 432H232c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H92.7L239.3 80H152c-13.3 0-24-10.7-24-24z" fill="#636e72" /></svg>';
icons['underline'] =
  '<svg width="15" height="15" viewBox="0 0 512 512"> <Path d="M16 56c0-13.3 10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24H112V224c0 61.9 50.1 112 112 112s112-50.1 112-112V80H312c-13.3 0-24-10.7-24-24s10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24H384V224c0 88.4-71.6 160-160 160s-160-71.6-160-160V80H40C26.7 80 16 69.3 16 56zM0 456c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24z" fill="#636e72" /></svg>';
icons['strike'] =
  '<svg width="15" height="15" viewBox="0 0 512 512"> <Path d="M145.5 138c4-21.5 17.9-37.4 41.7-47.4c24.7-10.4 59.4-13.7 99.9-7.5c12.8 2 52.4 9.5 64.9 12.8c12.8 3.3 25.9-4.3 29.3-17.2s-4.3-25.9-17.2-29.3c-14.7-3.8-56.1-11.7-69.7-13.8c-46.2-7.1-90.4-4.1-125.7 10.7c-36.1 15.1-63.3 43.1-70.5 83.9c-.1 .4-.1 .9-.2 1.3c-2.8 23.4 .5 44.2 9.8 62.2c9.2 17.8 23.2 31.2 38.8 41.5c2.4 1.6 5 3.2 7.5 4.7H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H295.8c-9.9-3.1-19.7-6-29.2-8.8l-.3-.1c-37.7-11.1-70.5-20.7-93.3-35.8c-10.9-7.2-18.2-14.9-22.6-23.5c-4.2-8.2-6.6-18.9-4.9-33.8zM364 337.1c3.7 8.6 5.5 20.1 2.6 36.3c-3.8 21.8-17.8 37.9-41.8 48c-24.7 10.4-59.4 13.7-99.8 7.5c-20.1-3.2-54.3-14.6-81.2-23.6l0 0 0 0c-5.9-2-11.4-3.8-16.3-5.4c-12.6-4.1-26.1 2.8-30.3 15.4s2.8 26.2 15.4 30.3c4 1.3 8.8 2.9 14 4.7c26.6 8.9 66.4 22.2 90.9 26.2l.1 0c46.2 7.1 90.4 4.1 125.7-10.7c36.1-15.1 63.3-43.1 70.5-83.9c4-22.9 2.4-43.5-5-61.7H351.6c5.7 5.3 9.7 11 12.3 17.1z" fill="#636e72" /></svg>';
icons['list']['ordered'] =
  '<svg width="15" height="15" viewBox="0 0 512 512"> <Path d="M32 48c0 8.8 7.2 16 16 16H64V192H32c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96V48c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16zM192 96c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zM58.7 323.9c11.1-11.1 29.2-10.5 39.6 1.3c9.2 10.5 8.9 26.2-.6 36.3L12.3 453.1c-4.3 4.7-5.5 11.5-3 17.3s8.3 9.6 14.7 9.6H128c8.8 0 16-7.2 16-16s-7.2-16-16-16H60.8l60.3-64.6c20.7-22.2 21.2-56.4 1.3-79.3c-22.5-25.7-62.1-27.1-86.3-2.9L20.7 316.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l15.4-15.4z" fill="#636e72" /></svg>';
icons['list']['bullet'] =
  '<svg width="15" height="15" viewBox="0 0 512 512"> <Path d="M48 112V80H80v32H48zM16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40C26.7 48 16 58.7 16 72zm160 8c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H176zm0 160c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H176zm0 160c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H176zM48 240H80v32H48V240zm-8-32c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40zm8 224V400H80v32H48zM16 392v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24z" fill="#636e72" /></svg>';

const SendIcon = (props) => (
  <Icon3
    {...props}
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16 }}
    name="send"
    color="#636e72"
  />
);

const GolobeIcon = (props) => <Icon2 {...props} style={styles.iconH} name="globe" color="#424242" />;

const RemoveIcon = (props) => <Icon {...props} style={styles.icon} name="times" color="#636e72" />;

const LockIcon = (props) => <Icon2 {...props} style={styles.iconH} name="lock" color="#424242" />;

const GolobeIconHeader = (props) => <Icon2 {...props} style={styles.iconHeader} name="globe" color="#424242" />;

const LockIconHeader = (props) => <Icon2 {...props} style={styles.iconHeader} name="lock" color="#424242" />;

const UnitIcon = (props) => (
  <Icon
    {...props}
    style={{
      marginLeft: 0,
      marginRight: 0,
      paddingTop: 0,
      paddingLeft: -2,
      fontSize: 13,
      minWidth: 20,
      textAlign: 'center',
      ...props.style,
    }}
    solid
  />
);

const AvatarImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar)
    return <Avatar style={{ borderRadius: 7 }} shape="square" size={props.size ? props.size : 'small'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 32,
          width: 32,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 7,
        }}
      >
        <Text style={{ color: '#ffffff', fontSize: 13, fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

const AvatarInChatImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar)
    return (
      <Avatar
        style={{ borderRadius: 6 }}
        appearance="ioc"
        shape="square"
        size={props.size ? props.size : 'small'}
        source={{ uri: avatarUrl }}
      />
    );
  else
    return (
      <View
        style={{
          height: 38,
          width: 38,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 6,
        }}
      >
        <Text style={{ color: '#ffffff', fontSize: 13, fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

const AvatarImageHeader = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar)
    return (
      <Avatar
        style={{ borderRadius: 10 }}
        appearance="ioc"
        shape="square"
        size={props.size ? props.size : 'giant'}
        source={{ uri: avatarUrl }}
      />
    );
  else
    return (
      <View
        style={{
          height: 60,
          width: 60,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
        }}
      >
        <Text style={{ color: '#ffffff', fontSize: 20, fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

declare global {
  interface Window {
    chatAppWS: WebSocket;
    onlineUser: any;
  }
}

const connectToWS = () => {
  // TODO: add to config
  window.chatAppWS = new WebSocket(getChatWsUrl(), encodeURIComponent(getAccessToken()));

  window.chatAppWS.onclose = () => {
    console.log('WS Closed, retry to connect in 1s');
    // setTimeout(() => connectToWS(), 1000);
  };

  window.chatAppWS.onerror = () => {
    console.log('Can not connect to ws, retry in 1s');
    // setTimeout(() => connectToWS(), 1000);
  };

  return window.chatAppWS;
};

const getWS = () => {
  if (window.chatAppWS) {
    return window.chatAppWS;
  }

  return connectToWS();
};

export default (props) => {
  const navigate = useNavigate();

  const [isWaiting, setIsWaiting] = React.useState(true);

  const showWaiting = () => {
    setIsWaiting(true);
    setTimeout(() => setIsWaiting(false), 500);
  };

  useEffect(() => {
    setTimeout(() => setIsWaiting(false), 500);
  }, []);

  const navigation = useContext(navigationContext);

  const getFileOrFolderIcon = (originalName: string) => {
    const fileName = (originalName || '').toLowerCase();

    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return <WordFileIcon></WordFileIcon>;
    } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      return <PowerPointIcon></PowerPointIcon>;
    } else if (fileName.endsWith('.pdf')) {
      return <PdfFileIcon></PdfFileIcon>;
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return <ExelFileIcon></ExelFileIcon>;
    } else if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.gif') ||
      fileName.endsWith('.psd') ||
      fileName.endsWith('.ai')
    ) {
      return <ImageIcon></ImageIcon>;
    } else {
      return <UnknownFileIcon></UnknownFileIcon>;
    }
  };
  const getUnitIcon = (originalName: string) => {
    if (originalName === 'Phó Giám đốc' || originalName === 'Giám đốc') {
      return <UnitIcon name="khanda" color="#636e72" />;
    } else {
      return <UnitIcon name="chess-bishop" color="#636e72" />;
    }
  };

  const getFileOrFolderIcon1 = (originalName: string, type: string, flag: boolean) => {
    const fileName = originalName.toLowerCase();

    let IconType = <FileFolderIcon name="folder" color={flag ? '#FFFFFF' : '#ffc107'} />;

    if (type === 'file') {
      if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
        IconType = <FileWordFileIcon color={flag ? '#FFFFFF' : '#0d6efd'} />;
      } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
        IconType = <FilePowerPointIcon color={flag ? '#FFFFFF' : '#dc3545'} />;
      } else if (fileName.endsWith('.pdf')) {
        IconType = <FilePdfFileIcon color={flag ? '#FFFFFF' : '#dc3545'} />;
      } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
        IconType = <FileExelFileIcon color={flag ? '#FFFFFF' : '#198754'} />;
      } else if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpg') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.gif') ||
        fileName.endsWith('.psd') ||
        fileName.endsWith('.ai')
      ) {
        IconType = <FileImageIcon color={flag ? '#FFFFFF' : '#636e72'} />;
      } else {
        IconType = <FileUnknownFileIcon color={flag ? '#FFFFFF' : ''} />;
      }
    }

    return IconType;
  };

  const [curserPos, setCurserPos] = React.useState(0);
  const [isScroll, setIsScroll] = React.useState(true);

  const downloadFile = async (id, filename) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf')) {
        newHref = '/filepreview.html?fileid=' + id;

      }

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const removeFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const uploadNewFile = async () => {
    const result = await DocumentPicker.getDocumentAsync({});
    const formData = new FormData();
    formData.append('parentId', '_temp');
    formData.append('attemptFor', 'Chat');
    formData.append('filedata', (result as any).file);

    const temp = await uploadFile({ data: formData });
    const fileid = temp?.result?.fileIds?.at(0);
    let filename = '';
    await getNameFile({ id: fileid }).then((name) => {
      filename = name;
    });
    files?.push({ id: fileid, name: filename });
    setFiles([...files]);
  };

  const { users } = useContext(authContext);

  const [socket, setSocket] = React.useState({
    reset: false,
    flag: false,
    data: null,
    isOpen: false,
  });

  useEffect(() => {
    socket.flag = true;

    if (socket?.data?.isAlive) {
      socket.data.close();
    }

    socket.data = getWS();
    setSocket({ ...socket });
  }, [navigation.state.bottomTab, window.chatAppWS]);

  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));

  const [selectID, setSelectID] = React.useState({
    type: '-1',
    flag: false,
    id: '',
    dataChat: {
      avatar: null,
      avatarname: null,
      name: null,
      nameHint: null,
      unitname: '',
      role: '',
      status: -1,
      idex: 0,
    },
    isPengding: false,
    data: [],
    dataR: [],
    users: [],
  });

  const chooseID = (type: string, id: string) => {
    selectID.type = type;
    selectID.flag = false;
    selectID.id = id;
    setSelectID({ ...selectID });
  };
  const UText = useQuery('chat', () => getUtext());
  const [utext, setUtext] = React.useState({ flag: false, data: null, process: false });

  const [pending, setPending] = React.useState({ flag: false, end: false });
  const [freshChatEntries, setFreshChatEntries] = React.useState([]);
  const [onlineUser, setOnlineUser] = React.useState(window.onlineUser);

  useEffect(() => {
    socket.data.onopen = function (evt) {
      const text = JSON.stringify({
        cmd: 'get-messages',
        params: { channelId: '_global', pgLimit: 1, pgSkip: 0 },
      });
      socket.data.send(text);
    };
    socket.data.onmessage = function async(event) {
      const data = JSON.parse(event.data);
      if (data?.cmd === 'online-users') {
        if (JSON.stringify(window.onlineUser) != JSON.stringify(data?.ess?.onlines)) setOnlineUser(data?.ess?.onlines);
        window.onlineUser = data?.ess?.onlines;
      } else if (data?.cmd === 'messages' && selectID.flag && data?.ess?.blocks?.length > 0 && selectID.type != '-1') {
        const temp = data?.ess?.blocks
          ?.at(0)
          ?.messages?.reverse()
          ?.map(async (item) => {
            const user = auth.users?.find((user) => user._id === item.from);
            const time = new Date(item.time);
            item.hour = time.toLocaleString('de-AT', { hour12: true, hour: '2-digit', minute: '2-digit' });
            let day = time.toLocaleString('es-CL', { day: '2-digit' });
            let month = time.toLocaleString('es-CL', { month: '2-digit' });
            let year = time.toLocaleString('es-CL', { year: 'numeric' });
            item.date = 'Ngày ' + day + ' Tháng ' + month + ', ' + year;
            item.name = user?.firstname + ' ' + user?.lastname?.split(' ')[0];
            item.avatar = user?.avatar;

            if (item?.files?.length > 0) {
              let filelist = [];

              for (let i = 0; i < item?.files?.length; i++) {
                const fileid = item?.files?.at(0);

                let filename = '';
                await getNameFile({ id: fileid }).then((name) => {
                  filename = name;
                });
                filelist?.push({ id: fileid, name: filename });
              }

              item.files = filelist;
            }
            return item;
          });
        if (selectID.isPengding) selectID.isPengding = false;

        Promise.all(temp).then((newData) => {
          selectID.data = [...selectID.data, ...newData];
          if (!isScroll) selectID.dataR = [...newData.reverse(), ...selectID.dataR];
          setSelectID({ ...selectID });
          if (!pending.end) {
            pending.flag = false;
            setPending({ ...pending });
            loadOld.flag = false;
          }
          if (loadOld.start) {
            loadOld.flag = false;
            setLoadOld({ ...loadOld });
          }
        });
      } else if (data?.cmd === 'messages' && selectID.flag && selectID.type != '-1' && data?.ess?.blocks?.length === 0) {
        selectID.dataChat.idex = -1;
        if (selectID.isPengding) selectID.isPengding = false;
        setSelectID({ ...selectID });
      } else if (
        data?.cmd === 'message' &&
        ((data?.ess?.channelType === 'users' && (selectID.type === '-3' || selectID.type === '-2') && data.ess.channelId === selectID.id) ||
          (selectID.type != '0' && data?.ess?.channelId === selectID.type) ||
          (selectID.type === '0' && data?.ess?.channelId === '_global'))
      ) {
        const user = auth.users?.find((user) => user._id === data.ess?.message.from);
        let temp = data.ess?.message;
        const time = new Date(temp.time);
        temp.hour = time.toLocaleString('de-AT', { hour12: true, hour: '2-digit', minute: '2-digit' });
        let day = time.toLocaleString('es-CL', { day: '2-digit' });
        let month = time.toLocaleString('es-CL', { month: '2-digit' });
        let year = time.toLocaleString('es-CL', { year: 'numeric' });
        temp.date = 'Ngày ' + day + ' Tháng ' + month + ', ' + year;
        temp.name = user?.firstname + ' ' + user?.lastname?.split(' ')[0];
        temp.avatar = user?.avatar;
        const tempfiles = temp?.files?.map(async (item) => {
          const fileid = item;
          let filename = '';
          await getNameFile({ id: item }).then((name) => {
            filename = name;
          });

          return { id: fileid, name: filename };
        });

        Promise.all(tempfiles).then((newData) => {
          temp.files = newData;
          selectID.data = [temp, ...selectID.data];
          if (!isScroll) selectID.dataR = [...selectID.dataR, temp];
          setSelectID({ ...selectID });
        });
      } else if (
        data?.cmd === 'message' &&
        (data?.ess?.channelType === 'unit' || data?.ess?.channelType === 'system') &&
        data?.ess?.channelId != selectID.type
      ) {
        if (UText.data?.freshChatEntries?.find((id) => id === data?.ess?.channelId) === undefined) {
          postfreshUtext([...UText.data.freshChatEntries, data?.ess?.channelId]);
          UText.data.freshChatEntries?.push(data?.ess?.channelId);
          freshChatEntries?.push(data?.ess?.channelId);
          setFreshChatEntries([...freshChatEntries]);
        }
      } else if (data?.cmd === 'message' && data?.ess?.channelId != selectID.id) {
        const channel = utext.data?.find((item) => item.id === data?.ess?.channelId);
        const userIds = data?.ess?.channelId?.split(',');
        if (channel === undefined && userIds?.length > 1) {
          addUser(data?.ess?.channelId, false);
          if (UText.data?.freshChatEntries?.find((id) => id === data?.ess?.channelId) != undefined) {
            postfreshUtext([...UText.data.freshChatEntries, data?.ess?.channelId]);
            setFreshChatEntries([...freshChatEntries]);
          }
        } else if (channel != undefined && userIds?.length > 1) {
          utext.data[utext.data?.indexOf(channel)].seen = false;
          setUtext({ ...utext });
        }
      }
    };
  }, [socket, socket.data, socket.flag, auth, auth.users, selectID, selectID.flag, selectID.data, utext]);

  const seenMessageOld = () => {
    if (selectID.type === '-1') return;
    if (selectID.dataChat.idex === -1) return;
    if (selectID.isPengding) return;
    if (!selectID.flag) return;
    if (selectID.dataChat.idex === -1) return;

    if (selectID.type === '0') {
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: '_global', pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);
    } else if (selectID.type === '-2') {
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: selectID.id, pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);
    } else if (selectID.type === '-3') {
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: selectID.id, pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);
    } else {
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: selectID.type, pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);
    }

    selectID.dataChat.idex += 1;
    selectID.isPengding = true;
    setSelectID({ ...selectID });
  };

  const { units, cohorts } = useContext(authContext);
  useEffect(() => {
    if (sign.index != null) {
      setSelectedIndex(new IndexPath(sign.index));
      sign.setNullIndex();
    }
    selectID.dataChat.idex = 0;
    setSelectID({ ...selectID });
  }, []);

  useEffect(() => {
    if (UText.isFetching || UText.data === undefined) return;
    if (utext.flag) return;
    if (units?.length <= 0 || units === null || units === undefined) return;
    if (UText.data?.freshChatEntries != undefined) setFreshChatEntries(JSON.parse(JSON.stringify(UText.data?.freshChatEntries)));

    utext.process = false;
    utext.flag = true;

    if (UText.data?.recentDirectChatEntries?.length > 0) {
      let data = [];
      for (let i = 0; i < UText.data.recentDirectChatEntries?.length; i++) {
        const userIds = UText.data.recentDirectChatEntries?.at(i)?.split(',');
        const temp = {
          type: userIds?.length > 2 ? '-3' : '-2',
          id: UText.data.recentDirectChatEntries?.at(i),
          seen: UText.data?.freshChatEntries?.find((id) => id === UText.data.recentDirectChatEntries?.at(i)) ? false : true,
          user: userIds
            ?.filter((item) => item != auth.user._id)
            ?.map((item, index) => {
              return item;
            }),
        };
        data.push(temp);
      }
      utext.data = data;
    } else {
      for (let i = 0; i < auth.user?.conscriptions?.length; i++) {
        if (units?.find((unit) => unit?._id === auth.user.conscriptions?.at(i)?.unitId && unit?.status === 'active') != undefined) {
          const unitId = auth.user.conscriptions?.at(i)?.unitId;
          let datatemp = units?.find((unit) => unit?._id === unitId)?.members;
          const const_bgd = 'DPM-0013';
          if (unitId === const_bgd) {
            for (let j = 0; j < units?.length; j++) {
              if (
                units?.at(j)?.code != 'ADM' &&
                units?.at(j)?.turf === 'local' &&
                units?.at(j)?.headmaster &&
                datatemp?.find((id) => id === units?.at(j)?.headmaster) === undefined
              ) {
                datatemp?.push(units?.at(j)?.headmaster);
              }
            }
          }
          let data = [];
          for (let j = 0; j < datatemp?.length; j++) {
            if (datatemp?.at(j) === auth.user._id) continue;
            const userIds = datatemp?.at(j)?.split(',');
            const temp = {
              type: userIds?.length > 2 ? '-3' : '-2',
              id: datatemp?.at(j) < auth.user._id ? datatemp?.at(j) + ',' + auth.user._id : auth.user._id + ',' + datatemp?.at(j),
              seen: true,
              user: userIds
                ?.filter((item) => item != auth.user._id)
                ?.map((item, index) => {
                  return item;
                }),
            };
            data.push(temp);
          }
          utext.data = data;
          break;
        }
      }
    }

    setUtext({ ...utext });
  }, [UText.isFetched, UText.isFetching, units]);

  const sendMessage = () => {
    let conclusion1 = conclusion?.replace('<br>', '');
    if ((conclusion1 === '<p></p>' || conclusion1 == '' || conclusion == '<p><br></p>') && (files?.length <= 0)) return;
    if (selectID.type === '-1') return;
    while (conclusion1?.includes('<p>')) {
      conclusion1 = conclusion1?.replace('<p>', '')?.replace('</p>', '\n');
    }

    const time = new Date();
    let hour = time.toLocaleString('de-AT', { hour12: true, hour: '2-digit', minute: '2-digit' });
    let day = time.toLocaleString('es-CL', { day: '2-digit' });
    let month = time.toLocaleString('es-CL', { month: '2-digit' });
    let year = time.toLocaleString('es-CL', { year: 'numeric' });
    let temp = {
      files: files,
      from: auth.user._id,
      text: conclusion1,
      time: time.getTime(),
      hour: hour,
      date: 'Ngày ' + day + ' Tháng ' + month + ', ' + year,
      name: auth.user?.firstname + ' ' + auth.user?.lastname?.split(' ')[0],
      avatar: auth.user?.avatar,
    };

    let listFile = files?.map((item) => {
      return item?.id;
    });

    selectID.data = [temp, ...selectID.data];
    if (!isScroll) selectID.dataR = [...selectID.dataR, temp];
    setSelectID({ ...selectID });

    const text = JSON.stringify(
      {
        cmd: 'send-message',
        params: {
          channelId: selectID.type === '-2' || selectID.type === '-3' ? selectID.id : selectID.type === '0' ? '_global' : selectID.type,
          text: conclusion1,
          files: listFile?.length > 0 ? listFile : null,
        },
      },
      (key, value) => {
        if (value !== null) return value;
      }
    );
    socket.data.send(text);

    setConclusion('');
    setCurserPos(0);
    setFiles([]);
  };

  const [navigationIndex, setNavigationIndex] = React.useState(false);

  useEffect(() => {
    if (navigation.state.indexState === -1) return;
    if (navigationIndex) return;
    setNavigationIndex(true);
    setSelectedIndex(new IndexPath(navigation.state.indexState));
  }, [navigation, navigation.state]);

  const [isShowShareModal, setIsShowShareModal] = React.useState<boolean>(false);
  const [selectedShareUsers, setSelectedShareUsers] = React.useState<{ _id: string; fullname: string }[]>([]);
  const [filteredUser, setFilteredUser] = React.useState(users);
  const [shareQueryUser, setShareQueryUser] = React.useState('');

  useEffect(() => {
    if (users === undefined || users === null || users?.length <= 0) return;
    if (utext.process) return;
    if (onlineUser === null || onlineUser?.length <= 0) return;
    utext.process = true;

    utext.data = utext.data?.map((item) => {
      item.user = item.user?.map((itemA) => {
        const user = users?.find((user) => user._id === itemA);
        return {
          name: item?.type === '-3' ? user?.firstname : user?.fullname,
          avatar: user?.avatar,
          avatarname: user?.firstname + ' ' + user?.lastname?.split(' ')[0],
          status: onlineUser?.find((id) => id === itemA) != undefined ? true : false,
        };
      });
      return item;
    });

    setUtext({ ...utext });
  }, [users, utext, utext.process, onlineUser, UText.data]);

  useEffect(() => {
    window.addEventListener('mousemove', () => {});

    return () => {
      window.removeEventListener('mousemove', () => {
        socket.data.close();
      });
    };
  }, []);

  useEffect(() => {
    if (onlineUser === null || onlineUser?.length <= 0) return;
    if (utext.data?.length > 0) {
      let online = JSON.parse(JSON.stringify(onlineUser));
      for (let i = 0; i < onlineUser?.length; i++) {
        const textId = [onlineUser?.at(i), auth.user._id].sort((itemA, itemB) => (itemA < itemB ? -1 : 1)).join();
        online[i] = textId;
      }

      for (let i = 0; i < utext.data?.length; i++) {
        const userIds = utext.data?.at(i)?.id?.split(',');

        const userId = online?.find(
          (id) =>
            ((id === userIds?.at(0) || id?.includes(userIds?.at(0))) && userIds?.at(0) != auth.user._id) ||
            ((id === userIds?.at(1) || id?.includes(userIds?.at(1))) && userIds?.at(1) != auth.user._id)
        );

        if (
          typeof utext.data?.at(i)?.user != 'string' &&
          utext.data?.at(i)?.type === '-2' &&
          utext.data?.at(i)?.user[0]?.status != undefined
        ) {
          if (userId != undefined && !utext.data[i].user[0].status) {
            utext.data[i].user[0].status = true;
            if (selectID.type === '-2' && selectID.id === utext.data?.at(i)?.id) {
              selectID.dataChat.status = 1;
              setSelectID({ ...selectID });
              sign.setIIndex({
                name: null,
                status: 'Vừa truy cập',
              });
            }
          } else if (userId === undefined) {
            utext.data[i].user[0].status = false;
            if (selectID.type === '-2' && selectID.id === utext.data?.at(i)?.id) {
              selectID.dataChat.status = 0;
              setSelectID({ ...selectID });
              sign.setIIndex({
                name: null,
                status: 'Đã offline',
              });
            }
          }
        }
      }
      setUtext({ ...utext });
    }
  }, [onlineUser]);

  useEffect(() => {
    if (selectID.type === '-1') return;
    if (units?.length <= 0 || units === null || units === undefined) return;
    if (users?.length <= 0 || users === null || users === undefined) return;
    if (onlineUser === null || onlineUser?.length <= 0) return;
    if (selectID.flag) return;

    if (selectID.type === '0') {
      if (UText.data?.freshChatEntries?.find((id) => id === '_global') != undefined) {
        UText.data?.freshChatEntries?.splice(UText.data?.freshChatEntries?.indexOf('_global'), 1);
        freshChatEntries?.splice(freshChatEntries?.indexOf('_global'), 1);
        setFreshChatEntries([...freshChatEntries]);
        postfreshUtext(UText.data?.freshChatEntries);
      }
      selectID.dataChat.name = 'THÔNG BÁO CHUNG';
      selectID.dataChat.nameHint = 'Thông báo chung';
      selectID.flag = true;
      showWaiting();
      selectID.dataChat.status = -1;
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: '_global', pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);
      sign.setIIndex({ name: 'THÔNG BÁO CHUNG', status: '' });
    } else if (selectID.type === '-2') {
      const channel = utext.data?.find((item) => item.id === selectID.id);
      if (UText.data?.freshChatEntries?.find((id) => id === selectID.id) != undefined || !utext.data[utext.data?.indexOf(channel)].seen) {
        UText.data?.freshChatEntries?.splice(UText.data?.freshChatEntries?.indexOf(selectID.id), 1);
        postfreshUtext(UText.data?.freshChatEntries);
        utext.data[utext.data?.indexOf(channel)].seen = true;
        setUtext({ ...utext });
      }
      const userIds = selectID.id?.split(',');
      const user = users?.find((user) => user._id === userIds?.find((id) => id != auth.user._id));
      selectID.dataChat.name = user?.fullname;
      selectID.dataChat.nameHint = user?.fullname;
      selectID.dataChat.avatar = user?.avatar;
      selectID.dataChat.avatarname = user?.firstname + ' ' + user?.lastname?.split(' ')[0];
      selectID.dataChat.unitname = user?.conscriptions?.at(0)?.unitName?.toUpperCase();
      selectID.dataChat.role = user?.conscriptions?.at(0)?.post;
      selectID.dataChat.status =
        onlineUser?.find(
          (id) =>
            ((id === userIds?.at(0) || id?.includes(userIds?.at(0))) && userIds?.at(0) != auth.user._id) ||
            ((id === userIds?.at(1) || id?.includes(userIds?.at(1))) && userIds?.at(1) != auth.user._id)
        ) != undefined
          ? 1
          : 0;
      selectID.flag = true;
      showWaiting();
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: selectID.id, pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);

      sign.setIIndex({
        name: user?.fullname,
        status:
          onlineUser?.find(
            (id) =>
              ((id === userIds?.at(0) || id?.includes(userIds?.at(0))) && userIds?.at(0) != auth.user._id) ||
              ((id === userIds?.at(1) || id?.includes(userIds?.at(1))) && userIds?.at(1) != auth.user._id)
          ) != undefined
            ? 'Vừa truy cập'
            : 'Đã offline',
      });
    } else if (selectID.type === '-3') {
      if (UText.data?.freshChatEntries?.find((id) => id === selectID.id) != undefined) {
        UText.data?.freshChatEntries?.splice(UText.data?.freshChatEntries?.indexOf(selectID.id), 1);
        postfreshUtext(UText.data?.freshChatEntries);
        const channel = utext.data?.find((item) => item.id === selectID.id);
        utext.data[utext.data?.indexOf(channel)].seen = true;
        setUtext({ ...utext });
      }
      const userIds = selectID.id?.split(',');
      let name = [];
      let stringName = '';
      let nameHint = [];
      let avatar = [];
      let avatarname = [];
      for (let i = 0; i < userIds?.length; i++) {
        if (userIds?.at(i) != auth.user._id) {
          const user = users?.find((user) => user._id === userIds?.at(i));
          if (i === userIds?.length - 2 && userIds?.at(userIds?.length - 1) === auth.user._id) {
            stringName += user?.firstname;
            name.push(user?.fullname);
            nameHint.push(user?.firstname + ' ' + user?.lastname?.split(' ')[0]);
          } else if (i < userIds?.length - 1) {
            stringName += user?.firstname + ', ';
            name.push(user?.fullname + ', ');
            nameHint.push(user?.firstname + ' ' + user?.lastname?.split(' ')[0] + ', ');
          } else {
            stringName += user?.firstname;
            name.push(user?.fullname);
            nameHint.push(user?.firstname + ' ' + user?.lastname?.split(' ')[0]);
          }

          avatar.push(user?.avatar);
          avatarname.push(user?.firstname + ' ' + user?.lastname?.split(' ')[0]);
        }
      }

      selectID.dataChat.name = name;
      selectID.dataChat.nameHint = nameHint;
      selectID.dataChat.avatar = avatar;
      selectID.dataChat.avatarname = avatarname;
      selectID.flag = true;
      showWaiting();
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: selectID.id, pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);
      sign.setIIndex({
        name: stringName,
        status: '',
      });
    } else {
      if (UText.data?.freshChatEntries?.find((id) => id === selectID.type) != undefined) {
        UText.data?.freshChatEntries?.splice(UText.data?.freshChatEntries?.indexOf(selectID.type), 1);
        freshChatEntries?.splice(freshChatEntries?.indexOf(selectID.type), 1);
        setFreshChatEntries([...freshChatEntries]);
        postfreshUtext(UText.data?.freshChatEntries);
      }
      const unit = units?.find((units) => units._id === selectID.type);
      selectID.dataChat.name = unit?.shortName?.toUpperCase();
      selectID.dataChat.nameHint = unit?.shortName;
      selectID.flag = true;
      showWaiting();
      selectID.dataChat.status = -1;
      setTimeout(() => {
        const text = JSON.stringify({
          cmd: 'get-messages',
          params: { channelId: selectID.type, pgLimit: 1, pgSkip: selectID.dataChat.idex },
        });
        socket.data.send(text);
      }, 40);

      sign.setIIndex({
        name: unit?.shortName?.toUpperCase(),
        status: '',
      });
    }

    setSelectID({ ...selectID });
  }, [selectID, selectID.type, units, auth.user, users, onlineUser]);

  const [conclusion, setConclusion] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const refScrollView = React.useRef<ScrollView>(null);
  const refInput = React.useRef<ReactQuill>(null);

  const [isShowAddMenu, setIsShowAddMenu] = React.useState<boolean>(false);

  useEffect(() => {
    if (sign.index != 100) {
      selectID.flag = false;
      selectID.type = '-1';
      selectID.id = '';
      selectID.dataChat.idex = 0;
      selectID.data = [];
      selectID.dataR = [];
      setSelectID({ ...selectID });
      setHidenToolBar(true);
      setIsScroll(false);
      setIsShowAddMenu(false);
      setCurserPos(0);
      setPending({ flag: false, end: false });
      setLoadOld({ flag: false, start: false });
      setSelectFile({ id: '', name: '', type: false });
      setSelectedIndexView({ index: 0, flag: false });
      setFolderPaths([{ id: '', name: 'root' }]);
      setFolderPathsShare([{ id: '/', name: 'root' }]);
      setCurrentFolderId('');
      setCurrentFolderIdShare('/');
      setHiddenShare(false);
      files?.splice(0, files?.length);
      setFiles([...files]);
      setConclusion('');
    }
  }, [sign, sign.index]);

  const removeUser = (index) => {
    if (UText.data?.recentDirectChatEntries) {
      UText.data.recentDirectChatEntries?.splice(index, 1);
      postUtext(UText.data.recentDirectChatEntries);
    }
    utext.data?.splice(index, 1);
    setUtext({ ...utext });
  };

  const addChannel = () => {
    const data = [...selectedShareUsers, { _id: auth.user._id }]?.sort((itemA, itemB) => (itemA._id < itemB._id ? -1 : 1));
    const id = data?.map((item, index) => item._id)?.join();
    addUser(id, true);
    setSelectedShareUsers([]);
  };

  const addUser = (id, isSeen) => {
    if (UText.data?.recentDirectChatEntries && UText.data?.recentDirectChatEntries?.find((i) => i === id) === undefined) {
      postUtext([id, ...UText.data.recentDirectChatEntries].splice(0, 15));
      UText.data.recentDirectChatEntries = [id, ...UText.data.recentDirectChatEntries].splice(0, 15);
    }

    const userIds = id?.split(',');
    if (isSeen) {
      chooseID(userIds?.length > 2 ? '-3' : '-2', id);
    }

    if (utext.data?.find((user) => user?.id === id)) return;

    const temp = {
      type: userIds?.length > 2 ? '-3' : '-2',
      id: id,
      seen: isSeen,
      user: userIds
        ?.filter((item) => item != auth.user._id)
        ?.map((item, index) => {
          const user = users?.find((user) => user._id === item);
          return {
            name: userIds?.length > 2 ? user?.firstname : user?.fullname,
            avatar: user?.avatar,
            avatarname: user?.firstname + ' ' + user?.lastname?.split(' ')[0],
            status: onlineUser?.find((id) => id === item) != undefined ? true : false,
          };
        }),
    };

    utext.data = [temp, ...utext.data].splice(0, 15);
    setUtext({ ...utext });
  };

  const [hidenToolBar, setHidenToolBar] = React.useState(true);

  const AaIcon = (props) => (
    <Svg width={17} height={17} viewBox="0 0 640 512">
      <Path
        d="M206 52.8C201.3 40.3 189.3 32 176 32s-25.3 8.3-30 20.8L2 436.8c-6.2 16.5 2.2 35 18.7 41.2s35-2.2 41.2-18.7L96.2 368H255.8L290 459.2c6.2 16.5 24.7 24.9 41.2 18.7s24.9-24.6 18.7-41.2L206 52.8zM231.8 304H120.2L176 155.1 231.8 304zM608 160c-13 0-24.1 7.7-29.2 18.8C559.4 166.9 536.5 160 512 160c-70.7 0-128 57.3-128 128v64c0 70.7 57.3 128 128 128c24.5 0 47.4-6.9 66.8-18.8c5 11.1 16.2 18.8 29.2 18.8c17.7 0 32-14.3 32-32V352 288 192c0-17.7-14.3-32-32-32zM576 288v64c0 35.3-28.7 64-64 64s-64-28.7-64-64V288c0-35.3 28.7-64 64-64s64 28.7 64 64z"
        fill={hidenToolBar ? '#636e72' : '#5b5fc7'}
      />
    </Svg>
  );
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };
  const modules1 = {
    toolbar: false,
  };

  let formats = [
    'header',
    'height',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'size',
  ];

  const [hiddenShare, setHiddenShare] = React.useState(false);

  const [selectFile, setSelectFile] = React.useState({ id: '', name: '', type: false });

  const [folderPaths, setFolderPaths] = React.useState([{ id: '', name: 'root' }]);
  const [currentFolderId, setCurrentFolderId] = React.useState('');
  const rootFolder = useQuery(['files', currentFolderId], () => getFolder(currentFolderId));

  let displayedFolderPath = [...folderPaths];

  while (displayedFolderPath.length > 3) {
    displayedFolderPath.splice(1, 1);
  }

  if (displayedFolderPath.length !== folderPaths.length) {
    displayedFolderPath.splice(1, 0, { id: '....', name: '....' });
  }

  const [currentFolderIdShare, setCurrentFolderIdShare] = React.useState('/');
  const [folderPathsShare, setFolderPathsShare] = React.useState([{ id: '/', name: 'root' }]);
  const rootFolderShare = useQuery(['shared', currentFolderIdShare], () =>
    currentFolderIdShare === '/' ? getSharedFolder() : getFolder(currentFolderIdShare)
  );

  let displayedFolderPathShare = [...folderPathsShare];

  while (displayedFolderPathShare.length > 3) {
    displayedFolderPathShare.splice(1, 1);
  }

  if (displayedFolderPathShare.length !== folderPathsShare.length) {
    displayedFolderPathShare.splice(1, 0, { id: '....', name: '....' });
  }

  const renderFiles = ({ item, index }) => {
    const createdAt = new Date(item.createdAt);

    const description = `${
      createdAt.toLocaleString('hi', { day: '2-digit' }) +
      ' tháng ' +
      createdAt.toLocaleString('hi', { month: '2-digit' }) +
      ', ' +
      createdAt.getFullYear()
    }`;
    const flag = selectFile.id === item._id;

    let IconType = getFileOrFolderIcon1(item.name, item.type, flag);

    return (
      <View style={styles.renderFile}>
        <ListItem
          key={index}
          title={
            <View>
              <Text style={{ fontSize: 14, fontWeight: '400', color: item._id === selectFile.id ? '#FFFFFF' : '#222' }}>{item.name}</Text>
              <View style={{ padding: 3 }}></View>
            </View>
          }
          style={{ backgroundColor: item._id === selectFile.id ? '#5b5fc7' : '' }}
          description={
            <View>
              <Text style={{ fontSize: 13, fontWeight: '400', color: item._id === selectFile.id ? '#FFFFFF' : '#8f9bb3' }}>
                {description}
              </Text>
            </View>
          }
          accessoryLeft={IconType}
          onPress={() => {
            if (item.type === 'directory') {
              setCurrentFolderId(item._id);
              setFolderPaths([...folderPaths, { id: item._id, name: item.name }]);
              setSelectFile({ id: '', name: '', type: false });
            } else if (item._id != selectFile.id) setSelectFile({ id: item._id, name: item.name, type: true });
            else setSelectFile({ id: '', name: '', type: false });
          }}
        />
      </View>
    );
  };

  const renderFilesShare = ({ item, index }) => {
    const createdAt = new Date(item.createdAt);
    const owner = users.find((user) => user._id === item.ownerId);
    const description = `${owner?.fullname ? '' + owner?.fullname : ''}`;
    const flag = selectFile.id === item._id;

    let IconType = getFileOrFolderIcon1(item.name, item.type, flag);

    return (
      <View style={styles.renderFile}>
        <ListItem
          key={index}
          title={
            <View>
              <Text style={{ fontSize: 14, fontWeight: '400', color: item._id === selectFile.id ? '#FFFFFF' : '#222' }}>{item.name}</Text>
              <View style={{ padding: 3 }}></View>
            </View>
          }
          style={{ backgroundColor: item._id === selectFile.id ? '#5b5fc7' : '' }}
          description={
            <View>
              <Text style={{ fontSize: 13, fontWeight: '400', color: item._id === selectFile.id ? '#FFFFFF' : '#8f9bb3' }}>
                {description}
              </Text>
            </View>
          }
          accessoryLeft={IconType}
          onPress={() => {
            if (item.type === 'directory') {
              setCurrentFolderIdShare(item._id);
              setFolderPathsShare([...folderPathsShare, { id: item._id, name: item.name }]);
              setSelectFile({ id: '', name: '', type: false });
            } else if (item._id != selectFile.id) setSelectFile({ id: item._id, name: item.name, type: true });
            else setSelectFile({ id: '', name: '', type: false });
          }}
        />
      </View>
    );
  };
  const [selectedIndexView, setSelectedIndexView] = React.useState({ index: 0, flag: false });

  const [loadOld, setLoadOld] = React.useState({ flag: false, start: false });

  const addEmoji = (emoji: string) => {
    if (conclusion === '<p><br></p>' || conclusion === '') {
      setConclusion(`<p>${emoji}</p>`);
      return;
    }
    if (conclusion === '<ol><li><br></li></ol>' || conclusion === '') {
      setConclusion(`<ol><li>${emoji}</li></ol>`);
      return;
    }
    if (conclusion === '<ul><li><br></li></ul>' || conclusion === '') {
      setConclusion(`<ul><li>${emoji}</li></ul>`);
      return;
    }

    let count = 0;
    let flag = false;
    for (let i = 3; i < conclusion?.length - 4; i++) {
      if (conclusion[i] === '<' && !flag) {
        flag = true;
        continue;
      }
      if (conclusion[i] === '>' && flag) {
        flag = false;
        if (i === conclusion?.length - 5) {
          setConclusion(conclusion.substring(0, i + 1) + emoji + conclusion.substring(i + 1));
          return;
        }
        if (i === conclusion?.length - 11) {
          setConclusion(conclusion.substring(0, i + 1) + emoji + conclusion.substring(i + 1));
          return;
        }
        continue;
      }
      if (flag) continue;
      if (/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(conclusion[i])) {
        if (count === curserPos) {
          setConclusion(conclusion.substring(0, i) + emoji + conclusion.substring(i));
          return;
        } else if (count === curserPos - 1 && i === conclusion?.length - 5) {
          setConclusion(conclusion.substring(0, i + 1) + emoji + conclusion.substring(i + 1));
          return;
        }
        count += 1;
      } else if (
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
          conclusion[i] + conclusion[i + 1]
        )
      ) {
        if (count === curserPos) {
          setConclusion(conclusion.substring(0, i) + emoji + conclusion.substring(i));
          return;
        } else if (count === curserPos - 2 && i === conclusion?.length - 5 - 1) {
          setConclusion(conclusion.substring(0, i + 2) + emoji + conclusion.substring(i + 2));
          return;
        }
        count += 2;
        i += 1;
      } else if (
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
          conclusion[i] + conclusion[i + 1] + conclusion[i + 2]
        )
      ) {
        if (count === curserPos) {
          setConclusion(conclusion.substring(0, i) + emoji + conclusion.substring(i));
          return;
        } else if (count === curserPos - 3 && i === conclusion?.length - 5 - 2) {
          setConclusion(conclusion.substring(0, i + 3) + emoji + conclusion.substring(i + 3));
          return;
        }
        count += 3;
        i += 2;
      } else if (
        conclusion[i] === '&' &&
        (conclusion[i + 1] === 'l' || conclusion[i + 1] === 'g') &&
        conclusion[i + 2] === 't' &&
        conclusion[i + 3] === ';'
      ) {
        if (count === curserPos) {
          setConclusion(conclusion.substring(0, i) + emoji + conclusion.substring(i));
          return;
        } else if (count === curserPos - 1 && i === conclusion?.length - 5 - 3) {
          setConclusion(conclusion.substring(0, i + 4) + emoji + conclusion.substring(i + 4));
          return;
        }
        count += 1;
        i += 3;
      } else if (
        conclusion[i] === '&' &&
        conclusion[i + 1] === 'a' &&
        conclusion[i + 2] === 'm' &&
        conclusion[i + 3] === 'p' &&
        conclusion[i + 4] === ';'
      ) {
        if (count === curserPos) {
          setConclusion(conclusion.substring(0, i) + emoji + conclusion.substring(i));
          return;
        } else if (count === curserPos - 1 && i === conclusion?.length - 5 - 4) {
          setConclusion(conclusion.substring(0, i + 5) + emoji + conclusion.substring(i + 5));
          return;
        }
        count += 1;
        i += 4;
      } else {
        if (count === curserPos) {
          setConclusion(conclusion.substring(0, i) + emoji + conclusion.substring(i));
          return;
        } else if (count === curserPos - 1 && i === conclusion?.length - 5) {
          setConclusion(conclusion.substring(0, i + 1) + emoji + conclusion.substring(i + 1));
          return;
        }
        count += 1;
      }
    }
  };

  useEffect(() => {
    const selectedUser = users?.filter((user) => selectedShareUsers?.find((user1) => user1._id === user._id) === undefined);
    setFilteredUser(selectedUser);
  }, [selectedShareUsers]);

  useEffect(() => {
    setTimeout(() => {
      setShareQueryUser('');
      setFilteredUser(users);
    }, 500);
  }, [users]);

  return (
    <Layout style={styles.layout} nativeID="layoutAgenda">
      {!selectID.flag && (
        <Layout style={styles.layout}>
          {!isShowShareModal && (
            <View style={styles.layoutColumn}>
              <View
                style={{
                  height: '100%',
                  width: '100%',
                  flexShrink: 1,
                }}
              >
                <ScrollView style={styles.maxLayout}>
                  <View style={{}}>
                    <View style={styles.header1View}>
                      <Text>Kênh tổng hợp</Text>
                    </View>

                    <Button
                      size="small"
                      appearance="ghost"
                      style={{ justifyContent: 'flex-start' }}
                      onPress={() => {
                        chooseID('0', '');
                      }}
                    >
                      <View style={styles.maxWidthLeft}>
                        <View style={styles.rownotShrinkView}>
                          <GolobeIcon></GolobeIcon>
                          <View style={styles.padding5}></View>
                          <Text
                            style={{
                              fontSize: 15,
                              fontWeight: freshChatEntries?.find((item) => item === '_global') != undefined ? '600' : 'normal',
                            }}
                          >
                            Thông báo chung
                          </Text>
                        </View>
                      </View>
                    </Button>
                    {auth?.user?.conscriptions?.length > 0 &&
                      auth?.user?.conscriptions?.map((item, index) => (
                        <Button
                          size="small"
                          appearance="ghost"
                          style={{ justifyContent: 'flex-start' }}
                          onPress={() => {
                            chooseID(item?.unitId, '');
                          }}
                          key={index}
                        >
                          <View style={styles.maxWidthLeft}>
                            <View style={styles.rownotShrinkView}>
                              <LockIcon></LockIcon>
                              <View style={styles.padding5}></View>
                              <Text
                                style={{
                                  fontSize: 15,
                                  fontWeight: freshChatEntries?.find((itemA) => itemA === item?.unitId) != undefined ? '600' : 'normal',
                                }}
                              >
                                {item?.unitName}
                              </Text>
                            </View>
                          </View>
                        </Button>
                      ))}
                  </View>

                  <View style={styles.trucTiepView}>
                    <View style={styles.tructiepHeader}>
                      <Text>Trực tiếp</Text>
                      <Button
                        appearance="ghost"
                        size="tiny"
                        status="basic"
                        accessoryLeft={PlusIcon}
                        onPress={() => {
                          setIsShowShareModal(true);
                        }}
                      ></Button>
                    </View>

                    <View style={styles.view1}>
                      {utext.flag &&
                        utext.process &&
                        utext.data?.length > 0 &&
                        utext.data?.map((item, index) => (
                          <View key={index} style={styles.viewItemTT}>
                            <Button
                              size="small"
                              appearance="ghost"
                              style={{ justifyContent: 'flex-start', width: '100%', flexShrink: 1 }}
                              onPress={() => {
                                chooseID(item.type, item.id);
                              }}
                            >
                              <View style={styles.view2}>
                                <View style={styles.rowCenterView}>
                                  <View>
                                    <AvatarImage avatar={item.user?.at(0).avatar} name={item.user?.at(0)?.avatarname}></AvatarImage>
                                    {item.type === '-2' && (
                                      <View style={styles.statusView}>
                                        <View
                                          style={{
                                            height: 10,
                                            width: 10,
                                            borderWidth: 2,
                                            borderRadius: 12,
                                            backgroundColor: item.user?.at(0)?.status ? '#198753' : '#FFFFFF',
                                            borderColor: item.user?.at(0)?.status ? '#198753' : '#636e72',
                                          }}
                                        ></View>
                                      </View>
                                    )}
                                    {item.type === '-3' && (
                                      <View style={styles.view5}>
                                        <Text
                                          style={{
                                            marginBottom: 1,
                                            color: '#FFFFFF',
                                            fontSize: 10,
                                            textAlign: 'center',
                                            textAlignVertical: 'center',
                                          }}
                                        >
                                          {item.user?.length}
                                        </Text>
                                      </View>
                                    )}
                                  </View>

                                  <View style={styles.view6}>
                                    {item.type === '-2' && (
                                      <Text
                                        style={{ fontSize: 15, fontWeight: !item.seen ? '600' : 'normal' }}
                                        category={!item.seen ? 's1' : ''}
                                      >
                                        {item.user?.at(0)?.name}
                                      </Text>
                                    )}
                                    {item.type === '-3' && (
                                      <Text
                                        style={{ fontSize: 15, fontWeight: !item.seen ? '600' : 'normal' }}
                                        category={!item.seen ? 's1' : ''}
                                      >
                                        {item.user?.map((itemD, indexD) =>
                                          indexD < item.user?.length - 1 ? itemD?.name + ', ' : itemD?.name
                                        )}
                                      </Text>
                                    )}
                                  </View>
                                </View>
                              </View>
                            </Button>
                            <Button
                              size="tiny"
                              appearance="ghost"
                              accessoryLeft={<EjectIcon></EjectIcon>}
                              onPress={() => {
                                removeUser(index);
                              }}
                            ></Button>
                          </View>
                        ))}
                    </View>
                  </View>
                </ScrollView>
              </View>
            </View>
          )}
          {isShowShareModal && (
            <Layout style={styles.modalSharedHeaderLayout}>
              <View style={styles.modalHeaderView}>
                <Text style={{ color: '#424242', fontWeight: '600', fontSize: 15 }}>Mở chat mới</Text>
              </View>
            </Layout>
          )}
          {isShowShareModal && (
            <Layout style={styles.addChatLayout}>
              <Layout style={styles.rownotShrinkView}>
                <Layout style={styles.layoutView}>
                  <View style={styles.layoutView1}>
                    <Autocomplete
                      placeholder="Tên người nhận"
                      style={{ width: '100%', minWidth: 'calc(100vw - 94.5px)' }}
                      value={shareQueryUser}
                      onChangeText={(value) => {
                        setShareQueryUser(value);
                        setFilteredUser(
                          users.filter(
                            (user) =>
                              user.fullname.toLocaleLowerCase().includes(value.toLocaleLowerCase()) &&
                              selectedShareUsers?.find((user1) => user1._id === user._id) === undefined
                          )
                        );
                      }}
                      onSelect={(index) => {
                        setSelectedShareUsers([
                          ...selectedShareUsers,
                          { _id: filteredUser[index]?._id, fullname: filteredUser[index]?.fullname },
                        ]);
                        setShareQueryUser('');
                      }}
                    >
                      {filteredUser.map((user) => (
                        <AutocompleteItem
                          appearance="ioc_autocomplete"
                          key={user._id}
                          title={user.fullname}
                          style={{ margin: 5, backgroundColor: '#f7f9fc' }}
                        ></AutocompleteItem>
                      ))}
                    </Autocomplete>
                  </View>
                  <Button
                    size="small"
                    appearance="file-pact"
                    style={{ marginVertical: 4, backgroundColor: '#FFFFFF', borderColor: '#424242', height: 40 }}
                    onPress={() => {
                      setShareQueryUser('');
                      setFilteredUser(users?.filter((user) => selectedShareUsers?.find((user1) => user1._id === user._id) === undefined));
                    }}
                  >
                    Clear
                  </Button>
                </Layout>
              </Layout>

              <Text style={{ paddingBottom: 5.5, paddingTop: 15, fontSize: 14, fontWeight: '600' }}>Danh sách liên hệ</Text>
              <View style={{ flexShrink: 1 }}>
                {selectedShareUsers?.length > 0 && (
                  <List
                    style={{ marginLeft: -4 }}
                    data={selectedShareUsers.map(({ _id: id }) => users.find((user) => user._id == id))}
                    renderItem={({ item, index }) => {
                      return (
                        <ListItem
                          appearance="ioc"
                          key={index}
                          title={item?.fullname}
                          accessoryRight={
                            <Button
                              accessoryLeft={<EjectIcon></EjectIcon>}
                              size="tiny"
                              appearance="ghost"
                              onPress={() => {
                                setFilteredUser(
                                  users?.filter(
                                    (user) =>
                                      item._id === user._id || selectedShareUsers?.find((user1) => user1._id === user._id) === undefined
                                  )
                                );
                                setSelectedShareUsers(selectedShareUsers.filter((user) => user._id != item._id));
                              }}
                            ></Button>
                          }
                        ></ListItem>
                      );
                    }}
                  ></List>
                )}
              </View>
              {selectedShareUsers?.length <= 0 && (
                <View style={{ borderWidth: 0, paddingTop: 6 }}>
                  <Text style={{ textAlign: 'center', fontSize: 13 }}>Chưa có ai</Text>
                </View>
              )}
              <Layout
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingVertical: 11.5,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Button
                  size="tiny"
                  style={{ marginVertical: 4, backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                  onPress={() => {
                    addChannel();
                    setIsShowShareModal(false);
                  }}
                >
                  Mở
                </Button>
                <View style={styles.paddingH5}></View>
                <Button
                  size="tiny"
                  style={{ marginVertical: 4, backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                  onPress={() => {
                    setIsShowShareModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Layout>
            </Layout>
          )}
        </Layout>
      )}
      {selectID.flag && sign.index === 100 && !hiddenShare && (
        <Layout style={styles.layout}>
          {isWaiting && (
            <View
              // @ts-ignore
              style={{
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80%',
                width: '100%',
                background: 'white',
                zIndex: 999,
              }}
            >
              <View style={{ top: '-10%' }}>
                <Spinner appearance="ioc" status="primary" />
              </View>
            </View>
          )}
          <View style={styles.layoutColumn}>
            <View style={styles.layoutH}>
              {selectID.data?.length <= 0 && selectID.dataChat.idex === -1 && (
                <Layout style={styles.rownotShrinkView}>
                  <View style={styles.maxWidthView}>
                    <View style={{}}>
                      <View style={styles.columnMaxWidth}>
                        <View style={styles.view2}>
                          {selectID.type === '0' && <GolobeIconHeader></GolobeIconHeader>}
                          {selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3' && <LockIconHeader></LockIconHeader>}
                          {selectID.type === '-2' && (
                            <AvatarImageHeader avatar={selectID.dataChat.avatar} name={selectID.dataChat.avatarname}></AvatarImageHeader>
                          )}
                          {selectID.type === '-3' &&
                            selectID.dataChat.avatar?.map((item, index) => (
                              <View style={{ paddingRight: 5 }} key={index}>
                                <AvatarImageHeader avatar={item} name={selectID.dataChat.avatarname?.at(index)}></AvatarImageHeader>
                              </View>
                            ))}
                          <View style={styles.view7}>
                            {selectID.type === '0' && (
                              <View>
                                <Text style={{ fontSize: 14, color: '#636e72', fontWeight: '600' }}>Kênh công</Text>
                                <View style={styles.padding3}></View>
                              </View>
                            )}
                            {selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3' && (
                              <View>
                                <Text style={{ fontSize: 14, color: '#636e72', fontWeight: '600' }}>Kênh riêng</Text>
                                <View style={styles.padding3}></View>
                              </View>
                            )}
                            {selectID.type != '-3' && (
                              <View style={styles.rowCenter}>
                                <Text style={{ fontSize: 15, fontWeight: '600' }} category="s1">
                                  {selectID.dataChat.name}
                                </Text>
                                <View style={styles.padding3}></View>
                                {selectID.dataChat.status != -1 && (
                                  <View style={styles.maxHeightCenter}>
                                    <View
                                      style={{
                                        height: 10,
                                        width: 10,
                                        borderWidth: 2,
                                        borderRadius: 12,
                                        backgroundColor: selectID.dataChat.status === 1 ? '#198753' : '#FFFFFF',
                                        borderColor: selectID.dataChat.status === 1 ? '#198753' : '#636e72',
                                      }}
                                    ></View>
                                  </View>
                                )}
                              </View>
                            )}
                            {selectID.type === '-2' && (
                              <View style={styles.columnnotShrinkView}>
                                <View style={styles.padding3}></View>
                                <Text style={{ fontSize: 13, color: '#636e72', fontWeight: '600' }}>{selectID.dataChat.unitname}</Text>
                                <View style={styles.padding3}></View>
                                <View style={styles.rowCenter}>
                                  {getUnitIcon(selectID.dataChat.role)}
                                  <View style={{ padding: 2 }}></View>
                                  <Text style={{ fontSize: 14, color: '#636e72' }}>{selectID.dataChat.role}</Text>
                                </View>
                              </View>
                            )}
                          </View>
                        </View>
                        <View style={styles.view8}>
                          <Text style={{ lineHeight: 25 }}>
                            {`Điểm bắt đầu khung chat `}
                            {(selectID.type === '-3' || selectID.type === '-2') && 'với '}
                            {selectID.type != '-3' && (
                              <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{selectID.dataChat.nameHint}</Text>
                            )}
                            {selectID.type === '-3' && (
                              <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{selectID.dataChat.nameHint}</Text>
                            )}
                            {`${
                              selectID.type === '0'
                                ? ' thuộc mục kênh công.'
                                : selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3'
                                ? ' thuộc mục kênh riêng.'
                                : ' thuộc mục liên hệ trực tiếp.'
                            }`}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </Layout>
              )}
              {selectID.isPengding && (
                <View style={styles.loadingView}>
                  <ActivityIndicator style={styles.loadingIcon} size="small" color="#424242" />
                  <Text style={{ color: '#424242', paddingVertical: 5, paddingLeft: 5, fontSize: 16 }}>Loading...</Text>
                </View>
              )}
              {!isScroll && (
                <ScrollView
                  nativeID="chatView"
                  onContentSizeChange={(w, h) => {
                    if (h >= window.document.getElementById('chatView')?.clientHeight && !pending.end) {
                      pending.end = true;
                      setPending({ ...pending });
                    }
                    if (h >= window.document.getElementById('chatView')?.clientHeight && !isScroll) {
                      setIsScroll(true);
                    }

                    if (
                      h < window.document.getElementById('chatView')?.clientHeight &&
                      selectID.data?.length > 0 &&
                      !pending.end &&
                      !pending.flag
                    ) {
                      pending.flag = true;
                      setPending({ ...pending });
                      seenMessageOld();
                    }

                    if (loadOld.start && !loadOld.flag) {
                      setLoadOld({ flag: false, start: false });
                    }
                  }}
                  style={{
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                  contentContainerStyle={{ flexDirection: 'column' }}
                  scrollEventThrottle={16}
                  ref={refScrollView}
                  scrollEnabled={!selectID.isPengding}
                >
                  {!isScroll &&
                    selectID.dataR?.map((item, index) => (
                      <View style={styles.columnMaxWidth} key={index}>
                        {index === 0 && selectID.dataChat.idex === -1 && (
                          <Layout style={styles.rownotShrinkView}>
                            <View style={styles.maxWidthView}>
                              <View style={{}}>
                                <View style={styles.columnMaxWidth}>
                                  <View style={styles.view2}>
                                    {selectID.type === '0' && <GolobeIconHeader></GolobeIconHeader>}
                                    {selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3' && (
                                      <LockIconHeader></LockIconHeader>
                                    )}
                                    {selectID.type === '-2' && (
                                      <AvatarImageHeader
                                        avatar={selectID.dataChat.avatar}
                                        name={selectID.dataChat.avatarname}
                                      ></AvatarImageHeader>
                                    )}
                                    {selectID.type === '-3' &&
                                      selectID.dataChat.avatar?.map((item, index) => (
                                        <View style={{ paddingRight: 5 }} key={index}>
                                          <AvatarImageHeader
                                            avatar={item}
                                            name={selectID.dataChat.avatarname?.at(index)}
                                          ></AvatarImageHeader>
                                        </View>
                                      ))}
                                    <View style={styles.view7}>
                                      {selectID.type === '0' && (
                                        <View>
                                          <Text style={{ fontSize: 14, color: '#636e72', fontWeight: '600' }}>Kênh công</Text>
                                          <View style={styles.padding3}></View>
                                        </View>
                                      )}
                                      {selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3' && (
                                        <View>
                                          <Text style={{ fontSize: 14, color: '#636e72', fontWeight: '600' }}>Kênh riêng</Text>
                                          <View style={styles.padding3}></View>
                                        </View>
                                      )}
                                      {selectID.type != '-3' && (
                                        <View style={styles.rowCenter}>
                                          <Text style={{ fontSize: 15, fontWeight: '600' }} category="s1">
                                            {selectID.dataChat.name}
                                          </Text>
                                          <View style={styles.padding3}></View>
                                          {selectID.dataChat.status != -1 && (
                                            <View style={styles.maxHeightCenter}>
                                              <View
                                                style={{
                                                  height: 10,
                                                  width: 10,
                                                  borderWidth: 2,
                                                  borderRadius: 12,
                                                  backgroundColor: selectID.dataChat.status === 1 ? '#198753' : '#FFFFFF',
                                                  borderColor: selectID.dataChat.status === 1 ? '#198753' : '#636e72',
                                                }}
                                              ></View>
                                            </View>
                                          )}
                                        </View>
                                      )}
                                      {selectID.type === '-2' && (
                                        <View style={styles.columnnotShrinkView}>
                                          <View style={styles.padding3}></View>
                                          <Text style={{ fontSize: 13, color: '#636e72', fontWeight: '600' }}>
                                            {selectID.dataChat.unitname}
                                          </Text>
                                          <View style={styles.padding3}></View>
                                          <View style={styles.rowCenter}>
                                            {getUnitIcon(selectID.dataChat.role)}
                                            <View style={{ padding: 2 }}></View>
                                            <Text style={{ fontSize: 14, color: '#636e72' }}>{selectID.dataChat.role}</Text>
                                          </View>
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                  <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ lineHeight: 25 }}>
                                      {`Điểm bắt đầu khung chat `}
                                      {(selectID.type === '-3' || selectID.type === '-2') && 'với '}
                                      {selectID.type != '-3' && (
                                        <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{selectID.dataChat.nameHint}</Text>
                                      )}
                                      {selectID.type === '-3' && (
                                        <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{selectID.dataChat.nameHint}</Text>
                                      )}
                                      {`${
                                        selectID.type === '0'
                                          ? ' thuộc mục kênh công.'
                                          : selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3'
                                          ? ' thuộc mục kênh riêng.'
                                          : ' thuộc mục liên hệ trực tiếp.'
                                      }`}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </Layout>
                        )}
                        {(index === 0 || item.date != selectID.dataR?.at(index - 1)?.date) && (
                          <View style={styles.view9}>
                            <View style={styles.view10} />
                            <View style={styles.view11}>
                              <Text style={{ fontSize: 12 }}>{item.date}</Text>
                            </View>
                            <View style={styles.view10} />
                          </View>
                        )}
                        {(index === 0 ||
                          item.from != selectID.dataR?.at(index - 1).from ||
                          item.time - selectID.dataR?.at(index - 1).time > 120000) && (
                          <View style={styles.columnMaxWidth}>
                            <View style={styles.rowmaxWidthP5}>
                              <AvatarInChatImage avatar={item.avatar} name={item.name}></AvatarInChatImage>

                              <View style={styles.columnmaxWidthPl10}>
                                <View style={styles.rowmaxWidthCenter}>
                                  <Text style={{ fontSize: 15, fontWeight: '600' }} category="s1">
                                    {item.name} <Text style={{ color: '#787777', fontSize: 12 }}> {item.hour}</Text>
                                  </Text>
                                </View>
                                {item.text != '' && <View style={styles.padding2_5}></View>}
                                {item.text != '' && (
                                  <Text style={{ fontSize: 15, lineHeight: 25 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                  </Text>
                                )}
                                {item?.files?.length > 0 && <View style={styles.padding3}></View>}
                                {item?.files?.length > 0 &&
                                  item?.files?.map((items, index) => (
                                    <View key={index}>
                                      {index > 0 && <View style={styles.padding3}></View>}
                                      <View style={styles.rowmaxWith} key={index}>
                                        <Button
                                          appearance="file-pact-Chat"
                                          style={{
                                            flexShrink: 1,
                                            justifyContent: 'flex-start',
                                            borderColor: '#dee2e6',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 5,
                                            marginBottom: 0,
                                            marginLeft: 0,
                                            marginRight: 0,
                                          }}
                                          size="small"
                                          accessoryLeft={getFileOrFolderIcon(items?.name)}
                                          onPress={() => {
                                            downloadFile(items?.id, items?.name);
                                          }}
                                        >
                                          <Text numberOfLines={1} ellipsizeMode="tail">
                                            {items?.name}
                                          </Text>
                                        </Button>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        )}
                        {!(
                          index === 0 ||
                          item.from != selectID.dataR?.at(index - 1).from ||
                          item.time - selectID.dataR?.at(index - 1).time > 120000
                        ) && (
                          <View style={styles.columnMaxWidth}>
                            <View
                              style={{
                                flexDirection: 'row',
                                paddingBottom: 5,
                                width: '100%',
                              }}
                            >
                              <View style={{ width: 38 }}></View>

                              <View style={styles.columnmaxWidthPl10}>
                                <Text style={{ fontSize: 15, lineHeight: 25 }}>
                                  <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                </Text>
                                {item?.files?.length > 0 && <View style={styles.padding3}></View>}
                                {item?.files?.length > 0 &&
                                  item?.files?.map((items, index) => (
                                    <View key={index}>
                                      {index > 0 && <View style={styles.padding2_5}></View>}
                                      <View style={styles.rowmaxWith} key={index}>
                                        <Button
                                          appearance="file-pact-Chat"
                                          style={{
                                            flexShrink: 1,
                                            justifyContent: 'flex-start',
                                            borderColor: '#dee2e6',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 5,
                                            marginBottom: 0,
                                            marginLeft: 0,
                                            marginRight: 0,
                                          }}
                                          size="small"
                                          accessoryLeft={getFileOrFolderIcon(items?.name)}
                                          onPress={() => {
                                            downloadFile(items?.id, items?.name);
                                          }}
                                        >
                                          <Text numberOfLines={1} ellipsizeMode="tail">
                                            {items?.name}
                                          </Text>
                                        </Button>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        )}
                      </View>
                    ))}
                </ScrollView>
              )}
              {isScroll && (
                <ScrollView
                  nativeID="chatView"
                  onContentSizeChange={(w, h) => {
                    if (h >= window.document.getElementById('chatView')?.clientHeight && !pending.end) {
                      pending.end = true;
                      setPending({ ...pending });
                    }
                    if (h >= window.document.getElementById('chatView')?.clientHeight && !isScroll) {
                      setIsScroll(true);
                    }

                    if (
                      h < window.document.getElementById('chatView')?.clientHeight &&
                      selectID.data?.length > 0 &&
                      !pending.end &&
                      !pending.flag
                    ) {
                      pending.flag = true;
                      setPending({ ...pending });
                      seenMessageOld();
                    }

                    if (loadOld.start && !loadOld.flag) {
                      setLoadOld({ flag: false, start: false });
                    }
                  }}
                  style={{
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column-reverse',
                  }}
                  contentContainerStyle={{ flexDirection: 'column-reverse' }}
                  onScroll={(evt) => {
                    if (
                      isScroll &&
                      evt.nativeEvent.contentOffset.y <=
                        -(
                          window.document.getElementById('chatView')?.firstElementChild?.clientHeight -
                          window.document.getElementById('chatView')?.clientHeight +
                          8 -
                          40
                        ) &&
                      selectID.dataChat.idex != -1 &&
                      !loadOld.start &&
                      !loadOld.flag &&
                      pending.end
                    ) {
                      setLoadOld({ flag: true, start: true });
                      seenMessageOld();
                    }
                  }}
                  scrollEventThrottle={16}
                  ref={refScrollView}
                  scrollEnabled={!selectID.isPengding}
                >
                  {isScroll &&
                    selectID.data?.map((item, index) => (
                      <View style={styles.columnMaxWidth} key={index}>
                        {index === selectID.data?.length - 1 && selectID.dataChat.idex === -1 && (
                          <Layout style={styles.rownotShrinkView}>
                            <View style={styles.maxWidthView}>
                              <View style={{}}>
                                <View style={styles.columnMaxWidth}>
                                  <View style={styles.view2}>
                                    {selectID.type === '0' && <GolobeIconHeader></GolobeIconHeader>}
                                    {selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3' && (
                                      <LockIconHeader></LockIconHeader>
                                    )}
                                    {selectID.type === '-2' && (
                                      <AvatarImageHeader
                                        avatar={selectID.dataChat.avatar}
                                        name={selectID.dataChat.avatarname}
                                      ></AvatarImageHeader>
                                    )}
                                    {selectID.type === '-3' &&
                                      selectID.dataChat.avatar?.map((item, index) => (
                                        <View style={{ paddingRight: 5 }} key={index}>
                                          <AvatarImageHeader
                                            avatar={item}
                                            name={selectID.dataChat.avatarname?.at(index)}
                                          ></AvatarImageHeader>
                                        </View>
                                      ))}
                                    <View style={styles.view7}>
                                      {selectID.type === '0' && (
                                        <View>
                                          <Text style={{ fontSize: 14, color: '#636e72', fontWeight: '600' }}>Kênh công</Text>
                                          <View style={styles.padding3}></View>
                                        </View>
                                      )}
                                      {selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3' && (
                                        <View>
                                          <Text style={{ fontSize: 14, color: '#636e72', fontWeight: '600' }}>Kênh riêng</Text>
                                          <View style={styles.padding3}></View>
                                        </View>
                                      )}
                                      {selectID.type != '-3' && (
                                        <View style={styles.rowCenter}>
                                          <Text style={{ fontSize: 15, fontWeight: '600' }} category="s1">
                                            {selectID.dataChat.name}
                                          </Text>
                                          <View style={styles.padding3}></View>
                                          {selectID.dataChat.status != -1 && (
                                            <View style={styles.maxHeightCenter}>
                                              <View
                                                style={{
                                                  height: 10,
                                                  width: 10,
                                                  borderWidth: 2,
                                                  borderRadius: 12,
                                                  backgroundColor: selectID.dataChat.status === 1 ? '#198753' : '#FFFFFF',
                                                  borderColor: selectID.dataChat.status === 1 ? '#198753' : '#636e72',
                                                }}
                                              ></View>
                                            </View>
                                          )}
                                        </View>
                                      )}
                                      {selectID.type === '-2' && (
                                        <View style={styles.columnnotShrinkView}>
                                          <View style={styles.padding3}></View>
                                          <Text style={{ fontSize: 13, color: '#636e72', fontWeight: '600' }}>
                                            {selectID.dataChat.unitname}
                                          </Text>
                                          <View style={styles.padding3}></View>
                                          <View style={styles.rowCenter}>
                                            {getUnitIcon(selectID.dataChat.role)}
                                            <View style={{ padding: 2 }}></View>
                                            <Text style={{ fontSize: 14, color: '#636e72' }}>{selectID.dataChat.role}</Text>
                                          </View>
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                  <View style={styles.view8}>
                                    <Text style={{ lineHeight: 25 }}>
                                      {`Điểm bắt đầu khung chat `}
                                      {(selectID.type === '-3' || selectID.type === '-2') && 'với '}
                                      {selectID.type != '-3' && (
                                        <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{selectID.dataChat.nameHint}</Text>
                                      )}
                                      {selectID.type === '-3' && (
                                        <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{selectID.dataChat.nameHint}</Text>
                                      )}
                                      {`${
                                        selectID.type === '0'
                                          ? ' thuộc mục kênh công.'
                                          : selectID.type != '0' && selectID.type != '-2' && selectID.type != '-3'
                                          ? ' thuộc mục kênh riêng.'
                                          : ' thuộc mục liên hệ trực tiếp.'
                                      }`}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </Layout>
                        )}
                        {(index === selectID.data?.length - 1 || item.date != selectID.data?.at(index + 1)?.date) && (
                          <View style={styles.view9}>
                            <View style={styles.view10} />
                            <View style={styles.view11}>
                              <Text style={{ fontSize: 12 }}>{item.date}</Text>
                            </View>
                            <View style={styles.view10} />
                          </View>
                        )}
                        {(index === selectID.data?.length - 1 ||
                          item.from != selectID.data?.at(index + 1).from ||
                          item.time - selectID.data?.at(index + 1).time > 120000) && (
                          <View style={styles.columnMaxWidth}>
                            <View style={styles.rowmaxWidthP5}>
                              <AvatarInChatImage avatar={item.avatar} name={item.name}></AvatarInChatImage>

                              <View style={styles.columnmaxWidthPl10}>
                                <View style={styles.rowmaxWidthCenter}>
                                  <Text style={{ fontSize: 15, fontWeight: '600' }} category="s1">
                                    {item.name} <Text style={{ color: '#787777', fontSize: 12, marginBottom: 1 }}> {item.hour}</Text>
                                  </Text>
                                </View>
                                {item.text != '' && <View style={styles.padding2_5}></View>}
                                {item.text != '' && (
                                  <Text style={{ fontSize: 15, lineHeight: 25 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                  </Text>
                                )}
                                {item?.files?.length > 0 && <View style={styles.padding3}></View>}
                                {item?.files?.length > 0 &&
                                  item?.files?.map((items, index) => (
                                    <View key={index}>
                                      {index > 0 && <View style={styles.padding3}></View>}
                                      <View style={styles.rowmaxWith} key={index}>
                                        <Button
                                          appearance="file-pact-Chat"
                                          style={{
                                            flexShrink: 1,
                                            justifyContent: 'flex-start',
                                            borderColor: '#dee2e6',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 5,
                                            marginBottom: 0,
                                            marginLeft: 0,
                                            marginRight: 0,
                                          }}
                                          size="small"
                                          accessoryLeft={getFileOrFolderIcon(items?.name)}
                                          onPress={() => {
                                            downloadFile(items?.id, items?.name);
                                          }}
                                        >
                                          <Text numberOfLines={1} ellipsizeMode="tail">
                                            {items?.name}
                                          </Text>
                                        </Button>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        )}
                        {!(
                          index === selectID.data?.length - 1 ||
                          item.from != selectID.data?.at(index + 1).from ||
                          item.time - selectID.data?.at(index + 1).time > 120000
                        ) && (
                          <View style={styles.columnMaxWidth}>
                            <View
                              style={{
                                flexDirection: 'row',
                                paddingBottom: 5,
                                width: '100%',
                              }}
                            >
                              <View style={{ width: 38 }}></View>

                              <View style={styles.columnmaxWidthPl10}>
                                <Text style={{ fontSize: 15, lineHeight: 25 }}>
                                  <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                </Text>
                                {item?.files?.length > 0 && <View style={styles.padding3}></View>}
                                {item?.files?.length > 0 &&
                                  item?.files?.map((items, index) => (
                                    <View key={index}>
                                      {index > 0 && <View style={styles.padding2_5}></View>}
                                      <View style={styles.rowmaxWith} key={index}>
                                        <Button
                                          appearance="file-pact-Chat"
                                          style={{
                                            flexShrink: 1,
                                            justifyContent: 'flex-start',
                                            borderColor: '#dee2e6',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 5,
                                            marginBottom: 0,
                                            marginLeft: 0,
                                            marginRight: 0,
                                          }}
                                          size="small"
                                          accessoryLeft={getFileOrFolderIcon(items?.name)}
                                          onPress={() => {
                                            downloadFile(items?.id, items?.name);
                                          }}
                                        >
                                          <Text numberOfLines={1} ellipsizeMode="tail">
                                            {items?.name}
                                          </Text>
                                        </Button>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        )}
                      </View>
                    ))}
                </ScrollView>
              )}
            </View>
          </View>
          <View style={{ paddingHorizontal: 10, paddingBottom: 10, flexDirection: 'column' }}>
            <View style={{}}>
              <View style={{ flexDirection: 'column', width: '100%', borderColor: '#ced4da', borderWidth: 1, borderRadius: 8 }}>
                {!hidenToolBar && (
                  <ReactQuill
                    ref={refInput}
                    modules={modules}
                    formats={formats}
                    placeholder={'Nội dung tin nhắn'}
                    onChange={(value) => {
                      if (refInput.current?.selection?.index != undefined) {
                        setCurserPos(refInput.current.selection.index);
                      }
                      setConclusion(value);
                    }}
                    onChangeSelection={() => {
                      if (refInput.current?.selection?.index != undefined) {
                        setCurserPos(refInput.current.selection.index);
                      }
                    }}
                    value={conclusion}
                    style={{ minHeight: '41px', maxHeight: '193px', color: '#424242' }}
                  ></ReactQuill>
                )}
                {hidenToolBar && (
                  <ReactQuill
                    ref={refInput}
                    modules={modules1}
                    formats={formats}
                    placeholder={'Nội dung tin nhắn'}
                    onChange={(value) => {
                      if (refInput.current?.selection?.index != undefined) setCurserPos(refInput.current.selection.index);
                      setConclusion(value);
                    }}
                    onChangeSelection={() => {
                      if (refInput.current?.selection?.index != undefined) setCurserPos(refInput.current.selection.index);
                    }}
                    value={conclusion}
                    style={{ minHeight: '41px', maxHeight: '152px', color: '#424242' }}
                  ></ReactQuill>
                )}

                <View style={{}}>
                  {files?.map((item, index) => (
                    <View style={{ flexDirection: 'row', width: '100%', flexShrink: 1, paddingBottom: 7 }} key={index}>
                      <Button
                        appearance="file-pact"
                        style={{
                          flexShrink: 1,
                          justifyContent: 'flex-start',
                          borderColor: '#dee2e6',
                          borderRadius: 5,
                          marginBottom: 0,
                          marginLeft: 7,
                          marginRight: 0,
                        }}
                        size="small"
                        accessoryLeft={getFileOrFolderIcon(item?.name)}
                        onPress={() => {
                          downloadFile(item?.id, item?.name);
                        }}
                      >
                        <Text numberOfLines={1} ellipsizeMode="tail">
                          {item?.name}
                        </Text>
                      </Button>
                      <Button
                        appearance="ghost"
                        style={{ paddingHorizontal: 0 }}
                        size="small"
                        accessoryLeft={RemoveIcon}
                        onPress={() => {
                          removeFile(index);
                        }}
                      ></Button>
                    </View>
                  ))}
                </View>
                <View
                  style={{
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexShrink: 1,
                    borderColor: '#ced4da',

                    borderTopWidth: 1,
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      flexShrink: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Button
                      style={{ height: 41, width: 41 }}
                      appearance="ghost"
                      accessoryLeft={AaIcon}
                      onPress={() => {
                        setHidenToolBar(hidenToolBar ? false : true);
                      }}
                    ></Button>
                    <View style={{ padding: 1 }}></View>
                    <Button
                      style={{ height: 41, width: 41 }}
                      appearance="ghost"
                      accessoryLeft={LinkIcon}
                      onPress={() => {
                        uploadNewFile();
                      }}
                    ></Button>
                    <View style={{ padding: 1 }}></View>
                    <Button
                      style={{ height: 41, width: 41 }}
                      appearance="ghost"
                      accessoryLeft={CloudUpIcon}
                      onPress={() => {
                        setHiddenShare(true);
                        rootFolder.refetch();
                      }}
                    ></Button>
                    <View style={{ padding: 1 }}></View>
                    <View>
                      <Tooltip
                        anchor={() => (
                          <Button
                            style={{ height: 41, width: 44 }}
                            appearance="ghost"
                            accessoryLeft={FaceIcon}
                            onPress={() => setIsShowAddMenu(true)}
                          />
                        )}
                        placement={'top'}
                        visible={isShowAddMenu}
                        onBackdropPress={() => setIsShowAddMenu(false)}
                        style={{ backgroundColor: '#FFFFFF', borderColor: '#787777', paddingHorizontal: 0, paddingVertical: 5 }}
                      >
                        <View style={{ flexDirection: 'column', maxWidth: screen.width }}>
                          <View style={{ flexDirection: 'row', maxWidth: screen.width }}>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😀');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😀
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😃');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😃
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😄');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😄
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😁');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😁
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😆');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😆
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😅');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😅
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤣');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤣
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😂');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😂
                            </Text>
                          </View>
                          <View style={{ flexDirection: 'row', maxWidth: screen.width }}>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🙂');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🙂
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🙃');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🙃
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😉');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😉
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😊');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😊
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😇');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😇
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😍');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😍
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤩');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤩
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😘');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😘
                            </Text>
                          </View>
                          <View style={{ flexDirection: 'row', maxWidth: screen.width }}>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😗');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😗
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😊');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😊
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😚');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😚
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😙');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😙
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🥲');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🥲
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🥰');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🥰
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😋');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😋
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😛');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😛
                            </Text>
                          </View>
                          <View style={{ flexDirection: 'row', maxWidth: screen.width }}>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😜');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😜
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤪');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤪
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😝');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😝
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤑');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤑
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤗');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤗
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤭');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤭
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🫢');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🫢
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🫣');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🫣
                            </Text>
                          </View>
                          <View style={{ flexDirection: 'row', maxWidth: screen.width }}>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😴');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😴
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😷');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😷
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤢');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤢
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤮');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤮
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤧');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤧
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🥶');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🥶
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🥳');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🥳
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('☹️');
                                setIsShowAddMenu(false);
                              }}
                            >
                              ☹️
                            </Text>
                          </View>
                          <View style={{ flexDirection: 'row', maxWidth: screen.width }}>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😟');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😟
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😳');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😳
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😡');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😡
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('🤬');
                                setIsShowAddMenu(false);
                              }}
                            >
                              🤬
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('😖');
                                setIsShowAddMenu(false);
                              }}
                            >
                              😖
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('👽');
                                setIsShowAddMenu(false);
                              }}
                            >
                              👽
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('❤️');
                                setIsShowAddMenu(false);
                              }}
                            >
                              ❤️
                            </Text>
                            <Text
                              style={styles.padding6}
                              onPress={() => {
                                addEmoji('💔');
                                setIsShowAddMenu(false);
                              }}
                            >
                              💔
                            </Text>
                          </View>
                        </View>
                      </Tooltip>
                    </View>
                    <View style={{ padding: 1 }}></View>
                    <Button style={{ height: 41, width: 41 }} appearance="ghost" accessoryLeft={CalenderIcon}></Button>
                  </View>

                  <Button
                    size="small"
                    appearance="ghost"
                    style={{ borderColor: '#FFFFFF', backgroundColor: '#FFFFFF', height: 41, borderRadius: 10 }}
                    accessoryLeft={SendIcon}
                    onPress={() => {
                      sendMessage();
                      setTimeout(() => {
                        refScrollView.current.scrollToEnd({ animated: true });
                      }, 100);
                    }}
                  ></Button>
                </View>
              </View>
            </View>
          </View>
        </Layout>
      )}
      {selectID.flag && sign.index === 100 && hiddenShare && (
        <View style={{ height: '100%', flexShrink: 1 }}>
          <View
            style={{ flexDirection: 'row', paddingHorizontal: 10, paddingTop: 8, alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Text style={{ fontSize: 15, fontWeight: '600' }}>Chọn file</Text>
            <View
              style={{
                flexDirection: 'row',

                flexShrink: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              {selectFile.type && (
                <View style={styles.paddingH5}>
                  <Button
                    style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                    size="tiny"
                    onPress={() => {
                      files?.push({ id: selectFile.id, name: selectFile.name });
                      setFiles([...files]);
                      setTimeout(() => {
                        setHiddenShare(false);
                        setSelectFile({ id: '', name: '', type: false });
                      }, 200);
                    }}
                  >
                    Attach
                  </Button>
                </View>
              )}
              <View style={{ paddingLeft: 5 }}>
                <Button
                  style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                  size="tiny"
                  onPress={() => {
                    setHiddenShare(false);
                    setSelectFile({ id: '', name: '', type: false });
                    setFolderPaths([{ id: '', name: 'root' }]);
                    setFolderPathsShare([{ id: '/', name: 'root' }]);
                    setCurrentFolderId('');
                    setCurrentFolderIdShare('/');
                    setSelectedIndexView({ index: 0, flag: false });
                  }}
                >
                  Cancel
                </Button>
              </View>
            </View>
          </View>
          <View style={{ height: '100%', flexShrink: 1 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderBottomColor: '#5b5fc7',
                  borderBottomWidth: selectedIndexView.index === 0 ? 2 : 0,

                  width: '50%',
                  flexShrink: 1,
                }}
              >
                <Button
                  status="basic"
                  appearance="ghost"
                  style={{ width: '100%' }}
                  onPress={() => {
                    selectedIndexView.index = 0;
                    setSelectedIndexView({ ...selectedIndexView });
                    rootFolder.refetch();

                    setFolderPathsShare([{ id: '/', name: 'root' }]);

                    setCurrentFolderIdShare('/');
                  }}
                >
                  Drive
                </Button>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderBottomColor: '#5b5fc7',

                  borderBottomWidth: selectedIndexView.index === 1 ? 2 : 0,
                  width: '50%',
                  flexShrink: 1,
                }}
              >
                <Button
                  status="basic"
                  appearance="ghost"
                  style={{ width: '100%' }}
                  onPress={() => {
                    selectedIndexView.index = 1;
                    setSelectedIndexView({ ...selectedIndexView });
                    rootFolderShare.refetch();
                    setFolderPaths([{ id: '', name: 'root' }]);

                    setCurrentFolderId('');
                  }}
                >
                  Shared
                </Button>
              </View>
            </View>
            {selectedIndexView.index === 0 && (
              <View style={{ flex: 1, flexDirection: 'column' }}>
                <View style={styles.container}>
                  <View style={styles.folderPath}>
                    <ButtonGroup appearance="ghost" size="small" style={{ margin: 0 }}>
                      {displayedFolderPath.map((item, index) => (
                        <React.Fragment key={item.id}>
                          <Button
                            style={{ backgroundColor: '#ffffff', marginBottom: 1 }}
                            appearance={displayedFolderPath?.length - 1 === index ? 'root-pact1' : 'root-pact'}
                            size="small"
                            disabled={item.name === '....'}
                            onPress={() => {
                              setFolderPaths(folderPaths.splice(0, folderPaths.findIndex((value) => value.id == item.id) + 1));
                              setCurrentFolderId(item.id);

                              if (item.id != currentFolderId) setSelectFile({ id: '', name: '', type: false });
                            }}
                          >
                            {item.name.length < 15 ? item.name : item.name.slice(0, 15) + '...'}
                          </Button>
                          {displayedFolderPath?.length - 1 != index && (
                            <Button
                              disabled
                              style={{ backgroundColor: '#ffffff', width: 6, minWidth: 6, marginBottom: 1 }}
                              appearance="root-pact"
                              size="small"
                              accessoryLeft={RightIcon}
                            ></Button>
                          )}
                        </React.Fragment>
                      ))}
                    </ButtonGroup>
                  </View>
                  {rootFolder.isFetched &&
                    (rootFolder.data?.contains?.length > 0 ? (
                      <List
                        style={{ backgroundColor: '#FFFFFF' }}
                        data={rootFolder.data.contains.sort((itemA, itemB) => {
                          if (itemA.type === 'directory' && itemB.type === 'file') {
                            return -1;
                          }

                          if (itemA.type === 'file' && itemB.type === 'directory') {
                            return 1;
                          }

                          return itemA.name > itemB.name ? 1 : -1;
                        })}
                        renderItem={renderFiles}
                      />
                    ) : (
                      <Card style={{ borderWidth: 0 }}>
                        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                          <NullIcon></NullIcon>
                        </View>
                        <View style={styles.padding6}></View>
                        <Text style={{ textAlign: 'center' }}>Chưa có files</Text>
                      </Card>
                    ))}
                </View>
              </View>
            )}
            {selectedIndexView.index === 1 && (
              <View style={{ flex: 1, flexDirection: 'column' }}>
                <View style={styles.container}>
                  <View style={styles.folderPath}>
                    <ButtonGroup appearance="ghost" size="small" style={{ margin: 0 }}>
                      {displayedFolderPathShare.map((item, index) => (
                        <React.Fragment key={item.id}>
                          <Button
                            style={{ backgroundColor: '#ffffff', marginBottom: 1 }}
                            appearance={displayedFolderPathShare?.length - 1 === index ? 'root-pact1' : 'root-pact'}
                            size="small"
                            disabled={item.name === '....'}
                            onPress={() => {
                              setFolderPathsShare(
                                folderPathsShare.splice(0, folderPathsShare.findIndex((value) => value.id == item.id) + 1)
                              );
                              setCurrentFolderIdShare(item.id);
                              if (item.id != currentFolderIdShare) setSelectFile({ id: '', name: '', type: false });
                            }}
                          >
                            {item.name.length < 15 ? item.name : item.name.slice(0, 15) + '...'}
                          </Button>
                          {displayedFolderPathShare?.length - 1 != index && (
                            <Button
                              disabled
                              style={{ backgroundColor: '#ffffff', width: 6, minWidth: 6, marginBottom: 1 }}
                              appearance="root-pact"
                              size="small"
                              accessoryLeft={RightIcon}
                            ></Button>
                          )}
                        </React.Fragment>
                      ))}
                    </ButtonGroup>
                  </View>
                  {rootFolderShare.isFetched &&
                    (rootFolderShare.data?.contains?.length > 0 || (currentFolderIdShare === '/' && rootFolderShare.data?.length > 0) ? (
                      <List
                        style={{ backgroundColor: '#FFFFFF' }}
                        data={(currentFolderIdShare === '/' ? rootFolderShare.data : rootFolderShare.data.contains).sort((itemA, itemB) => {
                          if (itemA.type === 'directory' && itemB.type === 'file') {
                            return -1;
                          }

                          if (itemA.type === 'file' && itemB.type === 'directory') {
                            return 1;
                          }

                          return itemA.name > itemB.name ? 1 : -1;
                        })}
                        renderItem={renderFilesShare}
                      />
                    ) : (
                      <Card style={{ borderWidth: 0 }}>
                        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                          <NullIcon></NullIcon>
                        </View>
                        <View style={styles.padding6}></View>
                        <Text style={{ textAlign: 'center' }}>Chưa có files</Text>
                      </Card>
                    ))}
                </View>
              </View>
            )}
          </View>
        </View>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    margin: 2,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconH: {
    fontWeight: '600',
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 17,
    marginBottom: 2,
  },
  iconHeader: {
    display: 'flex',
    fontSize: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutRow: {
    display: 'flex',
    paddingTop: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft45: {
    display: 'flex',
    width: '45%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutColumnLeft45: {
    display: 'flex',
    width: '45%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter25: {
    display: 'flex',
    width: '25%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 40,
    backgroundColor: '#5b5fc7',
    borderRadius: 40,
  },
  dividerline: {
    width: '100%',
    color: '#adb5bd',
    borderColor: '#adb5bd',
    backgroundColorColor: '#adb5bd',
    height: 3,
    fontWeight: '600',
    flexShrink: 1,
  },

  toolbarButton: {
    fontSize: 20,
    width: 28,
    height: 28,
    textAlign: 'center',
  },
  folderPath: {
    display: 'flex',
    width: '100%',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderTopColor: '#f0f0f0',
    borderTopWidth: 1,
  },
  renderFile: {
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
    paddingLeft: 7,
  },
  layout: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  maxLayout: {
    height: '100%',
    width: '100%',
  },
  header1View: {
    paddingHorizontal: 12,
    paddingBottom: 8,
    paddingTop: 16,
  },
  maxWidthLeft: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  rownotShrinkView: {
    flexDirection: 'row',
  },
  padding5: {
    padding: 5,
  },
  trucTiepView: {
    flexShrink: 1,
    paddingBottom: 8,
  },
  tructiepHeader: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  view1: {
    height: '100%',
    width: '100%',
    flexShrink: 1,
    flexDirection: 'column',
  },
  viewItemTT: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: 12,
  },
  view2: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 12,
    width: '100%',
  },
  rowCenterView: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  statusView: {
    position: 'absolute',
    bottom: -5,
    right: -5,
    height: 13,
    width: 13,
    borderWidth: 2,
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
  },
  view5: {
    position: 'absolute',
    bottom: -5,
    right: -5,
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#FFFFFF',
    backgroundColor: '#636e72',
    justifyContent: 'center',
    alignItems: 'center',
  },
  view6: {
    flexDirection: 'column',
    paddingLeft: 10,
    justifyContent: 'center',
  },
  addChatLayout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingTop: 5.5,
    flexShrink: 1,
    backgroundColor: 'white',
    width: '100%',
  },
  layoutView: {
    flex: 6,
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    alignItems: 'center',
  },
  layoutView1: {
    width: '100%',
    flexShrink: 1,
    paddingRight: 11.5,
  },
  paddingH5: {
    paddingHorizontal: 5,
  },
  maxWidthView: {
    flexShrink: 1,
    width: '100%',
  },
  columnMaxWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  view7: {
    flexDirection: 'column',
    paddingLeft: 10,
  },
  padding3: {
    padding: 3,
  },
  layoutH: {
    height: '100%',
    width: '100%',
    flexShrink: 1,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  columnnotShrinkView: {
    flexDirection: 'column',
  },
  view8: {
    flexDirection: 'row',
    paddingTop: 5,
    lineHeight: 25,
  },
  loadingView: {
    zIndex: 1000,
    backgroundColor: '#FFFFFF',
    borderWidth: 0,
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    justifyContent: 'center',
  },
  loadingIcon: {
    transform: [{ scaleX: 0.5 }, { scaleY: 0.5 }],
    paddingTop: 8,
  },
  view9: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  view10: {
    flex: 1,
    height: 1,
    backgroundColor: '#adb5bd',
  },
  maxHeightCenter: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowmaxWidthCenter: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-end',
  },
  rowmaxWidthP5: {
    flexDirection: 'row',
    paddingVertical: 5,
    width: '100%',
  },
  columnmaxWidthPl10: {
    flexDirection: 'column',
    paddingLeft: 10,
    width: '100%',
    flexShrink: 1,
  },
  rowmaxWith: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
  view11: {
    borderRadius: 25,
    borderWidth: 0,
    borderColor: '#adb5bd',
    paddingVertical: 5,
    paddingHorizontal: 14,
  },
  padding6: {
    padding: 6,
  },
  padding2_5: {
    padding: 3,
  },
  iconHeader1: {
    display: 'flex',
    fontSize: 15,
    fontWeight: 400,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 1,
  },
  modalSharedHeaderLayout: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  modalHeaderView: {
    paddingHorizontal: 15,
    paddingTop: 11.5,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
});
