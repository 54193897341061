import { Avatar, Button, MenuItem, OverflowMenu, Text } from '@ui-kitten/components';
import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useLocation, useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { EBottomTab, navigationContext } from '../../hooks/navigation';
import { signContext } from '../../hooks/signingprocess';
import { getAvatarColor } from '../../utils/avatar';

import { getBaseApiUrl } from '../../config';

const MenuIcon = (props) => <Icon {...props} style={{ ...styles.iconHeader }} name="align-justify" fill="#5b5fc7" />;

const BackIcon = (props) => <Icon {...props} style={styles.iconHeader} name="angle-left" color="#5b5fc7" />;

const AvatarImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + auth.user.avatar;
  const imageName = auth.user?.firstname?.at(0) + auth.user?.lastname?.at(0);

  if (auth.user.avatar != '') return <Avatar size={props.size ? props.size : 'medium'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 40,
          width: 40,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Text style={{ color: '#ffffff', fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

const SignOutIcon = (props) => (
  <Svg width={17} height={14} viewBox="0 0 512 512">
    <Path
      d="M352 146.2L462 256 352 365.8l0-53.8c0-13.3-10.7-24-24-24l-120 0 0-64 120 0c13.3 0 24-10.7 24-24l0-53.8zM512 256c0-11.5-4.6-22.5-12.7-30.6L383.2 109.6c-8.7-8.7-20.5-13.6-32.8-13.6c-25.6 0-46.4 20.8-46.4 46.4l0 33.6-96 0c-26.5 0-48 21.5-48 48l0 64c0 26.5 21.5 48 48 48l96 0 0 33.6c0 25.6 20.8 46.4 46.4 46.4c12.3 0 24.1-4.9 32.8-13.6L499.3 286.6c8.1-8.1 12.7-19.1 12.7-30.6zM168 80c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l80 0z"
      fill="#424242"
    />
  </Svg>
);

const AccountIcon = (props) => (
  <Svg width={17} height={14} viewBox="0 0 576 512">
    <Path
      d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z"
      fill="#424242"
    />
  </Svg>
);

export const TopNavigation = () => {
  const navigate = useNavigate();
  const navigation = useContext(navigationContext);
  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const state = useLocation();
  const [visible, setVisible] = React.useState(false);
  return (
    <View style={styles.layoutBar} nativeID="NavBar">
      {!(
        (state.pathname.slice(0, 8) === '/dpacts/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 9) === '/dlumens/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 8) === '/dfuses/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 7) === '/pacts/' && state.pathname.slice(7, 8) != '') ||
        (state.pathname.slice(0, 8) === '/lumens/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 9) === '/elumens/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 10) === '/edlumens/' && state.pathname.slice(10, 11) != '') ||
        (state.pathname.slice(0, 7) === '/fuses/' && state.pathname.slice(7, 8) != '') ||
        (state.pathname.slice(0, 8) === '/efuses/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 9) === '/edfuses/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 9) === '/agendas/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 10) === '/dagendas/' && state.pathname.slice(10, 11) != '') ||
        (state.pathname.slice(0, 7) === '/tasks/' && state.pathname.slice(7, 8) != '') ||
        sign.index === 100
      ) && (
        <Button style={styles.button} appearance="ghost" onPress={() => navigation.setShowSideBar(true)} accessoryLeft={MenuIcon}></Button>
      )}
      {((state.pathname.slice(0, 8) === '/dpacts/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 9) === '/dlumens/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 8) === '/dfuses/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 7) === '/pacts/' && state.pathname.slice(7, 8) != '') ||
        (state.pathname.slice(0, 8) === '/lumens/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 9) === '/elumens/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 10) === '/edlumens/' && state.pathname.slice(10, 11) != '') ||
        (state.pathname.slice(0, 7) === '/fuses/' && state.pathname.slice(7, 8) != '') ||
        (state.pathname.slice(0, 8) === '/efuses/' && state.pathname.slice(8, 9) != '') ||
        (state.pathname.slice(0, 9) === '/edfuses/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 9) === '/agendas/' && state.pathname.slice(9, 10) != '') ||
        (state.pathname.slice(0, 10) === '/dagendas/' && state.pathname.slice(10, 11) != '') ||
        (state.pathname.slice(0, 7) === '/tasks/' && state.pathname.slice(7, 8) != '') ||
        sign.index === 100) && (
        <Button
          style={styles.button}
          appearance="ghost"
          onPress={() => {
            if (sign.revert.flag) {
              navigate(sign.revert.id);
              sign.setnullRe();
            } else if (state.pathname.slice(0, 8) === '/dpacts/' && state.pathname.slice(8, 9) != '') navigate('/dpacts');
            else if (state.pathname.slice(0, 9) === '/dlumens/' && state.pathname.slice(9, 10) != '') navigate('/dlumens');
            else if (state.pathname.slice(0, 8) === '/dfuses/' && state.pathname.slice(8, 9) != '') navigate('/dfuses');
            else if (state.pathname.slice(0, 8) === '/efuses/' && state.pathname.slice(8, 9) != '') navigate('/efuses');
            else if (state.pathname.slice(0, 9) === '/edfuses/' && state.pathname.slice(9, 10) != '') navigate('/edfuses');
            else if (state.pathname.slice(0, 7) === '/pacts/' && state.pathname.slice(7, 8) != '') navigate('/pacts');
            else if (state.pathname.slice(0, 8) === '/lumens/' && state.pathname.slice(8, 9) != '') navigate('/lumens');
            else if (state.pathname.slice(0, 9) === '/elumens/' && state.pathname.slice(9, 10) != '') navigate('/elumens');
            else if (state.pathname.slice(0, 10) === '/edlumens/' && state.pathname.slice(10, 11) != '') navigate('/edlumens');
            else if (state.pathname.slice(0, 9) === '/agendas/' && state.pathname.slice(9, 10) != '') navigate('/agendas');
            else if (state.pathname.slice(0, 10) === '/dagendas/' && state.pathname.slice(10, 11) != '') navigate('/dagendas');
            else if (state.pathname.slice(0, 7) === '/tasks/' && state.pathname.slice(7, 8) != '') navigate('/tasks');
            else if (sign.index === 100) sign.setNullIndex();
            else navigate('/fuses');
            sign.setFlag();
            sign.setStatus({ color: '', tag: '' });
          }}
          accessoryLeft={BackIcon}
        ></Button>
      )}
      {navigation.state.bottomTab === EBottomTab.PACT && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>QUY TRÌNH</Text>
          {sign.status.tag != '' && <View style={{ padding: 4 }}></View>}
          {sign.status.tag != '' && (
            <View
              style={{ backgroundColor: sign.status.color, paddingHorizontal: 9, paddingVertical: 2.5, borderRadius: 5, marginRight: 5 }}
            >
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 12,
                  paddingBottom:
                    sign.status.tag === 'Hủy' || sign.status.tag === 'Chờ phê duyệt' || sign.status.tag === 'Đang xử lý' ? 1 : 0,
                }}
              >
                {sign.status.tag}
              </Text>
            </View>
          )}
        </View>
      )}
      {navigation.state.bottomTab === EBottomTab.FILE && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>FILES</Text>
        </View>
      )}
      {navigation.state.bottomTab === EBottomTab.SCHEDULE && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>LỊCH BIỂU</Text>
        </View>
      )}
      {navigation.state.bottomTab === EBottomTab.MEETING && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>PHIÊN HỌP</Text>
          {sign.status.tag != '' && <View style={{ padding: 4 }}></View>}
          {sign.status.tag != '' && (
            <View
              style={{ backgroundColor: sign.status.color, paddingHorizontal: 9, paddingVertical: 2.5, borderRadius: 5, marginRight: 5 }}
            >
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 12,
                  paddingBottom: sign.status.tag === 'Đã hủy' || sign.status.tag === 'Đang soạn' || sign.status.tag === 'Đang mở' ? 1 : 0,
                }}
              >
                {sign.status.tag}
              </Text>
            </View>
          )}
        </View>
      )}
      {navigation.state.bottomTab === EBottomTab.TASK && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>CHỈ ĐẠO</Text>
          {sign.status.tag != '' && <View style={{ padding: 4 }}></View>}
          {sign.status.tag != '' && (
            <View
              style={{ backgroundColor: sign.status.color, paddingHorizontal: 9, paddingVertical: 2.5, borderRadius: 5, marginRight: 5 }}
            >
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 12,
                  paddingBottom:
                    sign.status.tag === 'Đã hủy' ||
                    sign.status.tag === 'Quá hạn' ||
                    sign.status.tag === 'Sắp đến hạn' ||
                    sign.status.tag === 'Đang xử lý'
                      ? 1
                      : 0,
                }}
              >
                {sign.status.tag}
              </Text>
            </View>
          )}
        </View>
      )}
      {navigation.state.bottomTab === EBottomTab.PROFILE && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>{auth.user.fullname?.toUpperCase()}</Text>
        </View>
      )}

      {sign.index === 100 && navigation.state.bottomTab === EBottomTab.CHAT && (
        <View style={styles.headerChatView}>
          <Text style={{ color: '#5b5fc7', fontWeight: '600', fontSize: 15 }}>{sign.chat.name}</Text>
          <View style={{ padding: sign.chat.status != '' ? 1.5 : 0 }}></View>
          <Text style={{ fontSize: 12, color: '#c2c5ce' }}>{sign.chat.status}</Text>
        </View>
      )}
      {sign.index != 100 && navigation.state.bottomTab === EBottomTab.CHAT && (
        <View style={styles.headerView}>
          <Text style={{ color: '#5b5fc7', fontWeight: 'bold', fontSize: 15 }}>CHAT</Text>
        </View>
      )}

      <OverflowMenu
        anchor={() => (
          <Button
            style={styles.avatar}
            appearance="ghost"
            accessoryLeft={AvatarImage}
            onPress={() => {
              setVisible(true);
            }}
          ></Button>
        )}
        visible={visible}
        onBackdropPress={() => setVisible(false)}
      >
        <MenuItem
          appearance="file-menu"
          accessoryLeft={AccountIcon}
          onPress={() => {
            sign.setStatus({ color: '', tag: '' });
            setVisible(false);
            sign.setNullIndex();
            navigate('/profile');
          }}
          title={'Tài khoản'}
        />
        <MenuItem
          appearance="file-menu"
          accessoryLeft={SignOutIcon}
          onPress={() => {
            window?.ReactNativeWebView?.postMessage('Data from WebView / Website');
            sign.setStatus({ color: '', tag: '' });
            navigation.setShowSideBar(false);
            navigation.setRole(false, false, false, false, false);
            auth.signOut();
            navigate('/login');
            window.location.reload();
          }}
          title={'Đăng xuất'}
        />
      </OverflowMenu>
    </View>
  );
};

const styles = StyleSheet.create({
  imageAvatar: {
    height: 15,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#5b5fc7',
    marginBottom: 1,
  },
  layoutBar: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 5,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#eaecf0',
    paddingBottom: 0,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#5b5fc7',
  },
  input: {
    width: '100%',
    flexShrink: 1,
    paddingLeft: 10,
    paddingRight: 10,
    marginVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {},
  headerView: {
    width: '100%',
    flexShrink: 1,
    minHeight: 64,
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerChatView: {
    flexDirection: 'column',
    width: '100%',
    flexShrink: 1,
    minHeight: 64,
    justifyContent: 'center',
  },
  menuItem: {
    paddingVertical: 4,
  },
});
