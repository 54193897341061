import axios from 'axios';

export const getServerConfig = async () => {
  return await axios.request({
    url: `/config.json`,
    method: 'GET',
  });
};

declare global {
  interface Window {
    backendConfig: {
      baseUrl: string;
      baseApiUrl: string;
      chatUrl: string;
      googleClientId: string;
      buildNumber: string;
    };
  }
}

