import {
  Button,
  Card,
  IndexPath,
  Input,
  Layout,
  List,
  ListItem,
  MenuItem,
  OverflowMenu,
  Select,
  SelectItem,
  Spinner,
  Text,
} from '@ui-kitten/components';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon3 from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon2 from 'react-native-vector-icons/Octicons';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { navigationContext } from '../../hooks/navigation';
import { signContext } from '../../hooks/signingprocess';
import { getTask, taskId } from '../../services/agendaprocess';
import { putSeen } from '../../services/signingprocess';
import {
  AllIcon,
  AllRenderIcon,
  DeadIcon,
  DeadRenderIcon,
  DoneIcon,
  DoneRenderIcon,
  EjectIcon,
  EjectRenderIcon,
  KNIcon,
  KNRenderIcon,
  ProgressIcon,
  SearchIcon,
  WaitIcon,
  WaitRenderIcon,
} from '../../share/filter-icon';

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 576 512">
    <Path
      d="M320 32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H296.2c9.8 11.8 21 22.3 33.5 31.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V198.6c-11.2 3.2-21.9 7.4-32 12.6V64c0-17.7-14.3-32-32-32zM64 144c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm16 80H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H208c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zM432 480a112 112 0 1 0 0-224 112 112 0 1 0 0 224zm0-256a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm44.7 100.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7z"
      fill="#424242"
    />
  </Svg>
);

const SortDownIcon = (props) => (
  <Icon1
    {...props}
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      minWidth: 16,
      minHeight: 16,
    }}
    name="caret-down"
    color="#424242"
  />
);

const SortUpIcon = (props) => (
  <Icon1
    {...props}
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      minWidth: 16,
      minHeight: 16,
    }}
    name="caret-up"
    color="#424242"
  />
);

const FlagIcon = (props) => (
  <Icon1
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}
    name="circle"
    color={props.check === 1 ? '#12B76A' : props.check === 2 ? '#0d6efd' : props.check === 3 ? '#dc3545' : '#BDBDBD'}
  />
);
const WarningIcon = (props) => (
  <Svg width={18} height={15} viewBox="0 0 512 512">
    <Path
      d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
      fill="#dc3545"
    />
  </Svg>
);

const SoonIcon = (props) => (
  <Icon3
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}
    name="circle-half-full"
    color={'#e58e26'}
  />
);

export default (props) => {
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = React.useState(false);

  const showWaiting = () => {
    // setIsWaiting(true);
    // setTimeout(() => setIsWaiting(false), 500);
  };

  useEffect(() => {
    // setTimeout(() => setIsWaiting(false), 500);
  }, []);

  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  useEffect(() => {
    if (sign.index != null) {
      setSelectedIndex(new IndexPath(sign.index));
      sign.setNullIndex();
    }
  }, []);

  const [selectedProcess, setSelectedProcess] = React.useState<string>(taskId.all);
  const [visible, setVisible] = React.useState(false);
  const rootPact = useQuery(['tasks', selectedProcess], () => getTask(selectedProcess));
  const [pactProcessed2, setPactProcessed2] = React.useState({
    currentData: null,
    preData: null,
    newData: null,
    isNewData: false,
    isCreate: false,
    isReset: false,
    isLoadOld: false,
    index: 0,
  });
  const { units, cohorts } = useContext(authContext);
  const [isSortName, setIsSortName] = React.useState(false);
  const [isSortDate, setIsSortDate] = React.useState(false);
  const [sortType, setSortType] = React.useState(-1);

  const onItemSelect = (index) => {
    if (index.row === selectedIndex.row) {
      setVisible(false);
      return;
    }
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });

    if (index?.row === 0) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.all);
    } else if (index?.row === 1) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.process);
    } else if (index?.row === 2) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.process);
    } else if (index?.row === 3) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.process);
    } else if (index?.row === 4) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.reminded);
    } else if (index?.row === 5) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.done);
    } else if (index?.row === 6) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(taskId.cancel);
    } else if (index?.row === 7) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(false);
      setIsSearch(true);
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 0);
    showWaiting();
    setVisible(false);
  };

  const [isShowSearch, setIsShowSearch] = React.useState(true);
  const [isSearch, setIsSearch] = React.useState(false);
  const [searchName, setSearchName] = React.useState('');
  const [selectedIndexSearchDV, setSelectedIndexSearchDV] = React.useState(new IndexPath(0));
  const [selectedIndexSearchTV, setSelectedIndexSearchTV] = React.useState(new IndexPath(0));
  const dataDV = [
    'Đơn vị phụ trách',
    'B245',
    'BCSĐ',
    'BGĐ',
    'BTC',
    'CTSV',
    'CĐCQ',
    'CĐKVP',
    'IPMU',
    'KHCN',
    'KHĐT',
    'QLDA',
    'QSCQ',
    'TCCB',
    'TCĐU',
    'TGĐU',
    'TTPC',
    'TĐKT',
    'UBKT',
    'VPCĐ',
    'VPGĐ',
    'VPĐU',
    'ĐNDA',
    'ĐTĐH',
  ];
  const dataOPDV = [
    '',
    'DPM-0079',
    'DPM-0014',
    'DPM-0013',
    'DPM-0078',
    'DPM-0001',
    'DPM-0075',
    'DPM-0076',
    'DPM-0049',
    'DPM-0004',
    'DPM-0005',
    'DPM-0046',
    'DPM-0077',
    'DPM-0006',
    'DPM-0012',
    'DPM-0053',
    'DPM-0008',
    'DPM-0007',
    'DPM-0054',
    'DPM-0011',
    'DPM-0009',
    'DPM-0010',
    'DPM-0003',
    'DPM-0002',
  ];

  const displayValueDV = dataDV[selectedIndexSearchDV.row];
  const dataTV = ['Loại tác vụ', 'Xử lý văn bản', 'Chỉ đạo cuộc họp', 'Phân công'];
  const dataTVId = ['', 'Fuse', 'Agenda', 'Task'];
  const displayValueTV = dataTV[selectedIndexSearchTV.row];
  const searchUnit = dataOPDV[selectedIndexSearchDV.row];
  const searchTV = dataTVId[selectedIndexSearchTV.row];

  const onClickSearch = () => {
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    const id = searchName != '' ? '&keyword=' + searchName : '';
    const unit = searchUnit != '' ? '&unitId=' + searchUnit : '';
    const tv = searchTV != '' ? '&originType=' + searchTV : '';
    setSelectedProcess('/?pgSkip=0&pgLimit=40' + id + unit + tv);
    setTimeout(() => {
      setSelectedIndex(new IndexPath(7));
    }, 20);
    setIsShowSearch(true);
    showWaiting();
  };

  const FiltersIcon = (props) => <Icon {...props} style={styles.iconHeader} color="#5b5fc7" />;

  const Filters1Icon = (props) => <Icon2 {...props} style={styles.iconHeader} color="#5b5fc7" />;

  const renderToggleButton = () => (
    <Button
      style={styles.filterButton}
      size="tiny"
      appearance="ghost-filter"
      onPress={() => setVisible(true)}
      accessoryRight={
        selectedIndex.row === 3 ? (
          <DeadRenderIcon></DeadRenderIcon>
        ) : selectedIndex.row === 4 ? (
          <KNRenderIcon></KNRenderIcon>
        ) : selectedIndex.row === 2 ? (
          <WaitRenderIcon></WaitRenderIcon>
        ) : selectedIndex.row === 0 ? (
          <AllRenderIcon></AllRenderIcon>
        ) : selectedIndex.row === 1 ? (
          <ProgressIcon></ProgressIcon>
        ) : selectedIndex.row === 6 ? (
          <EjectRenderIcon></EjectRenderIcon>
        ) : selectedIndex.row === 5 ? (
          <DoneRenderIcon></DoneRenderIcon>
        ) : (
          <SearchIcon></SearchIcon>
        )
      }
    >
      {selectedIndex.row === 0
        ? 'Tất cả'
        : selectedIndex.row === 1
        ? 'Đang xử lý'
        : selectedIndex.row === 2
        ? 'Chờ tôi'
        : selectedIndex.row === 3
        ? 'Quá hạn'
        : selectedIndex.row === 4
        ? 'Kiến nghị'
        : selectedIndex.row === 5
        ? 'Hoàn tất'
        : selectedIndex.row === 6
        ? 'Đã hủy'
        : 'Tra cứu'}
    </Button>
  );

  useEffect(() => {
    if (rootPact.isFetching) return;
    if (rootPact.data === undefined) return;
    if (units === null) return;
    if (cohorts === null) return;
    const data = rootPact.data
      ?.sort((itemA, itemB) => {
        if (isSortDate) return itemA.deadline > itemB.deadline ? 1 : -1;
        else return itemA.deadline < itemB.deadline ? 1 : -1;
      })
      ?.map((item, index, array) => {
        const time = new Date();
        if (item?.status === 'cancelled') {
          item.tagSpec = 'Đã hủy';
          item.tagSpecColor = '#636e72';
        } else if (item?.status === 'closed') {
          item.tagSpec = 'Hoàn tất';
          item.tagSpecColor = '#575fcf';
        } else if (item?.status === 'open' && item?.deadline - time.getTime() > 0 && item?.deadline - time.getTime() < 86400000) {
          item.tagSpec = 'Sắp đến hạn';
          item.tagSpecColor = '#e17055';
        } else if (item?.status === 'open' && item?.deadline < time.getTime()) {
          item.tagSpec = 'Quá hạn';
          item.tagSpecColor = '#DC3545';
        } else if (item?.status === 'reminded' && item?.deadline - time.getTime() > 0 && item?.deadline - time.getTime() < 86400000) {
          item.tagSpec = 'Sắp đến hạn';
          item.tagSpecColor = '#e17055';
        } else if (item?.status === 'reminded' && item?.deadline < time.getTime()) {
          item.tagSpec = 'Quá hạn';
          item.tagSpecColor = '#DC3545';
        } else {
          item.tagSpec = 'Đang xử lý';
          item.tagSpecColor = '#198754';
        }

        if (item?.seen[auth.user._id] === undefined) {
          item.isSeen = false;
        } else {
          if (item?.modifiedBy === auth.user._id) {
            item.isSeen = true;
          } else if (item?.seen[auth.user._id] > item?.modifiedAt) {
            item.isSeen = true;
          } else item.isSeen = false;
        }

        const handle = auth.users?.find((user) => user._id === item?.undertakerId);
        const unit = units?.find((unit) => unit?._id === item?.unitId);

        const timeEnd = new Date(item.deadline);

        item.handle = item?.originType != 'Task' ? unit?.shortName : handle?.firstname + ' ' + handle?.lastname.split(' ')[0];

        const date = timeEnd.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
        item.date = date;
        return item;
      })
      ?.filter((item) => {
        if (selectedIndex.row === 2)
          return (
            item?.pendingForUsers?.find((id) => id === auth.user._id) != undefined ||
            units?.find(
              (unit) =>
                item?.pendingForUnits?.find((uId) => uId === unit._id) != undefined &&
                unit?.members?.find((id) => id === auth.user._id) != undefined
            ) != undefined ||
            cohorts?.find(
              (cohort) =>
                item?.pendingForCohorts?.find((uId) => uId === cohort._id) != undefined &&
                cohort?.members?.find((id) => id === auth.user._id) != undefined
            ) != undefined
          );
        if (selectedIndex.row === 3) return item.tagSpec === 'Quá hạn';
        else return item;
      });

    pactProcessed2.newData = data;
    pactProcessed2.isNewData = true;
    setPactProcessed2({ ...pactProcessed2 });
  }, [rootPact.isFetched, rootPact.isFetching, selectedIndex, auth, auth.user, auth.users, units, cohorts]);

  useEffect(() => {
    if (!pactProcessed2.isCreate && pactProcessed2.isNewData) {
      pactProcessed2.currentData = pactProcessed2.newData;
      pactProcessed2.preData = pactProcessed2.newData;
      pactProcessed2.isNewData = false;
      pactProcessed2.isCreate = true;
      pactProcessed2.index = pactProcessed2.newData?.length > 39 ? 1 : -1;
      pactProcessed2.newData = null;
      setPactProcessed2({ ...pactProcessed2 });
    } else if (pactProcessed2.isNewData && JSON.stringify(pactProcessed2.newData) === JSON.stringify(pactProcessed2.preData)) {
      pactProcessed2.newData = null;
      pactProcessed2.isNewData = false;
      setPactProcessed2({ ...pactProcessed2 });
    } else if (
      pactProcessed2.isNewData &&
      JSON.stringify(pactProcessed2.newData) != JSON.stringify(pactProcessed2.preData) &&
      pactProcessed2.isLoadOld
    ) {
      pactProcessed2.currentData = [...pactProcessed2.currentData, ...pactProcessed2.newData];
      pactProcessed2.preData = pactProcessed2.newData;
      pactProcessed2.isNewData = false;
      pactProcessed2.isLoadOld = false;
      pactProcessed2.index = pactProcessed2.newData?.length > 39 ? pactProcessed2.index + 1 : -1;
      pactProcessed2.newData = null;
      setPactProcessed2({ ...pactProcessed2 });
    }
  }, [pactProcessed2]);

  const onClickSortName = () => {
    setIsSortName(isSortName ? false : true);
    if (isSortName) {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.name < itemB.name ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    } else {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.name > itemB.name ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    }
  };

  const onClickSortDate = () => {
    setIsSortDate(isSortDate ? false : true);
    if (isSortDate) {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.modifiedAt < itemB.modifiedAt ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    } else {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.modifiedAt > itemB.modifiedAt ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    }
  };

  const navigation = useContext(navigationContext);
  const [navigationIndex, setNavigationIndex] = React.useState(false);

  useEffect(() => {
    if (navigation.state.indexState === -1) return;
    if (navigation.state.indexState === 7) return;
    if (navigationIndex) return;
    setNavigationIndex(true);
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    if (navigation.state.indexState === 0) {
      setSelectedProcess(taskId.all);
    } else if (navigation.state.indexState === 1) {
      setSelectedProcess(taskId.process);
    } else if (navigation.state.indexState === 2) {
      setSelectedProcess(taskId.process);
    } else if (navigation.state.indexState === 3) {
      setSelectedProcess(taskId.process);
    } else if (navigation.state.indexState === 4) {
      setSelectedProcess(taskId.reminded);
    } else if (navigation.state.indexState === 5) {
      setSelectedProcess(taskId.done);
    } else if (navigation.state.indexState === 6) {
      setSelectedProcess(taskId.cancel);
    }
    setTimeout(() => {
      setSelectedIndex(new IndexPath(navigation.state.indexState));
    }, 20);
  }, [navigation, navigation.state]);

  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        style={{
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onPress={() => {
          navigation.setIndexState(selectedIndex.row);
          putSeen(item?._id, 'Task');
          navigate(`/tasks/${item?._id}`);
        }}
        key={index}
      >
        <View style={styles.layoutBorder}>
          <View style={styles.layoutColumnLeft50}>
            <Text
              style={{ fontSize: 14, fontWeight: item?.isSeen ? '400' : '600', color: item?.isSeen ? '#222' : '#424242' }}
              numberOfLines={1}
              category="s1"
            >
              {item.name != '' && item.name != null ? item.name : ''}
            </Text>
            <View style={{ padding: 3 }}></View>
            <Text style={{ color: '#667085', fontSize: 13 }} numberOfLines={1}>
              {item.handle != '-' && item.handle != null ? item.handle : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter20}>
            {item.tagSpec != 'Quá hạn' && item.tagSpec != 'Sắp đến hạn' && (
              <FlagIcon check={item.status === 'open' || item.status === 'reminded' ? 1 : 0}></FlagIcon>
            )}
            {item.tagSpec === 'Quá hạn' && <WarningIcon></WarningIcon>}
            {item.tagSpec === 'Sắp đến hạn' && <SoonIcon></SoonIcon>}
          </View>
          <View style={styles.layoutCenter30}>
            <Text style={{ fontSize: 14, marginRight: 16 }}>{item.date != '' && item.date != null ? item.date : '01-01-1997'}</Text>
          </View>
        </View>
      </ListItem>
    );
  };

  return (
    <Layout style={{ flex: 1, flexDirection: 'column' }} nativeID="layoutTask">
      <Layout style={styles.mainLayout}>
        <View style={styles.headerView}>
          <View style={styles.layoutRow}>
            <Text style={styles.textHeader} category="s1">
              TIẾN ĐỘ XỬ LÝ
            </Text>
          </View>
        </View>

        <View style={{}}>
          <OverflowMenu
            style={{ minHeight: 323 }}
            anchor={renderToggleButton}
            visible={visible}
            selectedIndex={selectedIndex}
            onSelect={onItemSelect}
            onBackdropPress={() => setVisible(false)}
          >
            <MenuItem appearance="file-menu" accessoryLeft={AllIcon} title="Tất cả" />
            <MenuItem appearance="file-menu" accessoryLeft={ProgressIcon} title="Đang xử lý" />
            <MenuItem appearance="file-menu" accessoryLeft={WaitIcon} title="Chờ tôi" />
            <MenuItem appearance="file-menu" accessoryLeft={DeadIcon} title="Quá hạn" />
            <MenuItem appearance="file-menu" accessoryLeft={KNIcon} title="Kiến nghị" />
            <MenuItem appearance="file-menu" accessoryLeft={DoneIcon} title="Hoàn tất" />
            <MenuItem appearance="file-menu" accessoryLeft={EjectIcon} title="Đã hủy" />
            <MenuItem appearance="file-menu" accessoryLeft={SearchIcon} title="Tra cứu" />
          </OverflowMenu>
        </View>
      </Layout>
      <View style={styles.maxlayout}>
        {isWaiting && (
          <View
            // @ts-ignore
            style={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '80%',
              width: '100%',
              background: 'white',
              zIndex: 999,
            }}
          >
            <Spinner appearance="ioc" status="primary" />
          </View>
        )}
        {isSearch && (
          <View style={styles.searchView}>
            {!isShowSearch && (
              <Input
                style={{ paddingVertical: 5 }}
                placeholder="Từ khóa"
                value={searchName}
                onChangeText={(nextValue) => setSearchName(nextValue)}
              />
            )}
            {!isShowSearch && (
              <Select
                appearance="ioc"
                style={{ backgroundColor: 'white', paddingVertical: 5 }}
                selectedIndex={selectedIndexSearchTV}
                value={displayValueTV}
                onSelect={(index) => setSelectedIndexSearchTV(index)}
              >
                <SelectItem appearance="ioc" title="Loại tác vụ" />
                <SelectItem appearance="ioc" title="Xử lý văn bản" />
                <SelectItem appearance="ioc" title="Chỉ đạo cuộc họp" />
                <SelectItem appearance="ioc" title="Phân công" />
              </Select>
            )}
            {!isShowSearch && (
              <Select
                appearance="ioc"
                style={{ backgroundColor: 'white', paddingVertical: 5, paddingBottom: 10 }}
                selectedIndex={selectedIndexSearchDV}
                value={displayValueDV}
                onSelect={(index) => setSelectedIndexSearchDV(index)}
              >
                <SelectItem appearance="ioc" title="Đơn vị phụ trách" />
                <SelectItem appearance="ioc" title="B245" />
                <SelectItem appearance="ioc" title="BCSĐ" />
                <SelectItem appearance="ioc" title="BGĐ" />
                <SelectItem appearance="ioc" title="BTC" />
                <SelectItem appearance="ioc" title="CTSV" />
                <SelectItem appearance="ioc" title="CĐCQ" />
                <SelectItem appearance="ioc" title="CĐKVP" />
                <SelectItem appearance="ioc" title="IPMU" />
                <SelectItem appearance="ioc" title="KHCN" />
                <SelectItem appearance="ioc" title="KHĐT" />
                <SelectItem appearance="ioc" title="QLDA" />
                <SelectItem appearance="ioc" title="QSCQ" />
                <SelectItem appearance="ioc" title="TCCB" />
                <SelectItem appearance="ioc" title="TCĐU" />
                <SelectItem appearance="ioc" title="TGĐU" />
                <SelectItem appearance="ioc" title="TTPC" />
                <SelectItem appearance="ioc" title="TĐKT" />
                <SelectItem appearance="ioc" title="UBKT" />
                <SelectItem appearance="ioc" title="VPCĐ" />
                <SelectItem appearance="ioc" title="VPGĐ" />
                <SelectItem appearance="ioc" title="VPĐU" />
                <SelectItem appearance="ioc" title="ĐNDA" />
                <SelectItem appearance="ioc" title="ĐTĐH" />
              </Select>
            )}

            {!isShowSearch && (
              <Button
                style={{ borderColor: '#dee2e6', borderRadius: 48, maxHeight: 40 }}
                status="basic"
                appearance="ghost"
                accessoryLeft={SearchIcon}
                onPress={() => onClickSearch()}
              >
                Search
              </Button>
            )}
            {isShowSearch && (
              <Button
                style={{ borderColor: '#dee2e6', borderRadius: 48, minHeight: 30, maxHeight: 30, marginTop: -10, marginBottom: 3 }}
                status="basic"
                appearance="ghost"
                onPress={() => setIsShowSearch(false)}
              >
                SEARCH LẠI
              </Button>
            )}
          </View>
        )}
        {isShowSearch &&
          pactProcessed2.isCreate &&
          (pactProcessed2.currentData?.length > 0 ? (
            <View style={styles.layoutColumn}>
              <View style={styles.layoutRowHeader}>
                <View style={styles.layoutRowLeft50}>
                  <Button
                    style={styles.buttonMall}
                    size="small"
                    appearance="left"
                    onPress={() => {
                      setSortType(0);
                      onClickSortName();
                    }}
                    accessoryRight={sortType === 0 ? (isSortName ? SortUpIcon : SortDownIcon) : null}
                  >
                    Tác vụ
                  </Button>
                </View>
                <View style={styles.layoutCenter20}>
                  <Text style={{ fontSize: 14 }}>Tiến độ</Text>
                </View>
                <View style={styles.layoutCenter30}>
                  <Button
                    style={styles.buttonMallDay}
                    size="small"
                    appearance="left"
                    onPress={() => {
                      setSortType(1);
                      onClickSortDate();
                    }}
                    accessoryRight={sortType === 1 ? (isSortDate ? SortUpIcon : SortDownIcon) : null}
                  >
                    Deadline
                  </Button>
                </View>
              </View>

              {pactProcessed2.isLoadOld && (
                <View style={styles.loadingView}>
                  <ActivityIndicator style={styles.loadingIcon} size="small" color="#424242" />
                  <Text style={{ color: '#424242', paddingVertical: 5, paddingLeft: 5, fontSize: 16 }}>Loading...</Text>
                </View>
              )}

              <List
                nativeID="chatView"
                onScroll={(evt) => {
                  setTimeout(() => {
                    if (
                      (selectedIndex.row === 0 || selectedIndex.row === 4 || selectedIndex.row === 5 || selectedIndex.row === 7) &&
                      evt.nativeEvent.contentOffset.y >=
                        window.document.getElementById('chatView')?.firstElementChild?.clientHeight -
                          window.document.getElementById('chatView')?.clientHeight +
                          8 -
                          40 &&
                      pactProcessed2.index != -1 &&
                      !pactProcessed2.isLoadOld &&
                      pactProcessed2.isCreate
                    ) {
                      pactProcessed2.isLoadOld = true;
                      setPactProcessed2({ ...pactProcessed2 });
                      setTimeout(() => {
                        if (selectedIndex.row === 0) {
                          setSelectedProcess('/?pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 4) {
                          setSelectedProcess('/?status=closed&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 5) {
                          setSelectedProcess('/?status=cancelled&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 7 && isShowSearch) {
                          const id = searchName != '' ? '&keyword=' + searchName : '';
                          const unit = searchUnit != '' ? '&unitId=' + searchUnit : '';
                          const tv = searchTV != '' ? '&originType=' + searchTV : '';
                          setSelectedProcess('/?pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40' + id + unit + tv);
                        }
                      }, 20);
                    }
                  }, 50);
                }}
                scrollEnabled={!pactProcessed2.isLoadOld}
                style={{
                  maxHeight: '100%',
                  width: '100%',
                }}
                data={pactProcessed2.currentData}
                renderItem={renderItem}
              />
            </View>
          ) : (
            <Card style={{ borderWidth: 0 }}>
              <View style={styles.nullView}>
                <NullIcon></NullIcon>
              </View>
              <View style={{ padding: 6 }}></View>
              <Text style={{ textAlign: 'center' }}>Chưa có tác vụ liên quan</Text>
            </Card>
          ))}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    margin: 2,
  },
  icon: {
    minHeight: 14,
    minWidth: 18,
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMallDay: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 75,
  },
  layoutRow: {
    display: 'flex',
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '18%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '25%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 40,
    backgroundColor: '#5b5fc7',
    borderRadius: 40,
  },

  headerView: {
    flexShrink: 1,
    width: '100%',
    paddingRight: 8,
    minHeight: 45,
    justifyContent: 'center',
  },

  mainLayout: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  filterButton: {
    marginHorizontal: 4,
    marginLeft: 4,
    marginRight: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchView: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  loadingView: {
    zIndex: 1000,
    backgroundColor: '#FFFFFF',
    borderWidth: 0,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    justifyContent: 'center',
  },
  loadingIcon: { transform: [{ scaleX: 0.5 }, { scaleY: 0.5 }], paddingTop: 8 },
  maxlayout: {
    width: '100%',
    height: '100%',
    flexShrink: 1,
  },
  nullView: { width: '100%', justifyContent: 'center', alignItems: 'center' },
});
