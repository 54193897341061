import axios from 'axios';
import qs from 'qs';
import { getBaseApiUrl } from '../config';
import { Platform } from 'react-native';
import { sendAuthenicatedRequest } from './base';

const baseConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const getAccessToken = () => {
  if (Platform.OS === 'web') {
    const token = localStorage.getItem('accessToken');

    if (token) {
      const data = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());

      if (Date.now() < new Date(data.exp * 1000).getTime()) {
        return token;
      } else {
        localStorage.clear();
        window.location.reload();
      }
    }
  }
};

export const loginWithUsername = ({ username, password }) => {
  const data = qs.stringify({
    username: username,
    password: password,
  });

  return axios.post(`${getBaseApiUrl()}/ranni/auth`, data, baseConfig);
};

export const loginWithGoogleToken = ({ ggToken }) => {
  const data = qs.stringify({ ggToken });

  return axios.post(`${getBaseApiUrl()}/ranni/gauth`, data, baseConfig);
};

export const forgotPassword = ({ username }) => {
  const data = qs.stringify({
    username: username,
  });

  return axios.post(`${getBaseApiUrl()}/ranni/recovery`, data, baseConfig);
};

export const getAllUsers = async () => {
  return await (
    await sendAuthenicatedRequest('/users', 'GET', null)
  ).data.result.users;
};
