export default {
  strict: {
    'text-font-family':
      'SanFranciscoText,system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  components: {
    Spinner: {
      appearances: {
        ioc: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'color-basic-default',
              },
              primary: {
                borderColor: '#5b5fc7',
              },
              success: {
                borderColor: 'color-success-default',
              },
              info: {
                borderColor: 'color-info-default',
              },
              warning: {
                borderColor: 'color-warning-default',
              },
              danger: {
                borderColor: 'color-danger-default',
              },
              control: {
                borderColor: 'color-control-default',
              },
            },
            size: {
              tiny: {
                width: 16,
                height: 16,
                borderRadius: 8,
                borderWidth: 2.3,
              },
              small: {
                width: 20,
                height: 20,
                borderRadius: 10,
                borderWidth: 2.8,
              },
              medium: {
                width: 24,
                height: 24,
                borderRadius: 12,
                borderWidth: 3.4,
              },
              large: {
                width: 28,
                height: 28,
                borderRadius: 14,
                borderWidth: 3.9,
              },
              giant: {
                width: 32,
                height: 32,
                borderRadius: 16,
                borderWidth: 4.5,
              },
            },
          },
        },
      },
    },
    BottomNavigation: {
      appearances: {
        default: {
          mapping: {
            minHeight: 65,
            indicatorBackgroundColor: '#5b5fc7',
          },
        },
        noIndicator: {
          mapping: {},
        },
      },
    },
    BottomNavigationTab: {
      appearances: {
        default: {
          mapping: {
            textMarginVertical: 4,
            textColor: '#656469',
            iconTintColor: '#656469',
            state: {
              selected: {
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
              },
              hover: {
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
              },
            },
          },
        },
      },
    },
    ListItem: {
      appearances: {
        default: {
          mapping: {
            paddingHorizontal: 4,
            paddingVertical: 4,
          },
        },
        ioc: {
          mapping: {
            titleMarginHorizontal: 0,
            paddingHorizontal: 4,
            paddingVertical: 4,
            titleFontWeight: '400',
            titleFontSize: 14,
            iconMarginHorizontal: -8.5,
          },
        },
        ioc_autocomplete: {
          mapping: {
            paddingHorizontal: 4,
            paddingVertical: 4,
            titleFontWeight: '400',
            titleFontSize: 14,
            iconMarginHorizontal: -6,
          },
        },
      },
    },
    Input: {
      meta: {},
      appearances: {
        default: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                textColor: '#424242',

                backgroundColor: 'white',
              },
            },
          },
        },
      },
    },
    Avatar: {
      meta: {},
      appearances: {
        ioc: {
          mapping: {},
          variantGroups: {
            shape: {
              round: {
                roundCoefficient: 0.5,
              },
              rounded: {
                roundCoefficient: 0.3,
              },
              square: {
                roundCoefficient: 0.0,
              },
            },
            size: {
              tiny: {
                width: 'size-tiny',
                height: 'size-tiny',
              },
              small: {
                width: 38,
                height: 38,
              },
              medium: {
                width: 'size-medium',
                height: 'size-medium',
              },
              large: {
                width: 'size-large',
                height: 'size-large',
              },
              giant: {
                width: 60,
                height: 60,
              },
            },
          },
        },
      },
    },
    Datepicker: {
      appearances: {
        ioc: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                textColor: '#424242',
                backgroundColor: 'white',
                borderColor: 'white',
                state: {
                  active: {
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
          },
        },
        'ioc-s': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                textColor: '#636e72',
                backgroundColor: 'white',
                borderColor: 'white',
                state: {
                  active: {
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
            "size": {
              "small": {
                "minHeight": "size-small",
                "borderRadius": "border-radius",
                "borderWidth": "border-width",
                "paddingVertical": 3,
                "textFontSize": "text-subtitle-2-font-size",
                "textFontWeight": "normal"
              },
              "medium": {
                "minHeight": 31,
                "borderRadius": "border-radius",
                "borderWidth": "border-width",
                "paddingVertical": 4.5,
                "textFontSize": "text-subtitle-1-font-size",
                "textFontWeight": "normal"
              },
              "large": {
                "minHeight": "size-large",
                "borderRadius": "border-radius",
                "borderWidth": "border-width",
                "paddingVertical": 11,
                "textFontSize": "text-subtitle-1-font-size",
                "textFontWeight": "normal"
              }
            }
          },
        },
        'ioc-create': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'white',
                borderColor: 'white',
                state: {
                  active: {
                    textColor: '#424242',
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
          },
        },
        'ioc-create1': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                textColor: '#424242',
                backgroundColor: 'white',
                borderColor: 'white',
                state: {
                  active: {
                    textColor: '#424242',
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
          },
        },
        'ioc-Agenda': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'white',
                borderColor: '#e4e9f2',

                placeholderColor: '#8f9bb3',
                state: {
                  active: {
                    textColor: '#424242',
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
          },
        },
        'ioc-Agenda1': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                textColor: '#424242',
                backgroundColor: 'white',
                borderColor: '#e4e9f2',

                placeholderColor: '#8f9bb3',
                state: {
                  active: {
                    textColor: '#424242',
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
          },
        },
      },
    },
    Tooltip: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            indicatorBackgroundColor: '#0d91fd',
            "paddingVertical": 6,
          },
        },
      },
    },
    Select: {
      appearances: {
        ioc: {
          mapping: {
            "iconMarginHorizontal": -3,
            labelFontWeight: '400',
          },
          variantGroups: {
            status: {
              basic: {
                "iconTintColor": "white",
                textColor: '#636e72',
                textFontWeight: 'normal',
                textFontSize: 152,
                backgroundColor: 'white',
                borderColor: '#e4e9f2',
                state: {
                  hover: {
                    backgroundColor: 'white',
                  },
                  active: {
                    backgroundColor: 'white',
                  },
                  disabled: {
                    backgroundColor: 'white',
                  },
                },
              },
            },
            size: {
              medium: {
                textFontWeight: '400',
              },
            },
          },
        },
        ioc1: {
          mapping: {
            borderRadius: 0,
            labelFontWeight: '400',
            popoverBorderRadius: 0,
          },
          variantGroups: {
            status: {
              basic: {
                "iconTintColor": "white",
                textColor: '#636e72',
                textFontWeight: 'normal',
                textFontSize: 152,
                backgroundColor: 'white',
                borderColor: '#e4e9f2',
                state: {
                  hover: {
                    backgroundColor: 'white',
                  },
                  active: {
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                  disabled: {
                    backgroundColor: 'white',
                    borderColor: 'white',
                  },
                },
              },
            },
            size: {
              medium: {
                borderRadius: 0,
                borderWidth: 0,
                textFontWeight: '400',
              },
            },
          },
        },
      },
    },
    SelectOption: {
      appearances: {
        ioc: {
          mapping: {
            textColor: '#8F9BB3',
            textFontWeight: 'normal',
            state: {
              hover: {
                iconTintColor: 'text-primary-hover-color',
                backgroundColor: 'color-basic-transparent-hover',
                textFontWeight: 'normal',
              },
              active: {
                textColor: '#8F9BB3',
                backgroundColor: 'color-basic-transparent-active',
                textFontWeight: 'normal',
              },
              selected: {
                backgroundColor: '#0d6efd',
                textColor: '#FFFFFF',
                textFontWeight: 'normal',
                iconTintColor: 'text-primary-color',
              },
              'selected.hover': {
                'background-color': 'color-primary-transparent-100',
                textFontWeight: 'normal',
                textColor: '#8F9BB3',
              },
            },
          },
        },
      },
    },
    MenuItem: {
      appearances: {
        default: {
          mapping: {
            titleColor: '#424242',
            state: {
              hover: {
                titleColor: '#424242',
                iconTintColor: '#424242',
              },
              active: {
                backgroundColor: 'color-basic-transparent-active',
              },
              selected: {
                backgroundColor: 'color-primary-transparent-default',
                indicatorWidth: 4,
                indicatorBackgroundColor: 'color-primary-default',
                titleColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
              },
              disabled: {
                titleColor: '#424242',
                iconTintColor: 'text-disabled-color',
              },
            },
          },
        },
        'file-menu': {
          mapping: {
            titleMarginHorizontal: 6,
            titleColor: '#424242',
            titleFontWeight: 400,
            paddingTop: 20,
            state: {
              hover: {
                titleColor: '#424242',
                iconTintColor: '#424242',
              },
              active: {
                backgroundColor: 'color-basic-transparent-active',
              },
              selected: {
                backgroundColor: 'color-primary-transparent-default',
                indicatorWidth: 4,
                indicatorBackgroundColor: 'color-primary-default',
                titleColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
              },
              disabled: {
                titleColor: '#424242',
                iconTintColor: 'text-disabled-color',
              },
            },
          },
        },
      },
    },
    Text: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            color: '#424242',
          },
          variantGroups: {},
        },
        alternative: {
          mapping: {
            color: 'text-alternate-color',
          },
        },
        hint: {
          mapping: {
            color: 'text-hint-color',
          },
        },
      },
    },
    Button: {
      meta: {},
      appearances: {
        default: {
          mapping: {},
          variantGroups: {
            status: {

            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
            },
          },
        },
        ghost: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
            },
          },
        },
        ghost_add: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              small: {
                minHeight: 18,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 0,
                paddingVertical: 0,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 8,
              },
              tiny: {
                minHeight: 12,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 8,
                paddingVertical: 0,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 8,
              },
            },
          },
        },
        ghost_remove: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 18,
                minHeight: 18,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 0,
                paddingVertical: 0,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 0,
              },
            },
          },
        },
        ghost_ioc: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 3,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
            },
          },
        },
        filled: {
          mapping: {
            textFontFamily: 'text-font-family',
            iconMarginHorizontal: 4,
          },
          variantGroups: {
            status: {
              basic: {
                borderColor: 'color-basic-default-border',
                backgroundColor: 'color-basic-default',
                textColor: 'color-basic-800',
                iconTintColor: 'color-basic-800',
                state: {
                  focused: {
                    borderColor: 'color-basic-focus-border',
                    backgroundColor: 'color-basic-focus',
                  },
                  hover: {
                    borderColor: 'color-basic-hover-border',
                    backgroundColor: 'color-basic-hover',
                  },
                  active: {
                    borderColor: 'color-basic-active-border',
                    backgroundColor: 'color-basic-active',
                  },
                  disabled: {
                    borderColor: 'color-basic-disabled-border',
                    backgroundColor: 'color-basic-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'color-primary-default-border',
                backgroundColor: 'color-primary-default',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-primary-focus-border',
                    backgroundColor: 'color-primary-focus',
                  },
                  hover: {
                    borderColor: 'color-primary-hover-border',
                    backgroundColor: 'color-primary-hover',
                  },
                  active: {
                    borderColor: 'color-primary-active-border',
                    backgroundColor: 'color-primary-active',
                  },
                  disabled: {
                    borderColor: 'color-primary-disabled-border',
                    backgroundColor: 'color-primary-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              success: {
                borderColor: 'color-success-default-border',
                backgroundColor: 'color-success-default',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-success-focus-border',
                    backgroundColor: 'color-success-focus',
                  },
                  hover: {
                    borderColor: 'color-success-hover-border',
                    backgroundColor: 'color-success-hover',
                  },
                  active: {
                    borderColor: 'color-success-active-border',
                    backgroundColor: 'color-success-active',
                  },
                  disabled: {
                    borderColor: 'color-success-disabled-border',
                    backgroundColor: 'color-success-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              info: {
                borderColor: 'color-info-default-border',
                backgroundColor: 'color-info-default',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-info-focus-border',
                    backgroundColor: 'color-info-focus',
                  },
                  hover: {
                    borderColor: 'color-info-hover-border',
                    backgroundColor: 'color-info-hover',
                  },
                  active: {
                    borderColor: 'color-info-active-border',
                    backgroundColor: 'color-info-active',
                  },
                  disabled: {
                    borderColor: 'color-info-disabled-border',
                    backgroundColor: 'color-info-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              warning: {
                borderColor: 'color-warning-default-border',
                backgroundColor: 'color-warning-default',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-warning-focus-border',
                    backgroundColor: 'color-warning-focus',
                  },
                  hover: {
                    borderColor: 'color-warning-hover-border',
                    backgroundColor: 'color-warning-hover',
                  },
                  active: {
                    borderColor: 'color-warning-active-border',
                    backgroundColor: 'color-warning-active',
                  },
                  disabled: {
                    borderColor: 'color-warning-disabled-border',
                    backgroundColor: 'color-warning-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              danger: {
                borderColor: 'color-danger-default-border',
                backgroundColor: 'color-danger-default',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-danger-focus-border',
                    backgroundColor: 'color-danger-focus',
                  },
                  hover: {
                    borderColor: 'color-danger-hover-border',
                    backgroundColor: 'color-danger-hover',
                  },
                  active: {
                    borderColor: 'color-danger-active-border',
                    backgroundColor: 'color-danger-active',
                  },
                  disabled: {
                    borderColor: 'color-danger-disabled-border',
                    backgroundColor: 'color-danger-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              control: {
                borderColor: 'color-control-default-border',
                backgroundColor: 'color-control-default',
                textColor: 'color-basic-800',
                iconTintColor: 'color-basic-800',
                state: {
                  focused: {
                    borderColor: 'color-control-focus-border',
                    backgroundColor: 'color-control-focus',
                  },
                  hover: {
                    borderColor: 'color-control-hover-border',
                    backgroundColor: 'color-control-hover',
                  },
                  active: {
                    borderColor: 'color-control-active-border',
                    backgroundColor: 'color-control-active',
                  },
                  disabled: {
                    borderColor: 'color-control-disabled-border',
                    backgroundColor: 'color-control-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 8,
                paddingVertical: 8,
                textMarginHorizontal: 8,
                textFontSize: 12,
                textFontWeight: 'normal',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        outline: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'color-basic-transparent-default-border',
                backgroundColor: 'color-basic-transparent-default',
                textColor: 'text-hint-color',
                iconTintColor: 'text-hint-color',
                state: {
                  focused: {
                    borderColor: 'color-basic-transparent-focus-border',
                    backgroundColor: 'color-basic-transparent-focus',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  hover: {
                    borderColor: 'color-basic-transparent-hover-border',
                    backgroundColor: 'color-basic-transparent-hover',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  active: {
                    borderColor: 'color-basic-transparent-active-border',
                    backgroundColor: 'color-basic-transparent-active',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  disabled: {
                    borderColor: 'color-basic-transparent-disabled-border',
                    backgroundColor: 'color-basic-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'color-primary-transparent-default-border',
                backgroundColor: 'color-primary-transparent-default',
                textColor: 'text-primary-color',
                iconTintColor: 'text-primary-color',
                state: {
                  focused: {
                    borderColor: 'color-primary-transparent-focus-border',
                    backgroundColor: 'color-primary-transparent-focus',
                    textColor: 'text-primary-color',
                    iconTintColor: 'text-primary-color',
                  },
                  hover: {
                    borderColor: 'color-primary-transparent-hover-border',
                    backgroundColor: 'color-primary-transparent-hover',
                    textColor: 'text-primary-color',
                    iconTintColor: 'text-primary-color',
                  },
                  active: {
                    borderColor: 'color-primary-transparent-active-border',
                    backgroundColor: 'color-primary-transparent-active',
                    textColor: 'text-primary-color',
                    iconTintColor: 'text-primary-color',
                  },
                  disabled: {
                    borderColor: 'color-primary-transparent-disabled-border',
                    backgroundColor: 'color-primary-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              success: {
                borderColor: 'color-success-transparent-default-border',
                backgroundColor: 'color-success-transparent-default',
                textColor: 'text-success-color',
                iconTintColor: 'text-success-color',
                state: {
                  focused: {
                    borderColor: 'color-success-transparent-focus-border',
                    backgroundColor: 'color-success-transparent-focus',
                    textColor: 'text-success-color',
                    iconTintColor: 'text-success-color',
                  },
                  hover: {
                    borderColor: 'color-success-transparent-hover-border',
                    backgroundColor: 'color-success-transparent-hover',
                    textColor: 'text-success-color',
                    iconTintColor: 'text-success-color',
                  },
                  active: {
                    borderColor: 'color-success-transparent-active-border',
                    backgroundColor: 'color-success-transparent-active',
                    textColor: 'text-success-color',
                    iconTintColor: 'text-success-color',
                  },
                  disabled: {
                    borderColor: 'color-success-transparent-disabled-border',
                    backgroundColor: 'color-success-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              info: {
                borderColor: 'color-info-transparent-default-border',
                backgroundColor: 'color-info-transparent-default',
                textColor: 'text-info-color',
                iconTintColor: 'text-info-color',
                state: {
                  focused: {
                    borderColor: 'color-info-transparent-focus-border',
                    backgroundColor: 'color-info-transparent-focus',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  hover: {
                    borderColor: 'color-info-transparent-hover-border',
                    backgroundColor: 'color-info-transparent-hover',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  active: {
                    borderColor: 'color-info-transparent-active-border',
                    backgroundColor: 'color-info-transparent-active',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  disabled: {
                    borderColor: 'color-info-transparent-disabled-border',
                    backgroundColor: 'color-info-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              warning: {
                borderColor: 'color-warning-transparent-default-border',
                backgroundColor: 'color-warning-transparent-default',
                textColor: 'text-warning-color',
                iconTintColor: 'text-warning-color',
                state: {
                  focused: {
                    borderColor: 'color-warning-transparent-focus-border',
                    backgroundColor: 'color-warning-transparent-focus',
                    textColor: 'text-warning-color',
                    iconTintColor: 'text-warning-color',
                  },
                  hover: {
                    borderColor: 'color-warning-transparent-hover-border',
                    backgroundColor: 'color-warning-transparent-hover',
                    textColor: 'text-warning-color',
                    iconTintColor: 'text-warning-color',
                  },
                  active: {
                    borderColor: 'color-warning-transparent-active-border',
                    backgroundColor: 'color-warning-transparent-active',
                    textColor: 'text-warning-color',
                    iconTintColor: 'text-warning-color',
                  },
                  disabled: {
                    borderColor: 'color-warning-transparent-disabled-border',
                    backgroundColor: 'color-warning-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              danger: {
                borderColor: 'color-danger-transparent-default-border',
                backgroundColor: 'color-danger-transparent-default',
                textColor: 'text-danger-color',
                iconTintColor: 'text-danger-color',
                state: {
                  focused: {
                    borderColor: 'color-danger-transparent-focus-border',
                    backgroundColor: 'color-danger-transparent-focus',
                    textColor: 'text-danger-color',
                    iconTintColor: 'text-danger-color',
                  },
                  hover: {
                    borderColor: 'color-danger-transparent-hover-border',
                    backgroundColor: 'color-danger-transparent-hover',
                    textColor: 'text-danger-color',
                    iconTintColor: 'text-danger-color',
                  },
                  active: {
                    borderColor: 'color-danger-transparent-active-border',
                    backgroundColor: 'color-danger-transparent-active',
                    textColor: 'text-danger-color',
                    iconTintColor: 'text-danger-color',
                  },
                  disabled: {
                    borderColor: 'color-danger-transparent-disabled-border',
                    backgroundColor: 'color-danger-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              control: {
                borderColor: 'color-control-transparent-default-border',
                backgroundColor: 'color-control-transparent-default',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-control-transparent-focus-border',
                    backgroundColor: 'color-control-transparent-focus',
                    textColor: 'text-control-color',
                    iconTintColor: 'text-control-color',
                  },
                  hover: {
                    borderColor: 'color-control-transparent-hover-border',
                    backgroundColor: 'color-control-transparent-hover',
                    textColor: 'text-control-color',
                    iconTintColor: 'text-control-color',
                  },
                  active: {
                    borderColor: 'color-control-transparent-active-border',
                    backgroundColor: 'color-control-transparent-active',
                    textColor: 'text-control-color',
                    iconTintColor: 'text-control-color',
                  },
                  disabled: {
                    borderColor: 'color-control-transparent-disabled-border',
                    backgroundColor: 'color-control-transparent-disabled',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
            },
          },
        },
        'ghost-filter': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: '400',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 0,
                textMarginHorizontal: 0,
                textFontSize: 15,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        'ghost-filter-calendar': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 13,
                textFontWeight: '600',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 0,
                textMarginHorizontal: 0,
                textFontSize: 15,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        ghost1: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',

                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#5b5fc7',
                iconTintColor: '#5b5fc7',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#5b5fc7',
                    iconTintColor: '#5b5fc7',
                  },
                },
              },
            },
          },
        },
        'ghost-top-tab': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textFontWeight: 'normal',
                textColor: '#424242',
                iconTintColor: '#424242',
                width: '100%',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textFontWeight: 'normal',
                textColor: '#424242',
                iconTintColor: '#424242',
                width: '100%',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 0,
                textMarginHorizontal: 0,
                textFontSize: 15,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: '34px',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 4,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        'file-pact-left': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  disabled: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 40,
                minHeight: 40,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 7,
                paddingVertical: 0,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 27,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 9,
                paddingVertical: 1,
                textMarginHorizontal: 1,
                textFontSize: 13,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 7,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'bold',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        'file-pact': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  disabled: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 27,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 9,
                paddingVertical: 1,
                textMarginHorizontal: 1,
                textFontSize: 13,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 7,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'bold',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        'file-pact-Chat': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  disabled: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 25,
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 5,
                paddingVertical: 1,
                textMarginHorizontal: 1,
                textFontSize: 13,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'bold',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        'root-pact': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
              success: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-success-color',
                iconTintColor: 'text-success-color',
                state: {
                  focused: {
                    borderColor: 'color-basic-transparent-500',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-success-color',
                    iconTintColor: 'text-success-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-100',
                    textColor: 'text-success-color',
                    iconTintColor: 'text-success-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-success-color',
                    iconTintColor: 'text-success-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              info: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-info-color',
                iconTintColor: 'text-info-color',
                state: {
                  focused: {
                    borderColor: 'color-basic-transparent-500',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-100',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              warning: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-warning-color',
                iconTintColor: 'text-warning-color',
                state: {
                  focused: {
                    borderColor: 'color-basic-transparent-500',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-info-color',
                    iconTintColor: 'text-info-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-100',
                    textColor: 'text-warning-color',
                    iconTintColor: 'text-warning-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-warning-color',
                    iconTintColor: 'text-warning-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              danger: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-danger-color',
                iconTintColor: 'text-danger-color',
                state: {
                  focused: {
                    borderColor: 'color-basic-transparent-500',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-danger-color',
                    iconTintColor: 'text-danger-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-100',
                    textColor: 'text-danger-color',
                    iconTintColor: 'text-danger-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-danger-color',
                    iconTintColor: 'text-danger-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              control: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-control-color',
                iconTintColor: 'text-control-color',
                state: {
                  focused: {
                    borderColor: 'color-basic-transparent-500',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-control-color',
                    iconTintColor: 'text-control-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-100',
                    textColor: 'text-control-color',
                    iconTintColor: 'text-control-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-control-color',
                    iconTintColor: 'text-control-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 0,
                textMarginHorizontal: 4,
                textFontSize: 15,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        'root-pact1': {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-hint-color',
                    iconTintColor: 'text-hint-color',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: 'text-disabled-color',
                    iconTintColor: 'text-disabled-color',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 0,
                textMarginHorizontal: 4,
                textFontSize: 15,
                textFontWeight: 'bold',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 14,
                textFontWeight: 'normal',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        avatar: {
          mapping: {},
          variantGroups: {
            status: {
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#636e72',
                iconTintColor: '#636e72',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#636e72',
                    iconTintColor: '#636e72',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#636e72',
                    iconTintColor: '#636e72',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#636e72',
                    iconTintColor: '#636e72',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#636e72',
                    iconTintColor: '#636e72',
                  },
                },
              },
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              tiny: {
                minWidth: 'size-tiny',
                minHeight: 'size-tiny',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 6,
                paddingVertical: 6,
                textMarginHorizontal: 6,
                textFontSize: 10,
                textFontWeight: 'normal',
                iconWidth: 12,
                iconHeight: 12,
                iconMarginHorizontal: 6,
              },
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 0,
                textMarginHorizontal: 4,
                textFontSize: 15,
                textFontWeight: 'normal',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
              medium: {
                minWidth: 'size-medium',
                minHeight: 'size-medium',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 12,
                textMarginHorizontal: 10,
                textFontSize: 15,
                textFontWeight: '400',
                iconWidth: 20,
                iconHeight: 20,
                iconMarginHorizontal: 10,
              },
              large: {
                minWidth: 'size-large',
                minHeight: 'size-large',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 10,
                paddingVertical: 14,
                textMarginHorizontal: 10,
                textFontSize: 16,
                textFontWeight: 'normal',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 10,
              },
              giant: {
                minWidth: 'size-giant',
                minHeight: 'size-giant',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 12,
                paddingVertical: 16,
                textMarginHorizontal: 12,
                textFontSize: 18,
                textFontWeight: 'bold',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 12,
              },
            },
          },
        },
        left: {
          mapping: {},
          variantGroups: {
            status: {
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: '#424242',
                iconTintColor: '#424242',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'color-basic-transparent-200',
                    textColor: '#424242',
                    iconTintColor: '#424242',
                  },
                },
              },
            },
            size: {
              small: {
                minWidth: 'size-small',
                minHeight: 'size-small',
                borderRadius: 'border-radius',
                borderWidth: 'border-width',
                paddingHorizontal: 0,

                paddingVertical: 0,
                textMarginHorizontal: 0,
                textFontSize: 14,
                textFontWeight: '400',
                iconWidth: 16,
                iconHeight: 16,
                iconMarginHorizontal: 8,
              },
            },
          },
        },
      },
    },
  },
};
