const avatarColors = [
  '#0d6efd',
  '#6610f2',
  '#6f42c1',
  '#d63384',
  '#dc3545',
  '#fd7e14',
  '#ffc107',
  '#198754',
  '#20c997',
  '#0dcaf0',
  '#6c757d',
  '#343a40',
  '#6c757d',
  '#495057',
  '#343a40',
  '#212529',
  '#0d6efd',
  '#6c757d',
  '#198754',
  '#0dcaf0',
  '#ffc107',
  '#dc3545',
  '#212529',
];

const randomNumber = Math.floor(Math.random() * avatarColors.length);

export const getAvatarColor = (name: string) => {
  const sumAscii = [...name].reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const index = (Math.abs(sumAscii) + randomNumber) % avatarColors.length;
  return avatarColors[index];
}
