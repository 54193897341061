import { sendAuthenicatedRequest } from './base';
import qs from 'qs';

export enum HostIDs {
    organization = "_organization",
    all = "129d2929ecae8ee9eca0d6a477_0003%2C129d2929ecae8ee9eca0d6a477_0004",
    MrQuan = "129d2929ecae8ee9eca0d6a477_0003",
    MrTam = "129d2929ecae8ee9eca0d6a477_0004"
}

export const getSchedule = async (hostIds: string, rangeBegin: number, rangeEnd: number) => {
    return await (
        await sendAuthenicatedRequest('/plan?hostIds=' + hostIds + '&rangeBegin=' + rangeBegin + '&rangeEnd=' + rangeEnd, 'GET', null)
  ).data.result.schedules;
};

export const removeSchedule = async (id: string) => {
    return await (
        await sendAuthenicatedRequest('/plan/'+id, 'DELETE', null)
  ).data;
};


export const setSchedule = async (hostId: string, start: number, end: number, agenda: string, attendances:string, location: string) => {
    const data = qs.stringify({
        hostId: hostId,
        start: start,
        end: end,
        agenda: agenda,
        attendances: attendances,
        location: location
    })
    return await (
        await sendAuthenicatedRequest('/plan', 'POST',data, null)
  ).data;
};
export const updateSchedule = async (id:string, start: number, end: number, agenda: string, attendances:string, location: string) => {
    const data = qs.stringify({
        start: start,
        end: end,
        agenda: agenda,
        attendances: attendances,
        location: location
    })
    return await (
        await sendAuthenicatedRequest('/plan/'+id, 'PUT',data, null)
  ).data;
};


