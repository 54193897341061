import React, { useContext } from 'react';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { StyleSheet, View } from 'react-native';

import FAIcon from 'react-native-vector-icons/FontAwesome5';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { EBottomTab, navigationContext } from '../../hooks/navigation';
import { useLocation, useNavigate } from 'react-router-native';
import { signContext } from '../../hooks/signingprocess';
import { getCohorts, getUnits } from '../../services/signingprocess';
import { authContext } from '../../hooks/authentication';
import Svg, { Path } from 'react-native-svg';
import { getBaseApiUrl } from '../../config';

const PactIcon = (props) => (
  <Svg width={25} height={25} viewBox="0 0 384 512">
    <Path
      d="M0 208C0 104.4 75.7 18.5 174.9 2.6C184 1.2 192 8.6 192 17.9V81.2c0 8.4 6.5 15.3 14.7 16.5C307 112.5 384 199 384 303.4c0 103.6-75.7 189.5-174.9 205.4c-9.2 1.5-17.1-5.9-17.1-15.2V430.2c0-8.4-6.5-15.3-14.7-16.5C77 398.9 0 312.4 0 208zm288 48A96 96 0 1 0 96 256a96 96 0 1 0 192 0zm-96-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
      fill={props.style.tintColor}
    />
  </Svg>
);

const FilesIcon = (props) => (
  <Svg width={25} height={25} viewBox="0 0 512 512">
    <Path
      d="M0 352c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V352zm352 32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm64 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
      fill={props.style.tintColor}
    />
    <Path
      d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V320c0 5.5-.7 10.9-2 16c-7.1-27.6-32.2-48-62-48H64c-29.8 0-54.9 20.4-62 48c-1.3-5.1-2-10.5-2-16V96zM320 352a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
      fill={props.style.tintColor}
      opacity={0.4}
    />
  </Svg>
);

const CalenderIcon = (props) => (
  <Svg width={25} height={25} viewBox="0 0 448 512">
    <Path
      d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192zm48 88c0 13.3 10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-13.3 0-24 10.7-24 24zm24 72c-13.3 0-24 10.7-24 24s10.7 24 24 24H232c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z"
      fill={props.style.tintColor}
    />
  </Svg>
);

const MeetingIcon = (props) => (
  <Svg width={25} height={25} viewBox="0 0 640 512">
    <Path
      d="M135 7c-9.1 9.1-9.4 23.7-.7 33.2L360 286.9V181.8L168.2 6.3C158.7-2.4 144.1-2.1 135 7zm263 349.7l49 49 27.3-27.3 32-32L533.7 319 411.3 196.7c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8V322.7c0 12.7 5.1 24.9 14.1 33.9zM523.3 352L629 457.7l3.5-3.5c4.8-4.8 7.5-11.3 7.5-18.1s-2.7-13.3-7.5-18.1L545 330.3 523.3 352zM512 363.3L491.3 384 597 489.7 617.7 469 512 363.3zm-32 32L458.3 417l87.5 87.5c4.8 4.8 11.3 7.5 18.1 7.5s13.3-2.7 18.1-7.5l3.5-3.5L480 395.3zM247.6 211.4l-24.7 22.6c-1.6-3.9-4.7-7.2-8.7-8.8c-6-2.5-12.9-1.1-17.4 3.5l-25.4 25.4-4.9-4.9c-12.5-12.3-32.6-12.2-45 .2c-12.5 12.5-12.5 32.8 0 45.3l4.7 4.7L7.5 417.9C2.7 422.7 0 429.2 0 436s2.7 13.3 7.5 18.1l50.3 50.3c4.8 4.8 11.3 7.5 18.1 7.5s13.3-2.7 18.1-7.5L209.9 388.7c9-9 14.1-21.2 14.1-33.9v-6.6l74.3-81.2-50.7-55.5zm142.8-45.2l115.3-126c8.7-9.5 8.3-24.1-.7-33.2s-23.7-9.4-33.2-.7L343.7 123.5l46.7 42.7zM152 344a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"
      fill={props.style.tintColor}
    />
  </Svg>
);

const TaskIcon = (props) => (
  <Svg width={25} height={25} viewBox="0 0 512 512">
    <Path
      d="M472 64a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm-88 0a64 64 0 1 1 128 0A64 64 0 1 1 384 64zM0 160C0 93.7 53.7 40 120 40H256V17.9C256 8 264 0 273.9 0c4 0 7.8 1.3 11 3.8l60.8 47.3c4 3.1 6.3 7.9 6.3 12.9s-2.3 9.8-6.3 12.9l-60.8 47.3c-3.1 2.4-7 3.8-11 3.8c-9.9 0-17.9-8-17.9-17.9V88H120c-39.8 0-72 32.2-72 72s32.2 72 72 72H392c66.3 0 120 53.7 120 120c0 59.5-43.3 108.8-100 118.3c-9.1 24.3-32.5 41.7-60 41.7c-35.3 0-64-28.7-64-64s28.7-64 64-64c25.9 0 48.3 15.4 58.3 37.6c30.9-8.1 53.7-36.2 53.7-69.6c0-39.8-32.2-72-72-72H120C53.7 280 0 226.3 0 160zM123.3 424H160V401.9c0-9.9 8-17.9 17.9-17.9c4 0 7.8 1.3 11 3.8l60.8 47.3c4 3.1 6.3 7.9 6.3 12.9s-2.3 9.8-6.3 12.9l-60.8 47.3c-3.1 2.4-7 3.8-11 3.8c-9.9 0-17.9-8-17.9-17.9V472H123.3c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40zM88 448a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm264 24a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
      fill={props.style.tintColor}
    />
  </Svg>
);

const ChatIcon = (props) => (
  <Svg width={25} height={25} viewBox="0 0 512 512">
    <Path
      d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM152 176H360c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 96H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
      fill={props.style.tintColor}
    />
  </Svg>
);

export const BottomTab = () => {
  const navigation = useContext(navigationContext);
  const auth = useContext(authContext);
  const navigate = useNavigate();
  const location = useLocation();
  const sign = useContext(signContext);

  React.useEffect(()=>{
    localStorage.setItem('apiURL', getBaseApiUrl());
    window?.ReactNativeWebView?.postMessage('Complete WebView / Website');
  },[])

  React.useEffect(() => {
    if (location.pathname.search('/pacts') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/dpacts') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/lumens') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/dlumens') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/elumens') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/edlumens') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/fuses') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/dfuses') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/efuses') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/edfuses') >= 0) {
      navigation.setBottomTab(EBottomTab.PACT);
    } else if (location.pathname.search('/files') >= 0) {
      navigation.setBottomTab(EBottomTab.FILE);
    } else if (location.pathname.search('/shared') >= 0) {
      navigation.setBottomTab(EBottomTab.FILE);
    } else if (location.pathname.search('/trash') >= 0) {
      navigation.setBottomTab(EBottomTab.FILE);
    } else if (location.pathname.search('/agendas') >= 0) {
      navigation.setBottomTab(EBottomTab.MEETING);
    } else if (location.pathname.search('/dagendas') >= 0) {
      navigation.setBottomTab(EBottomTab.MEETING);
    } else if (location.pathname.search('/schedule') >= 0) {
      navigation.setBottomTab(EBottomTab.SCHEDULE);
    } else if (location.pathname.search('/scheduleO') >= 0) {
      navigation.setBottomTab(EBottomTab.SCHEDULE);
    } else if (location.pathname.search('/tasks') >= 0) {
      navigation.setBottomTab(EBottomTab.TASK);
    } else if (location.pathname.search('/chat') >= 0) {
      navigation.setBottomTab(EBottomTab.CHAT);
    } else if (location.pathname.search('/profile') >= 0) {
      navigation.setBottomTab(EBottomTab.PROFILE);
    }
    sign.setStatus({ color: '', tag: '' });
  }, [location.pathname]);

  const DS = ['DPM-0010', 'DPM-0011', 'DPM-0054', 'DPM-0012', 'DPM-0053'];

  React.useEffect(() => {
    if (auth.user?.accessToken && (auth.units === null || auth.units === undefined || auth.units?.length <= 0)) {
      setTimeout(() => {
        if ((auth.units === null || auth.units === undefined || auth.units?.length <= 0) && !auth.login)
          getUnits().then((units) => auth.setUnits({ data: units }));
      }, 100);
    }
  }, [auth.user, auth.units, auth.login]);

  React.useEffect(() => {
    if (auth.user?.accessToken && (auth.cohorts === null || auth.cohorts === undefined || auth.cohorts?.length <= 0)) {
      getCohorts().then((cohosts) =>
        setTimeout(() => {
          if (auth.cohorts === null || auth.cohorts === undefined || auth.cohorts?.length <= 0) auth.setCohorts({ data: cohosts });
        }, 100)
      );
    }
  }, [auth.user, auth.cohorts]);

  React.useEffect(() => {
    if (auth.cohorts === null || auth.cohorts?.length <= 0 || auth.cohorts === undefined) return;
    if (navigation.state._schedule_editors) return;
    if (
      auth.user?.conscriptions?.find((unit) => unit?.unitId === 'DPM-0013' || unit?.unitId === 'ADM-0001') != undefined ||
      auth.cohorts?.find(
        (cohort) => cohort?._id === '_schedule_editors' && cohort?.members?.find((id) => id === auth.user._id) != undefined
      ) != undefined
    )
      navigation.set_schedule_editors();
  }, [auth.cohorts, auth, auth.user, navigation.state._schedule_editors]);

  React.useEffect(() => {
    if (auth.units === null || auth.units === undefined || auth.units?.length <= 0 || auth.users?.length <= 0) return;
    if (navigation.state.role.flag && navigation.state.role.flag) return;
    let roleCQ = false;
    if (
      auth.units?.find(
        (unit) =>
          (unit?.turf === 'local' || DS?.find((id) => id === unit?._id) != undefined) && unit?.members?.find((id) => id === auth.user._id)
      ) != undefined
    )
      roleCQ = true;

    let roleDang = false;
    if (
      auth.units?.find(
        (unit) => (unit?.turf === 'd-local' || unit?._id === 'DPM-0014') && unit?.members?.find((id) => id === auth.user._id)
      ) != undefined
    )
      roleDang = true;
    let roleDV = false;
    if (auth.units?.find((unit) => unit?.turf === 'tether' && unit?.members?.find((id) => id === auth.user._id)) != undefined)
      roleDV = true;
    let roleCSD = false;
    if (auth.units?.find((unit) => unit?.turf === 'd-tether' && unit?.members?.find((id) => id === auth.user._id)) != undefined)
      roleCSD = true;
    navigation.setRole(true, roleCQ, roleDang, roleDV, roleCSD );
  }, [auth, auth.user, auth.units]);

  return (
    <View style={styles.layoutBottomBar}>
      <BottomNavigation
        // style={{ position: 'absolute', width: '100%', paddingBottom: 5 }}
        style={{ width: '100%', paddingBottom: 5 }}
        selectedIndex={navigation.state.bottomTab}
        onSelect={(index) => navigation.setBottomTab(index)}
      >
        <BottomNavigationTab
          style={{ width: 'auto' }}
          icon={PactIcon}
          title="Quy trình"
          onPressIn={() => {
            sign.setStatus({ color: '', tag: '' });
            sign.setnullRe();
            navigation.setIndexState(-1);
            if (navigation.state.role.isCQShow) navigate('/pacts');
            else if (navigation.state.role.isDVShow) navigate('/elumens');
            else if (navigation.state.role.isDangShow) navigate('/dpacts');
            else if (navigation.state.role.isCSDShow) navigate('/edlumens');

            sign.setNullIndex();
          }}
        />
        <BottomNavigationTab
          style={{ width: 'auto' }}
          icon={FilesIcon}
          title="Files"
          onPressIn={() => {
            sign.setStatus({ color: '', tag: '' });
            sign.setnullRe();
            navigation.setIndexState(-1);
            navigate('/files');
            sign.setNullIndex();
          }}
        />
        <BottomNavigationTab
          style={{ width: 'auto' }}
          icon={MeetingIcon}
          title="Meeting"
          onPressIn={() => {
            sign.setStatus({ color: '', tag: '' });
            sign.setnullRe();
            navigation.setIndexState(-1);
            navigate('/agendas');
            sign.setNullIndex();
          }}
        />
        <BottomNavigationTab
          style={{ width: 'auto' }}
          icon={TaskIcon}
          title="Task"
          onPressIn={() => {
            sign.setStatus({ color: '', tag: '' });
            sign.setnullRe();
            navigation.setIndexState(-1);
            navigate('/tasks');
            sign.setNullIndex();
          }}
        />
        <BottomNavigationTab
          style={{ width: 'auto' }}
          icon={CalenderIcon}
          title="Lịch biểu"
          onPressIn={() => {
            sign.setStatus({ color: '', tag: '' });
            sign.setnullRe();
            navigation.setIndexState(-1);
            navigate('/schedule');
            sign.setNullIndex();
          }}
        />
        <BottomNavigationTab
          style={{ width: 'auto' }}
          icon={ChatIcon}
          title="Chat"
          onPressIn={() => {
            sign.setStatus({ color: '', tag: '' });
            sign.setnullRe();
            navigation.setIndexState(-1);
            navigate('/chat');
            sign.setNullIndex();
          }}
        />
      </BottomNavigation>
    </View>
  );
};

const styles = StyleSheet.create({
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutBottomBar: {
    marginVertical: 1,
    borderTopWidth: 1,
    borderTopColor: '#c9cbd1',
    width: '100%',
  },
});
