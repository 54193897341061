import {
  Button,
  Card,
  IndexPath,
  Input,
  Layout,
  List,
  ListItem,
  MenuItem,
  OverflowMenu,
  Select,
  SelectItem,
  Text,
} from '@ui-kitten/components';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { navigationContext } from '../../hooks/navigation';
import { signContext } from '../../hooks/signingprocess';
import { getLumen, HostIDs, putSeen, specId } from '../../services/signingprocess';
import {
  AllIcon,
  AllRenderIcon,
  DoneIcon,
  DoneRenderIcon,
  EjectIcon,
  EjectRenderIcon,
  PenIcon,
  PenRenderIcon,
  SearchIcon,
  SearchRenderIcon,
  TasksIcon,
  TasksRenderIcon,
  WaitIcon,
  WaitRenderIcon,
} from '../../share/filter-icon';
import { isTypePheDuyet } from '../../utils/function';

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 576 512">
    <Path
      d="M320 32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H296.2c9.8 11.8 21 22.3 33.5 31.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V198.6c-11.2 3.2-21.9 7.4-32 12.6V64c0-17.7-14.3-32-32-32zM64 144c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm16 80H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H208c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zM432 480a112 112 0 1 0 0-224 112 112 0 1 0 0 224zm0-256a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm44.7 100.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7z"
      fill="#424242"
    />
  </Svg>
);

const SortDownIcon = (props) => (
  <Icon1
    {...props}
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      minWidth: 16,
      minHeight: 16,
    }}
    name="caret-down"
    color="#424242"
  />
);

const SortUpIcon = (props) => (
  <Icon1
    {...props}
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      minWidth: 16,
      minHeight: 16,
    }}
    name="caret-up"
    color="#424242"
  />
);

const FlagIcon = (props) => (
  <Icon1
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}
    name="circle"
    color={props.check === 1 ? '#12B76A' : props.check === 2 ? '#0d6efd' : props.check === 3 ? '#dc3545' : '#BDBDBD'}
  />
);

export default (props) => {
  const navigate = useNavigate();

  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));

  const [selectedProcess, setSelectedProcess] = React.useState<string>(specId.all);
  const [visible, setVisible] = React.useState(false);
  const rootPact = useQuery(['elumens', selectedProcess], () => getLumen(selectedProcess));
  const [pactProcessed2, setPactProcessed2] = React.useState({
    currentData: null,
    preData: null,
    newData: null,
    isNewData: false,
    isCreate: false,
    isReset: false,
    isLoadOld: false,
    index: 0,
  });
  const { units, cohorts } = useContext(authContext);
  const [isSortName, setIsSortName] = React.useState(false);
  const [isSortDate, setIsSortDate] = React.useState(false);
  const [sortType, setSortType] = React.useState(-1);

  useEffect(() => {
    if (auth.user._id === HostIDs.MrQuan || auth.user._id === HostIDs.MrTam) {
      setSelectedProcess(specId.process)
      setSelectedIndex(new IndexPath(2));
    }

    if (sign.index != null) {
      setSelectedIndex(new IndexPath(sign.index));
      sign.setNullIndex();
    }
  }, []);

  const onItemSelect = (index) => {
    if (index.row === selectedIndex.row) {
      setVisible(false);
      return;
    }
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    if (index?.row === 0) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(specId.all);
    } else if (index?.row === 1) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(specId.process);
    } else if (index?.row === 2) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(specId.process);
    } else if (index?.row === 3) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(specId.process);
    } else if (index?.row === 4) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(specId.done);
    } else if (index?.row === 5) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(true);
      setIsSearch(false);
      setSelectedProcess(specId.cancel);
    } else if (index?.row === 6) {
      setIsSortDate(false);
      setIsSortName(false);
      setIsShowSearch(false);
      setIsSearch(true);
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 0);
    setVisible(false);
  };

  const [isShowSearch, setIsShowSearch] = React.useState(true);
  const [isSearch, setIsSearch] = React.useState(false);
  const [searchName, setSearchName] = React.useState('');
  const [selectedIndexSearchDV, setSelectedIndexSearchDV] = React.useState(new IndexPath(0));
  const [selectedIndexSearchDVN, setSelectedIndexSearchDVN] = React.useState(new IndexPath(0));
  const [selectedIndexSearchId, setSelectedIndexSearchId] = React.useState(new IndexPath(0));
  const dataDV = [
    'Đơn vị gửi',
    'CTSV',
    'ĐTĐH',
    'ĐNDA',
    'KHCN',
    'KHĐT',
    'TCCB',
    'TĐKT',
    'TTPC',
    'VPGĐ',
    'BGĐ',
    'BCSĐ',
    'CĐCQ',
    'CĐKVP',
    'QSCQ',
    'BTC',
    'MOD',
    'VPĐU',
    'VPCĐ',
    'TCĐU',
    'TGĐU',
    'UBKT',
  ];
  const dataOPDV = [
    '',
    'DPM-0001',
    'DPM-0002',
    'DPM-0003',
    'DPM-0004',
    'DPM-0005',
    'DPM-0006',
    'DPM-0007',
    'DPM-0008',
    'DPM-0009',
    'DPM-0013',
    'DPM-0014',
    'DPM-0075',
    'DPM-0076',
    'DPM-0077',
    'DPM-0078',
    'MOD-0001',
    'DPM-0010',
    'DPM-0011',
    'DPM-0012',
    'DPM-0053',
    'DPM-0054',
  ];

  const dataDVN = [
    'Đơn vị nhận',
    'CTSV',
    'ĐTĐH',
    'ĐNDA',
    'KHCN',
    'KHĐT',
    'TCCB',
    'TĐKT',
    'TTPC',
    'VPGĐ',
    'BGĐ',
    'BCSĐ',
    'CĐCQ',
    'CĐKVP',
    'QSCQ',
    'BTC',
    'MOD',
    'VPĐU',
    'VPCĐ',
    'TCĐU',
    'TGĐU',
    'UBKT',
  ];
  const dataOPDVN = [
    '',
    'DPM-0001',
    'DPM-0002',
    'DPM-0003',
    'DPM-0004',
    'DPM-0005',
    'DPM-0006',
    'DPM-0007',
    'DPM-0008',
    'DPM-0009',
    'DPM-0013',
    'DPM-0014',
    'DPM-0075',
    'DPM-0076',
    'DPM-0077',
    'DPM-0078',
    'MOD-0001',
    'DPM-0010',
    'DPM-0011',
    'DPM-0012',
    'DPM-0053',
    'DPM-0054',
  ];

  const displayValueDV = dataDV[selectedIndexSearchDV.row];
  const displayValueDVN = dataDVN[selectedIndexSearchDVN.row];
  const dataId = ['Lãnh đạo ký', 'PGS.TS. Vũ Hải Quân', 'PGS.TS. Nguyễn Minh Tâm'];
  const dataOPId = ['', '129d2929ecae8ee9eca0d6a477_0003', '129d2929ecae8ee9eca0d6a477_0004'];
  const displayValueId = dataId[selectedIndexSearchId.row];
  const searchId = dataOPId[selectedIndexSearchId.row];
  const searchUnit = dataOPDV[selectedIndexSearchDV.row];
  const searchUnitN = dataOPDVN[selectedIndexSearchDVN.row];

  const onClickSearch = () => {
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    const id = searchName != '' ? '&keyword=' + searchName : '';
    const unit = searchUnit != '' ? '&unitId=' + searchUnit : '';
    const unitN = searchUnitN != '' ? '&destinationId=' + searchUnitN : '';
    const boss = searchId != '' ? '&bossId=' + searchId : '';
    setSelectedProcess('/?pgSkip=0&pgLimit=40' + id + unit + unitN + boss);
    setTimeout(() => {
      setSelectedIndex(new IndexPath(6));
    }, 20);
    setIsShowSearch(true);
  };

  const FiltersIcon = (props) => <Icon {...props} style={styles.iconHeader} color="#5b5fc7" />;

  const renderToggleButton = () => (
    <Button
      style={styles.filterButton}
      size="tiny"
      appearance="ghost-filter"
      onPress={() => setVisible(true)}
      accessoryRight={
        selectedIndex.row === 2 ? (
          <PenRenderIcon></PenRenderIcon>
        ) : selectedIndex.row === 0 ? (
          <AllRenderIcon></AllRenderIcon>
        ) : selectedIndex.row === 1 ? (
          <TasksRenderIcon></TasksRenderIcon>
        ) : selectedIndex.row === 3 ? (
          <WaitRenderIcon></WaitRenderIcon>
        ) : selectedIndex.row === 4 ? (
          <DoneRenderIcon></DoneRenderIcon>
        ) : selectedIndex.row === 5 ? (
          <EjectRenderIcon></EjectRenderIcon>
        ) : (
          <SearchRenderIcon></SearchRenderIcon>
        )
      }
    >
      {selectedIndex.row === 0
        ? 'Tất cả'
        : selectedIndex.row === 1
        ? 'Đang xử lý'
        : selectedIndex.row === 2
        ? 'Chờ phê duyệt'
        : selectedIndex.row === 3
        ? 'Chờ tôi'
        : selectedIndex.row === 4
        ? 'Hoàn tất'
        : selectedIndex.row === 5
        ? 'Trả về'
        : 'Tra cứu'}
    </Button>
  );

  useEffect(() => {
    if (rootPact.isFetching) return;
    if (rootPact.data === undefined) return;
    if (units === null) return;
    if (cohorts === null) return;
    const data = rootPact.data
      ?.sort((itemA, itemB) => {
        if (isSortDate) return itemA.modifiedAt > itemB.modifiedAt ? 1 : -1;
        else return itemA.modifiedAt < itemB.modifiedAt ? 1 : -1;
      })
      ?.map((item, index, array) => {
        const actionlast = item?.progresses?.at(item?.progresses?.length - 1);

        if (item?.status === 'cancelled' && actionlast?.step === 0) {
          item.tagSpec = 'Hủy';
          item.tagSpecColor = '#636e72';
        } else if (item?.status === 'cancelled') {
          item.tagSpec = 'Trả hồ sơ';
          item.tagSpecColor = '#636e72';
        } else if (actionlast?.status === 'done') {
          item.tagSpec = 'Hoàn tất';
          item.tagSpecColor = '#575fcf';
        } else if (
          actionlast?.status === 'pending' &&
          actionlast?.name === 'Ban GĐ ký' &&
          actionlast?.actions?.find(action=>action?.actorId ==='' && action?.tag === '_boss' && isTypePheDuyet('CQ', action?.candidateType, action?.candidateId, auth.user._id, auth.units, auth.cohorts)) != undefined
        ) {
          item.tagSpec = 'Chờ phê duyệt';
          item.tagSpecColor = '#0984e3';
        } else {
          item.tagSpec = 'Đang xử lý';
          item.tagSpecColor = '#e84393';
        }

        if (item?.seen[auth.user._id] === undefined) {
          item.isSeen = false;
        } else {
          let maxTime = 0;
          const lastList = actionlast?.status === 'done' ? actionlast : item?.progresses?.at(item?.progresses?.length - 2);
          for (let i = 0; i < lastList?.actions?.length; i++) {
            if (lastList?.actions?.at(i)?.atTime > maxTime) {
              maxTime = lastList?.actions?.at(i)?.atTime;
            }
          }

          if (item?.modifiedBy === auth.user._id) {
            item.isSeen = true;
          } else if (item?.seen[auth.user._id] > maxTime) {
            item.isSeen = true;
          } else item.isSeen = false;
        }

        const timeStart = new Date(item.modifiedAt);
        item.pendingForUsers?.length > 0
          ? (item.handle =
              item.pendingForUsers?.find((id) => id === auth.user._id) != undefined
                ? auth.user.firstname + ' ' + auth.user.lastname.split(' ')[0]
                : auth.users?.find((user) => user._id === item.pendingForUsers?.at(0))?.firstname +
                  ' ' +
                  auth.users?.find((user) => user._id === item.pendingForUsers?.at(0))?.lastname?.split(' ')[0])
          : item.pendingForUnits?.length > 0
          ? (item.handle = units?.find((unit) => unit._id === item.pendingForUnits?.at(0))?.code)
          : item.pendingForCohorts?.length > 0
          ? (item.handle = cohorts?.find((cohort) => cohort._id === item.pendingForCohorts?.at(0))?.code)
          : (item.handle = '-');

        const unit = units?.find((unit) => unit._id === item.unitId);

        item.code = unit ? unit?.shortName : null;

        const date = timeStart.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
        item.date = date;
        return item;
      })
      ?.filter((item) => {
        if (selectedIndex.row === 1) return item.tagSpec === 'Đang xử lý';
        else if (selectedIndex.row === 2) return item.tagSpec === 'Chờ phê duyệt';
        else if (selectedIndex.row === 3)
          return (
            item.tagSpec === 'Đang xử lý' &&
            (item?.pendingForUsers?.find((id) => id === auth.user._id) != undefined ||
              units?.find(
                (unit) =>
                  item?.pendingForUnits?.find((uId) => uId === unit._id) != undefined &&
                  unit?.members?.find((id) => id === auth.user._id) != undefined
              ) != undefined ||
              cohorts?.find(
                (cohort) =>
                  item?.pendingForCohorts?.find((uId) => uId === cohort._id) != undefined &&
                  cohort?.members?.find((id) => id === auth.user._id) != undefined
              ) != undefined)
          );
        else return item;
      });

    pactProcessed2.newData = data;
    pactProcessed2.isNewData = true;
    setPactProcessed2({ ...pactProcessed2 });
  }, [rootPact.isFetched, rootPact.isFetching, selectedIndex, auth, auth.user, auth.users, units, cohorts]);

  useEffect(() => {
    if (!pactProcessed2.isCreate && pactProcessed2.isNewData) {
      pactProcessed2.currentData = pactProcessed2.newData;
      pactProcessed2.preData = pactProcessed2.newData;
      pactProcessed2.isNewData = false;
      pactProcessed2.isCreate = true;
      pactProcessed2.index = pactProcessed2.newData?.length > 39 ? 1 : -1;
      pactProcessed2.newData = null;
      setPactProcessed2({ ...pactProcessed2 });
    } else if (pactProcessed2.isNewData && JSON.stringify(pactProcessed2.newData) === JSON.stringify(pactProcessed2.preData)) {
      pactProcessed2.newData = null;
      pactProcessed2.isNewData = false;
      setPactProcessed2({ ...pactProcessed2 });
    } else if (
      pactProcessed2.isNewData &&
      JSON.stringify(pactProcessed2.newData) != JSON.stringify(pactProcessed2.preData) &&
      pactProcessed2.isLoadOld
    ) {
      pactProcessed2.currentData = [...pactProcessed2.currentData, ...pactProcessed2.newData];
      pactProcessed2.preData = pactProcessed2.newData;
      pactProcessed2.isNewData = false;
      pactProcessed2.isLoadOld = false;
      pactProcessed2.index = pactProcessed2.newData?.length > 39 ? pactProcessed2.index + 1 : -1;
      pactProcessed2.newData = null;
      setPactProcessed2({ ...pactProcessed2 });
    }
  }, [pactProcessed2]);

  const onClickSortName = () => {
    setIsSortName(isSortName ? false : true);
    if (isSortName) {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.name < itemB.name ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    } else {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.name > itemB.name ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    }
  };

  const onClickSortDate = () => {
    setIsSortDate(isSortDate ? false : true);
    if (isSortDate) {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.modifiedAt < itemB.modifiedAt ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    } else {
      pactProcessed2.currentData?.sort((itemA, itemB) => {
        return itemA.modifiedAt > itemB.modifiedAt ? 1 : -1;
      });
      setPactProcessed2({ ...pactProcessed2 });
    }
  };

  const navigation = useContext(navigationContext);
  const [navigationIndex, setNavigationIndex] = React.useState(false);

  useEffect(() => {
    if (navigation.state.indexState === -1) return;
    if (navigation.state.indexState === 6) return;
    if (navigationIndex) return;
    setNavigationIndex(true);
    pactProcessed2.isCreate = false;
    pactProcessed2.currentData = null;
    pactProcessed2.preData = null;
    pactProcessed2.newData = null;
    pactProcessed2.isNewData = false;
    pactProcessed2.index = 0;
    setPactProcessed2({ ...pactProcessed2 });
    if (navigation.state.indexState === 0) {
      setSelectedProcess(specId.all);
    } else if (navigation.state.indexState === 1) {
      setSelectedProcess(specId.process);
    } else if (navigation.state.indexState === 2) {
      setSelectedProcess(specId.process);
    } else if (navigation.state.indexState === 3) {
      setSelectedProcess(specId.process);
    } else if (navigation.state.indexState === 4) {
      setSelectedProcess(specId.done);
    } else if (navigation.state.indexState === 5) {
      setSelectedProcess(specId.cancel);
    }
    setTimeout(() => {
      setSelectedIndex(new IndexPath(navigation.state.indexState));
    }, 20);
  }, [navigation, navigation.state]);

  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        style={{
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onPress={() => {
          navigation.setIndexState(selectedIndex.row);
          putSeen(item?._id, 'Lumen');
          navigate(`/elumens/${item?._id}`);
        }}
        key={index}
      >
        <View style={styles.layoutBorder}>
          <View style={styles.layoutColumnLeft50}>
            <Text
              style={{ fontSize: 14, fontWeight: item?.isSeen ? '400' : '600', color: item?.isSeen ? '#222' : '#424242' }}
              numberOfLines={1}
              category="s1"
            >
              {item.name != '' && item.name != null ? item.name : ''}
            </Text>
            <View style={{ padding: 3 }}></View>
            <Text style={{ color: '#667085', fontSize: 13 }} numberOfLines={1}>
              {item.code != '-' && item.code != null ? item.code : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter20}>
            <FlagIcon check={item.status === 'open' && (item?.applicantId === '_gov' || item?.applicantId === '_hyperspace') ? 2 : item?.priority === 1 || item?.priority === 2 ? 3 : item.status === 'open' ? 1 : 0}></FlagIcon>
          </View>
          <View style={styles.layoutCenter30}>
            <Text style={{ fontSize: 14, marginRight: 18 }}>{item.date != '' && item.date != null ? item.date : '01-01-1997'}</Text>
          </View>
        </View>
      </ListItem>
    );
  };

  return (
    <Layout style={{ flex: 1, flexDirection: 'column' }} nativeID="layoutLumen">
      <View style={styles.mainLayout}>
        <View style={styles.headerView}>
          <View style={styles.layoutRow}>
            <Text style={styles.textHeader} category="s1">
              VĂN BẢN TỪ VNUHCM
            </Text>
          </View>
        </View>

        <View style={{}}>
          <OverflowMenu
            style={{ minHeight: 295 }}
            anchor={renderToggleButton}
            visible={visible}
            selectedIndex={selectedIndex}
            onSelect={onItemSelect}
            onBackdropPress={() => setVisible(false)}
          >
            <MenuItem appearance="file-menu" accessoryLeft={AllIcon} title="Tất cả" />
            <MenuItem appearance="file-menu" accessoryLeft={TasksIcon} title="Đang xử lý" />
            <MenuItem appearance="file-menu" accessoryLeft={PenIcon} title="Chờ phê duyệt" />
            <MenuItem appearance="file-menu" accessoryLeft={WaitIcon} title="Chờ tôi" />
            <MenuItem appearance="file-menu" accessoryLeft={DoneIcon} title="Hoàn tất" />
            <MenuItem appearance="file-menu" accessoryLeft={EjectIcon} title="Trả về" />
            <MenuItem appearance="file-menu" accessoryLeft={SearchIcon} title="Tra cứu" />
          </OverflowMenu>
        </View>
      </View>
      <View style={styles.maxlayout}>
        {isSearch && (
          <View style={styles.searchView}>
            {!isShowSearch && (
              <Input
                style={{ paddingVertical: 5 }}
                placeholder="Từ khóa"
                value={searchName}
                onChangeText={(nextValue) => setSearchName(nextValue)}
              />
            )}
            {!isShowSearch && (
              <Select
                appearance="ioc"
                style={{ backgroundColor: 'white', paddingVertical: 5 }}
                selectedIndex={selectedIndexSearchDV}
                value={displayValueDV}
                onSelect={(index) => setSelectedIndexSearchDV(index)}
              >
                <SelectItem appearance="ioc" title="Đơn vị gửi" />
                <SelectItem appearance="ioc" title="CTSV" />
                <SelectItem appearance="ioc" title="ĐTĐH" />
                <SelectItem appearance="ioc" title="ĐNDA" />
                <SelectItem appearance="ioc" title="KHCN" />
                <SelectItem appearance="ioc" title="KHĐT" />
                <SelectItem appearance="ioc" title="TCCB" />
                <SelectItem appearance="ioc" title="TĐKT" />
                <SelectItem appearance="ioc" title="TTPC" />
                <SelectItem appearance="ioc" title="VPGĐ" />
                <SelectItem appearance="ioc" title="BGĐ" />
                <SelectItem appearance="ioc" title="BCSĐ" />
                <SelectItem appearance="ioc" title="CĐCQ" />
                <SelectItem appearance="ioc" title="CĐKVP" />
                <SelectItem appearance="ioc" title="QSCQ" />
                <SelectItem appearance="ioc" title="BTC" />
                <SelectItem appearance="ioc" title="MOD" />
                <SelectItem appearance="ioc" title="VPĐU" />
                <SelectItem appearance="ioc" title="VPCĐ" />
                <SelectItem appearance="ioc" title="TCĐU" />
                <SelectItem appearance="ioc" title="TGĐU" />
                <SelectItem appearance="ioc" title="UBKT" />
              </Select>
            )}
            {!isShowSearch && (
              <Select
                appearance="ioc"
                style={{ backgroundColor: 'white', paddingVertical: 5 }}
                selectedIndex={selectedIndexSearchDVN}
                value={displayValueDVN}
                onSelect={(index) => setSelectedIndexSearchDVN(index)}
              >
                <SelectItem appearance="ioc" title="Đơn vị nhận" />
                <SelectItem appearance="ioc" title="CTSV" />
                <SelectItem appearance="ioc" title="ĐTĐH" />
                <SelectItem appearance="ioc" title="ĐNDA" />
                <SelectItem appearance="ioc" title="KHCN" />
                <SelectItem appearance="ioc" title="KHĐT" />
                <SelectItem appearance="ioc" title="TCCB" />
                <SelectItem appearance="ioc" title="TĐKT" />
                <SelectItem appearance="ioc" title="TTPC" />
                <SelectItem appearance="ioc" title="VPGĐ" />
                <SelectItem appearance="ioc" title="BGĐ" />
                <SelectItem appearance="ioc" title="BCSĐ" />
                <SelectItem appearance="ioc" title="CĐCQ" />
                <SelectItem appearance="ioc" title="CĐKVP" />
                <SelectItem appearance="ioc" title="QSCQ" />
                <SelectItem appearance="ioc" title="BTC" />
                <SelectItem appearance="ioc" title="MOD" />
                <SelectItem appearance="ioc" title="VPĐU" />
                <SelectItem appearance="ioc" title="VPCĐ" />
                <SelectItem appearance="ioc" title="TCĐU" />
                <SelectItem appearance="ioc" title="TGĐU" />
                <SelectItem appearance="ioc" title="UBKT" />
              </Select>
            )}
            {!isShowSearch && (
              <Select
                appearance="ioc"
                style={{ backgroundColor: 'white', paddingVertical: 5, paddingBottom: 10 }}
                selectedIndex={selectedIndexSearchId}
                value={displayValueId}
                onSelect={(index) => setSelectedIndexSearchId(index)}
              >
                <SelectItem appearance="ioc" title="Lãnh đạo ký" />
                <SelectItem appearance="ioc" title="PGS.TS. Vũ Hải Quân" />
                <SelectItem appearance="ioc" title="PGS.TS. Nguyễn Minh Tâm" />
              </Select>
            )}
            {!isShowSearch && (
              <Button
                style={{ borderColor: '#dee2e6', borderRadius: 48, maxHeight: 40 }}
                status="basic"
                appearance="ghost"
                accessoryLeft={SearchIcon}
                onPress={() => onClickSearch()}
              >
                Search
              </Button>
            )}
            {isShowSearch && (
              <Button
                style={{ borderColor: '#dee2e6', borderRadius: 48, minHeight: 30, maxHeight: 30, marginTop: -10, marginBottom: 3 }}
                status="basic"
                appearance="ghost"
                onPress={() => setIsShowSearch(false)}
              >
                SEARCH LẠI
              </Button>
            )}
          </View>
        )}
        {isShowSearch &&
          pactProcessed2.isCreate &&
          (pactProcessed2.currentData?.length > 0 ? (
            <View style={styles.layoutColumn}>
              <View style={styles.layoutRowHeader}>
                <View style={styles.layoutRowLeft50}>
                  <Button
                    style={styles.buttonMall}
                    size="small"
                    appearance="left"
                    onPress={() => {
                      setSortType(0);
                      onClickSortName();
                    }}
                    accessoryRight={sortType === 0 ? (isSortName ? SortUpIcon : SortDownIcon) : null}
                  >
                    Hồ sơ
                  </Button>
                </View>
                <View style={styles.layoutCenter20}>
                  <Text style={{ fontSize: 14 }}>Độ khẩn</Text>
                </View>
                <View style={styles.layoutCenter30}>
                  <Button
                    style={styles.buttonMallDay}
                    size="small"
                    appearance="left"
                    onPress={() => {
                      setSortType(1);
                      onClickSortDate();
                    }}
                    accessoryRight={sortType === 1 ? (isSortDate ? SortUpIcon : SortDownIcon) : null}
                  >
                    Ngày tạo
                  </Button>
                </View>
              </View>

              {pactProcessed2.isLoadOld && (
                <View style={styles.loadingView}>
                  <ActivityIndicator style={styles.loadingIcon} size="small" color="#424242" />
                  <Text style={{ color: '#424242', paddingVertical: 5, paddingLeft: 5, fontSize: 16 }}>Loading...</Text>
                </View>
              )}
              <List
                nativeID="chatView"
                onScroll={(evt) => {
                  setTimeout(() => {
                    if (
                      (selectedIndex.row === 0 || selectedIndex.row === 4 || selectedIndex.row === 5 || selectedIndex.row === 6) &&
                      evt.nativeEvent.contentOffset.y >=
                        window.document.getElementById('chatView')?.firstElementChild?.clientHeight -
                          window.document.getElementById('chatView')?.clientHeight +
                          8 -
                          40 &&
                      pactProcessed2.index != -1 &&
                      !pactProcessed2.isLoadOld &&
                      pactProcessed2.isCreate
                    ) {
                      pactProcessed2.isLoadOld = true;
                      setPactProcessed2({ ...pactProcessed2 });
                      setTimeout(() => {
                        if (selectedIndex.row === 0) {
                          setSelectedProcess('/?pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 4) {
                          setSelectedProcess('/?status=closed&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 5) {
                          setSelectedProcess('/?status=cancelled&pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40');
                        } else if (selectedIndex.row === 6 && isShowSearch) {
                          const id = searchName != '' ? '&keyword=' + searchName : '';
                          const unit = searchUnit != '' ? '&unitId=' + searchUnit : '';
                          const unitN = searchUnitN != '' ? '&destinationId=' + searchUnitN : '';
                          const boss = searchId != '' ? '&bossId=' + searchId : '';
                          setSelectedProcess(
                            '/?pgSkip=' + JSON.stringify(40 * pactProcessed2.index) + '&pgLimit=40' + id + unit + unitN + boss
                          );
                        }
                      }, 20);
                    }
                  }, 50);
                }}
                scrollEnabled={!pactProcessed2.isLoadOld}
                style={{
                  maxHeight: '100%',
                  width: '100%',
                }}
                data={pactProcessed2.currentData}
                renderItem={renderItem}
              />
            </View>
          ) : (
            <Card style={{ borderWidth: 0 }}>
              <View style={styles.nullView}>
                <NullIcon></NullIcon>
              </View>
              <View style={{ padding: 6 }}></View>
              <Text style={{ textAlign: 'center', fontWeight: 'normal' }}>Chưa có quy trình liên quan</Text>
            </Card>
          ))}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    margin: 2,
  },
  icon: {
    minHeight: 14,
    minWidth: 18,
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    minWidth: 16,
    minHeight: 17,
    display: 'flex',
    fontSize: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMallDay: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 75,
  },
  layoutRow: {
    display: 'flex',
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '18%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '25%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 40,
    backgroundColor: '#5b5fc7',
    borderRadius: 40,
  },

  headerView: {
    flexShrink: 1,
    width: '100%',
    paddingRight: 8,
    minHeight: 45,
    justifyContent: 'center',
  },

  mainLayout: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  filterButton: {
    marginHorizontal: 4,
    marginLeft: 4,
    marginRight: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchView: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  loadingView: {
    zIndex: 1000,
    backgroundColor: '#FFFFFF',
    borderWidth: 0,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    justifyContent: 'center',
  },
  loadingIcon: { transform: [{ scaleX: 0.5 }, { scaleY: 0.5 }], paddingTop: 8 },
  maxlayout: {
    width: '100%',
    height: '100%',
    flexShrink: 1,
  },
  nullView: { width: '100%', justifyContent: 'center', alignItems: 'center' },
});
