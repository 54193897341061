import { sendAuthenicatedRequest, sendResetPasswordRequest } from './base';
import qs from 'qs';

export const getFolder = async (folderId: string) => {
  return await (
    await sendAuthenicatedRequest('/entry/' + folderId, 'GET', null)
  ).data.result.item;
};

export const getSharedFolder = async () => {
  return await (
    await sendAuthenicatedRequest('/intersect', 'GET', null)
  ).data.result.shared;
};

export const getTrashFolder = async () => {
  return await (
    await sendAuthenicatedRequest('/reveal', 'GET', null)
  ).data.result.trash;
};

export const createFolder = async ({ parentId, name }) => {
  const data = qs.stringify({ parentId, name });
  return await (
    await sendAuthenicatedRequest('/pot', 'POST', data)
  ).data;
};

export const uploadFile = async ({ data }) => {
  return await (
    await sendAuthenicatedRequest('/deposit', 'POST', data)
  ).data;
};

export const uploadAvatar = async ({ data }) => {
  return await (
    await sendAuthenicatedRequest('/user', 'PUT', data)
  ).data;
};
export const changePassword = async ({ data }) => {
  return await (
    await sendAuthenicatedRequest('/pwd', 'PUT', data)
  ).data;
};
export const changeResetPassword = async ({ username, recoveryToken,password,rPassword}) => {
   const data = qs.stringify({
      username: username,
      recoveryToken: recoveryToken,
      newPass: password,
      retypePass: rPassword,
    })
  return await (
    await sendResetPasswordRequest('/pwd', 'POST', data)
  ).data;
};


export const renameFileOrFolder = async ({id, name}) => {
  const data = qs.stringify({ name });
  return await (await sendAuthenicatedRequest('/entry/' + id, 'PUT', data)).data;
}

export const downloadFile = async ({id}) => {
  return await (await sendAuthenicatedRequest('/access/' + id, 'GET', null, 'blob')).data;
}

export const getNameFile = async ({id}) => {
  return await (await sendAuthenicatedRequest('/entry/' + id, 'GET', null)).data.result.item?.name;
}

export const getFile = async ({ id }) => {
  return await (await sendAuthenicatedRequest('/entry/' + id, 'GET', null)).data.result.item;
}

export const deleteFileOrFolder = async ({ id }) => {
  return await sendAuthenicatedRequest('/entry/' + id, 'DELETE', null);
};

export const deleteFileOrFolderFromTrash = async ({ id }) => {
  return await sendAuthenicatedRequest('/reveal/' + id, 'DELETE', null);
};

export const restoreFileOrFolder = async ({ id }) => {
  return await sendAuthenicatedRequest('/reveal/' + id, 'PUT', null);
};


export const shareFileOrFolder = async ({ id, sharings }) => {
  const data = qs.stringify({ sharings: sharings.reduce((a, b) => a + "," + b, '') });
  return await sendAuthenicatedRequest('/entry/' + id, 'PUT', data);
}

export const emptyTrashFolder = async () => {
  return await (
    await sendAuthenicatedRequest('/reveal//', 'DELETE', null)
  ).data.result.trash;
};