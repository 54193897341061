import { Button, Input, Layout, Text, ViewPager } from '@ui-kitten/components';
import * as DocumentPicker from 'expo-document-picker';
import React, { useContext } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { navigationContext } from '../../hooks/navigation';
import { changePassword, uploadAvatar } from '../../services/files';

const UserIcon = (props) => (
  <Svg width={23} height={20} viewBox="0 0 448 512">
    <Path
      d="M48 416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V368H48v48zm286-96h66V96c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V320h66c7.1-27.6 32.2-48 62-48h96c29.8 0 54.9 20.4 62 48zM448 96V320v24 24 48c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V368 344 320 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64zM160 176a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"
      fill="#636e72"
    />
  </Svg>
);

const FlagIcon = (props) => (
  <View
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: 90,
      width: 8,
      height: 8,
      borderColor: props.check === 'true' ? '#524f4f' : '#BDBDBD',
      backgroundColor: props.check === 'true' ? '#524f4f' : '#FFFFFF',
    }}
  />
);

export default (props) => {
  const navigate = useNavigate();
  const navigation = useContext(navigationContext);

  const auth = useContext(authContext);

  const [isShowProcess, setIsShowProcess] = React.useState(true);

  const [selectedIndexView, setSelectedIndexView] = React.useState(0);

  const [currentPSW, setCurrentPSW] = React.useState('');
  const [newPSW, setNewPSW] = React.useState('');
  const [newCPSW, setNewCPSW] = React.useState('');

  const Cleardata = () => {
    setCurrentPSW('');
    setNewCPSW('');
    setNewPSW('');
  };

  const AlertHandlerTL = () => {
    //function to make simple alert
    alert('Cập nhật ảnh đại diện thành công');
  };

  const AlertHandlerPass = () => {
    //function to make simple alert
    alert('Mật khẩu mới (Gõ lại) không trùng');
  };
  const AlertHandlerPassFail = () => {
    //function to make simple alert
    alert('Dữ liệu không hợp lệ');
  };

  const AlertHandler = (msg) => {
    //function to make simple alert
    alert(msg);
  };

  const changePSW = async () => {
    if (newPSW === '' || newCPSW === '') {
      AlertHandlerPassFail();
      return;
    }
    if (newPSW != newCPSW) {
      AlertHandlerPass();
      return;
    }
    const formData = new FormData();
    if (currentPSW != '') formData.append('oldPass', currentPSW);
    formData.append('newPass', newPSW);
    formData.append('retypePass', newCPSW);

    //encodeURIComponent

    const temp = await changePassword(
      currentPSW != ''
        ? {
            data: { oldPass: encodeURIComponent(currentPSW), newPass: encodeURIComponent(newPSW), retypePass: encodeURIComponent(newCPSW) },
          }
        : {
            data: { newPass: encodeURIComponent(newPSW), retypePass: encodeURIComponent(newCPSW) },
          }
    );
    if (temp?.status === 'ok') {
      AlertHandler('Mật khẩu đã được thay đổi thành công');
      navigation.setShowSideBar(false);
      navigation.setRole(false, false, false, false, false);
      auth.signOut();
      navigate('/login');
      window.location.reload();
    } else if (temp?.status === 'error') {
      AlertHandler(temp?.message);
    }
  };

  const uploadAV = async () => {
    const result = await DocumentPicker.getDocumentAsync({});
    const formData = new FormData();
    formData.append('filedata', (result as any).file);
    const temp = await uploadAvatar({ data: formData });
    if (temp?.status === 'ok') {
      auth.setAvatar({ avatar: temp?.result?.avatar });
      AlertHandlerTL();
    }
  };

  return (
    <Layout style={styles.layout} nativeID="layoutPact">
      {isShowProcess && (
        <Layout style={styles.maxLayout}>
          <View style={styles.maxLayout}>
            <ViewPager
              style={{ height: '100%', flexShrink: 1, display: 'flex' }}
              selectedIndex={selectedIndexView}
              onSelect={(index) => {
                setSelectedIndexView(index);
              }}
            >
              <View style={styles.columnmaxLayout}>
                <View style={styles.view1}>
                  <Text style={{ color: '#2C3A47', fontWeight: '700' }}>THÔNG TIN CÁ NHÂN</Text>
                </View>
                <View style={{ height: '100%', flexShrink: 1 }}>
                  <ScrollView style={styles.maxLayout1}>
                    <View style={styles.maxWidthnotShrink}>
                      <View style={styles.columnPb5}>
                        <View style={styles.view2}>
                          <View style={{ minWidth: 100 }}>
                            <Text style={{ fontWeight: '600' }}>Chức danh </Text>
                          </View>
                          <View style={styles.columnView}>
                            <Text style={{ display: 'flex' }}>{auth.user?.prefix === 'PGS.TS.' ? 'Phó Giáo sư' : '-'}</Text>
                          </View>
                        </View>
                        <View style={styles.view2}>
                          <View style={{ minWidth: 100 }}>
                            <Text>Ngày sinh </Text>
                          </View>
                          <View style={styles.columnView}>
                            <Text style={{ display: 'flex' }}>{auth.user?.dob != '' ? auth.user?.dob : '-'}</Text>
                          </View>
                        </View>
                        <View style={styles.view2}>
                          <View style={{ minWidth: 100 }}>
                            <Text>Giới tính </Text>
                          </View>
                          <View style={styles.columnView}>
                            <Text style={{ display: 'flex' }}>
                              {auth.user?.gender === 'male' ? 'Nam' : auth.user?.gender === 'female' ? 'Nữ' : 'unknown'}
                            </Text>
                          </View>
                        </View>
                        {auth.user?.conscriptions.length > 0 && (
                          <View style={styles.maxWidthnotShrink}>
                            {auth.user?.conscriptions?.map((item, index) => (
                              <View key={index} style={styles.view2}>
                                <View style={{ minWidth: 100 }}>
                                  <Text style={{ fontWeight: '600' }}>{index === 0 ? 'Công tác ' : ''}</Text>
                                </View>
                                <View style={styles.columnView}>
                                  <Text style={{ display: 'flex' }}>{item?.unitName}</Text>

                                  <View style={{ padding: 3 }}></View>
                                  <Text style={{ display: 'flex', fontStyle: 'italic' }}>{item?.post}</Text>
                                </View>
                              </View>
                            ))}
                          </View>
                        )}

                        <View style={styles.view2}>
                          <View style={{ minWidth: 100 }}>
                            <Text>Điện thoại </Text>
                          </View>
                          <View style={styles.columnView}>
                            <Text style={{ display: 'flex' }}>{auth.user?.phone != '' ? auth.user?.phone : '-'}</Text>
                          </View>
                        </View>
                        <View style={styles.view2}>
                          <View style={{ minWidth: 100 }}>
                            <Text>Email </Text>
                          </View>
                          <View style={styles.columnView}>
                            <Text style={{ display: 'flex' }}>{auth.user?.email != '' ? auth.user?.email : '-'}</Text>
                          </View>
                        </View>

                        <View style={styles.view2}>
                          <View style={{ minWidth: 100 }}>
                            <Text>Ảnh đại diện </Text>
                          </View>
                          <View style={styles.columnView}>
                            <Button
                              style={{ paddingHorizontal: 0, paddingVertical: 0, display: 'flex' }}
                              status="basic"
                              appearance="avatar"
                              accessoryLeft={UserIcon}
                              onPress={() => {
                                uploadAV();
                              }}
                            >
                              Cập nhật
                            </Button>
                          </View>
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
              <View style={styles.columnmaxLayout}>
                <View style={styles.view1}>
                  <Text style={{ color: '#2C3A47', fontWeight: '700' }}>ĐỔI MẬT KHẨU IOC</Text>
                </View>
                <View style={{ height: '100%', flexShrink: 1 }}>
                  <ScrollView style={styles.maxLayout1}>
                    <View style={styles.maxWidthnotShrink}>
                      <View style={styles.columnPb5}>
                        <View style={styles.columnView1}>
                          <View style={{ minWidth: 150 }}>
                            <Text>Tên đăng nhập </Text>
                          </View>
                          <View style={{ padding: 4 }}></View>
                          <View style={styles.columnmaxWidth1}>
                            <Input
                              placeholder={
                                auth.user?.email?.split('@')[1] === 'vnuhcm.edu.vn' ? auth.user?.email?.split('@')[0] : auth.user?.email
                              }
                              value={
                                auth.user?.email?.split('@')[1] === 'vnuhcm.edu.vn' ? auth.user?.email?.split('@')[0] : auth.user?.email
                              }
                            ></Input>
                          </View>
                        </View>
                        <View style={styles.columnView1}>
                          <View style={{ minWidth: 150 }}>
                            <Text>Mật khẩu hiện tại </Text>
                          </View>
                          <View style={{ padding: 4 }}></View>
                          <View style={styles.columnmaxWidth1}>
                            <Input
                              placeholder="Để trống nếu chưa có psw"
                              secureTextEntry={true}
                              value={currentPSW}
                              onChangeText={(nextValue) => setCurrentPSW(nextValue)}
                            ></Input>
                          </View>
                        </View>
                        <View style={styles.columnView1}>
                          <View style={{ minWidth: 150 }}>
                            <Text>Mật khẩu mới </Text>
                          </View>
                          <View style={{ padding: 4 }}></View>
                          <View style={styles.columnmaxWidth1}>
                            <Input
                              placeholder="Nhập"
                              value={newPSW}
                              secureTextEntry={true}
                              onChangeText={(nextValue) => setNewPSW(nextValue)}
                            ></Input>
                          </View>
                        </View>
                        <View style={styles.columnView1}>
                          <View style={{ minWidth: 150 }}>
                            <Text>Mật khẩu mới (Gõ lại) </Text>
                          </View>
                          <View style={{ padding: 4 }}></View>
                          <View style={styles.columnmaxWidth1}>
                            <Input
                              placeholder="Nhập"
                              value={newCPSW}
                              secureTextEntry={true}
                              onChangeText={(nextValue) => setNewCPSW(nextValue)}
                            ></Input>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={styles.view3}>
                      <View style={styles.paddingH5}>
                        <Button
                          style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                          size="small"
                          onPress={() => {
                            changePSW();
                          }}
                        >
                          Đổi mật khẩu
                        </Button>
                      </View>

                      <View style={styles.paddingH5}>
                        <Button
                          status="basic"
                          appearance="ghost"
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#424242' }}
                          size="small"
                          onPress={() => {
                            Cleardata();
                          }}
                        >
                          Clear
                        </Button>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
            </ViewPager>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: 5,
                position: 'absolute',
                bottom: 0,
              }}
            >
              <View style={{ padding: 2 }}>
                <FlagIcon check={0 === selectedIndexView ? 'true' : 'false'}></FlagIcon>
              </View>
              <View style={{ padding: 2 }}>
                <FlagIcon check={1 === selectedIndexView ? 'true' : 'false'}></FlagIcon>
              </View>
            </View>
            <View style={{ height: 26 }}></View>
          </View>
        </Layout>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    color: '#424242',
    margin: 2,
    fontSize: 17,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon1: {
    borderWidth: 1,
    borderColor: '#9ca59d',
    borderRadius: 5,
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProcess: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCirle: {
    display: 'flex',
    fontSize: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutRow: {
    display: 'flex',
    paddingTop: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 36,
    paddingHorizontal: 8,
    flexShrink: 1,
    justifyContent: 'center',
    borderColor: '#e6eddd',
    borderRadius: 50,
  },
  maxLayout: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  headerFileView: {
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    paddingBottom: 5,
  },
  fileView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 0,
  },
  columnmaxWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  rowmaxWidthPb5: {
    flexDirection: 'row',
    paddingBottom: 5,
    width: '100%',
  },
  columnPl5: {
    flexDirection: 'column',
    paddingLeft: 5,
  },
  rowCenterPt5: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
  },
  columnView: {
    flexDirection: 'column',
    flexShrink: 1,
  },
  columnmaxWidthStart: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flexShrink: 1,
  },
  width60maxHeight: {
    minWidth: 60,
    alignItems: 'flex-start',
    height: '100%',
  },
  Pv5Center: {
    paddingVertical: 10.5,
    alignItems: 'center',
  },
  maxHeightStart: {
    alignItems: 'flex-start',
    height: '100%',
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  rowmaxWidth: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
  columnmaxLayout: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexShrink: 1,
  },
  maxLayout1: {
    height: '100%',
    width: '100%',
  },
  paddingH5: {
    paddingHorizontal: 5,
  },
  alignStartStart: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  maxWidthnotShrink: {
    width: '100%',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowmaxWidthPv5: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  view1: {
    paddingTop: 22,
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: 16,
    borderBottomColor: '#eaecf0',
    borderBottomWidth: 1,
    borderStyle: 'dotted',
  },
  view2: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomColor: '#eaecf0',
    borderBottomWidth: 1,
    borderStyle: 'dotted',
    paddingHorizontal: 12,
  },
  columnPb5: {
    flexDirection: 'column',
    paddingBottom: 5,
  },
  columnmaxWidth1: {
    flexDirection: 'column',
    flexShrink: 1,
    width: '100%',
  },
  columnView1: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingVertical: 8,
    borderBottomColor: '#eaecf0',
    borderBottomWidth: 1,
    borderStyle: 'dotted',
    paddingHorizontal: 12,
  },
  view3: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 8,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  view4: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 5,
    position: 'absolute',
    bottom: 0,
  },
});
