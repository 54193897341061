import { Button, Card, Layout, List, ListItem, Modal, Spinner, Text } from '@ui-kitten/components';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useQuery } from 'react-query';
import HeaderTitle from '../../components/HeaderTitle';
import { authContext } from '../../hooks/authentication';
import { deleteFileOrFolderFromTrash, emptyTrashFolder, getTrashFolder, restoreFileOrFolder } from '../../services/files';
import { FileMoreMenu } from '../../share/fiile-more-menu';
import { getFileOrFolderIcon } from '../file';
import { EjectWhiteIcon } from '../../share/filter-icon';

const ABIcon = (props) => (
  <Svg width={26} height={15.6} viewBox="0 0 448 512">
    <Path
      d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
      fill="#FFFFFF"
    />
  </Svg>
);

const XIcon = (props) => <Icon {...props} style={styles.icon} name="times" color="#FFFFFF" />;

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 384 512">
    <Path
      d="M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
      fill="#424242"
    />
  </Svg>
);

const EmptyTrashIcon = (props) => (
  <Svg width={26} height={15} viewBox="0 0 448 512">
    <Path
      d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64h47.1H416h16c8.8 0 16 7.2 16 16s-7.2 16-16 16H413.7L388.2 452.6C385.9 486.1 358 512 324.4 512H123.6c-33.6 0-61.4-25.9-63.8-59.4L34.3 96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64.1h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM66.4 96L91.7 450.3C92.9 467 106.8 480 123.6 480H324.4c16.8 0 30.7-13 31.9-29.7L381.6 96H66.4zM224 176c4.2 0 8.3 1.7 11.3 4.7l80 80c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L240 230.6V392c0 8.8-7.2 16-16 16s-16-7.2-16-16V230.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l80-80c3-3 7.1-4.7 11.3-4.7z"
      fill="#424242"
    />
  </Svg>
);

export default (props) => {
  const rootFolder = useQuery('trash', () => getTrashFolder());
  const { users } = useContext(authContext);

  const [folderPaths, setFolderPaths] = useState([{ id: '/', name: 'root' }]);
  const [isShowEmptyTrashModal, setIsShowEmptyTrashModal] = React.useState(false);

  const deleteFile = async (id) => {
    await deleteFileOrFolderFromTrash({ id });

    rootFolder.refetch();
  };

  const restoreFile = async (id) => {
    await restoreFileOrFolder({ id });

    rootFolder.refetch();
  };

  const emptyTrash = async () => {
    await emptyTrashFolder();

    rootFolder.refetch();
  };

  const onclickEmptyTrash = () => {
    setIsShowEmptyTrashModal(true);
  };

  const [isShowDeleteModal, setIsShowDeleteModal] = React.useState(false);
  const [seletedDeleteId, setSeletedDeleteId] = React.useState('');

  const onclickDelete = (id) => {
    setIsShowDeleteModal(true);
    setSeletedDeleteId(id);
  };

  const renderFiles = ({ item, index }) => {
    const createdAt = new Date(item.createdAt);
    const description = `${
      createdAt.toLocaleString('hi', { day: '2-digit' }) +
      ' tháng ' +
      createdAt.toLocaleString('hi', { month: '2-digit' }) +
      ', ' +
      createdAt.getFullYear()
    }`;

    let IconType = getFileOrFolderIcon(item.name, item.type);

    return (
      <View style={styles.renderFile}>
        <FileMoreMenu
          handleDeleteFile={() => onclickDelete(item?._id)}
          handleRestoreFile={() => restoreFile(item._id)}
          isShowRename={false}
          isShowShare={false}
          isShowRestore={true}
          isShowOpen={false}
          AnchorElement={(props) => (
            <ListItem
              key={index}
              title={
                <View>
                  <Text style={{ fontSize: 14, fontWeight: '400', color: '#222' }}>{item.name}</Text>
                  <View style={{ padding: 3 }}></View>
                </View>
              }
              description={
                <View>
                  <Text style={{ fontSize: 13, fontWeight: '400', color: '#8f9bb3' }}>{description}</Text>
                </View>
              }
              accessoryLeft={IconType}
              accessoryRight={() => (
                <FileMoreMenu
                  handleDeleteFile={() => onclickDelete(item?._id)}
                  handleRestoreFile={() => restoreFile(item._id)}
                  isShowRename={false}
                  isShowShare={false}
                  isShowRestore={true}
                  isShowOpen={false}
                />
              )}
              onPress={() => {
                props.onPress();
              }}
            />
          )}
        />
      </View>
    );
  };

  let displayedFolderPath = [...folderPaths];

  while (displayedFolderPath.length > 3) {
    displayedFolderPath.splice(1, 1);
  }

  if (displayedFolderPath.length !== folderPaths.length) {
    displayedFolderPath.splice(1, 0, { id: '....', name: '....' });
  }

  return (
    <Layout style={styles.layout}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <HeaderTitle title="TRASH" icon={null} />
        <Button
          size="small"
          appearance="ghost"
          accessoryLeft={EmptyTrashIcon}
          onPress={() => {
            onclickEmptyTrash();
          }}
        ></Button>
      </View>

      {rootFolder.isFetched && (
        <View style={styles.container}>
          {rootFolder.data?.length > 0 ? (
            <List
              style={{ backgroundColor: '#FFFFFF' }}
              data={rootFolder.data.sort((itemA, itemB) => {
                if (itemA.type === 'directory' && itemB.type === 'file') {
                  return -1;
                }

                if (itemA.type === 'file' && itemB.type === 'directory') {
                  return 1;
                }

                return itemA.name > itemB.name ? 1 : -1;
              })}
              renderItem={renderFiles}
            />
          ) : (
            <Card style={{ borderWidth: 0 }}>
              <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <NullIcon></NullIcon>
              </View>
              <View style={{ padding: 6 }}></View>
              <Text style={{ textAlign: 'center' }}>Chưa có files</Text>
            </Card>
          )}
        </View>
      )}
      <Modal
        visible={isShowEmptyTrashModal}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onBackdropPress={() => setIsShowEmptyTrashModal(false)}
        style={styles.modalEmpty}
      >
        <Layout style={{ borderRadius: 3, overflow: 'hidden' }}>
          <Layout style={styles.modalLayout}>
            <View style={styles.modalHeaderView}>
              <ABIcon></ABIcon>
              <View style={{ padding: 5 }}></View>
              <Text style={{ color: 'white', fontWeight: '600' }}>Empty Trash</Text>
            </View>
            <View>
              <Button appearance="ghost" size="small" onPress={() => setIsShowEmptyTrashModal(false)} accessoryLeft={EjectWhiteIcon}></Button>
            </View>
          </Layout>
          <Layout style={styles.emptyLayout}>
            <View style={styles.layout1}>
              <Text>Có thực sự muốn xóa?</Text>
            </View>
            <View style={{ width: '100%', height: 1, backgroundColor: '#dee2e6' }} />
            <View style={{ padding: 3.5 }}></View>
            <View style={styles.editButtonView}>
              <View style={{ paddingRight: 10 }}>
                <Button
                  style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                  size="tiny"
                  onPress={() => {
                    setIsShowEmptyTrashModal(false);
                    emptyTrash();
                  }}
                >
                  OK
                </Button>
              </View>
              <Button
                size="tiny"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                onPress={() => setIsShowEmptyTrashModal(false)}
              >
                Cancel
              </Button>
            </View>
          </Layout>
        </Layout>
      </Modal>
      <Modal
        visible={isShowDeleteModal}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onBackdropPress={() => setIsShowDeleteModal(false)}
        style={styles.modalEmpty}
      >
        <Layout style={{ borderRadius: 3, overflow: 'hidden' }}>
          <Layout style={styles.modalLayout}>
            <View style={styles.modalHeaderView}>
              <ABIcon></ABIcon>
              <View style={{ padding: 5 }}></View>
              <Text style={{ color: 'white', fontWeight: '600' }}>Delete</Text>
            </View>
            <View>
              <Button appearance="ghost" size="small" onPress={() => setIsShowDeleteModal(false)} accessoryLeft={EjectWhiteIcon}></Button>
            </View>
          </Layout>
          <Layout style={styles.emptyLayout}>
            <View style={styles.layout1}>
              <Text>Có thực sự muốn xóa?</Text>
            </View>
            <View style={{ width: '100%', height: 1, backgroundColor: '#dee2e6' }} />
            <View style={{ padding: 3.5 }}></View>
            <View style={styles.editButtonView}>
              <View style={{ paddingRight: 10 }}>
                <Button
                  style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                  size="tiny"
                  onPress={() => {
                    setIsShowDeleteModal(false);
                    deleteFile(seletedDeleteId);
                  }}
                >
                  OK
                </Button>
              </View>
              <Button
                size="tiny"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                onPress={() => setIsShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </View>
          </Layout>
        </Layout>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  folderPath: {
    display: 'flex',
    width: '100%',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderTopColor: '#f0f0f0',
    borderTopWidth: 1,
    flexShrink: 1,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  addButton: {
    width: 50,
    height: 50,
    backgroundColor: '#5b5fc7',
    borderRadius: 40,
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  renderFile: {
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
    minHeight: 50,
    justifyContent: 'center',
    paddingLeft: 7,
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  modalEmpty: {
    padding: 0,
    width: '100%',
    paddingHorizontal: 10,
    paddingBottom: screen.height * 0.25,
  },
  emptyLayout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    padding: 10,
    backgroundColor: 'white',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  layout1: {
    paddingLeft: 6,
    paddingTop: 2,
    paddingBottom: 7,
  },
  editButtonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconHeader1: {
    display: 'flex',
    fontSize: 15,
    fontWeight: 400,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 1,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 21,
  },
  modalLayout: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#DC3545',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  modalHeaderView: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  maxlayout: {
    width: '100%',
    height: '100%',
    flexShrink: 1,
  },
});
