import { createContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { forgotPassword, getAllUsers, loginWithGoogleToken, loginWithUsername } from '../services/authentication';

export const authContext = createContext<IAuthContext>(null);

export interface User {
  accessToken?: string;
  dob: string;
  avatar: string;
  email: string;
  fullname: string;
  firstname: string;
  lastname: string;
  phone: string;
  prefix: string;
  simPKI: string;
  usbPKI: string;
  gender: string;
  status: string;
  _id: string;
  user?: object;
  conscriptions: Array<any>;
  turf: string;
  turfs: Array<string>;
}

export interface IAuthContext {
  signInWithUsernameAndPassword: (payload: { username: string; password: string }) => void;
  signInWithApp: (payload: {accessToken: string}) => void;
  signInWithGoogleToken: (payload: { ggToken: string }) => void;
  forgotPass: (payload: { username: string }) => void;
  signOut: () => void;
  setAvatar: (payload: { avatar: string }) => void;
  setUnits: (payload: { data: any }) => void;
  setCohorts: (payload: { data: any }) => void;
  user: User;
  users: User[];
  units: any;
  cohorts: any;
  login: boolean;
}

export const useProviderAuth = () => {
  let previousToken = undefined;
  let previousUser = undefined;
  let avatar = undefined;

  if (Platform.OS === 'web') {
    previousToken = localStorage.getItem('accessToken');
    let jwt = localStorage.getItem('jwt');
    avatar = localStorage.getItem('avatar');

    if (previousToken) {
      previousUser = JSON.parse(Buffer.from(previousToken.split('.')[1], 'base64').toString());
      if (jwt === undefined || jwt === null)
        localStorage.setItem('jwt', previousToken);
    }
  }

  const [user, setUser] = useState<User>({
    accessToken: previousToken,
    ...previousUser,
    avatar: avatar != undefined ? avatar : previousToken != undefined ? previousUser?.avatar : '',
  });

  const [users, setUsers] = useState<User[]>([]);
  const [units, setunits] = useState<any>(null);
  const [cohorts, setcohorts] = useState<any>(null);
  const [login, setLogin] = useState(false);

  const setUnits = ({ data }) => {
    setunits(data);
  };

  const setCohorts = ({ data }) => {
    setcohorts(data);
  };

  useEffect(() => {
    if (previousToken === null) return;
    getAllUsers().then((users) => setUsers(users));
  }, [previousToken]);

  const getUserInfo = async (accessToken) => {
    if (Platform.OS === 'web') {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('jwt', accessToken);
    }

    const body = accessToken.split('.')[1];
    const user = JSON.parse(Buffer.from(body, 'base64').toString());

    setUser({
      accessToken,
      ...user,
    });
  };

  const setAvatar = async ({ avatar }) => {
    if (Platform.OS === 'web') {
      localStorage.setItem('avatar', avatar);
    }
    user.avatar = avatar;
    setUser({ ...user });
  };

  const signInWithUsernameAndPassword = async ({ username, password }) => {
    const res = await loginWithUsername({ username, password });

    if (res.data.status == 'ok') {
      getUserInfo(res.data.result.czt);
      setLogin(true);
    }

    return res.data;
  };

  const signInWithApp = async ({ accessToken }) => {
    getUserInfo(accessToken);
    setLogin(true);
    return accessToken;
  };

  const signInWithGoogleToken = async ({ ggToken }) => {
    const res = await loginWithGoogleToken({ ggToken });

    if (res.data.status == 'ok') {
      getUserInfo(res.data.result.czt);
      setLogin(true);
    }

    return res.data;
  };

  const forgotPass = async ({ username }) => {
    const res = await forgotPassword({ username });

    return res.data;
  };

  const signOut = () => {
    if (Platform.OS === 'web') {
      localStorage.clear();
    }

    setUser(null);
  };

  return {
    user,
    users,
    units,
    login,
    cohorts,
    setCohorts,
    setUnits,
    setAvatar,
    signInWithUsernameAndPassword,
    signInWithApp,
    signInWithGoogleToken,
    forgotPass,
    signOut,
  };
};

export const AuthenticationProvider = ({ children }) => {
  const auth = useProviderAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
