import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';

interface Props {
  icon: React.FC<any>;
  title: string;
}

export default (props: Props) => {
  return (
    <Layout style={styles.headerLayout}>
      <View style={styles.headerTitleContainer}>
        <View style={styles.headerLeft}>
          {props.icon && <props.icon style={styles.headerIcon} />}
          <Text style={styles.headerTitle} category="s1">
            {props.title}
          </Text>
        </View>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  headerLayout: {
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 40,
  },
  headerTitleContainer: {
    flexShrink: 1,
    width: '100%',
    minHeight: 45,
    justifyContent: 'center',
  },
  headerTitle: {},
  headerLeft: {
    display: 'flex',
    width: '100%',
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
  },
  headerIcon: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
