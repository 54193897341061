import { sendSigningProcessRequest } from './base';

export enum agendaId {
  all = "/?spec=Agenda-BTB&pgSkip=0&pgLimit=40",
  process = "/?spec=Agenda-BTB&status=open",
  done = "/?spec=Agenda-BTB&status=closed&pgSkip=0&pgLimit=40",
  cancel = "/?spec=Agenda-BTB&status=cancelled&pgSkip=0&pgLimit=40"
}

export enum dagendaId {
  all = "/?spec=Agenda-PTB&pgSkip=0&pgLimit=40",
  process = "/?spec=Agenda-PTB&status=open",
  done = "/?spec=Agenda-PTB&status=closed&pgSkip=0&pgLimit=40",
  cancel = "/?spec=Agenda-PTB&status=cancelled&pgSkip=0&pgLimit=40"
}

export enum taskId {
  all = "/?pgSkip=0&pgLimit=40",
  process = "/?status=open",
  done = "/?status=closed&pgSkip=0&pgLimit=40",
  reminded = "/?status=reminded",
  cancel = "/?status=cancelled&pgSkip=0&pgLimit=40"
}



export const getAgenda = async (agendaId: string) => {
  return await (
    await sendSigningProcessRequest('/agenda'+agendaId, 'GET', null)
  ).data.result.agendas;
}

export const getTask = async (taskId: string) => {
  return await (
    await sendSigningProcessRequest('/task'+taskId, 'GET', null)
  ).data.result.tasks;
};

export const getTaskInfo = async (taskId) => {
  return await (
    await sendSigningProcessRequest('/task/'+taskId, 'GET', null)
  ).data.result.tasks;
};

export const updateTaskInfo = async (taskId, idata: { induction: string, statement: string }) => {
  const data =({
    induction: idata.induction,
    statement: idata.statement,
  });
  return await (
    await sendSigningProcessRequest('/task/'+taskId, 'PUT', data)
  ).data.result.tasks;
};

export const getAgendaInfo = async (agendaId) => {
  return await (
    await sendSigningProcessRequest('/agenda/'+agendaId, 'GET', null)
  ).data.result.agendas;
};