import { Avatar, Button, IndexPath, Input, Layout, Select, SelectItem, Spinner, Text } from '@ui-kitten/components';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { signContext } from '../../hooks/signingprocess';
import { getAgendaInfo, getTaskInfo, updateTaskInfo } from '../../services/agendaprocess';
import { downloadFile as dlFile, getNameFile } from '../../services/files';
import { HostIDs } from '../../services/schedule';
import { getFuseInfo } from '../../services/signingprocess';
import { ExelFileIcon, ImageIcon, PdfFileIcon, PowerPointIcon, UnknownFileIcon, WordFileIcon } from '../../share/filter-icon';
import { getAvatarColor } from '../../utils/avatar';
import { canOpen, isPdf } from '../../utils/files';
import { isChromeAndroid } from '../../utils/browser';
import { getBaseApiUrl } from '../../config';

const TasksIcon = (props) => (
  <Svg width={20} height={23} viewBox="0 0 640 512">
    <Path
      d="M112 32H400c8.8 0 16 7.2 16 16V64h32V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48V192H96V48c0-8.8 7.2-16 16-16zM224 144c0-8.8 7.2-16 16-16H592c8.8 0 16 7.2 16 16V368c0 8.8-7.2 16-16 16H416v32H592c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H240c-26.5 0-48 21.5-48 48v48h32V144zM48 256H336c8.8 0 16 7.2 16 16v5L196.9 391.9c-1.4 1-3.1 1.6-4.9 1.6s-3.5-.6-4.9-1.6L32 277v-5c0-8.8 7.2-16 16-16zM32 464V316.8L168 417.6c6.9 5.1 15.3 7.9 24 7.9s17-2.8 24-7.9L352 316.8V464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16zM48 224c-26.5 0-48 21.5-48 48V464c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48H48zm448-64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H496zm16 64V192h32v32H512z"
      fill="#424242"
    />
  </Svg>
);

const XLIcon = (props) => (
  <Svg width={20} height={23} viewBox="0 0 448 512" fontWeight={'400'} opacity={0.8}>
    <Path
      d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"
      fill="#424242"
    />
  </Svg>
);

const AvatarImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  
  const imageName = props.name
    ? name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar) return <Avatar size={props.size ? props.size : 'medium'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 40,
          width: 40,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Text style={{ color: '#ffffff', fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

export default (props) => {
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = React.useState(false);

  const showWaiting = () => {
    // setIsWaiting(true);
    // setTimeout(() => setIsWaiting(false), 500);
  };

  useEffect(() => {
    // setTimeout(() => setIsWaiting(false), 500);
  }, []);

  const auth = useContext(authContext);
  const sign = useContext(signContext);
  const { units, cohorts } = useContext(authContext);

  const [isShowProcess, setIsShowProcess] = React.useState(false);
  const [specId, setSpecId] = React.useState({ flag: false, data: null });
  const state = useLocation();

  useEffect(() => {
    if (specId.flag) return;
    specId.data = state?.pathname?.slice(7, 50);
    specId.flag = true;
    if (state != null) setSpecId({ ...specId });
  }, [specId, specId.flag]);

  const getTask = async ({ queryKey }) => {
    if (!queryKey) return null;
    const [_, prodId] = queryKey;
    return await getTaskInfo(prodId);
  };

  const rootSpec = useQuery(['agendainfo', specId.data], getTask);

  const [dataTask, setDataTask] = React.useState(null);
  const [dataTaskProcess, setDataTaskProcess] = React.useState(null);
  const [pactName, setPactName] = React.useState('');
  const refScrollView = React.useRef<ScrollView>(null);
  var dictionaryFileName = new Map<string, string>();

  useEffect(() => {
    if (rootSpec.data === undefined || rootSpec.data === null) return;
    setDataSelect({ data: ['Chọn hướng xử lý'], dataId: [''], vaitro: '' });
    setDataTask(rootSpec.data?.at(0));
    setPactName(rootSpec.data?.at(0)?.name);
    const time = new Date();
    if (rootSpec.data?.at(0)?.status === 'cancelled') {
      sign.setStatus({ color: '#636e72', tag: 'Đã hủy' });
    } else if (rootSpec.data?.at(0)?.status === 'closed') {
      sign.setStatus({ color: '#575fcf', tag: 'Hoàn tất' });
    } else if (
      rootSpec.data?.at(0)?.status === 'open' &&
      rootSpec.data?.at(0)?.deadline - time.getTime() > 0 &&
      rootSpec.data?.at(0)?.deadline - time.getTime() < 86400000
    ) {
      sign.setStatus({ color: '#e17055', tag: 'Sắp đến hạn' });
    } else if (rootSpec.data?.at(0)?.status === 'open' && rootSpec.data?.at(0)?.deadline < time.getTime()) {
      sign.setStatus({ color: '#DC3545', tag: 'Quá hạn' });
    } else if (
      rootSpec.data?.at(0)?.status === 'reminded' &&
      rootSpec.data?.at(0)?.deadline - time.getTime() > 0 &&
      rootSpec.data?.at(0)?.deadline - time.getTime() < 86400000
    ) {
      sign.setStatus({ color: '#e17055', tag: 'Sắp đến hạn' });
    } else if (rootSpec.data?.at(0)?.status === 'reminded' && rootSpec.data?.at(0)?.deadline < time.getTime()) {
      sign.setStatus({ color: '#DC3545', tag: 'Quá hạn' });
    } else {
      sign.setStatus({ color: '#198754', tag: 'Đang xử lý' });
    }
  }, [rootSpec.isFetched, rootSpec.isFetching]);

  const getFileOrFolderIcon = (originalName: string) => {
    const fileName = (originalName || '').toLowerCase();

    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return <WordFileIcon></WordFileIcon>;
    } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      return <PowerPointIcon></PowerPointIcon>;
    } else if (fileName.endsWith('.pdf')) {
      return <PdfFileIcon></PdfFileIcon>;
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return <ExelFileIcon></ExelFileIcon>;
    } else if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.gif') ||
      fileName.endsWith('.psd') ||
      fileName.endsWith('.ai')
    ) {
      return <ImageIcon></ImageIcon>;
    } else {
      return <UnknownFileIcon></UnknownFileIcon>;
    }
  };

  const [selectedIndexView, setSelectedIndexView] = React.useState(1);
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState({ data: [], flag: false });
  const index = [0, 0];

  useEffect(() => {
    if (file === null) return;
    if (file?.length <= 0) return;
    if (fileName.flag) return;
    for (let i = 0; i < file?.length; i++) {
      if (file?.at(i) === 'undefined') {
        file.splice(i, 1);
        i--;
        continue;
      }
      getNameFile({ id: file?.at(i) }).then((name) => {
        fileName.data.push(name);
        setFileName({ ...fileName });
      });
    }
  }, [file]);

  useEffect(() => {
    if (file === null) return;
    if (file?.length <= 0) return;
    if (fileName.flag) return;
    if (file?.length === fileName.data?.length) {
      fileName.flag = true;
      setFileName({ ...fileName });
    }
  }, [file, fileName]);

  const [dataSelect, setDataSelect] = React.useState({ data: ['Chọn hướng xử lý'], dataId: [''], vaitro: '' });

  useEffect(() => {
    if (dataTask === null || dataTask === undefined) return;
    if (auth.user === null) return;
    if (auth.users === null) return;
    if (units === null) return;
    if (auth.users?.length <= 0) return;

    if (dataTask?.files?.length > 0) {
      setFile(dataTask.files);
    }

    let vaitro = '';
    let dataT = ['Chọn hướng xử lý'];
    let dataId = [''];
    if (dataTask?.prosecutors && dataTask?.prosecutors?.find((id) => auth.user._id === id) != undefined) {
      vaitro = 'Nguyên đơn';
      dataT.push('Kiến nghị');
      dataId.push('remind');
      dataTask.pendingName = auth.user.firstname + ' ' + auth.user.lastname?.split(' ')[0];
      dataTask.pendingAvatar = auth.user.avatar;
    }
    if (
      dataTask?.status &&
      dataTask?.status != 'closed' &&
      dataTask?.status != 'cancelled' &&
      (dataTask?.undertakerId === auth.user._id ||
        units?.find((unit) => unit?._id === dataTask?.unitId)?.members?.find((id) => id === auth.user._id) != undefined)
    ) {
      vaitro = 'Nhóm phụ trách';
      if (auth.user.turf?.includes('local')) {
        dataT.push('Trình xin ý kiến');
        dataId.push('pact');
        dataT.push('Trình văn bản đi');
        dataId.push('lumen');
      }
      dataT.push('Đã xử lý xong');
      dataId.push('commit');
      dataT.push('Gia hạn');
      dataId.push('extend');
      dataTask.pendingName = auth.user.firstname + ' ' + auth.user.lastname?.split(' ')[0];
      dataTask.pendingAvatar = auth.user.avatar;
    }

    if (
      dataTask?.status &&
      dataTask?.status != 'closed' &&
      dataTask?.status != 'cancelled' &&
      cohorts?.length > 0 &&
      units?.length > 0 &&
      (cohorts?.find(
        (cohort) => cohort?._id === '_task_approvers_biz' && cohort?.members?.find((id) => id === auth.user._id) != undefined
      ) != undefined ||
        units?.find((unit) => unit?._id === 'DPM-0013' && unit?.members?.find((id) => id === auth.user._id) != undefined) != undefined)
    ) {
      if (vaitro === '') vaitro = 'Nhóm xét duyệt';
      dataT.push('Đóng nhiệm vụ');
      dataId.push('close');
      dataT.push('Hủy nhiêm vụ');
      dataId.push('cancel');
      let n = dataTask?.reactions?.length;
      if (n > 0 && dataTask?.reactions[n - 1]?.induction === 'commit') {
        dataT.push('Xác nhận');
        dataId.push('approve');
      }
      dataTask.pendingName = auth.user.firstname + ' ' + auth.user.lastname?.split(' ')[0];
      dataTask.pendingAvatar = auth.user.avatar;
    }

    dataSelect.vaitro = vaitro;
    dataSelect.data = dataT;
    dataSelect.dataId = dataId;
    setDataSelect({ ...dataSelect });

    if (dataTask?.deadline) {
      const deadline = new Date(dataTask.deadline);
      let ngay = deadline.getDay();
      let thu =
        ngay === 1
          ? 'Thứ hai, '
          : ngay === 2
          ? 'Thứ ba, '
          : ngay === 3
          ? 'Thứ tư, '
          : ngay === 4
          ? 'Thứ năm, '
          : ngay === 5
          ? 'Thứ sáu, '
          : ngay === 6
          ? 'Thứ bảy, '
          : ' Chủ nhật, ';
      let day = deadline.toLocaleString('es-CL', { day: '2-digit' });
      let month = deadline.toLocaleString('es-CL', { month: '2-digit' });
      let year = deadline.toLocaleString('es-CL', { year: 'numeric' });
      dataTask.deadlineTime = thu + day + ' tháng ' + month + ', ' + year;
    }

    if (dataTask?.createdAt) {
      const create = new Date(dataTask.createdAt);
      let ngay1 = create.getDay();
      let thu1 =
        ngay1 === 1
          ? 'Thứ hai, '
          : ngay1 === 2
          ? 'Thứ ba, '
          : ngay1 === 3
          ? 'Thứ tư, '
          : ngay1 === 4
          ? 'Thứ năm, '
          : ngay1 === 5
          ? 'Thứ sáu, '
          : ngay1 === 6
          ? 'Thứ bảy, '
          : ' Chủ nhật, ';
      let day1 = create.toLocaleString('es-CL', { day: '2-digit' });
      let month1 = create.toLocaleString('es-CL', { month: '2-digit' });
      let hour1 = create.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      dataTask.createTime = thu1 + day1 + ' tháng ' + month1 + ', ' + hour1;
    }

    if (dataTask?.modifiedAt != dataTask?.createdAt) {
      const modified = new Date(dataTask.modifiedAt);
      let ngay2 = modified.getDay();
      let thu2 =
        ngay2 === 1
          ? 'Thứ hai, '
          : ngay2 === 2
          ? 'Thứ ba, '
          : ngay2 === 3
          ? 'Thứ tư, '
          : ngay2 === 4
          ? 'Thứ năm, '
          : ngay2 === 5
          ? 'Thứ sáu, '
          : ngay2 === 6
          ? 'Thứ bảy, '
          : 'Chủ nhật, ';
      let day2 = modified.toLocaleString('es-CL', { day: '2-digit' });
      let month2 = modified.toLocaleString('es-CL', { month: '2-digit' });
      let hour2 = modified.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      dataTask.modifiedTime = thu2 + day2 + ' tháng ' + month2 + ', ' + hour2;
    }

    if (dataTask?.undertakerId) {
      let undertaker = auth.users?.find((user) => user._id === dataTask?.undertakerId);
      let undertakername = undertaker?.fullname;
      dataTask.undertakername = undertakername;
    }
    if (dataTask?.unitId) dataTask.unitname = units?.find((unit) => unit._id === dataTask?.unitId)?.shortName;

    if (dataTask?.originType)
      dataTask.typename =
        dataTask?.originType === 'Fuse'
          ? 'Xử lý văn bản'
          : dataTask?.originType === 'Task'
          ? 'Phân Công'
          : dataTask?.originType === 'Agenda'
          ? 'Chỉ đạo BGĐ'
          : '';

    if (dataTask?.directorId) {
      let director = auth.users?.find((user) => user._id === dataTask?.directorId);
      let directorname = director?.prefix + director?.fullname;

      dataTask.directorname =
        dataTask?.directorId === HostIDs.MrQuan
          ? 'GĐ. Vũ Hải Quân'
          : dataTask?.directorId === HostIDs.MrTam
          ? 'PGĐ. Nguyễn Minh Tâm'
          : directorname;
    }

    if (dataTask?.originType === 'Agenda' && dataTask?.originId != '') {
      getAgendaInfo(`${dataTask?.originId}`).then((data) => {
        if (data?.length <= 0) dataTask.showOrigin = false;
        else dataTask.showOrigin = true;
      });
    } else if (dataTask?.originType === 'Fuse' && dataTask?.originId != '') {
      getFuseInfo(`${dataTask?.originId}`).then((data) => {
        if (data?.length <= 0) dataTask.showOrigin = false;
        else dataTask.showOrigin = true;
      });
    } else {
      dataTask.showOrigin = true;
    }

    const data = dataTask?.reactions?.map(async (item, index, array) => {
      let user = auth.users?.find((user) => user._id === item.perpetratorId);
      item.name = user?.firstname + ' ' + user?.lastname.split(' ')[0];
      item.avatar = user?.avatar;

      const time = new Date(item.atTime);
      const month = time.toLocaleString('en-US', { month: '2-digit' });
      item.datetime =
        time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) + ', ' + time.getDate() + ' Tháng ' + month;

      if (item?.induction === 'issue') {
        item.type = dataTask?.originType === 'Task' ? 'Phân công' : dataTask?.originType === 'Agenda' ? 'Chỉ đạo' : 'Chuyển văn bản';
        item.typeColor = '#5b5fc7';
      } else if (item?.induction === 'submit') {
        item.type = 'Nộp hồ sơ';
        item.typeColor = '#0969da';
      } else if (item?.induction === 'commit') {
        item.type = 'Báo hoàn tất';
        item.typeColor = '#0969da';
      } else if (item?.induction === 'extend') {
        item.type = 'Gia hạn';
        item.typeColor = '#e17055';
      } else if (item?.induction === 'close') {
        item.type = 'Đóng nhiệm vụ';
        item.typeColor = '#0969da';
      } else if (item?.induction === 'cancel') {
        item.type = 'Hủy nhiệm vụ';
        item.typeColor = '#dc3545';
      } else if (item?.induction === 'remind') {
        item.type = 'Kiến nghị';
        item.typeColor = '#e84393';
      } else if (item?.induction === 'approve') {
        // item.type = 'Chuyển văn bản';
        // item.typeColor = '#5b5fc7';
        item.type = 'Xác nhận';
        item.typeColor = '#198754';
      }
      return item;
    });
    if (data?.length > 0) {
      Promise.all(data).then((newData) => {
        setDataTaskProcess(newData);
      });
    } else setIsShowProcess(true);
  }, [dataTask, auth, auth.users, units, cohorts]);

  useEffect(() => {
    if (dataTaskProcess === null) return;
    setIsShowProcess(true);
  }, [dataTaskProcess, cohorts, auth, auth.user, auth.users]);

  const downloadFile = async (id, filename) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf')) {
        newHref = '/filepreview.html?fileid=' + id;

      }

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [conclusion, setConclusion] = React.useState('');

  const AlertHandlerXL = () => {
    //function to make simple alert
    alert('Chưa chọn hướng xử lý');
  };
  const AlertHandlerWarning = () => {
    //function to make simple alert
    alert('Không thể tạo quy trình trên điện thoại');
  };

  const [waiting, setWaiting] = React.useState(false);

  const OnClick = async () => {
    let res;
    if (selectedIndex.row === 0) {
      AlertHandlerXL();
      return;
    }
    if (dataSelect.dataId[selectedIndex.row] === 'lumen' || dataSelect.dataId[selectedIndex.row] === 'pact') {
      AlertHandlerWarning();
      return;
    }
    setWaiting(true);

    res = await updateTaskInfo(specId.data, {
      induction: dataSelect.dataId[selectedIndex.row],
      statement: conclusion,
    });

    setConclusion('');
    setSelectedIndex(new IndexPath(0));
    setTimeout(() => setWaiting(false), 200);
    showWaiting();
    rootSpec.refetch();
  };

  return (
    <Layout style={styles.layout} nativeID="layoutPact">
      {isWaiting && (
        <View
          // @ts-ignore
          style={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80%',
            width: '100%',
            background: 'white',
            zIndex: 999,
          }}
        >
          <Spinner appearance="ioc" status="primary" />
        </View>
      )}
      {isShowProcess && (
        <Layout style={styles.maxLayout}>
          <ScrollView style={styles.maxLayout1} ref={refScrollView}>
            <View style={styles.maxLayout}>
              <View style={styles.layoutRow}>
                <Text
                  style={{
                    ...styles.textHeader,
                    fontWeight: pactName?.length > 80 ? '400' : '600',
                    fontSize: pactName?.length > 80 ? 15 : 16,
                  }}
                  category="s1"
                >
                  {pactName}
                </Text>
              </View>
              {
                <View style={{ paddingLeft: 13, paddingRight: 13, flexDirection: 'column' }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 4, paddingTop: 8 }}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Deadlines </Text>
                    </View>
                    <View style={styles.columnView}>
                      <Text>{' ' + dataTask?.deadlineTime}</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 4 }}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Phụ trách </Text>
                    </View>
                    <View style={styles.columnView}>
                      <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{' ' + dataTask?.undertakername}</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 4 }}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Đơn vị </Text>
                    </View>
                    <View style={styles.columnView}>
                      <Text>{' ' + dataTask?.unitname}</Text>
                    </View>
                  </View>
                  {file?.length > 0 && (
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 4 }}>
                      <View style={styles.columnView}>
                        {file?.map((itemF, indexF) => (
                          <View style={{ paddingTop: indexF != 0 ? 4 : 0, flexDirection: 'row', alignItems: 'center' }} key={indexF}>
                            <View style={{ minWidth: 73 }}>{indexF === 0 && <Text>DS Files </Text>}</View>
                            <Button
                              appearance="file-pact"
                              style={{
                                flexShrink: 1,
                                justifyContent: 'flex-start',
                                borderColor: '#dee2e6',
                                backgroundColor: '#FFFFFF',
                                borderRadius: 50,
                                marginVertical: 0,
                                marginLeft: 3,
                                marginRight: 0,
                              }}
                              size="small"
                              accessoryLeft={getFileOrFolderIcon(fileName.data?.at(indexF))}
                              onPress={() => {
                                downloadFile(itemF, fileName.data?.at(indexF));
                              }}
                            >
                              <Text numberOfLines={1} ellipsizeMode="tail">
                                {fileName.data?.at(indexF)}
                              </Text>
                            </Button>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              }
              <View style={{ height: '100%', width: '100%', flexShrink: 1 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginBottom: 0,
                    marginTop: 4,
                  }}
                >
                  <View
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderBottomColor: '#5b5fc7',
                      borderBottomWidth: selectedIndexView === 0 ? 2 : 0,

                      width: '50%',
                      flexShrink: 1,
                    }}
                  >
                    <Button
                      status="basic"
                      appearance="ghost-top-tab"
                      style={{}}
                      accessoryLeft={TasksIcon}
                      onPress={() => setSelectedIndexView(0)}
                    >
                      Thông tin nhiệm vụ
                    </Button>
                  </View>
                  <View
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderBottomColor: '#5b5fc7',

                      borderBottomWidth: selectedIndexView === 1 ? 2 : 0,
                      width: '50%',
                      flexShrink: 1,
                    }}
                  >
                    <Button
                      status="basic"
                      appearance="ghost-top-tab"
                      style={{}}
                      accessoryLeft={XLIcon}
                      onPress={() => setSelectedIndexView(1)}
                    >
                      Luồng xử lý
                    </Button>
                  </View>
                </View>

                {selectedIndexView === 0 && (
                  <View style={styles.columnmaxLayout}>
                    <View
                      style={{
                        paddingHorizontal: 13,
                        paddingTop: 10,
                        width: '100%',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        paddingBottom: 10,
                        borderBottomColor: '#dee2e6',
                        borderBottomWidth: 1,
                        borderTopColor: '#dee2e6',
                        borderTopWidth: 1,
                      }}
                    >
                      <View style={styles.dataRow}>
                        <Text>Chỉ đạo </Text>
                      </View>
                      <Text style={{}}>{dataTask?.directorname}</Text>
                    </View>
                    <View
                      style={{
                        paddingHorizontal: 13,
                        paddingTop: 10,
                        width: '100%',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        paddingBottom: 10,
                        borderBottomColor: '#dee2e6',
                        borderBottomWidth: 1,
                      }}
                    >
                      <View style={styles.dataRow}>
                        <Text>Loại </Text>
                      </View>
                      <Text style={{}}>{dataTask?.typename}</Text>
                    </View>
                    <View style={styles.view11}>
                      <View style={styles.dataRow}>
                        <Text>Ngày bắt đầu </Text>
                      </View>
                      <Text style={{}}>{dataTask?.createTime}</Text>
                    </View>
                    <View style={styles.view11}>
                      <View style={styles.dataRow}>
                        <Text>Ngày cập nhật </Text>
                      </View>
                      <Text style={{}}>{dataTask?.modifiedTime ? dataTask?.modifiedTime : '-'}</Text>
                    </View>
                    <View style={styles.view11}>
                      <View style={styles.dataRow}>
                        <Text>Bắt nguồn </Text>
                      </View>
                      {dataTask?.showOrigin && dataTask?.originType === 'Agenda' && dataTask?.originId != '' && (
                        <Text
                          style={{ fontWeight: '600' }}
                          onPress={() => {
                            sign.setRe({ id: location.pathname });
                            navigate(`/agendas/${dataTask?.originId}`);
                          }}
                        >
                          Link Meeting
                        </Text>
                      )}
                      {dataTask?.showOrigin && dataTask?.originType === 'Fuse' && dataTask?.originId != '' && (
                        <Text
                          style={{ fontWeight: '600' }}
                          onPress={() => {
                            sign.setRe({ id: location.pathname });
                            navigate(`/fuses/${dataTask?.originId}`);
                          }}
                        >
                          Link Quy trình
                        </Text>
                      )}
                      {(!dataTask?.showOrigin || dataTask?.originId === '') && <Text>-</Text>}
                    </View>
                    <View style={styles.view11}>
                      <View style={styles.dataRow}>
                        <Text>Phát sinh </Text>
                      </View>
                      {dataTask?.posterityType === 'Agenda' && dataTask?.posterityId != '' && (
                        <Text
                          style={{ fontWeight: '600' }}
                          onPress={() => {
                            sign.setRe({ id: location.pathname });
                            navigate(`/agendas/${dataTask?.posterityId}`);
                          }}
                        >
                          Link Meeting
                        </Text>
                      )}
                      {dataTask?.posterityType === 'Fuse' && dataTask?.posterityId != '' && (
                        <Text
                          style={{ fontWeight: '600' }}
                          onPress={() => {
                            sign.setRe({ id: location.pathname });
                            navigate(`/fuses/${dataTask?.posterityId}`);
                          }}
                        >
                          Link Quy trình
                        </Text>
                      )}
                      {dataTask?.posterityType === 'Pact' && dataTask?.posterityId != '' && (
                        <Text
                          style={{ fontWeight: '600' }}
                          onPress={() => {
                            sign.setRe({ id: location.pathname });
                            navigate(`/pacts/${dataTask?.posterityId}`);
                          }}
                        >
                          Link Quy trình
                        </Text>
                      )}
                      {dataTask?.posterityType === 'Lumen' && dataTask?.posterityId != '' && (
                        <Text
                          style={{ fontWeight: '600' }}
                          onPress={() => {
                            sign.setRe({ id: location.pathname });
                            navigate(`/lumens/${dataTask?.posterityId}`);
                          }}
                        >
                          Link Quy trình
                        </Text>
                      )}
                      {dataTask?.posterityId === '' && <Text>-</Text>}
                    </View>
                    <View style={styles.view11}>
                      <View style={styles.dataRow}>
                        <Text>Nội dung </Text>
                      </View>
                      <Text style={{}}>{dataTask?.description}</Text>
                    </View>
                    <View style={{ height: '100%', flexShrink: 1 }}></View>
                  </View>
                )}
                {selectedIndexView === 1 && (
                  <View style={styles.columnmaxLayout}>
                    <View style={{ maxHeight: '100%' }}>
                      <View style={styles.maxWidthnotShrink}>
                        <View style={{ width: '100%', flexDirection: 'column', alignItems: 'flex-start', paddingHorizontal: 13 }}>
                          {dataTaskProcess?.length > 0 &&
                            dataTaskProcess?.map((item, index) => (
                              <View
                                style={{
                                  width: '100%',
                                  paddingVertical: 5,
                                  paddingTop: index === 0 ? 10 : 5,
                                  paddingBottom: index === dataTaskProcess?.length - 1 ? 10 : 5,

                                  justifyContent: 'center',
                                }}
                                key={index}
                              >
                                <View
                                  style={{
                                    width: '100%',
                                    flexDirection: 'column',
                                    paddingHorizontal: 10,
                                    backgroundColor: '#FFFFFF',
                                    borderWidth: 1,
                                    borderColor: '#dee2e6',
                                    borderRadius: 10,
                                  }}
                                >
                                  <View style={{ flexDirection: 'row', paddingTop: 10, width: '100%' }}>
                                    <AvatarImage avatar={item.avatar} name={item.name}></AvatarImage>
                                    <View style={styles.columnPl5}>
                                      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                        <Text style={{ fontSize: 15, fontWeight: '700' }} category="s1">
                                          {item.name} <Text style={{ fontSize: 12, color: '#787777' }}> {item.datetime}</Text>
                                        </Text>
                                      </View>
                                      <View style={{ padding: 2 }}></View>
                                      <Text style={{ fontSize: 14, color: item.typeColor, fontWeight: '600' }}>{item.type}</Text>
                                    </View>
                                  </View>
                                  <View style={{ padding: 3.5 }}></View>
                                  <View style={{ flexDirection: 'row', width: '100%', paddingBottom: 10 }}>
                                    <Text style={{ marginLeft: 3.5 }}>
                                      {item.statement != '' ? item.statement : '(Không có diễn giải chi tiết)'}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            ))}
                          <View
                            style={{
                              width: '100%',
                              marginLeft: 10,
                              paddingBottom: 10,
                              paddingTop: dataTaskProcess?.length > 0 ? 0 : 10,
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Text>
                              {dataTask?.status === 'closed'
                                ? 'Đã hoàn tất...'
                                : dataTask?.status === 'cancelled'
                                ? 'Đã hủy...'
                                : `Chờ ${dataTask?.unitname} xử lý...`}
                            </Text>
                          </View>
                        </View>
                        {dataSelect?.data?.length > 1 && (
                          <View style={styles.maxWidthnotShrink}>
                            <View
                              style={{
                                flexDirection: 'row',
                                paddingHorizontal: 13,
                                paddingBottom: 10,
                                paddingTop: 3,
                                width: '100%',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <View style={{ flexDirection: 'column', paddingRight: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                                    {dataTask?.pendingName}
                                  </Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                  <Text style={{ fontSize: 15, color: '#787777' }}>{dataSelect.vaitro}</Text>
                                </View>
                              </View>
                              <AvatarImage avatar={dataTask?.pendingAvatar} name={dataTask?.pendingName}></AvatarImage>
                            </View>
                            <View style={{ width: '100%', paddingHorizontal: 13, flexDirection: 'row' }}>
                              <View style={{ width: '100%', flexShrink: 1 }}>
                                <Select
                                  appearance="ioc"
                                  style={{ backgroundColor: 'white' }}
                                  selectedIndex={selectedIndex}
                                  value={dataSelect.data[selectedIndex.row]}
                                  onSelect={(index) => setSelectedIndex(index)}
                                >
                                  {dataSelect.data?.map((item, indexF) => (
                                    <SelectItem appearance="ioc" title={item} key={indexF} />
                                  ))}
                                </Select>
                              </View>
                            </View>
                            <View style={{ width: '100%', padding: 13, flexDirection: 'row' }}>
                              <View style={{ width: '100%', flexShrink: 1 }}>
                                <Input
                                  style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                  placeholder="Diễn giải (nếu có)"
                                  placeholderTextColor={'#787777'}
                                  value={conclusion}
                                  onChangeText={(nextValue) => setConclusion(nextValue)}
                                />
                              </View>
                            </View>
                            <View
                              style={{
                                paddingHorizontal: 13,
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                              }}
                            >
                              {!waiting && (
                                <View style={{ paddingBottom: 10 }}>
                                  <Button
                                    style={{ backgroundColor: '#5B5FC7', borderColor: '#5B5FC7' }}
                                    size="tiny"
                                    onPress={() => {
                                      OnClick();
                                    }}
                                  >
                                    Thực hiện
                                  </Button>
                                </View>
                              )}
                              {waiting && (
                                <View
                                  style={{
                                    paddingHorizontal: 5,
                                    backgroundColor: '#5b5fc7',
                                    borderRadius: 3,
                                    flexDirection: 'row',
                                    marginBottom: 10,
                                  }}
                                >
                                  <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
                                  <Text style={{ color: '#FFFFFF', paddingVertical: 7, paddingHorizontal: 5, fontSize: 13 }}>
                                    Đang xử lý...
                                  </Text>
                                </View>
                              )}
                            </View>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </ScrollView>
        </Layout>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    color: '#424242',
    margin: 0,
    fontSize: 16,
    lineHeight: 22,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProcess: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCirle: {
    display: 'flex',
    fontSize: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutRow: {
    display: 'flex',
    paddingTop: 8,
    marginTop: 4,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 13,
    paddingRight: 13,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 36,
    paddingHorizontal: 8,
    flexShrink: 1,
    justifyContent: 'center',
    borderColor: '#dee2e6',
    borderRadius: 50,
  },
  dataRow: {
    minWidth: 110,
    lineHeight: 25,
    justifyContent: 'center',
  },
  maxLayout: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  headerFileView: {
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    paddingBottom: 5,
  },
  fileView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 0,
  },
  columnmaxWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  rowmaxWidthPb5: {
    flexDirection: 'row',
    paddingBottom: 5,
    width: '100%',
  },
  columnPl5: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 7,
  },
  rowCenterPt5: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
  },
  columnView: {
    flexDirection: 'column',
    flexShrink: 1,
  },
  columnmaxWidthStart: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flexShrink: 1,
  },
  width60maxHeight: {
    minWidth: 60,
    alignItems: 'flex-start',
    height: '100%',
  },
  Pv5Center: {
    paddingVertical: 10.5,
    alignItems: 'center',
  },
  maxHeightStart: {
    alignItems: 'flex-start',
    height: '100%',
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  rowmaxWidth: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
  columnmaxLayout: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexShrink: 1,
  },
  maxLayout1: {
    height: '100%',
    width: '100%',
  },
  paddingH5: {
    paddingHorizontal: 5,
  },
  alignStartStart: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  maxWidthnotShrink: {
    width: '100%',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowmaxWidthPv5: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  view11: {
    paddingHorizontal: 13,
    paddingTop: 10,
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: 10,
    borderBottomColor: '#dee2e6',
    borderBottomWidth: 1,
  },
  loadingIcon: {
    transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }],
  },
});
