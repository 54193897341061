import { Avatar, Button, Card, Layout, List, ListItem, Spinner, Text, Tooltip } from '@ui-kitten/components';
import React, { useContext, useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import IconF from 'react-native-vector-icons/FontAwesome';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { signContext } from '../../hooks/signingprocess';
import { getAgendaInfo, getTask } from '../../services/agendaprocess';
import { downloadFile as dlFile, getFile, getNameFile } from '../../services/files';
import { putSeen } from '../../services/signingprocess';
import { ExelFileIcon, ImageIcon, PdfFileIcon, PowerPointIcon, UnknownFileIcon, WordFileIcon } from '../../share/filter-icon';

import Svg, { Path } from 'react-native-svg';
import { getAvatarColor } from '../../utils/avatar';
import { canOpen, isPdf } from '../../utils/files';
import { isChromeAndroid } from '../../utils/browser';
import { getBaseApiUrl } from '../../config';

const NullIcon = (props) => (
  <Svg width={24} height={19} viewBox="0 0 576 512">
    <Path
      d="M320 32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H296.2c9.8 11.8 21 22.3 33.5 31.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V198.6c-11.2 3.2-21.9 7.4-32 12.6V64c0-17.7-14.3-32-32-32zM64 144c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm16 80H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H208c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zM432 480a112 112 0 1 0 0-224 112 112 0 1 0 0 224zm0-256a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm44.7 100.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7z"
      fill="#424242"
    />
  </Svg>
);

const GroupIcon = (props) => (
  <Svg width={15} height={12} viewBox="0 0 512 512">
    <Path
      d="M48 64V288C48 296.8 55.16 304 64 304H128V352H64C28.65 352 0 323.3 0 288V64C0 28.65 28.65 0 64 0H288C323.3 0 352 28.65 352 64V128H304V64C304 55.16 296.8 48 288 48H64C55.16 48 48 55.16 48 64zM208 384V448C208 456.8 215.2 464 224 464H448C456.8 464 464 456.8 464 448V224C464 215.2 456.8 208 448 208H384V160H448C483.3 160 512 188.7 512 224V448C512 483.3 483.3 512 448 512H224C188.7 512 160 483.3 160 448V384H208zM256 352V304H288C296.8 304 304 296.8 304 288H352C352 323.3 323.3 352 288 352H256zM352 256H304V208H288V160H352V256zM160 352V256H208V304H224V352H160zM224 160H256V208H224C215.2 208 208 215.2 208 224H160C160 188.7 188.7 160 224 160z"
      fill="#9ca59d"
    />
  </Svg>
);

const FAIcon = (props) => <Icon {...props} style={styles.iconProcess} />;

const FAI2con = (props) => <Icon2 {...props} style={styles.iconProcess1} />;

const WarningIcon = (props) => (
  <IconF
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}
    name="exclamation-circle"
    color={'#dc3545'}
  />
);

const SoonIcon = (props) => (
  <Icon2
    style={{ paddingLeft: 3, paddingRight: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}
    name="circle-half-full"
    color={'#e58e26'}
  />
);

const FlagIcon = (props) => (
  <View
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: 90,
      width: 8,
      height: 8,
      borderColor: props.check === 'true' ? '#524f4f' : '#BDBDBD',
      backgroundColor: props.check === 'true' ? '#524f4f' : '#FFFFFF',
    }}
  />
);

const AvatarImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" +  props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar) return <Avatar size={props.size ? props.size : 'tiny'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 24,
          width: 24,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Text style={{ color: '#ffffff', fontSize: 10, fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

const AvatarTDImage = (props) => {
  const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? (name?.length > 1 && name?.at(name?.length - 1)?.at(0) != undefined ? name?.at(name?.length - 1)?.at(0) : '') + name?.at(0)?.at(0)
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar) return <Avatar size={props.size ? props.size : 'small'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 32,
          width: 32,
          backgroundColor: getAvatarColor(imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Text style={{ color: '#ffffff', fontSize: 12 }}>{imageName}</Text>
      </View>
    );
};

export default (props) => {
  const navigate = useNavigate();

  const auth = useContext(authContext);
  const { units, cohorts } = useContext(authContext);

  const [isShowProcess, setIsShowProcess] = React.useState(false);
  const [specId, setSpecId] = React.useState({ flag: false, data: null });
  const state = useLocation();

  useEffect(() => {
    if (specId.flag) return;
    specId.data = state?.pathname?.slice(10, 50);
    specId.flag = true;
    if (state != null) setSpecId({ ...specId });
  }, [specId, specId.flag]);

  const getAgenda = async ({ queryKey }) => {
    if (!queryKey) return null;

    const [_, prodId] = queryKey;
    return await getAgendaInfo(prodId);
  };

  const rootSpec = useQuery(['agendainfo', specId.data], getAgenda);

  const sign = useContext(signContext);

  useEffect(() => {
    if (rootSpec.data === undefined || rootSpec.data === null) return;
    setDataAgenda(rootSpec.data?.at(0));
    setPactName(rootSpec.data?.at(0)?.name);
    if (rootSpec.data?.at(0)?.status === 'initial') {
      sign.setStatus({ color: '#198754', tag: 'Đang soạn' });
    } else if (rootSpec.data?.at(0)?.status === 'closed') {
      sign.setStatus({ color: '#575fcf', tag: 'Kết thúc' });
    } else if (rootSpec.data?.at(0)?.status === 'cancelled') {
      sign.setStatus({ color: '#636e72', tag: 'Đã hủy' });
    } else {
      sign.setStatus({ color: '#e84393', tag: 'Đang mở' });
    }
  }, [rootSpec.isFetched, rootSpec.isFetching]);
  const [dataAgenda, setDataAgenda] = React.useState(null);
  const [dataAgendaProcess, setDataAgendaProcess] = React.useState(null);
  const [pactName, setPactName] = React.useState('');
  var dictionaryFileName = new Map<string, string>();

  const getFileOrFolderIcon = (originalName: string) => {
    const fileName = (originalName || '').toLowerCase();

    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return <WordFileIcon></WordFileIcon>;
    } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      return <PowerPointIcon></PowerPointIcon>;
    } else if (fileName.endsWith('.pdf')) {
      return <PdfFileIcon></PdfFileIcon>;
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return <ExelFileIcon></ExelFileIcon>;
    } else if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.gif') ||
      fileName.endsWith('.psd') ||
      fileName.endsWith('.ai')
    ) {
      return <ImageIcon></ImageIcon>;
    } else {
      return <UnknownFileIcon></UnknownFileIcon>;
    }
  };

  const [selectedIndexView, setSelectedIndexView] = React.useState(0);

  useEffect(() => {
    if (dataAgenda === null) return;
    if (auth.user === null) return;
    if (auth.users === null) return;
    if (units === null) return;
    if (auth.users?.length <= 0) return;

    if (dataAgenda?.minutesFileId && dataAgenda.minutesFileId != '')
      getFile({ id: dataAgenda.minutesFileId }).then((file) => {
        if (file != undefined) dataAgenda.minutesFileName = { name: file?.name, time: file?.createdAt, size: file?.size };
        else dataAgenda.minutesFileId = '';
      });

    if (dataAgenda?.conclusionFileId != undefined && dataAgenda?.conclusionFileId != '')
      getFile({ id: dataAgenda.conclusionFileId }).then((file) => {
        if (file != undefined) dataAgenda.conclusionFileName = { name: file?.name, time: file?.createdAt, size: file?.size };
        else dataAgenda.conclusionFileId = '';
      });
    else if (dataAgenda?.conclusionFileIds != undefined && dataAgenda?.conclusionFileIds?.length > 0) {
      const temp = dataAgenda?.conclusionFileIds?.map(async (item, index) => {
        const file = await getFile({ id: item });
        if (file != undefined) return { id: item, name: file?.name, time: file?.createdAt, size: file?.size };
        return;
      });
      if (temp?.length > 0) {
        Promise.all(temp).then((newData) => (dataAgenda.conclusionFileIds = newData));
      }
    }
    if (dataAgenda?.time) {
      const time = new Date(dataAgenda?.time);

      let ngay = time.getDay();
      let thu =
        ngay === 1
          ? 'Thứ hai, '
          : ngay === 2
          ? 'Thứ ba, '
          : ngay === 3
          ? 'Thứ tư, '
          : ngay === 4
          ? 'Thứ năm, '
          : ngay === 5
          ? 'Thứ sáu, '
          : ngay === 6
          ? 'Thứ bảy, '
          : 'Chủ nhật, ';
      let day = time.toLocaleString('es-CL', { day: '2-digit' });
      let month = time.toLocaleString('es-CL', { month: '2-digit' });
      let year = time.toLocaleString('es-CL', { year: 'numeric' });
      dataAgenda.datetime = thu + day + ' tháng ' + month + ', ' + year;
    }
    if (dataAgenda?.chairId) {
      let chair = auth.users?.find((user) => user._id === dataAgenda?.chairId);
      let chairname = chair?.prefix + chair?.fullname;
      dataAgenda.chairname = chairname;
    }

    dataAgenda?.attendees?.map((item) => {
      let user = auth.users?.find((user) => user._id === item.userId);
      item.name = user?.fullname;
      item.avatar = user?.avatar;
      item.hostname = units.find((unit) => {
        if (item?.hostId != '') return unit._id === item.hostId;
        if (auth.user?.conscriptions?.length > 0) return auth.user?.conscriptions?.at(0)?.unitId === unit._id;
      })?.shortName;
      return item;
    });

    const data = dataAgenda?.sections
      ?.sort((itemA, itemB) => {
        return itemA?.time > itemB?.time ? 1 : -1;
      })
      ?.map(async (item, index, array) => {
        if (item.files?.length > 0) {
          let filename = [];
          for (let i = 0; i < item.files.length; i++) {
            if (dictionaryFileName.has(item.files.at(i))) {
              filename.push(dictionaryFileName.get(item.files.at(i)));
            } else {
              await getNameFile({ id: item.files.at(i) }).then((name) => {
                dictionaryFileName.set(item.files.at(i), name);
                filename.push(name);
              });
            }
          }

          item.fileName = filename;
        }
        if (item?.type === 'present') {
          let user = auth.users?.find((user) => user._id === item.speakerId);
          item.name = user?.fullname;
          item.avatar = user?.avatar;
          item.avatarName = user?.firstname + ' ' + user?.lastname?.split(' ')?.at(0);
          item.hostname = units.find((unit) => {
            if (item?.hostId != '') return unit._id === item.hostId;
            if (auth.user?.conscriptions?.length > 0) return auth.user?.conscriptions?.at(0)?.unitId === unit._id;
          })?.shortName;
        }
        for (let i = 0; i < item?.invitees?.length; i++) {
          let user = auth.users?.find((user) => user._id === item?.invitees?.at(i)?.userId);
          item.invitees[i].name = user?.firstname + ' ' + user?.lastname?.split(' ')[0];
          if (
            dataAgenda.seen.hasOwnProperty(item?.invitees?.at(i)?.userId) &&
            dataAgenda.seen[item?.invitees?.at(i)?.userId] > item?.createdAt
          ) {
            item.invitees[i].seen = true;
          }
        }

        const time = new Date(item.time);
        item.hour = time.toLocaleString('es-CL', { hour: '2-digit', minute: '2-digit' });
        return item;
      });
    if (data?.length > 0) {
      Promise.all(data).then((newData) => setDataAgendaProcess(newData));
    }
    if (dataAgenda?.attendees && dataAgenda?.sections?.length <= 0) {
      setIsShowProcess(true);
    }
  }, [dataAgenda, auth, auth.users, units]);

  useEffect(() => {
    if (dataAgendaProcess === null) return;
    setIsShowProcess(true);
  }, [dataAgendaProcess, cohorts, auth, auth.user, auth.users]);

  const downloadFile = async (id, filename) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf')) {
        newHref = '/filepreview.html?fileid=' + id;

      }

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const [visibles, setVisibles] = React.useState([false]);

  const [visible1s, setVisible1s] = React.useState([false]);

  const rootTask = useQuery(['tasks', specId.data], () => getTask('/?originId=' + specId.data));
  const [taskProcessed, setaskProcessed] = React.useState({ data: [], flag: false });

  useEffect(() => {
    const data = rootTask.data
      ?.sort((itemA, itemB) => {
        return itemA.deadline > itemB.deadline ? -1 : 1;
      })
      .map((item, index, array) => {
        const time = new Date();
        if (item?.status === 'cancelled') {
          item.tagSpec = 'Đã hủy';
          item.tagSpecColor = '#636e72';
        } else if (item?.status === 'closed') {
          item.tagSpec = 'Hoàn tất';
          item.tagSpecColor = '#575fcf';
        } else if (item?.status === 'open' && item?.deadline - time.getTime() > 0 && item?.deadline - time.getTime() < 86400000) {
          item.tagSpec = 'Sắp đến hạn';
          item.tagSpecColor = '#e17055';
        } else if (item?.status === 'open' && item?.deadline < time.getTime()) {
          item.tagSpec = 'Quá hạn';
          item.tagSpecColor = '#DC3545';
        } else if (item?.status === 'reminded' && item?.deadline - time.getTime() > 0 && item?.deadline - time.getTime() < 86400000) {
          item.tagSpec = 'Sắp đến hạn';
          item.tagSpecColor = '#e17055';
        } else if (item?.status === 'reminded' && item?.deadline < time.getTime()) {
          item.tagSpec = 'Quá hạn';
          item.tagSpecColor = '#DC3545';
        } else {
          item.tagSpec = 'Đang xử lý';
          item.tagSpecColor = '#198754';
        }

        if (item?.seen[auth.user._id] === undefined) {
          item.isSeen = false;
        } else {
          if (item?.modifiedBy === auth.user._id) {
            item.isSeen = true;
          } else if (item?.seen[auth.user._id] > item?.modifiedAt) {
            item.isSeen = true;
          } else item.isSeen = false;
        }

        const handle = auth.users?.find((user) => user._id === item?.undertakerId);
        const unit = units?.find((unit) => unit?._id === item?.unitId);

        const timeEnd = new Date(item.deadline);

        item.handle = item?.originType != 'Task' ? unit?.shortName : handle?.firstname + ' ' + handle?.lastname.split(' ')[0];

        const date = timeEnd.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
        item.date = date;
        return item;
      });

    taskProcessed.data = data;
    taskProcessed.flag = true;
    setaskProcessed({ ...taskProcessed });
  }, [rootTask.isFetched, rootTask.isFetching, auth, auth.user, auth.users, units, cohorts]);

  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        style={{
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onPress={() => {
          putSeen(item?._id, 'Task');
          navigate(`/tasks/${item?._id}`);
        }}
        key={index}
      >
        <View style={styles.layoutBorder}>
          <View style={styles.layoutColumnLeft50_2}>
            <Text
              style={{ fontSize: 14, fontWeight: item?.isSeen ? '400' : '600', color: item?.isSeen ? '#222' : '#424242' }}
              numberOfLines={1}
              category="s1"
            >
              {item.name != '' && item.name != null ? item.name : ''}
            </Text>
            <View style={{ padding: 3 }}></View>
            <Text style={{ color: '#667085', fontSize: 13 }} numberOfLines={1}>
              {item.handle != '-' && item.handle != null ? item.handle : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter20_1}>
            {item.tagSpec != 'Quá hạn' && item.tagSpec != 'Sắp đến hạn' && (
              <FlagIcon check={item.status === 'open' || item.status === 'reminded' ? 'true' : 'false'}></FlagIcon>
            )}
            {item.tagSpec === 'Quá hạn' && <WarningIcon></WarningIcon>}
            {item.tagSpec === 'Sắp đến hạn' && <SoonIcon></SoonIcon>}
          </View>
          <View style={styles.layoutCenter30}>
            <Text style={{ fontSize: 14 }}>{item.date != '' && item.date != null ? item.date : '01-01-1997'}</Text>
          </View>
        </View>
      </ListItem>
    );
  };

  const renderItemTBKL = ({ item, index }) => {
    const createAt = new Date(item?.time);
    const day = createAt.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
    let size = item?.size;
    let type = 'B';
    while (size > 1024 && type != 'M') {
      size = (size / 1024).toFixed(1);
      if (type === 'B') type = 'K';
      else type = 'M';
    }
    return (
      <ListItem
        style={{
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onPress={() => {
          downloadFile(item?.id, item?.name);
        }}
        key={index}
      >
        <View style={styles.layoutBorderFile}>
          <View style={styles.layoutColumnLeft50}>
            <Text style={{ fontSize: 14 }} numberOfLines={1}>
              {item?.name != '' && item?.name != null ? item.name : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter20}>
            <Text style={{ fontSize: 14 }} numberOfLines={1}>
              {item?.size != undefined && item?.size != null ? size + type : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter30}>
            <Text style={{ fontSize: 14 }} numberOfLines={1}>
              {item?.time != undefined && item?.time != null ? day : '01-01-1997'}
            </Text>
          </View>
        </View>
      </ListItem>
    );
  };

  const renderItemTBKL1 = ({ item, index }) => {
    const createAt = new Date(item?.time);
    const day = createAt.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
    let size = item?.size;
    let type = 'B';
    while (size > 1024 && type != 'M') {
      size = (size / 1024).toFixed(1);
      if (type === 'B') type = 'K';
      else type = 'M';
    }
    return (
      <ListItem
        style={{
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onPress={() => {
          downloadFile(item?.id, item?.name);
        }}
        key={index}
      >
        <View style={styles.layoutBorderFile}>
          <View style={styles.layoutColumnLeft50_1}>
            <Text style={{ fontSize: 14 }} numberOfLines={1}>
              {item?.name != '' && item?.name != null ? item.name : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter20}>
            <Text style={{ fontSize: 14 }} numberOfLines={1}>
              {item?.size != undefined && item?.size != null ? size + type : ''}
            </Text>
          </View>
          <View style={styles.layoutCenter30}>
            <Text style={{ fontSize: 14 }} numberOfLines={1}>
              {item?.time != undefined && item?.time != null ? day : '01-01-1997'}
            </Text>
          </View>
        </View>
      </ListItem>
    );
  };

  return (
    <Layout style={styles.layout} nativeID="layoutPact">
      {isShowProcess && (
        <Layout style={styles.maxLayout}>
          <View style={styles.maxLayout}>
            <ScrollView style={styles.maxLayout1}>
              <View style={styles.layoutRow}>
                <Text
                  style={{
                    ...styles.textHeader,
                    fontWeight: pactName?.length > 80 ? '400' : '600',
                    fontSize: pactName?.length > 80 ? 15 : 16,
                  }}
                  category="s1"
                >
                  {pactName}
                </Text>
              </View>
              {
                <View style={styles.view1}>
                  <View style={styles.view2}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Ngày họp </Text>
                    </View>
                    <View style={styles.columntView}>
                      <Text>{' ' + dataAgenda.datetime}</Text>
                    </View>
                  </View>
                  <View style={styles.rowCenterView}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Địa điểm </Text>
                    </View>
                    <View style={styles.columntView}>
                      <Text>
                        {!isNaN(dataAgenda.location) && !isNaN(parseFloat(dataAgenda.location))
                          ? ' Phòng ' + dataAgenda.location
                          : dataAgenda.location}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.rowCenterView}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Chủ trì </Text>
                    </View>
                    <View style={styles.columntView}>
                      <Text style={{ color: '#5b5fc7', fontWeight: '600' }}>{' ' + dataAgenda.chairname}</Text>
                    </View>
                  </View>
                  <View style={styles.rowCenterView}>
                    <View style={{ minWidth: 73 }}>
                      <Text>Tham dự </Text>
                    </View>
                    <View style={styles.rowView}>
                      {dataAgenda?.attendees?.map((item, index) =>
                        index < 5 || (index === 5 && dataAgenda?.attendees?.length === 6) ? (
                          <View style={styles.rownotShrinkView} key={index}>
                            <Tooltip
                              style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                              anchor={() => (
                                <Button
                                  style={{ borderRadius: 50, paddingRight: 0 }}
                                  size="tiny"
                                  appearance="file-pact"
                                  accessoryLeft={<AvatarTDImage avatar={item.avatar} name={item.name}></AvatarTDImage>}
                                  onPress={() => {
                                    visibles[index] = true;
                                    setVisibles([...visibles]);
                                  }}
                                ></Button>
                              )}
                              visible={visibles[index]}
                              onBackdropPress={() => {
                                visibles[index] = false;
                                setVisibles([...visibles]);
                              }}
                            >
                              <View style={styles.rownotShrinkView}>
                                <View key={index} style={styles.columnnotShrinkView}>
                                  <Text style={{ color: '#FFFFFF', marginBottom: 4, fontSize: 14 }}>{item?.name}</Text>
                                  <Text style={{ color: '#FFFFFF', fontSize: 14 }}>{item?.hostname}</Text>
                                </View>
                              </View>
                            </Tooltip>
                          </View>
                        ) : index === 5 ? (
                          <View style={styles.rownotShrinkView} key={index}>
                            <Tooltip
                              style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                              anchor={() => (
                                <Button
                                  style={{ borderRadius: 50, paddingRight: 0 }}
                                  size="tiny"
                                  appearance="file-pact"
                                  accessoryLeft={
                                    <AvatarTDImage avatar={item.avatar} name={`${dataAgenda?.attendees?.length - 5} +`}></AvatarTDImage>
                                  }
                                  onPress={() => {
                                    visibles[index] = true;
                                    setVisibles([...visibles]);
                                  }}
                                ></Button>
                              )}
                              visible={visibles[index]}
                              onBackdropPress={() => {
                                visibles[index] = false;
                                setVisibles([...visibles]);
                              }}
                            >
                              <View style={styles.rownotShrinkView}>
                                <View key={index} style={styles.columnnotShrinkView}>
                                  {dataAgenda?.attendees?.map(
                                    (item, index) =>
                                      index > 4 && (
                                        <Text
                                          style={{
                                            color: '#FFFFFF',
                                            marginBottom: index === dataAgenda?.attendees?.length - 1 ? 0 : 4,
                                            fontSize: 14,
                                          }}
                                          key={item?.name}
                                        >
                                          {item?.name}
                                        </Text>
                                      )
                                  )}
                                </View>
                              </View>
                            </Tooltip>
                          </View>
                        ) : null
                      )}
                    </View>
                  </View>
                </View>
              }

              <View style={styles.tabBarView}>
                <View
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderBottomColor: '#5b5fc7',
                    borderBottomWidth: selectedIndexView === 0 ? 2 : 0,

                    flexShrink: 1,
                  }}
                >
                  <Button status="basic" appearance="ghost-top-tab" style={{}} onPress={() => setSelectedIndexView(0)}>
                    Chương trình
                  </Button>
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderBottomColor: '#5b5fc7',
                    borderBottomWidth: selectedIndexView === 1 ? 2 : 0,

                    flexShrink: 1,
                  }}
                >
                  <Button status="basic" appearance="ghost-top-tab" style={{}} onPress={() => setSelectedIndexView(1)}>
                    Biên bản
                  </Button>
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderBottomColor: '#5b5fc7',
                    borderBottomWidth: selectedIndexView === 2 ? 2 : 0,

                    flexShrink: 1,
                  }}
                >
                  <Button status="basic" appearance="ghost-top-tab" style={{}} onPress={() => setSelectedIndexView(2)}>
                    Chỉ đạo
                  </Button>
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderBottomColor: '#5b5fc7',
                    borderBottomWidth: selectedIndexView === 3 ? 2 : 0,
                    flexShrink: 1,
                  }}
                >
                  <Button status="basic" appearance="ghost-top-tab" style={{}} onPress={() => setSelectedIndexView(3)}>
                    TB Kết luận
                  </Button>
                </View>
              </View>

              {selectedIndexView === 0 && dataAgendaProcess?.length > 0 && (
                <View style={styles.view3}>
                  <View style={styles.maxHeight}>
                    <View style={styles.maxWidth}>
                      <View style={styles.maxWidthColumLeft}>
                        {dataAgendaProcess?.map((item, index) => (
                          <View
                            style={{
                              width: '100%',
                              paddingTop: index === 0 ? 14 : 18,
                            }}
                            key={index}
                          >
                            {item?.type === 'present' && (
                              <View style={styles.viewE1}>
                                <View style={styles.viewE2}>
                                  <Text style={{ fontWeight: '600', minWidth: 42, fontSize: 14 }}>{item.hour}</Text>
                                  <View style={{ paddingRight: 10 }}></View>
                                  <View style={styles.view4}>
                                    <AvatarImage size={'tiny'} avatar={item.avatar} name={item.avatarName}></AvatarImage>
                                    <View style={{ flexDirection: 'column', padding: 5, paddingRight: 0 }}>
                                      <Text style={{ fontSize: 13, color: '#5b5fc7', fontWeight: '600' }}>{item.hostname}</Text>
                                      <Text style={{ fontSize: 13, color: '#787777' }}>{item.name}</Text>
                                    </View>
                                  </View>
                                </View>
                                {item?.invitees?.length > 0 && (
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      paddingTop: 7,
                                      paddingLeft: 54,
                                      alignItems: 'center',
                                      paddingRight: 18,
                                    }}
                                  >
                                    <GroupIcon></GroupIcon>
                                    <Text style={{ fontSize: 13, fontStyle: 'italic'  }}> Mời tham dự </Text>

                                    <Tooltip
                                      style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                                      anchor={() => (
                                        <Text
                                          style={{ color: '#e84393', fontSize: 13, fontStyle: 'italic', fontWeight: '600'  }}
                                          onPress={() => {
                                            visible1s[index] = true;
                                            setVisible1s([...visible1s]);
                                          }}
                                        >
                                          {item?.invitees?.length} cán bộ
                                        </Text>
                                      )}
                                      visible={visible1s[index]}
                                      onBackdropPress={() => {
                                        visible1s[index] = false;
                                        setVisible1s([...visible1s]);
                                      }}
                                    >
                                      <View style={styles.rownotShrinkView}>
                                        {item?.invitees?.map((itemG, indexG) => (
                                          <View key={indexG} style={styles.rownotShrinkView}>
                                            <Text
                                              style={{
                                                color: '#FFFFFF',
                                                marginBottom: indexG === item?.invitees?.length - 1 ? 0 : 4,
                                                fontSize: 14,
                                              }}
                                            >
                                              {itemG?.name}{' '}
                                              {itemG?.seen === true && <Text style={{ color: '#FFFFFF', fontSize: 14 }}> → Seen</Text>}
                                            </Text>
                                          </View>
                                        ))}
                                      </View>
                                    </Tooltip>
                                  </View>
                                )}
                                <View style={styles.paddingLeft86}>
                                  <Text style={{ marginLeft: 4, fontSize: 14, lineHeight: 20, textAlign: 'justify' }}>
                                    {item.description}
                                  </Text>
                                </View>
                                {item?.files?.length > 0 &&
                                  item?.files?.map((itemF, indexF) => (
                                    <View style={styles.paddingLeft79} key={indexF}>
                                      <View
                                        style={{
                                          paddingTop: item.fileName?.at(indexF) != undefined && indexF === 0 ? 5 : 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        {item.fileName?.at(indexF) != undefined && (
                                          <Button
                                            appearance="file-pact"
                                            style={{
                                              flexShrink: 1,
                                              justifyContent: 'flex-start',
                                              borderColor: '#e4e9f2',
                                              backgroundColor: '#FFFFFF',
                                              borderRadius: 50,
                                              marginVertical: 0,
                                              marginRight: 0,
                                              borderWidth: 1,
                                              maxWidth: '100%',
                                            }}
                                            size="small"
                                            accessoryLeft={getFileOrFolderIcon(item.fileName?.at(indexF))}
                                            onPress={() => {
                                              downloadFile(itemF, item.fileName?.at(indexF));
                                            }}
                                          >
                                            <Text numberOfLines={1} ellipsizeMode="tail">
                                              {item.fileName?.at(indexF)}
                                            </Text>
                                          </Button>
                                        )}
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            )}
                            {item?.type === 'mark' && (
                              <View style={styles.viewE1}>
                                <View style={styles.viewE2}>
                                  <Text style={{ fontWeight: '600', minWidth: 42, fontSize: 14 }}>{item.hour}</Text>
                                  <View style={{ paddingRight: 10 }}></View>

                                  <View style={styles.view5}>
                                    <FAIcon name="check" color="#FFFFFF"></FAIcon>
                                  </View>
                                </View>
                                <View style={styles.paddingLeft86}>
                                  <Text style={{ marginLeft: 4, fontSize: 14, lineHeight: 20, textAlign: 'justify' }}>
                                    {item.description}
                                  </Text>
                                </View>
                              </View>
                            )}
                            {item?.type === 'discuss' && (
                              <View style={styles.viewE1}>
                                <View style={styles.viewE2}>
                                  <Text style={{ fontWeight: '600', minWidth: 42, fontSize: 14 }}>{item.hour}</Text>
                                  <View style={{ paddingRight: 10 }}></View>

                                  <View style={styles.view6}>
                                    <FAI2con name="message-text-outline" color="#FFFFFF"></FAI2con>
                                  </View>
                                </View>
                                <View style={styles.paddingLeft86}>
                                  <Text style={{ marginLeft: 4, fontSize: 14, lineHeight: 20, textAlign: 'justify' }}>
                                    {item.description}
                                  </Text>
                                </View>
                              </View>
                            )}
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View style={{ height: '100%', flexShrink: 1 }}></View>
                </View>
              )}
              {selectedIndexView === 0 && (dataAgendaProcess?.length <= 0 || dataAgendaProcess === null) && (
                <View style={styles.view3}>
                  <Card style={{ borderWidth: 0 }}>
                    <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <NullIcon></NullIcon>
                    </View>
                    <View style={{ padding: 6 }}></View>
                    <Text style={{ textAlign: 'center' }}>Chưa có hạng mục trình bày</Text>
                  </Card>
                </View>
              )}
              {selectedIndexView === 1 && dataAgenda.minutesFileId != '' && (
                <View style={styles.layoutView}>
                  <View style={styles.maxHeight}>
                    <View style={styles.maxWidth}>
                      <View style={styles.maxWidthColumLeft}>
                        <List
                          style={{
                            maxHeight: '100%',
                            width: '100%',
                          }}
                          data={[
                            {
                              id: dataAgenda.minutesFileId,
                              name: dataAgenda?.minutesFileName?.name,
                              time: dataAgenda?.minutesFileName?.time,
                              size: dataAgenda?.minutesFileName?.size,
                            },
                          ]}
                          renderItem={renderItemTBKL}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              )}
              {selectedIndexView === 1 && dataAgenda.minutesFileId === '' && (
                <View style={styles.view3}>
                  <Card style={{ borderWidth: 0 }}>
                    <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <NullIcon></NullIcon>
                    </View>
                    <View style={{ padding: 6 }}></View>
                    <Text style={{ textAlign: 'center' }}>Chưa có biên bản</Text>
                  </Card>
                </View>
              )}
              {selectedIndexView === 2 && (
                <View style={styles.view3}>
                  {taskProcessed.flag && (
                    <View style={styles.maxHeight}>
                      {taskProcessed.data?.length > 0 && (
                        <View style={styles.maxHeight}>
                          <View style={styles.maxWidth}>
                            <View style={styles.maxWidthColumLeft}>
                              <List
                                style={{
                                  maxHeight: '100%',
                                  width: '100%',
                                }}
                                data={taskProcessed.data}
                                renderItem={renderItem}
                              />
                            </View>
                          </View>
                        </View>
                      )}
                      {taskProcessed.data?.length <= 0 && (
                        <Card style={{ borderWidth: 0 }}>
                          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <NullIcon></NullIcon>
                          </View>
                          <View style={{ padding: 6 }}></View>
                          <Text style={{ textAlign: 'center' }}>Chưa có tác vụ liên quan</Text>
                        </Card>
                      )}
                    </View>
                  )}
                </View>
              )}
              {selectedIndexView === 3 && (
                <View style={styles.layoutView}>
                  {((dataAgenda?.conclusionFileId != undefined && dataAgenda.conclusionFileId != '') ||
                    (dataAgenda.conclusionFileIds != undefined &&
                      dataAgenda.conclusionFileIds?.length > 0 &&
                      dataAgenda?.conclusionFileIds?.at(0) !== '')) && (
                    <View style={styles.maxHeight}>
                      <View style={styles.maxWidth}>
                        <View style={styles.maxWidthColumLeft}>
                          <List
                            style={{
                              maxHeight: '100%',
                              width: '100%',
                            }}
                            data={
                              dataAgenda?.conclusionFileName
                                ? [
                                    {
                                      id: dataAgenda.conclusionFileId,
                                      name: dataAgenda?.conclusionFileName?.name,
                                      time: dataAgenda?.conclusionFileName?.time,
                                      size: dataAgenda?.conclusionFileName?.size,
                                    },
                                  ]
                                : dataAgenda.conclusionFileIds
                            }
                            renderItem={renderItemTBKL1}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {((dataAgenda?.conclusionFileId != undefined && dataAgenda.conclusionFileId === '') ||
                    (dataAgenda.conclusionFileIds != undefined && dataAgenda.conclusionFileIds?.length <= 0) ||
                    (dataAgenda?.conclusionFileIds?.length === 1 && dataAgenda.conclusionFileIds?.at(0) === '')) && (
                    <Card style={{ borderWidth: 0 }}>
                      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <NullIcon></NullIcon>
                      </View>
                      <View style={{ padding: 6 }}></View>
                      <Text style={{ textAlign: 'center' }}>Chưa có thông báo kết luận</Text>
                    </Card>
                  )}
                </View>
              )}
            </ScrollView>
          </View>
        </Layout>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    color: '#424242',
    margin: 0,
    fontSize: 16,
    lineHeight: 22,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProcess: {
    display: 'flex',
    fontSize: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProcess1: {
    display: 'flex',
    fontSize: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCirle: {
    display: 'flex',
    fontSize: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutRow: {
    display: 'flex',
    paddingTop: 8,
    marginTop: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 13,
    paddingRight: 13,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutBorderFile: {
    display: 'flex',
    width: '100%',
    height: 46,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
    borderStyle: 'dotted',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 5,
  },
  layoutColumnLeft50_1: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 5,
  },
  layoutColumnLeft50_2: {
    display: 'flex',
    width: '100%',
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 5,
  },
  layoutCenter20: {
    minWidth: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter20_1: {
    display: 'flex',
    minWidth: 64,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    minWidth: 75,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 5,
    paddingRight: 8,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    width: 40,
    height: 36,
    paddingHorizontal: 8,
    flexShrink: 1,
    justifyContent: 'center',
    borderColor: '#dee2e6',
    borderRadius: 50,
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  maxLayout: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
  },
  view1: {
    paddingLeft: 13,
    paddingRight: 13,
    flexDirection: 'column',
  },
  view2: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingTop: 8,
  },
  columntView: {
    flexDirection: 'column',
    flexShrink: 1,
  },
  rowView: {
    flexDirection: 'row',
    flexShrink: 1,
  },
  rownotShrinkView: {
    flexDirection: 'row',
  },
  columnnotShrinkView: {
    flexDirection: 'column',
  },
  rowCenterView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  tabBarView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 3,
  },
  view3: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexShrink: 1,
    paddingBottom: 13,
  },
  maxHeight: {
    maxHeight: '100%',
  },
  maxLayout1: {
    height: '100%',
    width: '100%',
  },
  maxWidth: {
    width: '100%',
  },
  maxWidthColumLeft: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  viewE: {
    width: '100%',
    paddingVertical: 9,
  },
  viewE1: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 13,
    width: '100%',
    flexShrink: 1,
  },
  viewE2: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
  view4: {
    borderWidth: 1,
    borderColor: '#ced2d6',
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    minHeight: 30,
    paddingHorizontal: 9,
    flexDirection: 'row',
  },
  paddingLeft86: {
    paddingTop: 5,
    paddingLeft: 50,
    paddingRight: 18,
  },
  paddingLeft79: {
    paddingTop: 4,
    paddingRight: 18,
    paddingLeft: 52,
    maxWidth: '100%',
  },
  view5: {
    height: 26,
    width: 50,
    backgroundColor: '#5b5fc7',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  view6: {
    height: 26,
    width: 50,
    backgroundColor: '#0984e3',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  layoutView: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexShrink: 1,
    paddingHorizontal: 5,
    paddingBottom: 13,
  },
});
