import { createContext, useState } from 'react';

export const signContext = createContext<ISigningProcessContext>(null);

export interface ISigningProcessContext {
  setSpecInfo: (payload: { ispecId: string; pName: string; spName: string; indexSelect: number; tag: string; tagColor: string }) => void;
  setFlag: () => void;
  setNullIndex: () => void;
  setIIndex: (payload: { name: string; status: string }) => void;
  setRe: (payload: { id: string }) => void;
  setnullRe: () => void;
  setStatus: (payload: { color: string; tag: string }) => void;
  setSideBarState: (payload: {
    flag: boolean;
    pactNumber: number;
    lumenNumber: number;
    fuseNumber: number;
    dpactNumber: number;
    dlumenNumber: number;
    dfuseNumber: number;
  }) => void;
  specId: string;
  pactName: string;
  specName: string;
  index: number;
  chat: { name: string; status: string };
  flag: boolean;
  revert: { flag: boolean; id: string };
  status: { color: string; tag: string };
  sideBarState: {
    flag: boolean;
    pactNumber: number;
    lumenNumber: number;
    fuseNumber: number;
    dpactNumber: number;
    dlumenNumber: number;
    dfuseNumber: number;
  };
}

export const useProviderSigningProcess = () => {
  const [specId, setSpecId] = useState('');
  const [pactName, setPactName] = useState('');
  const [specName, setSpecName] = useState('');
  const [index, setIndex] = useState(null);
  const [chat, setChat] = useState({ name: '', status: '' });
  const [revert, setRevert] = useState({ flag: false, id: '' });
  const [flag, setflag] = useState(false);
  const [status, setstatus] = useState({ color: '', tag: '' });
  const [sideBarState, setsideBarState] = useState({
    flag: false,
    pactNumber: 0,
    lumenNumber: 0,
    fuseNumber: 0,
    dpactNumber: 0,
    dlumenNumber: 0,
    dfuseNumber: 0,
  });

  const setStatus = ({ color, tag }) => {
    setstatus({ color: color, tag: tag });
  };

  const setSpecInfo = ({ ispecId, pName, spName, indexSelect, tag, tagColor }) => {
    setSpecId(ispecId);
    setPactName(pName);
    setSpecName(spName);
    setIndex(indexSelect);
    setflag(true);
  };

  const setRe = ({ id }) => {
    revert.flag = true;
    revert.id = id;
    setRevert({ ...revert });
  };
  const setnullRe = () => {
    revert.flag = false;
    revert.id = '';
    setRevert({ ...revert });
  };

  const setSideBarState = ({ flag, pactNumber, lumenNumber, fuseNumber, dpactNumber, dlumenNumber, dfuseNumber }) => {
    if (sideBarState.flag) return;
    setsideBarState({ flag, pactNumber, lumenNumber, fuseNumber, dpactNumber, dlumenNumber, dfuseNumber });
  };

  const setFlag = () => {
    setflag(false);
  };

  const setNullIndex = () => {
    setIndex(null);
    setChat({ name: '', status: '' });
  };
  const setIIndex = ({ name, status }) => {
    setIndex(100);
    if (name != null) chat.name = name;
    chat.status = status;
    setChat({ ...chat });
  };

  return {
    sideBarState,
    setSideBarState,
    status,
    setStatus,
    setRe,
    revert,
    setnullRe,
    setIIndex,
    chat,
    setSpecInfo,
    setFlag,
    setNullIndex,
    specId,
    pactName,
    specName,
    index,
    flag,
  };
};

export const SigningProcessProvider = ({ children }) => {
  const sign = useProviderSigningProcess();

  return <signContext.Provider value={sign}>{children}</signContext.Provider>;
};
