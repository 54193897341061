import axios, { Method } from 'axios';
import { getBaseApiUrl } from '../config';
import { getAccessToken } from './authentication';

export const sendAuthenicatedRequest = async (url: string, method: Method, data: any, responseType='json') => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${getAccessToken()}`,
  };

  return await axios.request({
    url: `${getBaseApiUrl()}${'/ranni'}${url}`,
    method,
    data,
    headers,
    responseType: responseType as any,
  });
};


export const sendResetPasswordRequest = async (url: string, method: Method, data: any, responseType='json') => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  return await axios.request({
    url: `${getBaseApiUrl()}${'/ranni'}${url}`,
    method,
    data,
    headers,
    responseType: responseType as any,
  });
};

export const sendSignRequest = async (url: string, method: Method, data: any, responseType='json') => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  };

  return await axios.request({
    url: `${getBaseApiUrl()}${'/ranni'}${url}`,
    method,
    data,
    headers,
    responseType: responseType as any,
  });
};


export const sendSigningProcessRequest = async (url: string, method: Method, data: any, responseType='json') => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${getAccessToken()}`,
  };

  return await axios.request({
    url: `${getBaseApiUrl()}${'/renna'}${url}`,
    method,
    data,
    headers,
    responseType: responseType as any,
  });
};

export const sendSigningProcessRequestOrther = async (url: string, method: Method, data: any, responseType='json') => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  };

  return await axios.request({
    url: `${getBaseApiUrl()}${'/renna'}${url}`,
    method,
    data,
    headers,
    responseType: responseType as any,
  });
};
