import { Button, Input, Layout, Text } from '@ui-kitten/components';
import React, { useContext, useEffect, useRef } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-native';
import { getGoogleClientId } from '../../config';
import { authContext } from '../../hooks/authentication';
import { getUnits } from '../../services/signingprocess';
import { getAllUsers } from '../../services/authentication';

export const Login = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [forgotpass, setForgotPass] = React.useState(false);
  
  const [showLogin, setShowLogin] = React.useState(false);

  const auth = useContext(authContext);

  const location = useLocation();

  useEffect(() =>{
    if (location?.search?.slice(0, 13) != '?accessToken=') {
      setShowLogin(true);
      return;
    }
    const accessToken = location?.search?.split('?accessToken=')?.at(1)
    if (accessToken) {
      auth.signInWithApp({accessToken: accessToken});
      getAllUsers()
      .catch((error) => {
        navigate('/login');
        auth.signOut();
      });
    }

  },[location, auth])

  const loginMutation = useMutation((data: { username?: string; password?: string; ggToken?: string }) => {
    if (data.ggToken) {
      return auth.signInWithGoogleToken({ ggToken: data.ggToken });
    }

    return auth.signInWithUsernameAndPassword({
      username: data.username,
      password: data.password,
    });
  });

  const onClickLogin = async () => {
    return loginMutation.mutate({ username, password });
  };

  const forgotPassMutation = useMutation((data: { username?: string }) => {
    return auth.forgotPass({
      username: data.username,
    });
  });

  const onClickForgot = async () => {
    setWaiting(true);
    return forgotPassMutation.mutate({ username });
  };

  const loginGoogleButtonRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      google.accounts.id.initialize({
        client_id: getGoogleClientId(),
        callback: async (res) => {
          loginMutation.mutate({ ggToken: res.credential });
        },
      });

      google.accounts.id.renderButton(loginGoogleButtonRef.current, {
        type: 'standard',
        shape: 'pill',
        width: '350',
        logo_alignment: 'center',
        text: 'continue_with',
        theme: 'outline',
      });
    }, 1000);
  }, []);

  const AlertHandler = () => {
    //function to make simple alert
    alert('Email khôi phục mật khẩu đã được gửi đến hộp thư của bạn');
  };

  useEffect(() => {
    if (forgotPassMutation.isSuccess && forgotPassMutation.data.status === 'ok') {
      AlertHandler();
      setForgotPass(false);
      setWaiting(false);
    } else if (forgotPassMutation.isSuccess && forgotPassMutation.data.status === 'error') {
      setWaiting(false);
    }
  }, [forgotPassMutation.isSuccess, forgotPassMutation.data?.status]);

  const DS = ['DPM-0010', 'DPM-0011', 'DPM-0054', 'DPM-0012', 'DPM-0053'];

  useEffect(() => {
    if (auth.user?.accessToken === null || auth.user?.accessToken === undefined) return;
    if (auth.user?.accessToken && (auth.units === null || auth.units === undefined || auth.units?.length <= 0)) {
      getUnits().then((units) => auth.setUnits({ data: units }));

      return;
    }

    if (
      auth.units?.find(
        (unit) =>
          (unit?.turf === 'local' || DS?.find((id) => id === unit?._id) != undefined) && unit?.members?.find((id) => id === auth.user._id)
      ) != undefined
    ) {
      navigate('/pacts');
    } else if (auth.units?.find((unit) => unit?.turf === 'tether' && unit?.members?.find((id) => id === auth.user._id)) != undefined) {
      navigate('/elumens');
    } else if (
      auth.units?.find(
        (unit) => (unit?.turf === 'd-local' || unit?._id === 'DPM-0014') && unit?.members?.find((id) => id === auth.user._id)
      ) != undefined
    ) {
      navigate('/dpacts');
    } else if (auth.units?.find((unit) => unit?.turf === 'd-tether' && unit?.members?.find((id) => id === auth.user._id)) != undefined) {
      navigate('/edlumens');
    }
  }, [auth, auth.user, auth.units]);

  const [waiting, setWaiting] = React.useState(false);

    return (
      showLogin &&
      <Layout style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: '#01458E', fontSize: 35, fontWeight: '600' }}>IOC OFFICE</Text>
        </View>

        {!forgotpass && (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 20,
                width: '100%',
                justifyContent: 'center',
                paddingHorizontal: 50,
              }}
            >
              <Text style={{ textAlign: 'center', fontSize: 14 }}>Vui lòng đăng nhập bằng tài khoản được cấp để tiếp tục</Text>
            </View>
            {loginMutation.isSuccess && loginMutation.data.status === 'error' && (
              <Text style={styles.text} status="danger">
                {loginMutation.data.message}
              </Text>
            )}
            <Input style={styles.input} placeholder="Username" value={username} onChangeText={(nextValue) => setUsername(nextValue)} />
            <Input style={styles.input} placeholder="Password" secureTextEntry={true} onChangeText={(nextValue) => setPassword(nextValue)} />
            <View style={styles.rowRight}>
              <Text style={styles.textRight} onPress={() => setForgotPass(true)}>
                Quên mật khẩu IOC?
              </Text>
            </View>
            <Layout
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 15,
                paddingRight: 15,
                width: '100%',
              }}
            >
              <Button style={styles.button} onPress={() => onClickLogin()}>
                Đăng nhập
              </Button>
              <Text style={styles.text}>hoặc</Text>
              <View style={styles.loginGoogle}>
                <Button style={styles.buttonorther}>Đăng nhập với google</Button>
                <View nativeID="loginWithGoogle" ref={loginGoogleButtonRef} style={styles.loginGoogleButton}></View>
              </View>
            </Layout>
          </View>
        )}
        {forgotpass && (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 20,
                width: '100%',
                justifyContent: 'center',
                paddingHorizontal: 15,
              }}
            >
              <Text style={{ margin: 2, textAlign: 'center' }}>Nhập tên tài khoản để khôi phục mật khẩu</Text>
            </View>
            {forgotPassMutation.isSuccess && forgotPassMutation.data.status === 'error' && (
              <Text style={styles.text} status="danger">
                {forgotPassMutation.data.message}
              </Text>
            )}
            <Input style={styles.inputforgot} placeholder="Username" value={username} onChangeText={(nextValue) => setUsername(nextValue)} />
            {waiting && (
              <Layout
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 15,
                  paddingRight: 15,
                  width: '100%',
                  marginBottom: screen.height * 0.2,
                }}
              >
                <View
                  style={{
                    borderRadius: 48,
                    backgroundColor: '#8644f2',
                    alignItems: 'center',
                    height: 35,
                    justifyContent: 'center',
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
                  <Text style={{ color: '#FFFFFF', paddingVertical: 5, paddingLeft: 5 }}>Đang xử lý</Text>
                </View>
              </Layout>
            )}
            {!waiting && (
              <Layout
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 15,
                  paddingRight: 15,
                  width: '100%',
                  marginBottom: screen.height * 0.2,
                }}
              >
                <Button style={styles.button} onPress={() => onClickForgot()}>
                  Gửi yêu cầu
                </Button>
              </Layout>
            )}
          </View>
        )}
      </Layout>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '10%',
    height: 50,
    borderRadius: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
  },
  rowRight: {
    display: 'flex',
    width: '100%',
  },
  text: {
    margin: 2,
  },
  textRight: {
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 8,
    textAlign: 'right',
    color: '#636e72',
    fontWeight: 'bold',
    fontSize: 11,
    marginBottom: 5,
  },
  input: {
    paddingLeft: 15,
    paddingRight: 15,
    marginVertical: 4,
    width: '100%',
  },
  inputforgot: {
    paddingLeft: 15,
    paddingRight: 15,
    marginVertical: 4,
    paddingBottom: 10,
    width: '100%',
  },
  button: {
    marginVertical: 4,
    borderRadius: 48,
    maxHeight: 35,
    minHeight: 35,
    width: '100%',
    backgroundColor: '#5b5fc7',
    borderColor: '#5b5fc7',
  },
  buttonorther: {
    marginVertical: 4,
    maxHeight: 35,
    minHeight: 35,
    borderRadius: 48,
    width: '100%',
    backgroundColor: '#dc3545',
    borderColor: '#dc3545',
    marginBottom: screen.height * 0.2,
  },
  loginGoogle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginGoogleButton: {
    maxHeight: 35,
    minHeight: 35,
    marginBottom: screen.height * 0.2,
    position: 'absolute',
    opacity: 0,
  },
  iconProcess: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIcon: {
    transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }],
  },
});

export default Login;
