import Constants from 'expo-constants';
import './services/configService';

export const BASE_API_URL = Constants.expoConfig.extra.baseApiUrl;
export const BASE_URL = Constants.expoConfig.extra.baseUrl;
export const CHAT_WS_URL = Constants.expoConfig.extra.chatUrl;
export const GOOGLE_CLIENT_ID = Constants.expoConfig.extra.googleClientId;
export const BUILD_NUMBER = Constants.expoConfig.extra.buildNumber;


export const getBaseUrl = () => {
  return BASE_URL || window.backendConfig?.baseUrl;
}

export const getBaseApiUrl = () => {
  return BASE_API_URL || window.backendConfig?.baseApiUrl;
}

export const getChatWsUrl = () => {
  return CHAT_WS_URL || window.backendConfig?.chatUrl;
}

export const getGoogleClientId = () => {
  return GOOGLE_CLIENT_ID || window.backendConfig?.googleClientId;
}

export const getBuildNumber = () => {
  return BUILD_NUMBER || window.backendConfig?.buildNumber;
}
